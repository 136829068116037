import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail, LanguagesWords } from "../../utils/functions";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Earn from "../../assets/images/earn-money.png";
import Learn from "../../assets/images/learn_img.png";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import useToast from "../../hooks/useToast";
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillPlusCircle,
  AiFillQuestionCircle,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaHandPointRight, FaRegClock } from "react-icons/fa6";
import { LuClipboardList, LuRepeat } from "react-icons/lu";
import { Tooltip } from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { FatchApi } from "../../utils/FatchApi";

//

import {
  Get_Sou_Request,
  Get_WordArray_Request,
  LastUpdate_Request,
} from "../../lib/Redux/actions";
import Loader from "../../base-components/Loader/Loader";
import { LastUpdate_Update_Reducer } from "../../lib/Redux/slices/LastUpdate";

var SourceVal = JSON.parse(localStorage.getItem("source"));
var TargetVal = JSON.parse(localStorage.getItem("target"));

const sentences_lis = [
  {
    title: "Das Wetter ist gut heute.",
    tag: "classic",
    id: 1,
    done: 1,
    here: 0,
  },
  {
    title: "Das Wetter war sehr gut.",
    tag: "classic",
    id: 2,
    done: 1,
    here: 0,
  },
  {
    title: "Das Essen war sehr lecker.",
    tag: "slang",
    id: 3,
    done: 1,
    here: 0,
  },
  {
    title: "Das Fressen war sau lecker.",
    tag: "classic",
    id: 4,
    done: 1,
    here: 0,
  },
];

const source_text = [
  { title: "The", type: "" },
  { title: "weather", type: "" },
  { title: "is", type: "" },
  { title: "good", type: "" },
  { title: "today", type: "" },
];

const target_text = [
  { title: "Погода", type: "" },
  { title: "сегодня", type: "" },
  { title: "хорошая", type: "" },
];

function OnBoard() {
  const location = useLocation();
  const state = location.state;

  console.log(state, "state of latin here");

  const [pointsData, setPointsData] = useState(null);
  const [nativeDialects, setNativeDialects] = useState();

  const data = useSelector((state) => state);

  useEffect(() => {
    SourceVal = JSON.parse(localStorage.getItem("source"));
    TargetVal = JSON.parse(localStorage.getItem("target"));
  }, [SourceVal?.code, TargetVal?.code]);

  useEffect(() => {
    const fetchPointsData = async () => {
      try {
        const data = await FatchApi({
          request: "get",
          Api: "/users/get-user-points",
        });
        setPointsData(data?.nativeLanguage);
        setNativeDialects(data?.nativeDialect[0]);
        // console.log(data?.nativeDialect[0]?.name,'pointsss');
      } catch (error) {
        console.error("Error fetching points data:", error);
      }
    };

    fetchPointsData();
  }, []);

  console.log(state?.inputValue, "data of state");

  const Course = useSelector((state) => state.Course);
  const User = useSelector((state) => state.user);
  const Source = useSelector((state) => state.Source);
  const LastUpdate = useSelector((state) => state.LastUpdate);
  // const [isDialect, setIsDialect] = useState();
  const [play, setPlay] = useState(false);
  const { user } = useSelector((state) => state.user);
  const nativeLanguage = useSelector((state) => state.NativeLanguage);

  // console.log(LastUpdate.success, "lastupdate...");
  const isDialect = useSelector((state) => state.IsDialect);

  const LocalData = JSON.parse(localStorage.getItem("TargetData"));

  useEffect(() => {
    if (LastUpdate.success === true) {
      if (TargetRoute) {
        // console.log(LastUpdate,'dfdf target ');
        showToast(
          LastUpdate?.data?.message,
          LastUpdate?.data?.message ==
            "User has already contribute to this sentence"
            ? "danger"
            : "success"
        );
      } else {
        // console.log(LastUpdate,'dfdf sentance ');
        showToast("Sentence Added Successfully", "success");
      }
      setTimeout(() => {
        navigate("/earn");
        dispatch(LastUpdate_Update_Reducer());
        console.log("itz play() now");
        ////
        dispatch(
          Get_Sou_Request({
            user: user,
            request: "get",
            data: "",
            Api: isDialect.type
              ? `/api/v1/get-content-sentences-dia?courseId=${
                  Course.data[0]?._id
                }&nativeDialects=${
                  data?.languages?.data &&
                  data?.languages?.data?.languages[0]?.name
                }`
              : `/api/v1/get-content-sentences?courseId=${Course.data[0]?._id}&nativeLanguage=${TargetVal?.name}`,
          })
        );
        ////
      }, 2000);
    } else if (play === true) {
      showToast("Sentence Added Successfully", "success");
      navigate("/earn");
      // console.log("aaaaaaaaa sss ");
    } else {
      setLoading(false);
    }
  }, [play, LastUpdate.success]);

  const [TargetRoute, setTargetRoute] = useState(state?.TargetSentance);

  const showToast = useToast();

  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [heading, setHeading] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);

  const [classicChecked, setClassicChecked] = useState(true);
  const [slangChecked, setSlangChecked] = useState(false);
  const [insultChecked, setInsultChecked] = useState(false);

  const [sourcetext, setSourceText] = useState(
    TargetRoute
      ? Course?.data[0]?.spaces
        ? state?.inputValue &&
          state?.inputValue?.split(" ")?.map((word) => ({ title: word }))
        : state?.inputValue
      : Course?.data[0]?.spaces
      ? state?.inputValue &&
        state?.inputValue?.split(" ")?.map((word) => ({ title: word }))
      : state?.inputValue
  );

  // console.log(sourcetext,'source text...');

  const [targettext, setTargetText] = useState(
    state?.latin &&
      state?.latin?.split(" ")?.map((word) => ({ title: word, type: "" }))
  );

  const [sourceselected, setSourceSelected] = useState("");
  const [targetselected, setTargetSelected] = useState("");

  const [selected, setSelected] = useState([]);

  const dispatch = useDispatch();

  const handleClassicChange = () => {
    setClassicChecked(!classicChecked);
  };

  const handleSlangChange = () => {
    setSlangChecked(!slangChecked);
  };

  const handleInsultChange = () => {
    setInsultChecked(!insultChecked);
  };

  const show_popup = async (val) => {
    setHeading(val);
    setPopup(true);
  };

  const do_next_page = async () => {
    navigate("/step/three");
  };

  const do_source_click = async (v) => {
    setTargetSelected("");
    if (sourceselected === v.title) {
      setSourceSelected("");
    } else {
      setSourceSelected(v.title);
    }
  };

  const do_target_click = async (v) => {
    setSourceSelected("");
    if (targetselected == v.title) {
      setTargetSelected("");
    } else {
      setTargetSelected(v.title);
    }
  };

  const handleLangwords = (type) => {
    let selectedIndices = [];

    if (sourceselected) {
      selectedIndices =
        sourcetext?.length >= 1
          ? sourcetext.reduce((indices, item, index) => {
              if (item.title === sourceselected) indices.push(index);
              return indices;
            }, [])
          : [];
    } else {
      selectedIndices =
        targettext?.length >= 1
          ? targettext.reduce((indices, item, index) => {
              if (item.title === targetselected) indices.push(index);
              return indices;
            }, [])
          : [];
    }

    if (selectedIndices.length > 0) {
      if (sourceselected) {
        const updatedSourceText = [...sourcetext];
        selectedIndices.forEach((index) => {
          updatedSourceText[index].type = type;
        });
        setSourceText(updatedSourceText);
      } else {
        if (targettext) {
          const updatedTargetText = [...targettext];
          selectedIndices.forEach((index) => {
            updatedTargetText[index].type = type;
          });
          setTargetText(updatedTargetText);
        }
      }
      setSourceSelected("");
      setTargetSelected("");
    }
  };

  const handleFinsh = async () => {
    if (TargetRoute) {
      setLoading(true);
      const obj = {
        targetContentArray: sourcetext,
        targetContent: sourcetext.map((item) => item.title).join(" "),
        checkSentence: "Target Sentence",
        latinContent: state?.latin,
        latinContentArray: targettext,
        courseId: LocalData && LocalData?.courseId,
        languageSentence: LocalData && LocalData?.languageSentence,
        nativeLanguage: TargetVal && TargetVal?.name,
        sourceContent: LocalData && LocalData?.sourceContent,
        sourceContentArray: LocalData && LocalData?.sourceContentArray,
        status: LocalData && LocalData?.status,
        tag: LocalData && LocalData?.tag,
      };
      const SentenceId = Source?.data[0]?._id;

      // console.log(obj,'obj...');

      try {
        dispatch(
          LastUpdate_Request({
            user: User.user,
            request: "post",
            data: obj,
            Api: isDialect.type
              ? `/api/v1/create-sentences-dia`
              : `/api/v1/create-sentences`,
          })
        );
      } catch (err) {
        setLoading(false);
        console.error("Error updating sentence:", err);
      }
    } else {
      const obj = {
        sourceContentArray: sourcetext,
        sourceContent: sourcetext.map((item) => item.title).join(" "),
        status: true,
        languageSentence: isDialect.type ? false : true,
        courseId: Course.data[0]?._id,
        tag: state.checkbox,
        nativeLanguage: User?.user?.nativeLanguage?.name,
        nativeDialect: nativeDialects?.name,
      };

      localStorage.setItem("TargetData", JSON.stringify(obj));

      navigate("/add-sentence", { state: { TargetSentance: true } });

      // console.log(localdata,'localdat');

      // api for manage index or blur system

      // const data = await FatchApi({
      //   user: User.user,
      //   request: "post",
      //   data: obj,
      //   Api: isDialect.type
      //     ? `/api/v1/create-sentences-dia`
      //     : `/api/v1/create-sentences`,
      // });

      // console.log(data, "dfd");

      ////

      // if (data) {
      //   if (
      //     data.message ===
      //     "Sentence with the same source content already exists"
      //   ) {
      //     showToast(data.message, "error");
      //     setLoading(false);
      //   } else {
      //     setLoading(false);
      //     setPlay(true);
      //   }
      // }
    }
  };

  // useEffect(()=>{

  // },[])

  const handleNavi = () => {
    const obj = {
      targetContentArray: sourcetext,
      targetContent: sourcetext.map((item) => item.title).join(" "),
      checkSentence: "Target Sentence",
      latinContent: state?.latin,
      latinContentArray: targettext,
      courseId: LocalData && LocalData?.courseId,
      languageSentence: LocalData && LocalData?.languageSentence,
      nativeLanguage: LocalData && LocalData?.nativeLanguage,
      sourceContent: LocalData && LocalData?.sourceContent,
      sourceContentArray: LocalData && LocalData?.sourceContentArray,
      status: LocalData && LocalData?.status,
      tag: LocalData && LocalData?.tag,
    };

    navigate("/record/source/language", { state: obj });
  };

  const handleClearAll = () => {
    // console.log(state?.inputValue,'target...');

    // if (TargetRoute) {
    //   const newData = state?.inputValue?.map(({ type, ...rest }) => rest);
    //   console.log(newData,'newData...');
    //   setTargetText(newData);
    // } else {
    const newData = sourcetext?.map(({ type, ...rest }) => rest);
    setSourceText(newData);

    // console.log(sourcetext,'source...');

    // }
  };

  return (
    <>
      <div className="container">
        <HeaderLogin />
        <div className="wrapper">
          <div className="langugae_courses_heading">
            {/* //// */}
            <span>
              <span>{isDialect.type ? "" : <img src={SourceVal?.flag} />}</span>{" "}
              {SourceVal?.code}
            </span>
            {/* //// */}
            <AiOutlineArrowRight /> {/* //// */}
            <span>
              <span>
                {isDialect.type ? (
                  ""
                ) : (
                  <img src={TargetVal && TargetVal?.flag} />
                )}
              </span>{" "}
              {isDialect.type
                ? Course?.data[0]?.targetLanguage?.name
                : TargetVal && TargetVal?.code}{" "}
            </span>{" "}
            {/* //// */}
          </div>
          <div className="my_languages_wrap">
            <div className="box_left_add_ card">
              <div className="heading_languages mb-0">
                <div className="">Previous Sentences</div>
              </div>
              <div className="p10 previous_sentences">
                {Source.loading === false ? (
                  Source?.data?.map((v, i) => (
                    <>
                      <div className="senetence_">
                        <span>#{i + 1}</span>
                        <span style={{ wordBreak: 'break-word' }} >
                          {Course.data[0].spaces
                            ? v.sourceContent.split(" ").join("")
                            : v.sourceContent}
                        </span>
                      </div>
                    </>
                  ))
                ) : (
                  <Loader />
                )}
              </div>
            </div>

            <div className="box_left_add_">
              <div className="card p15 wd100 text-center">
                Please connect the types of words
              </div>
              <div className="card p20 mt-10px">
                {/* //// */}
                <div className="sentence_source flex-start pointer"  >
                  {
                    isDialect.type ? '' :
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src={ state?.TargetSentance ? SourceVal && SourceVal?.flag : TargetVal && TargetVal?.flag}
                    alt="User Language Flag"
                  />
                  }
                  {/* add sentence */}

                 <div  style={{maxWidth:'100%',display:'flex',flexWrap:'wrap',maxHeight:'200%',overflowY:'auto'}}  >
                  {sourcetext?.map((v, i) => (
                    <>
                      <span
                        //  style={{border:'solid 1px red'}}
                        className={`${v.type} ${
                          sourceselected == v.title ? "borderclass" : ""
                        }`}
                        onClick={() => do_source_click(v)}
                      >
                        {v.title}
                      </span>
                    </>
                  ))}
                 </div>

                  {
                    isDialect?.type ? '' :
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src={ state?.TargetSentance ? SourceVal && SourceVal?.flag : TargetVal && TargetVal?.flag}
                    alt="User Language Flag"
                  />
                  }

                </div>
                {/* //// */}
                {TargetRoute &&
                  (state?.latin ? (
                    <div
                      className="sentence_source flex-start mt-10px pointer"
                      style={{maxWidth:'100%',display:'flex',flexWrap:'wrap',maxHeight:'100%',overflowY:'auto'}} 
                    >
                      {state?.latin ? (
                        ""
                      ) : (
                        
                          isDialect?.type ? '' :
                        <img
                          style={{ height: "20px", width: "20px" }}
                          src={SourceVal && SourceVal?.flag}
                          alt="User Language Flag"
                        />
                        
                      )}
                      {targettext?.map((v, i) => (
                        <span
                          //  style={{border:'1px solid red'}}
                          key={i}
                          className={`${v.type} ${
                            targetselected === v.title ? "borderclass" : ""
                          }`}
                          onClick={() => do_target_click(v)}
                        >
                          {v.title}
                        </span>
                      ))}
                      {state?.latin ? (
                        ""
                      ) : (
                        isDialect?.type ? '' :
                        <img
                          style={{ height: "20px", width: "20px" }}
                          src={SourceVal && SourceVal?.flag}
                          alt="User Language Flag"
                        />
                      )}
                    </div>
                  ) : (
                    ""
                  ))}
                {/* //// */}
                <div
                  className="question_mark_"
                  data-tooltip-id="question_new_sentence"
                  data-tooltip-html="Please mark all the words in both sentences with one of the ten categories. <br /> Click on the word first and then on the applicable category beneath. You can find a definition<br /> of each word type by clicking on the words below. Leave a word unmarked if it doesn't<br /> fit any of these definitions. </br> You are free to consult an AI to find out which word categories the individual words correspond to, but you are ultimately responsible for what you submit. "
                >
                  <AiFillQuestionCircle />
                </div>
                <Tooltip
                  id="question_new_sentence"
                  style={{
                    textAlign: "center",
                    padding: "13px 10px",
                    fontSize: "13px",
                    zIndex: 9,
                  }}
                />
              </div>

              {/* //// grammer */}

              <div className="langword_bottom">
                {LanguagesWords().map((v, i) => (
                  <>
                    <div className="colum_custom_bototm">
                      <div
                        onClick={() => handleLangwords(v.type)}
                        key={"word_" + i}
                        className={v.type}
                      >
                        <div className={v.type}>{v.title}</div>
                      </div>
                      <Link
                        to={v.link}
                        target="_blank"
                        title="click to view defination"
                      >
                        <AiFillQuestionCircle size={11} color="orange" />
                      </Link>
                    </div>
                  </>
                ))}
                <p
                  onClick={handleClearAll}
                  style={{
                    marginLeft: "10px",
                    marginTop: "-5px",
                    fontSize: "14px",
                  }}
                >
                  Clear All
                </p>
              </div>

              {/* //// */}
            </div>

            <div className="box_left_add_ tags_button_next flex-space-between  p20">
              {/* <div className="tags_left">
                <label>
                  <input
                    type="checkbox"
                    checked={classicChecked}
                    onChange={handleClassicChange}
                  />
                  Classic
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={slangChecked}
                    onChange={handleSlangChange}
                  />
                  Slang
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={insultChecked}
                    onChange={handleInsultChange}
                  />
                  Insult
                </label>
              </div> */}

              <div className="button_tags">
                <button
                  className="button_custom_med secondary"
                  onClick={() => navigate(-1)}
                >
                  <AiOutlineArrowLeft /> Back{" "}
                </button>
                <button
                  className="button_custom_med primary mt-20px"
                  onClick={
                    isDialect.type && TargetRoute ? handleNavi : handleFinsh
                  }
                >
                  {isDialect.type && TargetRoute ? (
                    "Next"
                  ) : TargetRoute ? (
                    LastUpdate.loading ? (
                      <Loader />
                    ) : (
                      "Finish"
                    )
                  ) : loading ? (
                    <Loader />
                  ) : (
                    "Next"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OnBoard;
