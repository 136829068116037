import { message } from "antd";
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useToast = () => {
  const showToast = (message, type) => {
    if (type === "success") {
      toast.success(message);
    }
    if (type === "warning") {
      toast.warning(message);
    }
    if (type === "error") {
      toast.error(message);
    }
    if (type === "info") {
      toast.info(message);
    }
  };

  return showToast;
};

export default useToast;
