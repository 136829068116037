import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef, createRef, forwardRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Earn from "../../assets/images/earn-money.png";
import Learn from "../../assets/images/learn_img.png";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillEye,
  AiFillEyeInvisible,
  AiFillPlusCircle,
  AiFillQuestionCircle,
  AiOutlineArrowRight,
} from "react-icons/ai";
import {
  IoCloseCircleOutline,
  IoCopyOutline,
  IoHeartOutline,
  IoHeart,
  IoFlagOutline,
  IoFlag,
} from "react-icons/io5";
import {
  FaHandPointRight,
  FaRegClock,
  FaRegCirclePlay,
  FaRegCirclePause,
  FaCcPaypal,
  FaCreditCard,
} from "react-icons/fa6";
import { IoMdAttach } from "react-icons/io";

import { BiMessage } from "react-icons/bi";

import { LuClipboardList, LuRepeat } from "react-icons/lu";
import Source from "./../../assets/source.mp3";
import Target from "./../../assets/target.mp3";
import { Tooltip } from "react-tooltip";
import DepositPopup from "../../base-components/Deposit";
import { formatDateString } from "../../utils/DateFormate";
import Loader from "../../base-components/Loader/Loader";
import { useSelector } from "react-redux";
import { FatchApi } from "../../utils/FatchApi";

function OnBoard() {

 const withdrawRef = useRef(null) 

//  console.log(withdrawRef,'withdrawRef');

  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [heading, setHeading] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(true);
  const [spaces, setSpaces] = useState(true);
  const [play, setPlay] = useState(false);
  const [dictionary, setDictionary] = useState(false);
  const [selected, setSelected] = useState([]);
  const [audioCount, setAudioCount] = useState(0);
  const [audio, setAudio] = useState(null);
  const [isPlaying, setPlaying] = useState(false);
  const [countdown, setCountdown] = useState(false);
  const [count, setCount] = useState(5);
  const [heart, setHeart] = useState(false);
  const [tab, setTab] = useState(0);
  const [popupdeposit, setPopupDeposit] = useState(false);
  const [method, setMethod] = useState(0);
  const [recurring, setRecurrinh] = useState(0);
  const [popupwithdrawal, setPopupWithdrawal] = useState(false);

  const do_submit = async () => {
    toast.success("Withdrwal request processing started...");
    setPopupWithdrawal(false);
  };

  ////

  const [historyData, setHistoryData] = useState([]);
  const [loadinghistory, setLoadingHistory] = useState(false);

  const userPoints = useSelector((state) => state?.profileVerified?.data);
  console.log(userPoints?.userAmount, "points...");
  const User = useSelector((state) => state.user);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingHistory(true);
      try {
        const response = await FatchApi({
          request: "get",
          data: "",
          Api: "/users/user-withdraw-history",
        });
        console.log(response?.withdrawalHistory, "result... into veri");
        if (response) {
          // const result = await response.json();
          setLoadingHistory(false);
          setHistoryData(response?.withdrawalHistory);
        }
      } catch (err) {
        setLoadingHistory(false);
      }
    };

    fetchData();
  }, []);

  ////

  // const history = [
  //   {
  //     date: "2024-07-09T13:51:34.282Z",
  //     des: "description about Transaction here 1 .",
  //     amount: 20,
  //     status: false,
  //     Transaction_id: "1908768",
  //   },
  //   {
  //     date: "2024-07-09T13:51:34.282Z",
  //     des: "description about Transaction here 2 .",
  //     amount: 40,
  //     status: true,
  //     Transaction_id: "ssdf435",
  //   },
  //   {
  //     date: "2024-07-09T13:51:34.282Z",
  //     des: "description about Transaction here 3 .",
  //     amount: 260,
  //     status: true,
  //     Transaction_id: "43e5rt",
  //   },
  //   {
  //     date: "2024-07-09T13:51:34.282Z",
  //     des: "description about Transaction here 4 .",
  //     amount: 20,
  //     status: true,
  //     Transaction_id: "998yys",
  //   },
  //   {
  //     date: "2024-07-09T13:51:34.282Z",
  //     des: "description about Transaction here 5 .",
  //     amount: 70,
  //     status: false,
  //     Transaction_id: "33edtr",
  //   },
  //   {
  //     date: "2024-07-09T13:51:34.282Z",
  //     des: "description about Transaction here 6 .",
  //     amount: 40,
  //     status: true,
  //     Transaction_id: "sdcs33",
  //   },
  //   {
  //     date: "2024-07-09T13:51:34.282Z",
  //     des: "description about Transaction here 7 .",
  //     amount: 75,
  //     status: false,
  //     Transaction_id: "s3dw432",
  //   },
  //   {
  //     date: "2024-07-09T13:51:34.282Z",
  //     des: "description about Transaction here 8 .",
  //     amount: 55,
  //     status: true,
  //     Transaction_id: "sds344",
  //   },
  //   {
  //     date: "2024-07-09T13:51:34.282Z",
  //     des: "description about Transaction here 9 .",
  //     amount: 50,
  //     status: false,
  //     Transaction_id: "lkj83d",
  //   },
  //   {
  //     date: "2024-07-09T13:51:34.282Z",
  //     des: "description about Transaction here 10 .",
  //     amount: 70,
  //     status: true,
  //     Transaction_id: "llkoi4",
  //   },
  //   {
  //     date: "2024-07-09T13:51:34.282Z",
  //     des: "description about Transaction here 11 .",
  //     amount: 185,
  //     status: true,
  //     Transaction_id: "lohht3",
  //   },
  //   {
  //     date: "2024-07-09T13:51:34.282Z",
  //     des: "description about Transaction here 12 .",
  //     amount: 20,
  //     status: true,
  //     Transaction_id: "0lky34",
  //   },
  //   {
  //     date: "2024-07-09T13:51:34.282Z",
  //     des: "description about Transaction here 13 .",
  //     amount: 20,
  //     status: true,
  //     Transaction_id: "098762d",
  //   },
  //   {
  //     date: "2024-07-09T13:51:34.282Z",
  //     des: "description about Transaction here 14 .",
  //     amount: 70,
  //     status: false,
  //     Transaction_id: "sede555",
  //   },
  //   {
  //     date: "2024-07-09T13:51:34.282Z",
  //     des: "description about Transaction here 15 .",
  //     amount: 90,
  //     status: true,
  //     Transaction_id: "00998ee",
  //   },
  //   {
  //     date: "2024-07-09T13:51:34.282Z",
  //     des: "description about Transaction here 16 .",
  //     amount: 100,
  //     status: true,
  //     Transaction_id: "098ygty3a",
  //   },
  // ];

  // {
  //   date: "2024-07-09T13:51:34.282Z",
  //   des: "description about Transaction here 1 .",
  //   amount: 20,
  //   status: false,
  //   Transaction_id: "1908768",
  // },

  const TableInner = historyData?.map((item) => {
    const { amount, transactionId, status } = item;
    return (
      <>
        <tr>
          <td>{formatDateString(item?.createdAt)}</td>
          <td>{transactionId}</td>
          <td>£{amount}</td>
          <td>
            <div
              style={{
                backgroundColor: status === "Pending" ? "skyblue" : "green",
                color: "white",
                borderRadius: "20px",
                padding: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "62px",
              }}
            >
              {status}
            </div>
          </td>

          <td>{formatDateString(item?.updatedAt)}</td>
        </tr>
      </>
    );
  });

  const handleWindowOpen = ()=>{
    if(document){
     let x =  document.getElementById("newRefButton")
      x.click()
    }
   
  }

  return (
    <div className="container">
      <HeaderLogin  newRef={withdrawRef}  />
      <div className="wrapper">
        <div className="my_languages_wrap">
          <div className="verifiy_form w60">
            <div className="card wd100 p30 ">
              <p>Available Balance</p>
              <div className="amount_big">
                £ {userPoints && userPoints?.userAmount?.toFixed(2)} GBP
              </div>

              <div className="flex-space-between gap_45 transaction_amt">
                <div className="">
                  <p>Total Balance</p>
                  <div className="small_amount">
                    £ {userPoints && userPoints.userAmount?.toFixed(2)} GBP
                  </div>
                </div>
                <div className="">
                  <p>Withdrawal Pending</p>
                  <div className="small_amount">
                    £ {userPoints && userPoints?.pendingAmount} GBP
                  </div>
                  <div className="flex-space-between button_transactions" style={{position:'relative'}} >
                  <button
                    // eslint-disable-next-line no-undef
                    onClick={handleWindowOpen}
                    style={{position:'absolute',top:'22px'}}
                    className="button_custom_round"
                  >
                    Withdraw
                  </button>
              </div>
                </div>
              </div>
              <div className="flex-space-between button_transactions mt-20">
                <a href="https://www.patreon.com/Langbookproject">
                  <button
                    className="button_custom_round"
                    // onClick={() => setPopupDeposit(true)}
                    // onClick={<a href="" ></a>}
                  >
                    Donate
                  </button>
                </a>
                {/* <button
                  className="button_custom_round"
                  onClick={() => setPopupWithdrawal(true)}
                >
                  Withdraw
                </button> */}
              </div>
            </div>

            <div className="card wd100 p20 mt-20">
              <div className="tabs_wrap">
                <div
                  className={`tab_one ${tab == 1 ? "tab_one_hover" : ""}`}
                  onClick={() => setTab(1)}
                >
                  Transactions
                </div>
                {/* <div
                  className={`tab_one ${tab == 2 ? "tab_one_hover" : ""}`}
                  onClick={() => setTab(2)}
                >
                  Withdrawal History
                </div> */}
              </div>
              <div className="">
                <table className="table_transactions">
                  <tr>
                    <th>Request Date</th>
                    <th>Transaction ID</th>
                    <th>Amount</th>
                    <th>status</th>
                    <th>Approve Date</th>
                  </tr>
                  {loadinghistory ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginLeft: "170%",
                        marginTop: "17px",
                      }}
                    >
                      <Loader />
                    </div>
                  ) : (
                    TableInner
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {popupdeposit && <DepositPopup setPopupDeposit={setPopupDeposit} />}

      {popupwithdrawal && (
        <div className="outer_wrapper">
          <div className="inner_wrapper_bottom">
            <div className="white_wrapper wd100 ">
              <div className="heading_languages">
                <div className="">Withdrawing Money</div>
                <span
                  className="pointer"
                  onClick={() => setPopupWithdrawal(false)}
                >
                  <IoCloseCircleOutline />
                </span>
              </div>

              <div className="choose_lang_">
                <div className="withdrawal_info">
                  Withdrawals are processed on the 1st <br />
                  and 15th of every month.
                </div>
                <div className="flex-space-between-stretch wd100 deposit_withdrawal">
                  <div className="left_deposit_ w60">
                    <div className="">
                      Please enter the amount you would like to withdraw <br />
                      (Min: £30, Available Balance: £0,20).
                    </div>
                    <div className="input_deposit relative">
                      <input type="number" className="deposit_input" />
                      <div className="abs_symbol">GBP</div>
                    </div>

                    <div className="method_wrap">
                      <h4>Withdrawal method</h4>
                      <div className="methods_list">
                        <div
                          className={`w35 ${
                            method == 2 ? "method_box_hover" : ""
                          }`}
                        >
                          <input
                            type="email"
                            className="deposit_input wd100"
                            placeholder="Enter your paypal email address "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="left_deposit_ w40">
                    <h4>Restrictions</h4>
                    <p className="light_text_restriction">
                      Withdrawals are processed twice a month. If you
                      <br /> withdraw funds in any currency other than GBP, the{" "}
                      <br /> exchange rates on the day of processing will apply.
                    </p>
                  </div>
                </div>
              </div>

              <div className="footer_languages">
                <div className=""></div>
                <span onClick={() => do_submit()}>
                  <button className="button_custom_large_rount primary flex-space-between gap_10">
                    Submit
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OnBoard;
