import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { LanguagesWords, validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Earn from "../../assets/images/earn-money.png";
import Learn from "../../assets/images/learn_img.png";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import ReactAudioPlayer from "react-audio-player";
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillPlusCircle,
  AiOutlineArrowRight,
} from "react-icons/ai";
import {
  IoCloseCircleOutline,
  IoCopyOutline,
  IoHeartOutline,
  IoHeart,
  IoFlagOutline,
  IoFlag,
} from "react-icons/io5";
import {
  FaHandPointRight,
  FaRegClock,
  FaRegCirclePlay,
  FaRegCirclePause,
} from "react-icons/fa6";
import { BiMessage } from "react-icons/bi";

import { LuClipboardList, LuRepeat } from "react-icons/lu";
import Source from "./../../assets/source.mp3";
import Target from "./../../assets/target.mp3";
import { useDispatch, useSelector } from "react-redux";
import { LastUpdate_Request } from "../../lib/Redux/actions";
import useToast from "../../hooks/useToast";
import { LastUpdate_Update_Reducer } from "../../lib/Redux/slices/LastUpdate";
import { FatchApi } from "../../utils/FatchApi";

function OnBoard() {
  const isDialect = useSelector((state) => state.IsDialect);
  const course = useSelector((state) => state.Course);

  const audioPlayerRefSource = useRef(null);
  const audioPlayerRefTarget = useRef(null);
  const audioPlayerRefTarget2 = useRef(null);

  const showToast = useToast();

  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [heading, setHeading] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);
  const [spaces, setSpaces] = useState(true);
  const [play, setPlay] = useState(false);
  const [dictionary, setDictionary] = useState(false);
  const [selected, setSelected] = useState([]);
  const [audioCount, setAudioCount] = useState(0);
  const [audio, setAudio] = useState(null);
  // const [isPlaying, setPlaying] = useState(false);
  const [countdown, setCountdown] = useState(false);
  const [count, setCount] = useState(5);
  const [heart, setHeart] = useState(false);
  const [flag, setFlag] = useState(false);
  const [button, setbutton] = useState(false);
  const [issentence, setIssentence] = useState(false);
  const [isActualsentence, setActualsentence] = useState(false);
  const [sourceMode,setSourceMode]=useState(true);
  const [targetMode,setTargetMode]=useState(false);
  const [target2Mode,setTarget2Mode]=useState(false);

  const do_save_langs = async (val) => {
    const isValueInArray = selected.includes(val);
    if (isValueInArray) {
      const newArray = selected.filter((item) => item !== val);
      setSelected(newArray);
    } else {
      setSelected([...selected, val]);
    }
  };

  const sentence = [
    { title: "Do", type: "verbs" },
    { title: "you", type: "pronouns" },
    { title: "see", type: "verbs" },
    { title: "the", type: "articles" },
    { title: "men", type: "nouns" },
    { title: "over", type: "prepositions" },
    { title: "there", type: "adverb" },
  ];

  const actual_sentence = "Siehst du die Männer dort drüben";
  const sentence_target = [
    { title: "Siehst", type: "verbs" },
    { title: "du", type: "pronouns" },
    { title: "die", type: "verbs" },
    { title: "Männer", type: "nouns" },
    { title: "dort", type: "adverb" },
    { title: "drüben", type: "adverb" },
  ];

  const reasonsflag = [
    { title: "mistake in the source language audio", id: 1 },
    { title: "mistake in the target language audio", id: 2 },
    { title: "mistake in the source language", id: 3 },
    { title: "mistake in the target language", id: 4 },
    { title: "words  marked incorrectly", id: 5 },
    { title: "wrong tag (classic, slang, insult)", id: 6 },
    { title: "inappropriate sentence", id: 7 },
    { title: "other", id: 8 },
  ];

  const [isPlaying, setIsPlaying] = useState(false);
  const [InputVal, setInputVal] = useState("");
  const [RadioVal, setRadioVal] = useState("");

  // const [isDialect, setIsDialect] = useState();

  const AllAvilable = useSelector((state) => state.AllAvilable);
  const LastUpdate = useSelector((state) => state.LastUpdate);
  const { user } = useSelector((state) => state.user);

  // console.log(AllAvilable?.data[0]?.latinContentArray    ,'All Avilable...');

  const dispatch = useDispatch();

  const SourceCode =
    AllAvilable?.data &&
    AllAvilable?.data[0]?.courseId?.sourceLanguage?.code.toLowerCase();
  const TargetCode =
    AllAvilable?.data &&
    AllAvilable?.data[0]?.courseId?.targetLanguage?.code.toLowerCase();

  const urldictionary = `https://glosbe.com/${SourceCode && SourceCode}/${
    TargetCode && TargetCode
  }/`;
  const urldictionarytarget = `https://glosbe.com/${TargetCode && TargetCode}/${
    SourceCode && SourceCode
  }/`;

  ///////////////

  ///////////////

  const playSource = async () => {
    setSourceMode(true)
    setTargetMode(false)
    setTarget2Mode(false)
    setIssentence(true);
    const SourceEle = audioPlayerRefSource?.current?.audioEl?.current;
    await SourceEle.play();
    setPlay(true);
    // console.log('after paly S ');
  };
  
  const handleAudioEndSource = async () => {
    setSourceMode(false)
    setTargetMode(true)
    setTarget2Mode(false)
    setIssentence(false);
    setPlay(false);
    setTimeout(()=>{
      setPlay(true)
      setActualsentence(true);
      const TargetEle = audioPlayerRefTarget?.current?.audioEl?.current;
      TargetEle.play();
      // console.log('after paly t ');
    },2000)
  };
  
  const handleAudioEndTarget = async () =>{
    setSourceMode(false)
    setTargetMode(false)
    setTarget2Mode(true)
    setPlay(false)
    setTimeout(()=>{
      setPlay(true)
      setActualsentence(true);
      const TargetEle2 = audioPlayerRefTarget2?.current?.audioEl?.current;
      TargetEle2?.play();
      // console.log('after paly t2 ');
    },2000)
  }

  const handleAudioEndTarget2 = async () =>{
    setSourceMode(true)
    setTargetMode(false)
    setTarget2Mode(false)
    setActualsentence(false)
    setPlay(false)
    setCountdown(true)

  }

  const playAudioSource = async () => {
    try {
      if(sourceMode){
        await playSource();  
      }
      else if (targetMode){
       await handleAudioEndSource()
      }
      else if (target2Mode){
        await handleAudioEndTarget()
      }
    } catch (err) {
      console.error('Error playing audio:', err);
    }
  };

  const pauseAudioSource = async () => {
    setPlay(false);
    try {
      if(sourceMode){
        const SourceEle = audioPlayerRefSource?.current?.audioEl?.current;
        SourceEle.pause()
      }
      else if (targetMode){
        const TargetEle = audioPlayerRefTarget?.current?.audioEl?.current;
        TargetEle.pause()
      }
      else if (target2Mode){
        const Target2Ele = audioPlayerRefTarget2?.current?.audioEl?.current;
        Target2Ele.pause()
      }
    } catch (err) {
      console.error('Error playing audio:', err);
    }
  }

  const handleAudioEnded = () => {
    setPlay(false);
    setCountdown(true);
    setIsPlaying(false);
    setIssentence(false);
    setActualsentence(false);
  };

  const pauseAudio = () => {
    setIsPlaying(false);

    // if (audio) {
    //   audio.pause();
    // }
  };

  const copyText = async (val) => {
    navigator.clipboard.writeText(val);
    toast.success("Target sentence copied successfully!");
  };

  useEffect(() => {
    if (LastUpdate.success) {
      showToast("Proceed Successfully", "success");
      setTimeout(() => {
        navigate("/earn");
        dispatch(LastUpdate_Update_Reducer());
      }, 2000);
    }
  }, [LastUpdate.success]);

  const handleProceed = async () => {
    navigate("/check-sentance", { state: AllAvilable?.data[0] });
  };

  // const showToast = useToast();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleSubmit = () => {
    const SentenceId = AllAvilable?.data[0]?._id;

    const date = new Date();
    const currentDate = date.getDate();
    const currentMonth = date.getMonth();
    const currentYear = date.getFullYear();

    const monthName = monthNames[currentMonth];

    const obj = {
      isFinalCheck: false,
      isReported: true,
      checkSentence: "Flag Report",
      isLearner: true,
      reportFlag: {
        earnerSide: true,
        title: RadioVal,
        comment: InputVal,
        user: user,
        reportedDate: {
          data: currentDate,
          month: monthName,
          year: currentYear,
        },
      },
    };

    try {
      dispatch(
        LastUpdate_Request({
          // user: user.user,
          request: "put",
          data: obj,
          Api: isDialect.type
            ? `/api/v1/sentences-dia/${SentenceId} `
            : `/api/v1/sentences/${SentenceId}`,
        })
      );
    } catch (err) {
      console.error("Error updating sentence:", err);
    }
  };

  const [IsHeartBack, setIsHeartBack] = useState(true);

  useEffect(() => {
    const handleHeartGet = async () => {
      try {
        const sentenceId = AllAvilable?.data[0]?._id;

        const data = await FatchApi({
          request: "get",
          data: "",
          Api: isDialect.type
            ? `/api/v1/get-sentence-like-dia?sentenceId=${sentenceId}`
            : `/api/v1/get-sentence-like?sentenceId=${sentenceId}`,
        });

        if (data) {
          const finddata = data?.userLikes?.filter(
            (item) => item === user.userId
          );
          console.log(finddata, "finddata", user.userId);
          if (finddata && finddata.length > 0) {
            setIsHeartBack(true);
            console.log(IsHeartBack, "is heart back");
          } else {
            setIsHeartBack(false);
          }
        }
      } catch (error) {
        console.error("Error fetching sentence likes:", error);
      }
    };

    handleHeartGet();
  }, []);

  const handleHeart = async () => {
    console.log("playS");

    setHeart(!heart);

    var obj = {
      sentenceId: AllAvilable?.data[0]?._id,
      userId: user.userId,
    };

    const data = await FatchApi({
      request: "post",
      data: obj,
      Api: isDialect.type
        ? `/api/v1/sentence-like-dia`
        : `/api/v1/sentence-like`,
    });
    if (data) {
      showToast(data?.message, "success");
    }
  };

  return (
    <div className="container">
      <HeaderLogin />
      <div className="wrapper">
        <div className="my_languages_wrap" style={{ flexDirection: "column" }}>
          <div className="card wd100 p20">
            <div className="links_top_sentence flex-space-between">
              <div className="left_sentence_pills">
                <span className="pill_circle">{AllAvilable?.data[0]?.tag}</span>
                {/* <span className="pill_circle">Slang</span>  */}
              </div>

              <div className="left_sentence_pills">
                <span
                  className={`pill_circle pointer ${
                    dictionary ? "primary" : ""
                  }`}
                  onClick={() => setDictionary(!dictionary)}
                >
                  Link to the dictionary
                </span>
              </div>
            </div>

            <div className="sentence_wrapper">
              <div
                className="play_icons pointer"
                // onClick={() => setPlay(!play)}
              >
                {!play ? (
                  <FaRegCirclePlay
                    size={60}
                    color="var(--primary-color)"
                    onClick={playAudioSource}
                  />
                ) : (
                  <FaRegCirclePause
                    size={60}
                    color="var(--primary-color)"
                    onClick={pauseAudioSource}
                  />
                )}
              </div>

              <div className="right_sentences_data">
                <div
                  style={{ overflowX: "auto" }}
                  className={`sentence_data ${
                    course?.data[0]?.spaces ? "" : "gap_10"
                  } ${issentence ? "flashing-div" : ""}`}
                >
                  <>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        overflowX: "auto",
                        marginLeft: course?.data[0]?.spaces ? '':'10px',
                      }}
                      className={`${course?.data[0]?.spaces ? "" : "gap_10"}`}
                    >
                      {course?.data[0]?.spaces
                        ? AllAvilable?.data[0]?.targetContentArray?.map(
                            (items) => {
                              const cleanedTitle = items?.title.replace(
                                /[,\.;\]\:\'`\-=+\\|]/g,
                                ""
                              );
                              return (
                                <>
                                  {dictionary ? (
                                    <a
                                      target="_blank"
                                      href={urldictionary + cleanedTitle}
                                      className={items?.type}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {items?.title}
                                    </a>
                                  ) : (
                                    <div
                                      className={items?.type}
                                      // style={{ cursor: "pointer" }}
                                    >
                                      {items?.title}
                                    </div>
                                  )}
                                </>
                              );
                            }
                          )
                        : AllAvilable?.data[0]?.targetContentArray?.map(
                            (items) => {
                              const cleanedTitle = items?.title.replace(
                                /[,\.;\]\:\'`\-=+\\|]/g,
                                ""
                              );
                              return (
                                <>
                                  {dictionary ? (
                                    <a
                                      target="_blank"
                                      href={urldictionary + cleanedTitle}
                                      className={items?.type}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {items?.title}
                                    </a>
                                  ) : (
                                    <div
                                      className={items?.type}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {items?.title}
                                    </div>
                                  )}
                                </>
                              );
                            }
                          )}
                    </div>
                  </>
                </div>
                <div
                  className={`sentence_data ${
                    course?.data[0]?.spaces ? "" : "gap_10"
                  } ${isActualsentence ? "flashing-div" : ""}`}
                  style={{
                    minHeight: "105px",
                    height: "max-content",
                    maxHeight: "160px",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    // border:'solid 1px red',
                  }}
                >
                  <>
                    {/* //////////// Target Content //////////// */}
                    <div
                      style={{
                        width: "100%",
                        height: "80%",
                        display: "flex",
                        overflowX: "auto",
                        // border:'solid 1px blue',
                      }}
                      className={`${course?.data[0]?.spaces ? "" : "gap_10"}`}
                    >
                      {course?.data[0]?.spaces ? (
                        AllAvilable?.data[0]?.sourceContentArray?.map(
                          (items) => {
                            const cleanedTitle = items?.title.replace(
                              /[,\.;\]\:\'`\-=+\\|]/g,
                              ""
                            );
                            return (
                              <React.Fragment key={items.title}>
                                {dictionary ? (
                                  <a
                                    target="_blank"
                                    href={urldictionarytarget + cleanedTitle}
                                    className={items.type}
                                    style={{
                                      cursor: "pointer",
                                      // marginLeft: "6px",
                                      whiteSpace: "nowrap", // Prevents breaking within words
                                    }}
                                  >
                                    {items.title}
                                  </a>
                                ) : (
                                  <div
                                    className={items.type}
                                    style={{
                                      cursor: "pointer",
                                      // marginLeft: "6px",
                                      whiteSpace: "nowrap", // Prevents breaking within words
                                    }}
                                  >
                                    {items.title}
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          }
                        )
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          {AllAvilable?.data[0]?.sourceContentArray?.map(
                            (items) => {
                              const cleanedTitle = items?.title.replace(
                                /[,\.;\]\:\'`\-=+\\|]/g,
                                ""
                              );
                              return (
                                <React.Fragment key={items.title}>
                                  {dictionary ? (
                                    <a
                                      target="_blank"
                                      href={urldictionarytarget + cleanedTitle}
                                      className={items.type}
                                      style={{
                                        cursor: "pointer",
                                        // marginLeft: "10px",
                                        whiteSpace: "nowrap", // Prevents breaking within words
                                      }}
                                    >
                                      {items.title}
                                    </a>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginLeft: "10px",
                                          whiteSpace: "nowrap",
                                        }}
                                        className={items.type}
                                      >
                                        {items.title}
                                      </div>
                                      {items?.latinContentArray?.map(
                                        (latin) => (
                                          <>
                                            <div
                                              style={{
                                                marginLeft: "5px",
                                                whiteSpace: "nowrap",
                                              }}
                                              key={latin.title}
                                            >
                                              {latin.title}
                                            </div>
                                          </>
                                        )
                                      )}
                                    </div>
                                  )}
                                </React.Fragment>
                              );
                            }
                          )}
                        </div>
                      )}
                    </div>
                  </>
                  {/* /////////////// Latin //////////////// */}
                  <div
                    style={{
                      marginTop: "2.5px",
                      width: "100%",
                      // border: "solid 1px red",
                      display: "flex",
                      overflowX: "auto",
                      height: "20%",
                      justifyContent: "flex-start",
                    }}
                  >
                    {AllAvilable?.data[0]?.latinContentArray?.map((item) => (
                      <div
                        style={{ fontSize: "14px", marginLeft: "6px" }}
                        className={item?.type}
                      >
                        {" "}
                        {item?.title}{" "}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="icons_sentence">
                  <div
                    className="icon_set pointer"
                    onClick={() =>
                      copyText(AllAvilable?.data[0]?.sourceContent)
                    }
                  >
                    <IoCopyOutline />
                  </div>
                  <div className="icon_set pointer" onClick={handleHeart}>
                    {IsHeartBack ? (
                      <IoHeart />
                    ) : IsHeartBack && heart ? (
                      <IoHeart />
                    ) : heart ? (
                      <IoHeart />
                    ) : !heart && IsHeartBack ? (
                      <IoHeartOutline />
                    ) : (
                      <IoHeartOutline />
                    )}
                  </div>
                  {/* <div
                    className="icon_set pointer"
                    onClick={() => setFlag(true)}
                  >
                    <IoFlagOutline />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div
            className="icons_sentence"
            style={{ justifyContent: "center" }}
            // onClick={() => navigate("/questions/check")}
          >
            <button
              className="button_custom_large_rount primary"
              title="Play audio to make this button working"
              disabled={!countdown}
              onClick={handleProceed}
            >
              Proceed to check
            </button>
          </div>
        </div>

        {/* /// */}

        <div
          className="langword_bottom wd100"
          style={{ justifyContent: "center" }}
        >
          {LanguagesWords()?.map((v, i) => (
            <>
              <div key={"word_" + i} className={v.type}>
                <Link className={v.type} target="_blank" to={v.link}>
                  {v.title}
                </Link>
              </div>
            </>
          ))}
        </div>

        {/* /// */}
        {flag && (
          <div className="outer_wrapper">
            <div className="inner_wrapper">
              <div className="white_wrapper w40">
                <div className="heading_languages">
                  <div className="">Report the sentence</div>
                  <span className="pointer" onClick={() => setFlag(false)}>
                    <IoCloseCircleOutline />
                  </span>
                </div>
                <div className="" style={{ padding: "0px 40px" }}>
                  {reasonsflag.map((v, i) => (
                    <>
                      <div className="flag_reasons">
                        <label>
                          <input
                            onChange={(e) => {
                              setRadioVal(e.target.title);
                            }}
                            type="radio"
                            val={v.id}
                            name="flag"
                          />
                          <span>{v?.title}</span>
                        </label>
                      </div>
                    </>
                  ))}

                  <div className="flag_textarea">
                    <textarea
                      onChange={(e) => setInputVal(e.target.value)}
                      className="form-control"
                      placeholder="Please tell us more (optional)"
                    ></textarea>
                  </div>
                </div>
                <div className="footer_languages">
                  <div className=""></div>
                  <span
                    onClick={() => {
                      handleSubmit();
                      setFlag(false);
                    }}
                  >
                    <button className="button_custom_round primary">
                      Submit
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* ////////////// */}

      <ReactAudioPlayer
        ref={audioPlayerRefSource}
        src={AllAvilable?.data[0]?.sourceAudio}
        style={{ display: "none" }}
        controls
        onEnded={handleAudioEndSource}
      />

      {/* //////////////// */}

      <ReactAudioPlayer
        ref={audioPlayerRefTarget}
        src={AllAvilable?.data[0]?.targetAudio}
        style={{ display: "none" }}
        controls
        onEnded={handleAudioEndTarget}
      />

      {/* ////////////////// */}

      <ReactAudioPlayer
        ref={audioPlayerRefTarget2}
        src={AllAvilable?.data[0]?.targetAudio}
        style={{ display: "none" }}
        controls
        onEnded={handleAudioEndTarget2}
      />


    </div>
  );
}

export default OnBoard;
