// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success:false,
};

const SourceAndTargetAndSourceAudioAndTargetAudioSlice = createSlice({
  name: 'SourceAndTargetAndSourceAudioAndTargetAudioSlice',
  initialState,
  reducers: {
    Get_SouAndTarAndSouAudioAndTarAudio_Request_Reducer: (state) => {
      state.loading = true;
      state.error = null;
    },
    Get_SouAndTarAndSouAudioAndTarAudio_Success_Reducer: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
      state.success = true;
    },
    Get_SouAndTarAndSouAudioAndTarAudio_Failer_Reducer: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {  Get_SouAndTarAndSouAudioAndTarAudio_Request_Reducer,  Get_SouAndTarAndSouAudioAndTarAudio_Success_Reducer, Get_SouAndTarAndSouAudioAndTarAudio_Failer_Reducer } = SourceAndTargetAndSourceAudioAndTargetAudioSlice.actions;

export default SourceAndTargetAndSourceAudioAndTargetAudioSlice.reducer;