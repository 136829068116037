// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
};

const NativeLanguage = createSlice({
  name: 'NativeLanguage',
  initialState,
  reducers: {
    NativeLanguage_Reducer: (state,action) => {
      state.data = action.payload
    },
  },
});

export const { NativeLanguage_Reducer } = NativeLanguage.actions;

export default NativeLanguage.reducer;