//// Languages Data ////
export const FETCH_LANGUAGEDATA_REQUEST = "FETCH_LANGUAGEDATA_REQUEST";
export const FETCH_LANGUAGEDATA_SUCCESS = "FETCH_LANGUAGEDATA_SUCCESS";
export const FETCH_LANGUAGEDATA_FAILURE = "FETCH_LANGUAGEDATA_FAILURE";
//// CoursesSentence Data ////
export const GET_SOU_REQUEST = "GET_SOU_REQUEST";
export const GET_SOU_SUCCESS = "GET_SOU_SUCCESS";
export const GET_SOU_FAILURE = "GET_SOU_FAILURE";

// Source and Target Sentence
export const GET_SOU_AND_TAR_REQUEST = "GET_SOU_AND_TAR_REQUEST";
export const GET_SOU_AND_TAR_SUCCESS = "GET_SOU_AND_TAR_SUCCESS";
export const GET_SOU_AND_TAR_FAILURE = "GET_SOU_AND_TAR_FAILURE";

// Source Content And Target Content and Source Audio
export const GET_SOU_AND_TAR_AND_SOU_AUDIO_REQUEST =
  "GET_SOU_AND_TAR_AND_SOU_AUDIO_REQUEST";
export const GET_SOU_AND_TAR_AND_SOU_AUDIO_SUCCESS =
  "GET_SOU_AND_TAR_AND_SOU_AUDIO_SUCCESS";
export const GET_SOU_AND_TAR_AND_SOU_AUDIO_FAILURE =
  "GET_SOU_AND_TAR_AND_SOU_AUDIO_FAILURE";

// Source Content And Target Content and Source Audio and Target Audio
export const GET_SOU_AND_TAR_AND_SOU_AUDIO_AND_TAR_AUDIO_REQUEST =
  "GET_SOU_AND_TAR_AND_SOU_AUDIO_AND_TAR_AUDIO_REQUEST";
export const GET_SOU_AND_TAR_AND_SOU_AUDIO_AND_TAR_AUDIO_SUCCESS =
  "GET_SOU_AND_TAR_AND_SOU_AUDIO_AND_TAR_AUDIO_SUCCESS";
export const GET_SOU_AND_TAR_AND_SOU_AUDIO_AND_TAR_AUDIO_FAILURE =
  "GET_SOU_AND_TAR_AND_SOU_AUDIO_AND_TAR_AUDIO_FAILURE ";

// latest update

export const LAST_UPDATE_REQUEST = "LAST_UPDATE_REQUEST";
export const GLAST_UPDATE_SUCCESS = "GLAST_UPDATE_SUCCESS";
export const LAST_UPDATE_FAILER = "LAST_UPDATE_FAILER";

// all comments

export const GET_ALLCOMMENTS_REQUEST = "GET_ALLCOMMENTS_REQUEST";
export const GET_ALLCOMMENTS_SUCCESS = "GET_ALLCOMMENTS_SUCCESS";
export const GET_ALLCOMMENTS_FAILER = " GET_ALLCOMMENTS_FAILER ";

// all wordarray 

export const GET_WORDARRAY_REQUEST = "GET_WORDARRAY_REQUEST ";
export const GET_WORDARRAY_SUCCESS = "GET_WORDARRAY_SUCCESS";
export const GET_WORDARRAY_FAILER = "GET_WORDARRAY_FAILER ";

// all C1

export const GET_C1_REQUEST = "GET_C1_REQUEST ";
export const GET_C1_SUCCESS = "GET_C1_SUCCESS";
export const GET_C1_FAILER = "GET_C1_FAILER ";
