// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [{Source:false,Target:false}],
};

const RightToLeft = createSlice({
  name: 'RightToLeft',
  initialState,
  reducers: {
    RightToLeft_Reducer: (state,action) => {
        console.log(action.payload,'action payload...');
      state.data = action.payload;
    },
  },
});

export const { RightToLeft_Reducer } = RightToLeft.actions;

export default RightToLeft.reducer;
