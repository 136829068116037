// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { GetlanguageRequest } from '../../actions';
import { type } from '@testing-library/user-event/dist/type';


const initialState = {
 type:false
};

const IsDialectSlice = createSlice({
  name: 'isDialect',
  initialState,
  reducers: {
    IsDialect: (state,action) => {
        if(action.payload){
            state.type=true
        }
        else{
           state.type = false
        }
    },
  },
});

export const { IsDialect } = IsDialectSlice.actions;

export default IsDialectSlice.reducer;

