// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { GetlanguageRequest } from '../../actions';

const initialState = {
  data:null,
  loading: false,
  error: null,
};

const CourseSlice = createSlice({
  name: 'CourseSlice',
  initialState,
  reducers: {
    GetCourse: (state,action) => {
      state.data=action.payload;
    },
  },
});

export const {GetCourse} = CourseSlice.actions;

export default CourseSlice.reducer;

