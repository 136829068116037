// LanguagesSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  IsNext_type: false,
  IsHeart_type: false,
  IsPlay_type: false,
  method : 0,
};

const ExtraSlice = createSlice({
  name: "extraslice",
  initialState,
  reducers: {
    IsNext_Reducer: (state, action) => {
      state.IsNext_type = action.payload;
    },
    IsHeart_Reducer: (state, action) => {
      state.IsHeart_type = action.payload;
    },
    IsPlay_Reducer: (state, action) => {
      // console.log(action.payload,'act . payload');
      state.IsPlay_type = action.payload;
    },
    Method_Reducer: (state, action) => {
      state.method = action.payload;
    },
  },
});

export const { IsNext_Reducer } = ExtraSlice.actions;
export const { IsHeart_Reducer } = ExtraSlice.actions;
export const { IsPlay_Reducer } = ExtraSlice.actions;
export const { Method_Reducer } = ExtraSlice.actions;

export default ExtraSlice.reducer;
