// rootSaga.js

import { all } from 'redux-saga/effects';
import GetLanguages from './LanguageSaga/GetLanguages';
import Sou from './Sou'
import Get_SourceAndTarget from './Sou_Tar';
import Get_SourceAudio from './Sou_Tar_SouAudio';
import GetAllCotent from './Sou_Tar_SouAudio_TarAudio';
import LastUpdate from './LastUpdate';
import GetAllComments from './AllComments';
import GetWordArray from './WordArray';
import GetC1 from './C1Languages';

export default function* rootSaga() {
  yield all([
    GetLanguages(),
    Sou(),
    Get_SourceAudio(),
    Get_SourceAndTarget(),
    GetAllCotent(),
    LastUpdate(),
    GetAllComments(),
    GetWordArray(),
    GetC1(),
    
  ]);
}
