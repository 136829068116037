import { takeLatest, call, put } from 'redux-saga/effects';
import { Get_C1_Request,} from '../../Redux/actions';
import { GetlanguageFailerReducer,GetlanguageSuccessReducer,GetlanguageRequestReducer } from '../../Redux/slices/languagesSlices/languages';
import { FatchApi } from '../../../utils/FatchApi';
import { GET_ALL_LANGUAGES } from '../../../constants/apiEndPoints';
import { GetC1RFailer, GetC1RRequst, GetC1RSuccess } from '../../Redux/slices/C1languages';

function* fetchDataSaga(action) {
  try {
    yield put(GetC1RRequst())
    const data = yield  call(FatchApi,action.payload);
    if(data){
      yield put(GetC1RSuccess(data));
    }
  } catch (error) {
    yield put(GetC1RFailer(error));
  }
}

export default function* GetC1() {
  yield takeLatest(Get_C1_Request().type, fetchDataSaga);
}