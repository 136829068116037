import React from 'react'

const FaqItems = ({openFAQ,MdOutlineKeyboardArrowDown,MdKeyboardArrowUp,toggleFAQ,faq,}) => {
  return (
    <>
        <li
            key={faq.id}
            style={{
              backgroundColor: 'white',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              borderRadius: '0.375rem',
              marginBottom: '1rem',
              overflow: 'hidden',
            }}
          >
            <button
              style={{
                width: '100%',
                textAlign: 'left',
                padding: '1rem',
                outline: 'none',
                border: 'none',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'white',
                borderRadius: '0.375rem',
              }}
              onClick={() => toggleFAQ(faq._id)}
            >
              <h2 style={{ fontSize: '1.125rem', fontWeight: '600' }}>{faq.question}</h2>
              <span>
                {openFAQ === faq._id ? <MdOutlineKeyboardArrowDown /> : <MdKeyboardArrowUp />}
              </span>
            </button>
            {openFAQ === faq._id && (
              <div style={{ padding: '1rem', borderTop: '1px solid #e5e7eb' }}>
                <p style={{ color: '#4a5568' }}>{faq.answer}</p>
              </div>
            )}
          </li>
    </>
  )
}

export default FaqItems