import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../base-components/Header";
import { FORGOT_API } from "../../constants/apiEndPoints";
import httpRequest from "../../axios/index.js";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setTempEmail } from "../../lib/Redux/slices/userslice.jsx";
import Loader from "../../base-components/Loader/Loader.jsx";

function Forgot() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [loader,setLoader]=useState(false)

  const handleSubmit = async (e) => {
    setLoader(true)
    e.preventDefault();
    try {
      const response = await httpRequest.post(FORGOT_API, { email });
      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message);
        dispatch(setTempEmail(email));
        setLoader(false)
        setTimeout(() => {
          navigate("/otp");
        }, 2000);
      }
    } catch (err) {
      setLoader(false)
      toast.error('Email Address not found');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div className="container">
      <Header />
      <div className="wrapper_full">
        <div className="login-container">
          <div className="">
            <h1>Reset Password</h1>
            <p>
              or <Link to="/login">Log in</Link>
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form_middle">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Email Address"
                  required
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>

              <div className="form-group ">
                <div className="center_button_custom_width">
                  <button type="submit" className="button_custom primary wd100">
                    {
                      loader ? <Loader/> : "Reset Password"
                    }
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Forgot;
