// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { GetlanguageRequest } from '../../actions';

const initialState = {
  data:[],
  loading: false,
  error: null,
};

const C1Slice = createSlice({
  name: 'C1Slice',
  initialState,
  reducers: {
    GetC1RRequst: (state,action) => {
      state.loading=true
    },
    GetC1RSuccess: (state,action) => {
        state.loading=false;
        state.data=action.payload
      },
      GetC1RFailer: (state,action) => {
        state.error=action.payload;
        state.loading=false
      },
  },
});

export const {GetC1RRequst , GetC1RSuccess , GetC1RFailer} = C1Slice.actions;

export default C1Slice.reducer;

