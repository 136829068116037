import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail, LanguagesWords } from "../../utils/functions";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Earn from "../../assets/images/earn-money.png";
import Learn from "../../assets/images/learn_img.png";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillPlusCircle,
  AiFillQuestionCircle,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineAudio,
} from "react-icons/ai";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaHandPointRight, FaRegClock, FaCircleCheck } from "react-icons/fa6";
import { LuClipboardList, LuRepeat } from "react-icons/lu";
import { HiOutlineSpeakerWave } from "react-icons/hi2";
import { MdCancel } from "react-icons/md";

import { Tooltip } from "react-tooltip";
import Flag1 from "../../assets/images/eng.png";
import Flag2 from "../../assets/images/russia.png";
import Source from "./../../assets/source.mp3";
import { LastUpdate_Request } from "../../lib/Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../base-components/Loader/Loader";
import useToast from "../../hooks/useToast";
import { LastUpdate_Update_Reducer } from "../../lib/Redux/slices/LastUpdate";

var SourceVal = JSON.parse(localStorage.getItem("source"));
var TargetVal = JSON.parse(localStorage.getItem("target"));

const sentences_lis = [
  {
    title: "Das Wetter ist gut heute.",
    tag: "classic",
    id: 1,
    done: 1,
    here: 0,
  },
  {
    title: "Das Wetter war sehr gut.",
    tag: "classic",
    id: 2,
    done: 1,
    here: 0,
  },
  {
    title: "Das Essen war sehr lecker.",
    tag: "slang",
    id: 3,
    done: 1,
    here: 0,
  },
  {
    title: "Das Fressen war sau lecker.",
    tag: "classic",
    id: 4,
    done: 1,
    here: 0,
  },
];

const source_text = [
  { title: "The", type: "" },
  { title: "weather", type: "" },
  { title: "is", type: "" },
  { title: "good", type: "" },
  { title: "today", type: "" },
];

const target_text = [
  { title: "Погода", type: "" },
  { title: "сегодня", type: "" },
  { title: "хорошая", type: "" },
];

const reasonsflag = [
  { title: "mistake in the source language audio", id: 1 },
  { title: "mistake in the target language audio", id: 2 },
  { title: "mistake in the source language", id: 3 },
  { title: "mistake in the target language", id: 4 },
  { title: "words  marked incorrectly", id: 5 },
  { title: "wrong tag (classic, slang, insult)", id: 6 },
  { title: "inappropriate sentence", id: 7 },
  { title: "other", id: 8 },
];
function OnBoard() {
  const showToast = useToast();

  const location = useLocation();
  const course = useSelector((state) => state.Course);

  console.log(location.state.isIncorrectly, "location state getting tag");

  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [heading, setHeading] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);

  const [sourcetext, setSourceText] = useState(source_text);
  const [targettext, setTargetText] = useState(target_text);

  const [sourceselected, setSourceSelected] = useState("");
  const [targetselected, setTargetSelected] = useState("");

  const [selected, setSelected] = useState([]);
  const [audio, setAudio] = useState(null);

  const [comment, setComment] = useState(null);

  const SourceAndTargetAndSourceAudio = useSelector(
    (state) => state.SourceAndTargetAndSourceAudio
  );
  const lastupdated = useSelector((state) => state.LastUpdate);
  const isDialect = useSelector((state) => state.IsDialect);
  const user = useSelector((state) => state.user);
  const { NativeLanguage } = useSelector((state) => state);

  // console.log(isDialect,'isDialect...');

  // console.log(lastupdated.loading, "lastupdated");

  useEffect(() => {
    SourceVal = JSON.parse(localStorage.getItem("source"));
    TargetVal = JSON.parse(localStorage.getItem("target"));
  }, [SourceVal?.code, TargetVal?.code]);

  const dispatch = useDispatch();

  const show_popup = async (val) => {
    setHeading(val);
    setPopup(true);
  };

  const do_next_page = async () => {
    navigate("/step/three");
  };

  const do_source_click = async (v) => {
    setTargetSelected("");
    if (sourceselected == v.title) {
      setSourceSelected("");
    } else {
      setSourceSelected(v.title);
    }
  };

  const do_target_click = async (v) => {
    setSourceSelected("");
    if (targetselected == v.title) {
      setTargetSelected("");
    } else {
      setTargetSelected(v.title);
    }
  };

  const handleLangwords = (type) => {
    if (sourceselected) {
      var selectedIndex = sourcetext.findIndex(
        (item) => item.title === sourceselected
      );
    } else {
      var selectedIndex = targettext.findIndex(
        (item) => item.title === targetselected
      );
    }

    if (selectedIndex !== -1) {
      if (sourceselected) {
        const updatedSourceText = [...sourcetext];
        updatedSourceText[selectedIndex].type = type;
        setSourceText(updatedSourceText);
      } else {
        const updatetargettext = [...targettext];
        updatetargettext[selectedIndex].type = type;
        setTargetText(updatetargettext);
      }
      setSourceSelected("");
      setTargetSelected("");
    }
  };

  const handleFinish = () => {
    // console.log("finish reported");

    if(!comment || comment == null || comment == undefined || comment?.length <=0 ){
      // console.log(comment,'comment');
      showToast("empty field not valid",'warning');
       return
    }

    const SentenceId =
      SourceAndTargetAndSourceAudio.data[location?.state?.count]?._id;
    const sourceAudio =
      SourceAndTargetAndSourceAudio.data[location?.state?.count]?.sourceAudio;

    // console.log(SourceAndTargetAndSourceAudio.data[location?.state?.count],'Sentenceid');

    const obj = {
      isReported: true,
      checkSentence: "Report",
      isLearner: false,
      // sourceAudio:sourceAudio,
      report: {
        earnerSide: true,
        user: user?.user || null,
        mistakeArray: location?.state?.checkedReasons || null,
        correctSourceLanguage: location?.state?.inputDataSource || null,
        correctTargetLanguage: location?.state?.inputDataTarget || null,
        comment: comment || null,
        tag: location?.state?.tag || null,
      },
      isFinalCheck: false,
    };

    console.log(obj, "obj..");

    if (comment != null && comment?.length >= 1) {
      try {
        dispatch(
          LastUpdate_Request({
            // user: user.user,
            request: "put",
            data: obj,
            Api: isDialect.type
              ? `/api/v1/sentences-dia/${SentenceId} `
              : `/api/v1/sentences/${SentenceId}`,
          })
        );
      } catch (err) {
        console.error("Error updating sentence:", err);
      }

    } else {
      showToast("empty field not valid");
    }
  };

  const do_play_audio = () => {
    // console.log("play", location?.state?.Content?.sourceAudio);

    const newAudio = new Audio(location?.state?.Content?.sourceAudio);
    newAudio.pause();
    newAudio.play();
    setAudio(true);
  };

  useEffect(() => {
    if (lastupdated.success) {
      showToast("Sentence Reported Successfully", "success");
      setTimeout(() => {
        navigate("/earn");
        dispatch(LastUpdate_Update_Reducer());
      }, 2000);
    } else if (lastupdated.loading === false && lastupdated.error) {
      showToast("Error adding sentence", "error");
    }
  }, [lastupdated && lastupdated.success]);

  return (
    <div className="container">
      <HeaderLogin />
      <div className="wrapper" style={{width:'70%'}} >
        <div className="langugae_courses_heading">
          <span>
            <span>{isDialect.type ? "" : <img src={SourceVal?.flag} />}</span>{" "}
            {SourceVal?.code}{" "}
          </span>{" "}
          <AiOutlineArrowRight />{" "}
          <span>
            <span>{isDialect.type ? "" : <img src={TargetVal?.flag} />}</span>{" "}
            {TargetVal?.code}
          </span>
        </div>
        <div className="my_languages_wrap"  >
          <div className="box_left_add_ card">
            <div className="heading_languages mb-0">
              <div className="">Previous Sentences</div>
            </div>
            <div className="p10 previous_sentences">
              {!SourceAndTargetAndSourceAudio.loading ? (
                SourceAndTargetAndSourceAudio?.data?.map((v, i) => (
                  <>
                    <div className="senetence_">
                      <span>#{i + 1}</span>
                      <span style={{wordBreak: 'break-word'}} >
                        {course?.data[0]?.spaces
                          ? v.sourceContent?.split(" ").join("")
                          : v.sourceContent}
                      </span>
                    </div>
                  </>
                ))
              ) : (
                <Loader />
              )}
            </div>
          </div>

          <div className="box_left_add_" style={{width:'80%'}} >
            <div className="card p15 wd100 text-center">Add a comment</div>
            <div className="card p20 mt-10px">
              <div className="add_sentence_source">
                <textarea
                  name="add_sentence"
                  className="form-control-less"
                  placeholder= {location.state.isIncorrectly ? "Which word(s) has/have been marked incorrectly?" : 'Type here' }
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
                {/* <div>{location?.state?.tag}</div> */}
              </div>

              {/* ///// */}

              <div
                style={{
                  border: `solid 1px ${
                    location?.state?.tag ? "red" : "lightgray"
                  }`,
                  padding: "8px",
                  marginTop: "10px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {location?.state?.taghere}
                <span>
                  {location?.state?.tag ? (
                    <MdCancel color={"red"} size={24} />
                  ) : (
                    ""
                    // <FaCircleCheck color={"green"} size={20} />
                  )}
                </span>
              </div>

              {location?.state?.tag && (
                <div
                  style={{
                    border: `solid 1px green`,
                    padding: "8px",
                    marginTop: "10px",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {location?.state?.tag}
                  <FaCircleCheck color={"green"} size={20} />   
                </div>
              )}

              <div
                style={{
                  width: "90%",
                  marginLeft: "5%",
                  backgroundColor: "lightgray",
                  height: "0.5px",
                  marginTop: "10px",
                  opacity: "0.7 ",
                }}
              ></div>

              {location?.state?.isSource ||
              location.state.checkedReasons?.some(
                (item) => item.name === "source audio mistake"
              ) ? (
                <>
                  <h1 style={{ fontSize: "10px", marginTop: "8px" }}>Source</h1>

                  {location?.state?.inputDataSource != null &&
                    location?.state?.inputDataSource != undefined &&
                    location?.state?.inputDataSource?.length >= 1 && (
                      <div
                        style={{
                          border: "solid 1px #E5E5E5",
                          marginTop: "10px",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "10px",
                          // width:'100%',
                        }}
                      >
                        <span>
                          <FaCircleCheck color={"green"} size={20} />
                        </span>
                        <span style={{ wordBreak: 'break-word' ,textAlign:'center', marginLeft: "8px", marginRight: "8px",width:'80%',display:'flex',justifyContent:'center',color:'black' }}>
                          {location?.state?.inputDataSource}
                        </span>
                        <span>
                          <FaCircleCheck color={"green"} size={20} />
                        </span>
                      </div>
                    )}

                  <div
                    style={{
                      border: "solid 1px #E5E5E5",
                      marginTop: "10px",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <span>
                      <MdCancel color={"red"} size={24} />
                    </span>
                    <span
                      style={{
                        marginLeft: "8px",
                        marginRight: "8px",
                        overflowY:'auto',
                        width:'80%',
                        maxHeight:'30px',
                        display:'flex',
                        flexWrap:'wrap',
                        justifyContent:'center',
                        color:'black',
                        position:'relative',
                        // left:'90%',
                      }}
                    >
                      {location?.state?.Content?.targetContentArray?.map(
                        (item, index) => (
                          <div
                            key={index}
                            className={item.type}
                            style={{
                              marginLeft: location?.state?.Content?.courseId
                                ?.spaces
                                ? "7px"
                                : "5px",
                            }}
                          >
                            {item?.title}
                          </div>
                        )
                      )}
                    </span>

                    <span>
                      <MdCancel color={"red"} size={24} />
                    </span>

                    <span
                      style={{
                        fontSize: "18px",
                        marginLeft: "10px",
                        border: "solid 1px red",
                        borderRadius: "50%",
                        height: "27px",
                        width: "27px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position:'absolute',
                        left:'91%',
                      }}
                      className="pointer"
                      onClick={() => do_play_audio()}
                    >
                      <HiOutlineSpeakerWave />
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <h1 style={{ fontSize: "10px", marginTop: "8px" }}>Source</h1>
                  <div
                    style={{
                      border: "solid 1px #E5E5E5",
                      marginTop: "10px",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "10px",
    
                    }}
                  >
                    {location?.state?.isSource && (
                      <span>
                        <FaCircleCheck color={"green"} size={20} />
                      </span>
                    )}
                    <span style={{  marginRight: "8px",width:'95%',justifyContent:'flex-start' }}>
                      <div style={{ display: "flex",flexWrap:'wrap',width:'100%',overflowY:'auto',maxHeight:'60px', padding:'5px'  }}>
                        {location?.state?.sourcehere?.map((item) => (
                          <div
                            style={{ marginLeft: "5px", }}
                            className={item?.type}
                          >
                            {item?.title}
                          </div>
                        ))}
                      </div>
                    </span>
                    {location?.state?.isSource && (
                      <span>
                        <FaCircleCheck color={"green"} size={20} />
                      </span>
                    )}
                    {/* // */}
                    {!isDialect?.type && (
                      <span
                        style={{ fontSize: "22px", marginLeft: "10px" }}
                        className="pointer"
                        onClick={() => do_play_audio()}
                      >
                        <HiOutlineSpeakerWave />
                      </span>
                    )}
                  </div>
                </>
              )}

              {location?.state?.isTarget ? (
                <>
                  <h1 style={{ fontSize: "10px", marginTop: "8px" }}>Target</h1>

                  <div
                    style={{
                      border: "solid 1px #E5E5E5",
                      marginTop: "10px",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <span>
                      <FaCircleCheck color={"green"} size={20} />
                    </span>
                    <span style={{ wordBreak: 'break-word' ,textAlign:'center', marginLeft: "8px", marginRight: "8px",width:'80%',display:'flex',justifyContent:'center',color:'black' }}>
                      {location?.state?.inputDataTarget}
                    </span>
                    <span>
                      <FaCircleCheck color={"green"} size={20} />
                    </span>
                  </div>

                  {/* ////// */}

                  <div
                    style={{
                      border: "solid 1px #E5E5E5",
                      marginTop: "10px",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <span>
                      <MdCancel color={"red"} size={24} />
                    </span>
                    <span
                      style={{
                        marginLeft: "8px",
                        marginRight: "8px",
                        overflowY:'auto',
                        width:'80%',
                        maxHeight:'30px',
                        display:'flex',
                        flexWrap:'wrap',
                        justifyContent:'center',  
                        maxHeight:'60px',
                      }}
                    >
                      {location?.state?.Content?.sourceContentArray?.map(
                        (item, index) => (
                          <div
                            className={item?.type}
                            key={index}
                            style={{
                              color:'black',
                              marginLeft: location?.state?.Content?.courseId
                                ?.spaces
                                ? "7px"
                                : "5px",
                            }}
                          >
                            {item?.title}
                          </div>
                        )
                      )}
                    </span>

                    <span>
                      <MdCancel color={"red"} size={24} />
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <h1 style={{ fontSize: "10px", marginTop: "8px" }}>Target</h1>
                  <div
                    style={{
                      border: "solid 1px #E5E5E5",
                      marginTop: "10px",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    {location?.state?.isTarget && (
                      <span>
                        <FaCircleCheck color={"green"} size={20} />
                      </span>
                    )}
                    <span style={{ marginLeft: "8px", marginRight: "8px",width:'100%'}}>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          height: "95%",
                          overflow: "auto",
                          width:'100%',
                          padding:'5px' 
                        }}
                      >
                        {location?.state?.targethere?.map((item) => (
                          <div
                            style={{ marginLeft: "5px" }}
                            className={item?.type}
                          >
                            {item?.title}
                          </div>
                        ))}
                      </div>
                    </span>
                    {location?.state?.isTarget && (
                      <span>
                        <FaCircleCheck color={"green"} size={20} />
                      </span>
                    )}
                    {isDialect?.type && (
                      <span
                        style={{ fontSize: "22px", marginLeft: "10px" }}
                        className="pointer"
                        onClick={() => do_play_audio()}
                      >
                        <HiOutlineSpeakerWave />
                      </span>
                    )}
                  </div>
                </>
              )}

              {/* {location?.state?.isTarget && (
                <>
                  {location?.state?.inputDataSource ? (
                    <>
                      <div className="sentence_source mt-10px flex-startpointer text_user_show flex-space-between">
                        <span>
                          <FaCircleCheck color={"green"} size={20} />
                        </span>
                        <span>
                          {course?.data[0]?.spaces
                            ? location?.state?.inputDataSource
                                ?.split(" ")
                                .join("")
                            : location.state.inputDataSource}
                        </span>
                        <span>
                          <FaCircleCheck color={"green"} size={20} />
                        </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div style={{flexDirection:'column',height:` ${ location?.state?.mistake?.some(
                      (item) => (item.name = "source audio mistake")
                    ) ?  '120px':'60px'}`}} className="sentence_source flex-start  mt-10px  pointer text_user_show flex-space-between"  >
                    <div style={{display:'flex'}} >
                    <span>
                      <MdCancel color={"red"} size={24} />
                    </span>
                    <span>
                      {course?.data[0]?.spaces
                        ? location?.state?.targetContent.split(" ").join("")
                        : location?.state?.targetContent}
                    </span> 
                    <span>
                      <MdCancel color={"red"} size={24} />
                    </span>
                    </div>

                    {location?.state?.mistake?.some(
                      (item) => (item.name = "source audio mistake")
                    ) ? (
                      <>
                        <div style={{ height: "25px", marginTop: "5px" }}>
                          <audio
                            id="myAudio"
                            src={location?.state?.sourceAudio}
                            controls
                          ></audio>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              )} */}

              {/* <div className="sentence_source mt-10px flex-startpointer text_user_show flex-space-between">
                <span>
                  <FaCircleCheck color={"green"} size={20} />
                </span>
                <span>
                  {course?.data[0]?.spaces
                    ? location?.state?.inputDataTarget?.split(" ").join("")
                    : location?.state?.inputDataTarget}
                </span>
                <span>
                  <FaCircleCheck color={"green"} size={20} />
                </span>
              </div> */}
              {/* //////////////////////////////////////////////////////////////////////// */}

              {/* //////////////////////////////////////////////////////////////////////// */}

              {/* {location?.state?.isSource ? (
                <div
                  className=" flex-start  mt-10px  pointer text_user_show flex-space-between"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: "20px",
                    height: ` ${
                      location?.state?.mistake?.some(
                        (item) => (item.name = "source audio mistake")
                      )
                        ? "120px"
                        : "60px"
                    }`,
                    border: "solid 1px lightgray",
                  }}
                >
                  <div style={{ marginTop: "-15px" }}>
                    <>
                      <span>
                        <MdCancel color={"red"} size={24} />
                      </span>
                      <span style={{ marginLeft: "5px", marginRight: "5px" }}>
                        {course?.data[0]?.spaces
                          ? location?.state?.sourceContent?.split(" ").join("")
                          : location?.state?.sourceContent}
                      </span>
                      <span>
                        <MdCancel color={"red"} size={24} />
                      </span>
                    </>
                  </div>
                </div>
              ) : location?.state?.checkedReasons?.some(
                  (item) => (item.name = "source audio mistake")
                ) ? (
                <>
                  (
                <>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ marginTop: "-15px" }}>
                      <span>
                        <MdCancel color={"red"} size={24} />
                      </span>
                      <span style={{ marginLeft: "5px", marginRight: "5px" }}>
                        {course?.data[0]?.spaces
                          ? location?.state?.sourceContent?.split(" ").join("")
                          : location?.state?.sourceContent}
                      </span>
                      <span>
                        <MdCancel color={"red"} size={24} />
                      </span>
                    </div>

                    <div
                      style={{
                        height: "25px",
                        marginTop: "5px",
                        // border: "solid 1px red",
                        height: "70px",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <audio
                        id="myAudio"
                        src={location?.state?.sourceAudio}
                        controls
                      ></audio>
                    </div>
                  </div>
                </>
              ) 
                </>
              ) : (
                ""
              )} */}

              {/* //// */}

              <div
                className="question_mark_"
                data-tooltip-id="question_new_sentence"
                data-tooltip-html="Please explain why you think that a mistake has been made."
              >
                <AiFillQuestionCircle />
              </div>

              <Tooltip
                id="question_new_sentence"
                style={{
                  textAlign: "center",
                  padding: "13px 10px",
                  fontSize: "13px",
                  zIndex: 9,
                  width: "350px",
                }}
              />
            </div>

            <div className="text-center wd100 mt-20px float-left green" style={{display:'flex',flexWrap:'wrap',justifyContent:'center'}} >
              {location.state.checkedReasons?.map((item) => {
                return <div style={{marginLeft:'14px',marginTop:'5px'}} >{item.name}</div>;
              })}
            </div>
            
          </div>

          <div
            className="box_left_add_ tags_button_next flex-space-between  p20"
            style={{ flexDirection: "column" }}
          >
            <div className="flex-space-between gap_20 custom_tags_button">
              <div className="tags_left">
                {/* <label>
                  <input type="checkbox" val={1} checked />
                  Classic
                </label> */}
              </div>

              <div className="button_tags">
                <button
                  className="button_custom_med secondary"
                  onClick={() => navigate(-1)}
                >
                  <AiOutlineArrowLeft /> Back{" "}
                </button>
                <button
                  className="button_custom_med primary mt-20px"
                  onClick={handleFinish}
                >
                  {lastupdated.loading ? <Loader /> : "Finish"}
                </button>
              </div>
            </div>
            <div className="langword_bottom custom_mar">
              {LanguagesWords().map((v, i) => (
                <>
                  <Link
                    to={v.link}
                    target="_blank"
                    onClick={() => handleLangwords(v.type)}
                    key={"word_" + i}
                    className={v.type}
                  >
                    {v.title}
                  </Link>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnBoard;
