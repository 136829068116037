import { createAction } from "../../../utils/GenaricAction";
import {
  // languates get
  FETCH_LANGUAGEDATA_FAILURE,
  FETCH_LANGUAGEDATA_REQUEST,
  FETCH_LANGUAGEDATA_SUCCESS,
  //   source sentence
  GET_SOU_REQUEST,
  GET_SOU_SUCCESS,
  GET_SOU_FAILURE,

  // Source and Target Sentence
  GET_SOU_AND_TAR_REQUEST,
  GET_SOU_AND_TAR_SUCCESS,
  GET_SOU_AND_TAR_FAILURE,

  // Source Content And Target Content and Source Audio
  GET_SOU_AND_TAR_AND_SOU_AUDIO_REQUEST,
  GET_SOU_AND_TAR_AND_SOU_AUDIO_SUCCESS,
  GET_SOU_AND_TAR_AND_SOU_AUDIO_FAILURE,

  // Source Content And Target Content and Source Audio and Target Audio
  GET_SOU_AND_TAR_AND_SOU_AUDIO_AND_TAR_AUDIO_REQUEST,
  GET_SOU_AND_TAR_AND_SOU_AUDIO_AND_TAR_AUDIO_SUCCESS,
  GET_SOU_AND_TAR_AND_SOU_AUDIO_AND_TAR_AUDIO_FAILURE,

  // last update
  LAST_UPDATE_REQUEST,
  GLAST_UPDATE_SUCCESS,
  LAST_UPDATE_FAILER,

  // ALL COMMENTS
  GET_ALLCOMMENTS_REQUEST,
  GET_ALLCOMMENTS_SUCCESS,
  GET_ALLCOMMENTS_FAILER,

  // GET ALL WORDARRAY
  GET_WORDARRAY_REQUEST,
  GET_WORDARRAY_SUCCESS,
  GET_WORDARRAY_FAILER,

  // GET C1

  GET_C1_REQUEST,
  GET_C1_SUCCESS,
  GET_C1_FAILER,

  //

} from "../../../constants/actionstype";

// languates get

export const GetlanguageRequest = createAction(FETCH_LANGUAGEDATA_REQUEST);
export const GetlanguageSuccess = createAction(FETCH_LANGUAGEDATA_SUCCESS);
export const GetlanguageFailure = createAction(FETCH_LANGUAGEDATA_FAILURE);

//   Source Content sentence

export const Get_Sou_Request = createAction(GET_SOU_REQUEST);
export const Get_Sou_Success = createAction(GET_SOU_SUCCESS);
export const Get_Sou_Failure = createAction(GET_SOU_FAILURE);

// Source Content And Target Content

export const Get_SouAndTar_Request = createAction(GET_SOU_AND_TAR_REQUEST);
export const Get_SouAndTar_Success = createAction(GET_SOU_AND_TAR_SUCCESS);
export const Get_SouAndTar_Failure = createAction(GET_SOU_AND_TAR_FAILURE);

// Source Content And Target Content and Source Audio

export const Get_SouAndTarAndSouAudio_Request = createAction(
  GET_SOU_AND_TAR_AND_SOU_AUDIO_REQUEST
);
export const Get_SouAndTarAndSouAudio_Success = createAction(
  GET_SOU_AND_TAR_AND_SOU_AUDIO_SUCCESS
);
export const Get_SouAndTarAndSouAudio_Failure = createAction(
  GET_SOU_AND_TAR_AND_SOU_AUDIO_FAILURE
);

// Source Content And Target Content and Source Audio and Target Audio

export const Get_SouAndTarAndSouAudioAndTarAudio_Request = createAction(
  GET_SOU_AND_TAR_AND_SOU_AUDIO_AND_TAR_AUDIO_REQUEST
);
export const Get_SouAndTarAndSouAudioAndTarAudio_Success = createAction(
  GET_SOU_AND_TAR_AND_SOU_AUDIO_AND_TAR_AUDIO_SUCCESS
);
export const Get_SouAndTarAndSouAudioAndTarAudio_Failure = createAction(
  GET_SOU_AND_TAR_AND_SOU_AUDIO_AND_TAR_AUDIO_FAILURE
);

// lastupdate

export const LastUpdate_Request = createAction(LAST_UPDATE_REQUEST);
export const LastUpdate_Success = createAction(GLAST_UPDATE_SUCCESS);
export const LastUpdate_Failure = createAction(LAST_UPDATE_FAILER);

// Get AllComments

export const Get_AllComments_Request = createAction(GET_ALLCOMMENTS_REQUEST);
export const Get_AllComments_Success = createAction(GET_ALLCOMMENTS_SUCCESS);
export const Get_AllComments_Failer = createAction(GET_ALLCOMMENTS_FAILER);

// Get allWords

export const Get_WordArray_Request = createAction(GET_WORDARRAY_REQUEST);
export const Get_WordArray_Success = createAction(GET_WORDARRAY_SUCCESS);
export const Get_WordArray_Failer = createAction(GET_WORDARRAY_FAILER);

// c1 get 

export const Get_C1_Request = createAction(GET_C1_REQUEST);
export const Get_C1_Success = createAction(GET_C1_SUCCESS);
export const Get_C1_Failer = createAction(GET_C1_FAILER);

// GET_C1_REQUEST
