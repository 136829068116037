// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { GetlanguageRequest } from '../../actions';
import GetLanguages from '../../../saga/Sou';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success:false,
};

const languagesSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    GetlanguageRequestReducer: (state) => {
      state.loading = true;
      state.error = null;
    },
    GetlanguageSuccessReducer: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
      state.success = true;
    },
    GetlanguageFailerReducer: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    GetLanguagesUpdateReducer:(state,action)=>{
      state.success = false;
    }
  },
});

export const { GetlanguageRequestReducer, GetlanguageSuccessReducer, GetlanguageFailerReducer, GetLanguagesUpdateReducer } = languagesSlice.actions;

export default languagesSlice.reducer;

