import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Earn from "../../assets/images/earn-money.png";
import Learn from "../../assets/images/learn_img.png";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import { HiOutlineSpeakerWave } from "react-icons/hi2";
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillFlag,
  AiFillPlusCircle,
  AiFillQuestionCircle,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineFlag,
} from "react-icons/ai";
import { IoCloseCircleOutline, IoMicCircleSharp } from "react-icons/io5";
import { FaRegPauseCircle } from "react-icons/fa";
import { FaHandPointRight, FaRegClock } from "react-icons/fa6";
import { LuClipboardList, LuRepeat } from "react-icons/lu";
import { Tooltip } from "react-tooltip";
import Flag1 from "../../assets/images/eng.png";
import Flag2 from "../../assets/images/russia.png";
import useToast from "../../hooks/useToast";
import { UploadAssests } from "../../utils/Aws_Upload";
import { LastUpdate_Request } from "../../lib/Redux/actions";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import Loader from "../../base-components/Loader/Loader";
import { LastUpdate_Update_Reducer } from "../../lib/Redux/slices/LastUpdate";

var SourceVal = JSON.parse(localStorage.getItem("source"));
var TargetVal = JSON.parse(localStorage.getItem("target"));

const sentences_lis = [
  {
    title: "Das Wetter ist gut heute.",
    tag: "classic",
    id: 1,
    done: 1,
    here: 0,
  },
  {
    title: "Das Wetter war sehr gut.",
    tag: "classic",
    id: 2,
    done: 1,
    here: 0,
  },
  {
    title: "Das Essen war sehr lecker.",
    tag: "slang",
    id: 3,
    done: 1,
    here: 0,
  },
  {
    title: "Das Fressen war sau lecker.",
    tag: "classic",
    id: 4,
    done: 1,
    here: 0,
  },
];

const reasonsflag = [
  { title: "mistake in the source language audio", id: 1 },
  { title: "mistake in the target language audio", id: 2 },
  { title: "mistake in the source language", id: 3 },
  { title: "mistake in the target language", id: 4 },
  { title: "words marked incorrectly", id: 5 },
  { title: "wrong tag (classic, slang, insult)", id: 6 },
  { title: "inappropriate sentence", id: 7 },
  { title: "other", id: 8 },
];

function OnBoard() {
  const location = useLocation();

  console.log(location, "dfsdf");

  const dispatch = useDispatch();
  const showToast = useToast();
  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [heading, setHeading] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);
  const [spaceword, setSpaceWord] = useState(
    "Thiswouldbeanexamplesentencewheretherearenospacesbetweenwords."
  );
  const [selected, setSelected] = useState([]);
  const [sentencwritten, setSentenceWritten] = useState([]);
  const [first, setFirst] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioRef = useRef();
  const [microphoneList, setMicrophoneList] = useState([]);
  const [selectedMicrophone, setSelectedMicrophone] = useState(null);
  const [flag, setFlag] = useState(false);

  const LastUpdate = useSelector((state) => state.LastUpdate);

  // console.log(LastUpdate,'last update ..');

  const SourceAndTargetAndSourceAudio = useSelector(
    (state) => state.SourceAndTargetAndSourceAudio
  );

  console.log(SourceAndTargetAndSourceAudio, "ddd");

  const user = useSelector((state) => state.user);
  const { NativeLanguage } = useSelector((state) => state);
  const course = useSelector((state) => state.Course);

  const isDialect = useSelector((state) => state.IsDialect);

  useEffect(() => {
    SourceVal = JSON.parse(localStorage.getItem("source"));
    TargetVal = JSON.parse(localStorage.getItem("target"));
  }, [SourceVal?.code, TargetVal?.code]);

  const show_popup = async (val) => {
    setHeading(val);
    setPopup(true);
  };

  const do_next_page = async () => {
    const SentenceId =
      SourceAndTargetAndSourceAudio?.data[
        location?.state?.count === null ? 0 : location?.state?.count
      ]?._id;
      console.log(SentenceId,'SentenceId...');
    if (audioBlob) {
      const audioLink = await UploadAssests(audioBlob);

      var obj = {};

      if (isDialect?.type) {
        obj = {
          checkSentence: "Source-Audio",
          sourceAudio: audioLink,
          // targetAudio: audioLink,
          // isAudioSourceTargetCheck: true,
        };
      } else {
        obj = {
          targetAudio: audioLink,
          checkSentence: "Target Audio",
          isAudioSourceCheck: true,
        };
      }

      try {
        dispatch(
          LastUpdate_Request({
            user: user.user,
            request: "put",
            data: obj,
            Api: isDialect.type
              ? `/api/v1/sentences-dia/${
                  SentenceId 
                }`
              : `/api/v1/sentences/${SentenceId}`,
          })
        );
      } catch (err) {
        setLoading(false);
        console.error("Error updating sentence:", err);
      }
    } else {
      showToast("Add your Target Audio", "warning");
    }
  };

  useEffect(() => {
    if (LastUpdate.success) {
      showToast(LastUpdate?.data?.message, "success");
      setTimeout(() => {
        navigate("/earn");
        dispatch(LastUpdate_Update_Reducer());
      }, 2000);
    }
  }, [LastUpdate.success]);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: selectedMicrophone },
    });

    const mediaRecorder = new MediaRecorder(stream);
    const chunks = [];

    mediaRecorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        chunks.push(e.data);
      }
    };

    mediaRecorder.onstop = () => {
      const blob = new Blob(chunks, { type: "audio/wav" });
      setAudioBlob(blob);
      const audioUrl = URL.createObjectURL(blob);
      audioRef.current.src = audioUrl;
    };

    mediaRecorderRef.current = mediaRecorder;
    mediaRecorder.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const playAudio = () => {
    if (audioBlob) {
      const audioUrl = URL.createObjectURL(audioBlob);
      audioRef.current.src = audioUrl;
      audioRef.current.play();
    }
  };

  const handleMicrophoneChange = async (deviceId) => {
    stopRecording();
    setSelectedMicrophone(deviceId);
  };

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const audioDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      setMicrophoneList(audioDevices);
    });
  }, []);

  return (
    <div className="container">
      <HeaderLogin />
      <div className="wrapper">
        <div className="langugae_courses_heading">
          <span>
            <span>
              {isDialect?.type ? (
                ""
              ) : (
                <img src={SourceVal && SourceVal?.flag} />
              )}
            </span>
            {SourceVal && SourceVal?.code}
          </span>
          <AiOutlineArrowRight />
          <span>
            <span>{isDialect?.type ? "" : <img src={TargetVal?.flag} />}</span>
            {isDialect?.type
              ? course?.data[0]?.targetLanguage?.name
              : TargetVal?.code}
          </span>
        </div>
        <div className="my_languages_wrap">
          <div className="box_left_add_ card">
            <div className="heading_languages mb-0">
              <div className="">Previous Sentences</div>
            </div>
            <div className="p10 previous_sentences">
              {!first ? (
                SourceAndTargetAndSourceAudio.loading ? (
                  <Loader />
                ) : (
                  SourceAndTargetAndSourceAudio?.data?.map((v, i) => (
                    <div className="senetence_" key={i}>
                      <span>#{i + 1}</span>
                      <span style={{wordBreak: 'break-word'}} >
                        {course?.data[0]?.spaces
                          ? v?.sourceContent.split(" ").join("")
                          : v?.sourceContent}
                      </span>
                    </div>
                  ))
                )
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="box_left_add_">
            <div className="card p15 wd100 text-center">
              <span>
                {" "}
                {isDialect?.type
                  ? "Please record this sentence"
                  : "Please record this sentence"}
              </span>
            </div>
            <div className="card p20 mt-10px">
              <div className="sentence_source text_user_show flex-space-between">
                <span>
                  {isDialect.type ? (
                    ""
                  ) : (
                    // <img src={NativeLanguage?.data?.flag} />
                    <img src={TargetVal?.flag} />
                  )}
                </span>
                <div className="" style={{ display: "flex",width:'90%',flexWrap:'wrap',height:'110%',overflowY:'auto',justifyContent:'center',alignItems:'center' }}>
                  {isDialect.type ? (
                    //////////////////
                    <>
                      {course?.data[0]?.spaces
                        ? SourceAndTargetAndSourceAudio?.data[location?.state?.count === null ? 0 : location?.state?.count]?.targetContentArray?.map((items) => {
                            return (
                              <>
                                <div className={items.type}>{items.title}</div>
                              </>
                            );
                          })
                        : SourceAndTargetAndSourceAudio?.data[
                            location?.state?.count === null
                              ? 0
                              : location?.state?.count
                          ]?.targetContentArray?.map((items) => {
                            return (
                              <>
                                <div
                                  className={items.type}
                                  style={{
                                    marginLeft: "7px",
                                    // border: "solid 1px red",
                                  }}
                                >
                                  {items.title}
                                </div>
                              </>
                            );
                          })}
                    </>
                  ) : ///////////////

                  SourceAndTargetAndSourceAudio.loading ? (
                    <Loader />
                  ) : course?.data[0]?.spaces ? (
                    SourceAndTargetAndSourceAudio?.data[
                      location?.state?.count === null
                        ? 0
                        : location?.state?.count
                    ]?.sourceContentArray.map((items) => {
                      return (
                        <>
                          <div className={items.type}>{items.title}</div>
                        </>
                      );
                    })
                  ) : (
                    SourceAndTargetAndSourceAudio?.data[
                      location?.state === null ? 0 : location?.state?.count
                    ]?.sourceContentArray.map((items) => {
                      return (
                        <>
                          <div
                            className={items.type}
                            style={{ marginLeft: "7px" }}
                          >
                            {items.title}
                          </div>
                        </>
                      );
                    })
                  )}
                </div>
                <span>
                  {isDialect.type ? (
                    ""
                  ) : (
                    // <img src={NativeLanguage?.data?.flag} />
                    <img src={TargetVal?.flag} />
                  )}
                </span>
              </div>

              <div
                className="add_sentence_source microphone_divs flex-space-between"
                style={{ alignItems: "flex-end" }}
              >
                <div className="icon_flag pointer"></div>
                <div className="microphone_width">
                  {isRecording ? (
                    <div className="microphone pointer" onClick={stopRecording}>
                      <FaRegPauseCircle size={70} color="var(--dark-color)" />
                    </div>
                  ) : (
                    <div
                      className="microphone pointer"
                      onClick={startRecording}
                      disabled={isRecording}
                    >
                      <IoMicCircleSharp
                        size={70}
                        color="var(--primary-color)"
                      />
                    </div>
                  )}
                  <audio
                    ref={audioRef}
                    controls
                    style={{ display: ` ${audioBlob ? "block" : "none"} ` }}
                  />
                </div>

                <div className="">
                  <div style={{ marginBottom: "10px", position: "relative" }}>
                    {/* <HiOutlineSpeakerWave
                      onClick={playAudio}
                      style={{
                        cursor: audioBlob && "pointer",
                        color: audioBlob ? "black" : "lightgray",
                      }}
                    /> */}
                  </div>
                  <select
                    onChange={(e) => handleMicrophoneChange(e.target.value)}
                    style={{ width: "100px" }}
                  >
                    <option value={null}>Microphone</option>
                    {microphoneList.map((microphone) => (
                      <option
                        key={microphone.deviceId}
                        value={microphone.deviceId}
                      >
                        {microphone.label ||
                          `Microphone ${
                            microphoneList.indexOf(microphone) + 1
                          }`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div
                className="question_mark_"
                data-tooltip-id="question_new_sentence"
                data-tooltip-html={
                  '"Please record this sentence. Make sure that you use a microphone with a good quality and avoid background, rustling or otherwise distracting noise. Small dialectal varieties are desirable, but please keep it civil. Pronounce the sentence like you would if you were speaking with a fellow native speaker who is not familiar with your local dialect.'
                }
              >
                <AiFillQuestionCircle />
              </div>
              <Tooltip
                id="question_new_sentence"
                style={{
                  textAlign: "center",
                  padding: "13px 10px",
                  fontSize: "13px",
                  zIndex: 9,
                  width: "350px",
                }}
              />
            </div>
          </div>

          <div className="box_left_add_ tags_button_next flex-space-between p20">
            <div className="button_tags">
              <button
                className="button_custom_med secondary"
                onClick={() => navigate(-1)}
              >
                <AiOutlineArrowLeft /> Back
              </button>
              <button
                className="button_custom_med primary mt-20px"
                onClick={do_next_page}
              >
                {LastUpdate.loading ? <Loader /> : "Finish"}
              </button>
            </div>
          </div>
        </div>
      </div>

      {flag && (
        <div className="outer_wrapper">
          <div className="inner_wrapper">
            <div className="white_wrapper w40">
              <div className="heading_languages">
                <div className="">Report the sentence</div>
                <span className="pointer" onClick={() => setFlag(false)}>
                  <IoCloseCircleOutline />
                </span>
              </div>
              <div className="" style={{ padding: "0px 40px" }}>
                {reasonsflag.map((v, i) => (
                  <div className="flag_reasons" key={i}>
                    <label>
                      <input type="radio" value={v.id} name="flag" />
                      <span>{v.title}</span>
                    </label>
                  </div>
                ))}

                <div className="flag_textarea">
                  <textarea
                    className="form-control"
                    placeholder="Please tell us more (optional)"
                  ></textarea>
                </div>
              </div>
              <div className="footer_languages">
                <div className=""></div>
                <span onClick={() => setFlag(false)}>
                  <button className="button_custom_round primary">
                    Submit
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OnBoard;
