import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail, LanguagesWords } from "../../utils/functions";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Earn from "../../assets/images/earn-money.png";
import Learn from "../../assets/images/learn_img.png";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
// import { HiOutlineSpeakerWave } from "react-icons/hi2";
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillPlusCircle,
  AiFillQuestionCircle,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineAudio,
} from "react-icons/ai";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaHandPointRight, FaRegClock } from "react-icons/fa6";
import { LuClipboardList, LuRepeat } from "react-icons/lu";
import { HiOutlineSpeakerWave } from "react-icons/hi2";

import { Tooltip } from "react-tooltip";
import Flag1 from "../../assets/images/eng.png";
import Flag2 from "../../assets/images/russia.png";
import Source from "./../../assets/source.mp3";
import { useSelector } from "react-redux";
import Loader from "../../base-components/Loader/Loader";
import useToast from "../../hooks/useToast";

var SourceVal = JSON.parse(localStorage.getItem("source"));
var TargetVal = JSON.parse(localStorage.getItem("target"));

const sentences_lis = [
  {
    title: "Das Wetter ist gut heute.",
    tag: "classic",
    id: 1,
    done: 1,
    here: 0,
  },
  {
    title: "Das Wetter war sehr gut.",
    tag: "classic",
    id: 2,
    done: 1,
    here: 0,
  },
  {
    title: "Das Essen war sehr lecker.",
    tag: "slang",
    id: 3,
    done: 1,
    here: 0,
  },
  {
    title: "Das Fressen war sau lecker.",
    tag: "classic",
    id: 4,
    done: 1,
    here: 0,
  },
];

const source_text = [
  { title: "The", type: "" },
  { title: "weather", type: "" },
  { title: "is", type: "" },
  { title: "good", type: "" },
  { title: "today", type: "" },
];

const target_text = [
  { title: "Погода", type: "" },
  { title: "сегодня", type: "" },
  { title: "хорошая", type: "" },
];

function OnBoard() {
  const location = useLocation();
  const showToast = useToast();

  // console.log(location,'locatoin...');

  // console.log(location.state.sourceContent, location.state.targetContent);
  const course = useSelector((state) => state.Course);

  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [heading, setHeading] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);

  const [sourcetext, setSourceText] = useState(source_text);
  const [targettext, setTargetText] = useState(target_text);

  const [sourceselected, setSourceSelected] = useState("");
  const [targetselected, setTargetSelected] = useState("");

  const [selected, setSelected] = useState([]);
  const [audio, setAudio] = useState(null);

  const isDialect = useSelector((state) => state.IsDialect);

  useEffect(() => {
    SourceVal = JSON.parse(localStorage.getItem("source"));
    TargetVal = JSON.parse(localStorage.getItem("target"));
  }, [SourceVal?.code, TargetVal?.code]);

  const show_popup = async (val) => {
    setHeading(val);
    setPopup(true);
  };

  const do_next_page = async () => {
    navigate("/step/three");
  };

  const do_source_click = async (v) => {
    setTargetSelected("");
    if (sourceselected == v.title) {
      setSourceSelected("");
    } else {
      setSourceSelected(v.title);
    }
  };

  const do_target_click = async (v) => {
    setSourceSelected("");
    if (targetselected == v.title) {
      setTargetSelected("");
    } else {
      setTargetSelected(v.title);
    }
  };

  const handleLangwords = (type) => {
    if (sourceselected) {
      var selectedIndex = sourcetext.findIndex(
        (item) => item.title === sourceselected
      );
    } else {
      var selectedIndex = targettext.findIndex(
        (item) => item.title === targetselected
      );
    }

    if (selectedIndex !== -1) {
      if (sourceselected) {
        const updatedSourceText = [...sourcetext];
        updatedSourceText[selectedIndex].type = type;
        setSourceText(updatedSourceText);
      } else {
        const updatetargettext = [...targettext];
        updatetargettext[selectedIndex].type = type;
        setTargetText(updatetargettext);
      }
      setSourceSelected("");
      setTargetSelected("");
    }
  };

  const SourceAndTargetAndSourceAudio = useSelector(
    (state) => state.SourceAndTargetAndSourceAudio
  );
  const user = useSelector((state) => state.user);
  const { NativeLanguage } = useSelector((state) => state);

  const [checkedReasons, setCheckedReasons] = useState([]);

  const reasonsflag = [
    {
      title: "mistake in the source language audio",
      id: 1,
      name: "source audio mistake",
    },
    {
      title: "mistake in the source language",
      id: 3,
      name: "source language mistake",
    },
    {
      title: "mistake in the target language",
      id: 4,
      name: "target language mistake",
    },
    { title: "words marked incorrectly", id: 5, name: "marked incorrectly" },
    { title: "wrong tag (classic, slang, insult)", id: 6, name: "wrong tag" },
    { title: "inappropriate sentence", id: 7, name: "inappropriate sentence" },
    { title: "other", id: 8, name: "other mistake" },
  ];

  const handleCheckboxChange = (event) => {
    const { id, checked, name, title } = event.target;

    console.log(event.target.name, "nnn");

    setCheckedReasons((prevState) => {
      if (checked) {
        return [...prevState, { id, name, checked, title }];
      } else {
        return prevState.filter((item) => item.id !== id);
      }
    });

    console.log(checkedReasons, "resons");
  };

  const handleMove = (path) => {
    navigate(path, {
      state: {
        checkedReasons,
        sourceaudiohere:
          SourceAndTargetAndSourceAudio?.data[location?.state?.count]
            ?.sourceAudio,
        taghere:
          SourceAndTargetAndSourceAudio?.data[location?.state?.count]?.tag,
        sourcehere:
          SourceAndTargetAndSourceAudio?.data[location?.state?.count]
            ?.sourceContentArray,
        targethere:
          SourceAndTargetAndSourceAudio?.data[location?.state?.count]
            ?.targetContentArray,
        isSourceAudio: checkedReasons.some(
          (items) => items.name === "source audio mistake"
        ),
        isSource: checkedReasons.some(
          (items) => items.name === "source language mistake"
        ),
        isTarget: checkedReasons.some(
          (items) => items.name === "target language mistake"
        ),
        isIncorrectly: checkedReasons.some(
          (items) => items.name === "marked incorrectly"
        ),
        isWrongTag: checkedReasons.some((items) => items.name === "wrong tag"),
        isInappropriate: checkedReasons.some(
          (items) => items.name === "inappropriate sentence"
        ),
        other: checkedReasons.some((items) => items.name === "other mistake"),
        Content: SourceAndTargetAndSourceAudio?.data[location?.state?.count],
        count: location?.state?.count,
      },
    });
  };

  const handleNext = () => {
    console.log(checkedReasons, "ch");
    if (checkedReasons && checkedReasons?.length <= 0) {
      showToast("Please Select the tag", "error");
    } else {
      // checkedReasons.some((items) => items.name !== "source language mistake" || items.name !== "target language mistake" || items.name !== "wrong tag"

      if (
        checkedReasons.some(
          (items) =>
            items.name == "source language mistake" ||
            items.name == "target language mistake" ||
            items.name == "wrong tag"
        )
      ) {
        handleMove("/mistake/response");
      } else {
        handleMove("/mistake/comment");
      }
    }

    //////////////////////////////////////

    // if (
    //   checkedReasons.some((items) => items.name == "wrong tag") &&
    //   checkedReasons.length == 1
    // ) {
    //   navigate("/mistake/response", {
    //     state: {
    //       checkedReasons,
    //       isSource: false,
    //       isTarget: false,
    //       sourceAudio: SourceAndTargetAndSourceAudio?.data[
    //         location?.state && location?.state?.count
    //       ]?.sourceAudio,
    //       sourceContent:
    //         SourceAndTargetAndSourceAudio?.data[
    //           location?.state && location?.state?.count
    //         ]?.sourceContent,
    //       targetContent:
    //         SourceAndTargetAndSourceAudio?.data[
    //           location?.state && location?.state?.count
    //         ]?.targetContent,
    //       count: location?.state?.count,
    //     },
    //   });

    //   return;
    // }

    // if (
    //   checkedReasons.some((items) => items.name === "source language mistake")
    // ) {
    //   navigate("/mistake/response", {
    //     state: {
    //       checkedReasons,
    //       isSource: true,
    //       isTarget: false,
    //       sourceAudio: SourceAndTargetAndSourceAudio?.data[
    //         location?.state && location?.state?.count
    //       ]?.sourceAudio,
    //       sourceContent:
    //         SourceAndTargetAndSourceAudio?.data[
    //           location?.state && location?.state?.count
    //         ]?.sourceContent,
    //       targetContent:
    //         SourceAndTargetAndSourceAudio?.data[
    //           location?.state && location?.state?.count
    //         ]?.targetContent,
    //       count: location?.state?.count,
    //     },
    //   });
    // }
    // if (
    //   checkedReasons.some((items) => items.name === "target language mistake")
    // ) {
    //   navigate("/mistake/response", {
    //     state: {
    //       checkedReasons,
    //       isSource: false,
    //       isTarget: true,
    //       sourceAudio: SourceAndTargetAndSourceAudio?.data[
    //         location?.state && location?.state?.count
    //       ]?.sourceAudio,
    //       sourceContent:
    //         SourceAndTargetAndSourceAudio?.data[
    //           location?.state && location?.state?.count
    //         ]?.sourceContent,
    //       targetContent:
    //         SourceAndTargetAndSourceAudio?.data[
    //           location?.state && location?.state?.count
    //         ]?.targetContent,
    //       count: location?.state?.count,
    //     },
    //   });
    // }
    // if (
    //   checkedReasons.some(
    //     (items) => items.name === "source language mistake"
    //   ) &&
    //   checkedReasons.some((items) => items.name === "target language mistake")
    // ) {
    //   // console.log('T S',checkedReasons,'T S');
    //   navigate("/mistake/response", {
    //     state: {
    //       checkedReasons,
    //       isSource: true,
    //       isTarget: true,
    //       sourceAudio: SourceAndTargetAndSourceAudio?.data[
    //         location?.state && location?.state?.count
    //       ]?.sourceAudio,
    //       sourceContent:
    //         SourceAndTargetAndSourceAudio?.data[
    //           location?.state && location?.state?.count
    //         ]?.sourceContent,
    //       targetContent:
    //         SourceAndTargetAndSourceAudio?.data[
    //           location?.state && location?.state?.count
    //         ]?.targetContent,
    //       count: location?.state?.count,
    //     },
    //   });
    // }

    // if (
    //   checkedReasons.some((items) => items.name === "source audio mistake") &&
    //   checkedReasons.length === 1
    // ) {
    //   console.log("source..eeeeeeeeeeee");
    //   navigate("/mistake/comment", {
    //     state: {
    //       checkedReasons,
    //       isSource: false,
    //       isTarget: false,
    //       sourceAudio: SourceAndTargetAndSourceAudio?.data[
    //         location?.state && location?.state?.count
    //       ]?.sourceAudio,
    //       sourceContent:
    //         SourceAndTargetAndSourceAudio?.data[location?.state?.count]
    //           ?.sourceContent,
    //       // targetContent:
    //       //   SourceAndTargetAndSourceAudio?.data[location?.state?.count]
    //       //     ?.targetContent,
    //       count: location?.state?.count,
    //       ...(checkedReasons.some(
    //         (items) => items.name === "source audio mistake"
    //       ) && {
    //         sourceAudio:
    //           SourceAndTargetAndSourceAudio?.data[location?.state?.count]
    //             ?.sourceAudio,
    //       }),
    //     },
    //   });

    //   return;
    // }

    // if (
    //   !checkedReasons.some(
    //     (items) => items.name === "source language mistake"
    //   ) &&
    //   !checkedReasons.some(
    //     (items) => items.name === "target language mistake"
    //   ) &&
    //   !checkedReasons.some((items) => items.name === "source audio mistake")
    // ) {
    //   console.log("other ...............");
    //   navigate("/mistake/comment", {
    //     state: {
    //       // checkedReasons,
    //       isSource: false,
    //       isTarget: false,
    //       check:
    //         checkedReasons.some((items) => items.name === "other mistake") &&
    //         checkedReasons.some(
    //           (items) => items.name === "inappropriate sentence"
    //         )
    //           ? ["inappropriate", "other"]
    //           : checkedReasons.some(
    //               (items) => items.name === "other mistake"
    //             ) &&
    //             checkedReasons.some(
    //               (items) => items.name === "marked incorrectly"
    //             )
    //           ? ["marked incorrectly", "other"]
    //           : checkedReasons.some(
    //               (items) => items.name === "inappropriate sentence"
    //             ) &&
    //             checkedReasons.some(
    //               (items) => items.name === "marked incorrectly"
    //             )
    //           ? ["inappropriate sentence", "marked incorrectly"]
    //           : checkedReasons.some((items) => items.name === "other mistake")
    //           ? ["other"]
    //           : checkedReasons.some(
    //               (items) => items.name === "inappropriate sentence"
    //             )
    //           ? ["inappropriate"]
    //           : checkedReasons.some(
    //               (items) => items.name === "marked incorrectly"
    //             )
    //           ? ["marked incorrectly"]
    //           : "",

    //       // console.log(check);

    //       // ['marked incorrectly']

    //       // sourceContent:
    //       //   SourceAndTargetAndSourceAudio?.data[location?.state?.count]
    //       //     ?.sourceContent,
    //       // targetContent:
    //       //   SourceAndTargetAndSourceAudio?.data[location?.state?.count]
    //       //     ?.targetContent,
    //       // count: location?.state?.count,
    //       // ...(checkedReasons.some(
    //       //   (items) => items.name === "source audio mistake"
    //       // ) && {
    //       //   sourceAudio:
    //       //     SourceAndTargetAndSourceAudio?.data[location?.state?.count]
    //       //       ?.sourceAudio,
    //       // }),
    //     },
    //   });
    // }
  };

  const do_play_audio = () => {
    console.log(
      "play",
      SourceAndTargetAndSourceAudio?.data[
        location?.state && location?.state?.count
      ]?.sourceAudio
    );

    const newAudio = new Audio(
      SourceAndTargetAndSourceAudio?.data[
        location?.state && location?.state?.count
      ]?.sourceAudio
    );
    newAudio.pause();
    newAudio.play();
    setAudio(true);
  };

  return (
    <div className="container">
      <HeaderLogin />
      <div className="wrapper">
        <div className="langugae_courses_heading">
          <span>
            <span>{isDialect.type ? "" : <img src={SourceVal?.flag} />}</span>{" "}
            {SourceVal?.code}
          </span>
          <AiOutlineArrowRight />{" "}
          <span>
            <span>
              {isDialect && isDialect?.type ? (
                ""
              ) : (
                <img src={TargetVal && TargetVal?.flag} />
              )}
            </span>{" "}
            {isDialect.type
              ? course?.data[0]?.targetLanguage?.name
              : TargetVal?.code}
          </span>{" "}
        </div>
        <div className="my_languages_wrap">
          <div className="box_left_add_ card" style={{ height: "34vh" }}>
            <div className="heading_languages mb-0">
              <div className="">Previous Sentences</div>
            </div>
            <div className="p10 previous_sentences">
              {!SourceAndTargetAndSourceAudio?.loading ? (
                SourceAndTargetAndSourceAudio?.data?.map((v, i) => (
                  <>
                    <div className="senetence_">
                      <span>#{i + 1}</span>
                      <span
                        style={{
                         wordBreak: 'break-word',
                         display:'flex',
                         width:'87%',
                         flexWrap:'wrap',
                          // justifyContent:'center'
                        }}
                      >
                        {course?.data[0]?.spaces
                          ? v?.sourceContentArray?.length >= 1 &&
                            v?.sourceContentArray?.map((items) => {
                              return (
                                <div
                                // className={items.type}
                                // style={{ marginLeft: "7px" }}
                                >
                                  {items.title}
                                </div>
                              );
                            })
                          : v?.sourceContentArray?.map((items) => {
                              return (
                                <div
                                  // className={items.type}
                                  style={{ marginLeft: "7px" }}
                                >
                                  {items.title}
                                </div>
                              );
                            })}
                      </span>
                    </div>
                  </>
                ))
              ) : (
                <Loader />
              )}
            </div>
          </div>

          <div className="box_left_add_">
            <div className="card p15 wd100 text-center">
              Please select the mistake
            </div>
            <div
              className="card p20 mt-10px"
              style={{ height: "max-content" }}
            >
              <div
                className="sentence_source flex-startpointer text_user_show flex-space-between"
                style={{ position: "relative" }}
              >
                <span>
                  {isDialect.type ? "" : <img src={SourceVal?.flag} />}
                </span>
                <span
                  style={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    maxHeight: "145%",
                    overflowY: "auto",
                    justifyContent:'center'
                  }}
                >
                  {SourceAndTargetAndSourceAudio.loading ? (
                    <Loader />
                  ) : course?.data[0]?.spaces ? (
                    SourceAndTargetAndSourceAudio?.data[
                      location?.state && location?.state?.count
                    ]?.targetContentArray?.map((items) => {
                      return (
                        <>
                          <div className={items.type}>{items.title}</div>
                        </>
                      );
                    })
                  ) : (
                    SourceAndTargetAndSourceAudio?.data[
                      location?.state && location?.state?.count
                    ]?.targetContentArray?.map((items) => {
                      return (
                        <>
                          <div
                            style={{ marginLeft: "7px" }}
                            className={items.type}
                          >
                            {items.title}
                          </div>
                        </>
                      );
                    })
                  )}
                </span>
                <span>
                  {isDialect.type ? "" : <img src={SourceVal?.flag} />}
                </span>

                {/* ////// */}

                <span className="pointer" onClick={() => do_play_audio()}>
                  <HiOutlineSpeakerWave />
                </span>

                {/* ///// */}
              </div>

              <div className="sentence_source flex-start  mt-10px  pointer text_user_show flex-space-between">
                <span>
                  {isDialect.type ? "" : <img src={TargetVal?.flag} />}
                </span>
                <span
                  style={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    maxHeight: "145%",
                    overflowY: "auto",
                    justifyContent:'center'
                  }}
                >
                  {SourceAndTargetAndSourceAudio.loading ? (
                    <Loader />
                  ) : course?.data[0]?.spaces ? (
                    SourceAndTargetAndSourceAudio?.data[
                      location?.state && location?.state?.count
                    ]?.sourceContentArray?.map((item) => {
                      return (
                        <>
                          <div className={item.type}>{item?.title}</div>
                        </>
                      );
                    })
                  ) : (
                    SourceAndTargetAndSourceAudio?.data[
                      location?.state && location?.state?.count
                    ]?.sourceContentArray?.map((item) => {
                      return (
                        <>
                          <div
                            style={{ marginLeft: "7px" }}
                            className={item.type}
                          >
                            {item?.title}
                          </div>
                        </>
                      );
                    })
                  )}
                </span>
                <span>
                  {isDialect.type ? "" : <img src={TargetVal?.flag} />}
                </span>
              </div>

              <div
                className="question_mark_"
                data-tooltip-id="question_new_sentence"
                data-tooltip-html="Please select the mistake. You can select more than one."
              >
                <AiFillQuestionCircle />
              </div>
              <Tooltip
                id="question_new_sentence"
                style={{
                  textAlign: "center",
                  padding: "13px 10px",
                  fontSize: "13px",
                  zIndex: 9,
                  width: "350px",
                }}
              />

              {SourceAndTargetAndSourceAudio?.data[
                location?.state && location?.state?.count
              ]?.tag && (
                <div
                  style={{
                    border: "solid 1px lightgray",
                    borderRadius: "20px",
                    padding: "7px",
                    fontSize: "12px",
                    marginTop:'10px',
                    width:'max-content',
                    float:'right'
                  }}
                >
                  {
                    SourceAndTargetAndSourceAudio?.data[
                      location?.state && location?.state?.count
                    ]?.tag
                  }
                </div>
              )}
            </div>

            <div className="checks_buttons flex-space-between">
              <div
                className="langword_bottom"
                style={{ gap: "0px", marginLeft: "20%" }}
              >
                {reasonsflag.map((reason) => (
                  <div key={reason.id} className="flag_reasons wd100 pointer">
                    <label className="pointer">
                      <input
                        name={reason.name}
                        type="checkbox"
                        id={reason.id.toString()} // Convert id to string for consistency
                        checked={
                          // Check if the current reason is in the checkedReasons array
                          checkedReasons.some(
                            (item) => item.id === reason.id.toString()
                          )
                        }
                        onChange={handleCheckboxChange}
                      />
                      <span>{reason.title}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div
            className="box_left_add_ tags_button_next flex-space-between  p20"
            style={{ flexDirection: "column" }}
          >
            <div className="flex-space-between gap_20 custom_tags_button">
              {/* <div className="tags_left">
                <label>
                  <input type="checkbox" val={1} checked />
                  Classic
                </label>
              </div> */}

              <div className="button_tags">
                <button
                  className="button_custom_med secondary"
                  onClick={() => navigate(-1)}
                >
                  <AiOutlineArrowLeft /> Back{" "}
                </button>
                <button
                  className="button_custom_med primary mt-20px"
                  onClick={() => handleNext()}
                >
                  Next <AiOutlineArrowRight />
                </button>
              </div>
            </div>
            <div className="langword_bottom">
              {LanguagesWords().map((v, i) => (
                <>
                  <Link
                    to={v.link}
                    target="_blank"
                    onClick={() => handleLangwords(v.type)}
                    key={"word_" + i}
                    className={v.type}
                  >
                    {v.title}
                  </Link>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnBoard;
