// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
};

const LocalSentance = createSlice({
  name: 'LocalSentance',
  initialState,
  reducers: {
    LocalSentance_Reducer: (state,action) => {
      state.data = action.payload
    },
  },
});

export const { LocalSentance_Reducer } = LocalSentance.actions;

export default LocalSentance.reducer;