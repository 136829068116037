import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LOGO from "./../../assets/images/logo.svg";

function Header({ onSearch }) {
  const navigate = useNavigate();

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  const do_make_action = () => {
    document.body.classList.add("mobile_view_p");
  };

  const location = useLocation();

  return (
    <>
      <div className="">
        <div
          className="header_fixed"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Link to="/" className="logo">
            <img src={LOGO} />
          </Link>

          <button
            style={{
              border: "solid 1px #F89700",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "20px",
              color: "#F89700",
              cursor: "pointer",
              marginRight: "20px",
            }}
            onClick={() => navigate("/login")}
          >
            Login
          </button>

          {/* <div className="wrapper_full mobil_needede">
            <div className="flex-space-between">
              <div className="mobile_view">
                <div className="top_logo_section">
                  <div className="no_mobile dashboard_header_logo">
                    <img src={LOGO} />
                  </div>
                </div>
              </div>
              <div className="new_desktop flex-space-between wd100">
                <Link to="/" className="logo">
                  <img src={LOGO} />
                </Link>
                {!location.pathname === "/" ? (
                  <div className="buttons_login">
                    <Link to="/login">
                      <button className="button_custom_round primary">
                        Log in
                      </button>
                    </Link>
                    <Link to="/signup">
                      <button className="button_custom_round white">
                        Sign up
                      </button>
                    </Link>
                  </div>
                ) : (
                  ""
                )}

                <button
                  style={{
                    border: "solid 1px #F89700",
                    backgroundColor: "white",
                    padding: "10px",
                    borderRadius: "20px",
                    color: "#F89700",
                    cursor: "pointer",
                    marginRight: "20px",
                  }}
                  onClick={() => navigate("/login")}
                >
                  Login 
                </button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
export default Header;
