// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { GetlanguageRequest } from '../../actions';


const initialState = {
  data:[],
  loading: false,
  error: null,
};

const EachCourseSlice = createSlice({
  name: 'EachCourseSlice',
  initialState,
  reducers: {
    GetEachCourse: (state,action) => {
      state.data=action.payload;
    },
  },
});

export const {GetEachCourse} = EachCourseSlice.actions;

export default EachCourseSlice.reducer;

