// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {

  data: [
    "nouns",
    "pronouns",
    "verbs",
    "adverbs",
    "adjectives",
    "prepositions",
    "conjunctions",
    "interjections",
    "articles",
    "determiners",
  ],
 
  selectedVerbs: [],
  loading: false,
  error: null,
  success: false,
};

const Verb = createSlice({
  name: 'Verbvalue',
  initialState,
  reducers: {
    Verb_Reducer: (state, action) => {
      state.data = action.payload;
    },
    toggleVerb: (state, action) => {
      const verb = action.payload;
      // console.log(verb,'be');
      if (state.data?.includes(verb)) {
        state.data = state.data.filter((v) => v !== verb);
      } else {
        state.data.push(verb);
      }
    },
    selectAllVerbs: (state, action) => {
      state.data = action.payload;
    },
    deselectAllVerbs: (state) => {
      state.data = [];
    },
  },
});

export const { Verb_Reducer, toggleVerb, selectAllVerbs, deselectAllVerbs } = Verb.actions;

export default Verb.reducer;
