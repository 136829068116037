// store.js
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  languagesSlice,
  nonPersistedSlice,
  userslice,
  SourceSlice,
  SourceAndTargetSlice,
  SourceAndTargetAndSourceAudioSlice,
  SourceAndTargetAndSourceAudioAndTargetAudioSlice,
  CourseSlice,
  LastUpdateSlice,
  AllCommentsSlice,
  UserOwnCourse,
  UserOwnDialects,
  EachCourseSlice,
  IsDialectSlice,
  NativeLanguage,
  WordArraySlice,
  SentanceFilter,
  CourseId,
  LocalSentance,
  profileVerified,
  C1Slice,
  RightToLeft,
  Verb,
  ExtraSlice,
} from "./slices";
// import sagaMiddleware from 'redux-saga';
import createSagaMiddleware from "redux-saga";

import rootSaga from "../saga/Rootsaga";
import { CoursesSentenceFailure } from "./actions";

const rootReducer = combineReducers({
  user: userslice,
  UserTypes: nonPersistedSlice,
  languages: languagesSlice,
  Source: SourceSlice,
  SourceAndTarget: SourceAndTargetSlice,
  SourceAndTargetAndSourceAudio: SourceAndTargetAndSourceAudioSlice,
  AllAvilable: SourceAndTargetAndSourceAudioAndTargetAudioSlice,
  Course: CourseSlice,
  LastUpdate: LastUpdateSlice,
  AllComments: AllCommentsSlice,
  UserOwnCourse: UserOwnCourse,
  UserOwnDialects: UserOwnDialects,
  EachCourse: EachCourseSlice,
  IsDialect:IsDialectSlice,
  NativeLanguage:NativeLanguage,
  WordArray:WordArraySlice,
  SentanceFilter:SentanceFilter,
  CourseId:CourseId,
  LocalSentance:LocalSentance,
  profileVerified:profileVerified,
  C1:C1Slice,
  RightToLeft:RightToLeft,
  Verb:Verb,
  ExtraSlice:ExtraSlice,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "user",
    "UserTypes",
    "languages",
    "Source",
    "SourceAndTarget",
    "SourceAndTargetAndSourceAudio",
    "AllAvilable",
    "Course",
    "LastUpdate",
    "AllComments",
    "UserOwnCourse",
    "UserOwnDialects",
    "EachCourse",
    "IsDialect",
    "NativeLanguage",
    "WordArraySlice",
    "SentanceFilter",
    "CourseId",
    "LocalSentance",
    "profileVerified",
    "C1Slice",
    'RightToLeft',
    'Verb',
    'ExtraSlice',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
