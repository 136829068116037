import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../base-components/Header";
import { VERIFY_OTP_API } from "../../constants/apiEndPoints";
import httpRequest from "../../axios/index.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function OTPVerification() {
  const navigate = useNavigate();
  const [otp, setOTP] = useState("");
  const { TempForgotEmail } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await httpRequest.post(VERIFY_OTP_API, {
        email: TempForgotEmail,
        otp,
      });
      if (response.status === 200 || response.status === 201) {
        setLoading(false);
        toast.success(response?.data?.message);
        navigate("/reset-success");
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  const handleOTPChange = (e) => {
    // Allow only numeric input and limit to 4 digits
    setOTP(e.target.value.replace(/\D/, "").slice(0, 4));
  };

  return (
    <div className="container">
      <Header />
      <div className="wrapper_full">
        <div className="login-container">
          <div className="">
            <h1>OTP Code</h1>
            <p>
              or <Link to="/login">Log in</Link>
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form_middle">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="otp"
                  maxLength={4}
                  placeholder="Enter OTP Code"
                  value={otp}
                  onChange={handleOTPChange}
                  required
                />
              </div>

              <div className="form-group ">
                <div className="center_button_custom_width">
                  <button type="submit" className="button_custom primary wd100">
                    {loading ? "Verifying.." : "Reset Password"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default OTPVerification;
