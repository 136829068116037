// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success:false,
};

const WordArraySlice = createSlice({
  name: 'wordsarray',
  initialState,
  reducers: {
    Get_WordArraySlice_Request_Reducer: (state) => {
      state.loading = true;
      state.error = null;
    },
    Get_WordArraySlice_Success_Reducer: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
      state.success = true;
    },
    Get_WordArraySlice_Failer_Reducer: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    Get_WordArraySlice_Update_Reducer: (state, action) => {
        state.success = true
      },
  },
});

export const { Get_WordArraySlice_Request_Reducer,  Get_WordArraySlice_Success_Reducer, Get_WordArraySlice_Failer_Reducer , Get_WordArraySlice_Update_Reducer} = WordArraySlice.actions;

export default WordArraySlice.reducer;