import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import { HiOutlineSpeakerWave } from "react-icons/hi2";
import { IoCloseCircleOutline, IoMicCircleSharp } from "react-icons/io5";
import { FaRegPauseCircle } from "react-icons/fa";
import { validateEmail, LanguagesWords } from "../../utils/functions";
import { LastUpdate_Request } from "../../lib/Redux/actions";
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillPlusCircle,
  AiFillQuestionCircle,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import CheckSentence from "./CheckSentance";
import useToast from "../../hooks/useToast";
import { LastUpdate_Update_Reducer } from "../../lib/Redux/slices/LastUpdate";
import { UploadAssests } from "../../utils/Aws_Upload";
// import { IoCloseCircleOutline, IoMicCircleSharp } from "react-icons/io5";

const CorrectPage = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [microphoneList, setMicrophoneList] = useState([]);

  const dispatch = useDispatch();

  const isDialect = useSelector((state) => state.IsDialect);
  const IsDialectCondion = useSelector((state) => state?.IsDialect?.type);
  const [n, setN] = useState();

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const audioDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      setMicrophoneList(audioDevices);
    });
  }, []);

  // console.log(isDialect, "iiidd");

  const questions = [
    {
      question: IsDialectCondion
        ? "Is the dialect sentence correct?"
        : "Is the target language sentence grammatically correct?",
      id: 1,
    },
    {
      question: IsDialectCondion
        ? "Is the dialect audio correct and good"
        : "Is the target language audio correct and good?",
      id: 3,
    },
    {
      question: "Is the source language sentence grammatically correct?",
      id: 4,
    },
    { question: "Is the source language audio correct and good?", id: 6 }, // source audio
    { question: "Is the tag set correctly?", id: 7 }, // tag
  ];

  const location = useLocation();
  const audioRef = useRef();
  const mediaRecorderRef = useRef(null);
  const [selectedMicrophone, setSelectedMicrophone] = useState(null);
  const LastUpdate = useSelector((state) => state.LastUpdate);
  const [audioBlob, setAudioBlob] = useState(null);
  const [val, setVal] = useState(null);
  //   console.log(location?.state, "sss");

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: selectedMicrophone },
    });

    const mediaRecorder = new MediaRecorder(stream);
    const chunks = [];

    mediaRecorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        chunks.push(e.data);
      }
    };

    mediaRecorder.onstop = () => {
      const blob = new Blob(chunks, { type: "audio/wav" });
      setAudioBlob(blob);
      const audioUrl = URL.createObjectURL(blob);
      audioRef.current.src = audioUrl;
    };

    mediaRecorderRef.current = mediaRecorder;
    mediaRecorder.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleMicrophoneChange = async (deviceId) => {
    stopRecording();
    setSelectedMicrophone(deviceId);
  };

  const [classic, setClassic] = useState(false);
  const [slang, setSlang] = useState(false);
  const [insult, setInsult] = useState(false);

  const showToast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    // Set initial radio button state based on location.state.sentance.tag
    if (location?.state?.sentance.tag === "classic") {
      setClassic(true);
      setSlang(false);
      setInsult(false);
    } else if (location?.state?.sentance.tag === "slang") {
      setClassic(false);
      setSlang(true);
      setInsult(false);
    } else if (location?.state?.sentance.tag === "insult") {
      setClassic(false);
      setSlang(false);
      setInsult(true);
    }
  }, [location?.state?.sentance.tag]);

  const handleTag = (val) => {
    if (val === "classic") {
      setClassic(!classic);
      setInsult(false);
      setSlang(false);
    }
    if (val === "insult") {
      setClassic(false);
      setInsult(!insult);
      setSlang(false);
    }
    if (val === "slang") {
      setClassic(false);
      setInsult(false);
      setSlang(!slang);
    }
  };

  ///////////////////////////////////////

  const handleItemClick = (index) => {
    // Toggle 'selected' type on clicked item, and reset type of previously selected item
    const updatedVal = val.map((item, i) => ({
      ...item,
      type:
        i === index ? (item.type === "selected" ? "" : "selected") : item.type,
    }));
    setVal(updatedVal);
  };

  const handleTypeChange = (typeValue) => {
    // Find the index of the currently selected item
    const selectedIndex = val.findIndex((item) => item.type === "selected");
    if (selectedIndex !== -1) {
      // Update the type of the selected item
      setVal((prevVal) =>
        prevVal.map((item, index) => ({
          ...item,
          type: index === selectedIndex ? typeValue : item.type,
        }))
      );
    }

    // console.log(val, "vlll");
  };

  const handleChange = (e) => {
    const inputValue = e.target.value.trim(); // Trim whitespace from the input

    if (inputValue === "") {
      setVal([]); // Clear the values if input is empty
    } else {
      const words = inputValue.split(/\s+/); // Split input into words using whitespace regex
      const formattedValues = words.map((word) => ({
        title: word,
        pre: "",
        type: "",
      }));
      setVal(formattedValues); // Update state with array of object
      console.log(val, "val ....");
    }
  };

  const handleSubmit = async (type) => {
    if (type == 1) {
      const obj = {
        sourceContent: val.map((item) => item.title).join(" ") || location?.state?.sentance?.sourceContent,
        sourceContentArray: val || location?.state?.sentance?.sourceContentArray,
        targetContentArray: location?.state?.sentance?.targetContentArray ,
        targetContent: location?.state?.sentance?.targetContent ,
        status: true,
        languageSentence: isDialect.type ? false : true,
        checkSentence: isDialect.type
          ? "Target-Sentence"
          : "Target-Sentence",
        ...(isDialect.type
          ? {}
          : { sourceAudio: location?.state?.sentance?.sourceAudio }),
      };

      console.log(obj,'object here....');

      try {
        dispatch(
          LastUpdate_Request({
            //   user: user?.user,
            request: "put",
            data: obj,
            Api: isDialect.type
              ? `/api/v1/update-sentences-dia/${location?.state?.sentance?._id}`
              : `/api/v1/update-sentences/${location?.state?.sentance?._id}`,
          })
        );
      } catch (err) {
        console.error("Error updating sentence:", err);
      }
    }

    if (type == 3) {
      const audio = await UploadAssests(audioBlob);
      const obj = {
        // targetContentArray: val,
        // targetContent: val.map((item) => item.title).join(" "),
        // status: true,
        // languageSentence: isDialect.type ? false : true,
        checkSentence: "Target-Audio",
        targetAudio: audio,
        // courseId: Course.data[0]?._id,
        // tag: state.checkbox,
        // nativeLanguage: User?.user?.nativeLanguage?.name,
        // nativeDialect: User?.user?.nativeDialect,
      };
      try {
        dispatch(
          LastUpdate_Request({
            //   user: user?.user,
            request: "put",
            data: obj,
            Api: isDialect.type
              ? `/api/v1/update-sentences-dia/${location?.state?.sentance?._id}`
              : `/api/v1/update-sentences/${location?.state?.sentance?._id}`,
          })
        );
      } catch (err) {
        console.error("Error updating sentence:", err);
      }
    }

    if (type == 4) {
      const obj = {
        // targetContentArray: val,
        // targetContent: val.map((item) => item.title).join(" "),
        // status: true,
        languageSentence: isDialect.type ? false : true,
        checkSentence: "Source-Sentence",
        targetContentArray: val || location?.state?.sentance?.targetContentArray ,
        targetContent: val.map((item) => item.title).join(" ") || location?.state?.sentance?.targetContent ,

        // courseId: Course.data[0]?._id,
        // tag: state.checkbox,
        // nativeLanguage: User?.user?.nativeLanguage?.name,
        // nativeDialect: User?.user?.nativeDialect,
      };

      try {
        dispatch(
          LastUpdate_Request({
            //   user: user?.user,
            request: "put",
            data: obj,
            Api: isDialect.type
              ? `/api/v1/update-sentences-dia/${location?.state?.sentance?._id}`
              : `/api/v1/update-sentences/${location?.state?.sentance?._id}`,
          })
        );
      } catch (err) {
        console.error("Error updating sentence:", err);
      }
    }

    if (type == 6) {
      const audio = await UploadAssests(audioBlob);
      const obj = {
        // targetContentArray: val,
        // targetContent: val.map((item) => item.title).join(" "),
        // status: true,
        // languageSentence: isDialect.type ? false : true,
        checkSentence: "Source-Audio",
        sourceAudio: audio,
        //////: audio,
        // courseId: Course.data[0]?._id,
        // tag: state.checkbox,
        // nativeLanguage: User?.user?.nativeLanguage?.name,
        // nativeDialect: User?.user?.nativeDialect,
      };
      try {
        dispatch(
          LastUpdate_Request({
            //   user: user?.user,
            request: "put",
            data: obj,
            Api: isDialect.type
              ? `/api/v1/update-sentences-dia/${location?.state?.sentance?._id}`
              : `/api/v1/update-sentences/${location?.state?.sentance?._id}`,
          })
        );
      } catch (err) {
        console.error("Error updating sentence:", err);
      }
    }

    if (type == 7) {
      const obj = {
        checkSentence: "tag",
        tag: classic == true ? "classic" : insult == true ? "insult" : "slang",
      };
      try {
        dispatch(
          LastUpdate_Request({
            //   user: user?.user,
            request: "put",
            data: obj,
            Api: isDialect.type
              ? `/api/v1/update-sentences-dia/${location?.state?.sentance?._id}`
              : `/api/v1/update-sentences/${location?.state?.sentance?._id}`,
          })
        );
      } catch (err) {
        console.error("Error updating sentence:", err);
      }
    }
  };

  useEffect(() => {
    if (LastUpdate.success === true) {
      if (location?.state?.sentance?.id === 1) {
        showToast("Target Sentence Corrected Successfully", "success");
      }
      if (location?.state?.sentance?.id === 3) {
        showToast("Target Audio Corrected Successfully", "success");
      }
      if (location?.state?.sentance?.id === 3) {
        showToast("Source Audio Corrected Successfully", "success");
      }
      setTimeout(() => {
        navigate("/earn");
        dispatch(LastUpdate_Update_Reducer());
      }, 2000);
    }
  }, [LastUpdate.success]);

  const RenderByCondition = () => {
    /////

    if (location?.state?.question?.id === 1) {
      return (
        <>
          <div
            style={{
              display: "flex",
              width: "50%",
              marginLeft: "25%",
              flexDirection: "column",
            }}
          >
            <div
              className="words_source_flex_data"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              {location?.state?.sentance &&
                location?.state?.sentance?.sourceContentArray?.map((v, i) => (
                  <>
                    <div style={{ fontSize: "22px" }} className={`${v.type}`}>
                      {v.title}
                    </div>
                  </>
                ))}
            </div>

            <div style={{ display: "flex" }}>
              {val &&
                val?.map((item, index) => (
                  <p
                    key={index}
                    style={{
                      marginLeft: "10px",
                      border:
                        item.type === "selected" ? "1px solid black" : "none",
                      cursor: "pointer",
                    }}
                    className={`${item.type}`}
                    onClick={() => handleItemClick(index)}
                  >
                    {item.title}
                  </p>
                ))}
            </div>
            <div>
              {/* <button onClick={() => handleTypeChange("none")}>
                Set Type 'none'
              </button> */}
              {/* Add other buttons to set different types */}
            </div>

            <textarea
              name="add_sentence"
              className="form-control-less"
              placeholder="Type here"
              onChange={handleChange}
            ></textarea>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                onClick={() => handleSubmit(1)}
                style={{
                  backgroundColor: "black",
                  width: "100px",
                  height: "35px",
                  color: "white",
                  borderRadius: "20px",
                  outline: "none",
                  border: "none",
                  marginTop: "20px",
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </>
      );
    }
    //////
    if (location?.state?.question?.id === 3) {
      return (
        <>
          <div
            className="words_source_flex_data"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "25px",

            }}
          >
            {location?.state?.sentance?.sourceContentArray?.map((v, i) => (
              <div key={i} style={{ fontSize: "22px" }} className={`${v.type}`}>
                {v.title}
              </div>
            ))}
          </div>
          <div className="audio_video_wrapper">
            <div className="d-sm-flex d-block align-items-center">
              <div className="audio_video_box">
                <div className="audio_video_box_title d-flex justify-content-between">
                  <span>{/* Add your title or component here */}</span>
                </div>
                <div className="audio_video_box_content">
                  <div
                    className="audio_video_box_content_audio d-flex align-items-center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <span className="d-flex align-items-center">
                      {/* <HiOutlineSpeakerWave /> */}
                    </span>
                    <audio
                      controls
                      src={location?.state?.sentance?.targetAudio}
                    ></audio>
                  </div>
                  <div
                    className="audio_video_box_content_audio d-flex align-items-center"
                    style={{
                      display: audioBlob ? "flex" : "none",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <span className="d-flex align-items-center">
                      {/* <IoMicCircleSharp /> */}
                    </span>
                    <audio ref={audioRef} controls />
                  </div>
                </div>
                <div className="audio_video_box_buttons d-flex justify-content-between align-items-center">
                  <div
                    className="d-flex align-items-center"
                    style={{
                      width: "20%",
                      marginLeft: "40%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {isRecording ? (
                      <button
                        onClick={stopRecording}
                        className="btn_audio"
                        style={{
                          backgroundColor: "transparent",
                          border: "solid 1px transparent",
                        }}
                      >
                        <FaRegPauseCircle style={{ fontSize: "60px" }} />
                      </button>
                    ) : (
                      <button
                        onClick={startRecording}
                        className="btn_audio"
                        style={{
                          backgroundColor: "transparent",
                          border: "solid 1px transparent",
                          cursor: "pointer",
                        }}
                      >
                        <IoMicCircleSharp style={{ fontSize: "60px" }} />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "25px",
              flexDirection:'column',
              alignItems:'center',
            }}
          >
            <button
              onClick={() => handleSubmit(3)}
              style={{
                backgroundColor: "black",
                width: "100px",
                height: "35px",
                color: "white",
                borderRadius: "20px",
                outline: "none",
                border: "none",
              }}
            >
              Submit
            </button>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <select
                onChange={(e) => handleMicrophoneChange(e.target.value)}
                // style={{ width: "100px",margin:'auto',border:'solid 1px red' }}
              >
                <option value={null}>Microphone</option>
                {microphoneList.map((microphone) => (
                  <option key={microphone.deviceId} value={microphone.deviceId}>
                    {microphone.label ||
                      `Microphone ${microphoneList.indexOf(microphone) + 1}`}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
      );
    }

    if (location?.state?.question?.id === 4) {
      return (
        <>
          <div
            style={{
              display: "flex",
              width: "50%",
              marginLeft: "25%",
              flexDirection: "column",
              // border:'solid 1px red'
            }}
          >
            <div
              className="words_source_flex_data"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              {location?.state?.sentance &&
                location?.state?.sentance?.targetContentArray?.map((v, i) => (
                  <>
                    <div style={{ fontSize: "22px" }} className={`${v.type}`}>
                      {v.title}
                    </div>
                  </>
                ))}
            </div>

            <div style={{ display: "flex" }}>
              {val &&
                val?.map((item, index) => (
                  <p
                    key={index}
                    style={{
                      marginLeft: "10px",
                      border:
                        item.type === "selected" ? "1px solid black" : "none",
                      cursor: "pointer",
                    }}
                    className={`${item.type}`}
                    onClick={() => handleItemClick(index)}
                  >
                    {item.title}  
                  </p>
                ))}
            </div>
            <div>
            </div>

            <textarea
              name="add_sentence"
              className="form-control-less"
              placeholder="Type here  "
              onChange={handleChange}
            ></textarea>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                onClick={() => handleSubmit(4)}
                style={{
                  backgroundColor: "black",
                  width: "100px",
                  height: "35px",
                  color: "white",
                  borderRadius: "20px",
                  outline: "none",
                  border: "none",
                  marginTop: "20px",
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </>
      );
    }

    if (location?.state?.question?.id === 6) {
      return (
        <>
          <div
            className="words_source_flex_data"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "25px",
              // border:'solid 1px red',
            }}
          >
            {location?.state?.sentance?.targetContentArray?.map((v, i) => (
              <div key={i} style={{ fontSize: "22px" }} className={`${v.type}`}>
                {v.title}
              </div>
            ))}
          </div>
          <div className="audio_video_wrapper">
            <div className="d-sm-flex d-block align-items-center">
              <div className="audio_video_box">
                <div className="audio_video_box_title d-flex justify-content-between">
                  <span>{/* Add your title or component here */}</span>
                </div>
                <div className="audio_video_box_content">
                  <div
                    className="audio_video_box_content_audio d-flex align-items-center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <span className="d-flex align-items-center">
                      {/* <HiOutlineSpeakerWave /> */}
                    </span>
                    <audio
                      controls
                      src={location?.state?.sentance?.sourceAudio}
                    ></audio>
                  </div>
                  <div
                    className="audio_video_box_content_audio d-flex align-items-center"
                    style={{
                      display: audioBlob ? "flex" : "none",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <span className="d-flex align-items-center">
                      {/* <IoMicCircleSharp /> */}
                    </span>
                    <audio ref={audioRef} controls />
                  </div>
                </div>
                <div className="audio_video_box_buttons d-flex justify-content-between align-items-center">
                  <div
                    className="d-flex align-items-center"
                    style={{
                      width: "20%",
                      marginLeft: "40%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {isRecording ? (
                      <button
                        onClick={stopRecording}
                        className="btn_audio"
                        style={{
                          backgroundColor: "transparent",
                          border: "solid 1px transparent",
                        }}
                      >
                        <FaRegPauseCircle style={{ fontSize: "60px" }} />
                      </button>
                    ) : (
                      <button
                        onClick={startRecording}
                        className="btn_audio"
                        style={{
                          backgroundColor: "transparent",
                          border: "solid 1px transparent",
                          cursor: "pointer",
                        }}
                      >
                        <IoMicCircleSharp style={{ fontSize: "60px" }} />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "25px",
              display:'flex',
              justifyItems:'center',
              alignItems:'center',
              flexDirection:'column'
            }}
          >
            <button
              onClick={() => handleSubmit(6)}
              style={{
                backgroundColor: "black",
                width: "100px",
                height: "35px",
                color: "white",
                borderRadius: "20px",
                outline: "none",
                border: "none",
              }}
            >
              Submit 
            </button>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <select
                onChange={(e) => handleMicrophoneChange(e.target.value)}
                // style={{ width: "100px",margin:'auto',border:'solid 1px red' }}
              >
                <option value={null}>Microphone</option>
                {microphoneList.map((microphone) => (
                  <option key={microphone.deviceId} value={microphone.deviceId}>
                    {microphone.label ||
                      `Microphone ${microphoneList.indexOf(microphone) + 1}`}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* /////////////////////// */}
        </>
      );
    }

    if (location?.state?.question?.id === 7) {
      return (
        <>
          <div
            className="words_source_flex_data"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            {location?.state?.sentance &&
              location?.state?.sentance?.targetContentArray?.map((v, i) => (
                <>
                  <div style={{ fontSize: "22px" }} className={`${v.type}`}>
                    {v.title}
                  </div>
                </>
              ))}
          </div>

          <div
            style={{
              display: "flex",
              width: "20%",
              marginLeft: "40%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label style={{ marginLeft: "15px" }}>Classic</label>
            <input
              // defaultChecked={location?.state?.sentance.tag === 'classic' ? true : false }
              onChange={() => handleTag("classic")}
              checked={classic}
              style={{ marginLeft: "5px" }}
              type="radio"
            />
            <label style={{ marginLeft: "15px" }}>Slang</label>
            <input
              //    defaultChecked={location?.state?.sentance.tag === 'slang' ? true : false }
              onChange={() => handleTag("slang")}
              checked={slang}
              style={{ marginLeft: "5px" }}
              type="radio"
            />
            <label style={{ marginLeft: "15px" }}>Insult</label>
            <input
              //    defaultChecked={location?.state?.sentance.tag === 'insult' ? true : false }
              onChange={() => handleTag("insult")}
              checked={insult}
              style={{ marginLeft: "5px" }}
              type="radio"
            />
          </div>

          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <button
              onClick={() => handleSubmit(7)}
              style={{
                backgroundColor: "black",
                width: "100px",
                height: "35px",
                color: "white",
                borderRadius: "20px",
                outline: "none",
                border: "none",
                marginTop: "35px",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
          </div>
        </>
      );
    }

    if (location?.state?.question?.id === 8) {
      return (
        <>
          <div
            style={{
              display: "flex",
              width: "50%",
              marginLeft: "25%",
              flexDirection: "column",
            }}
          >
            <div
              className="words_source_flex_data"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              {location?.state?.sentance &&
                location?.state?.sentance?.sourceContentArray?.map((v, i) => (
                  <>
                    <div style={{ fontSize: "22px" }} className={`${v.type}`}>
                      {v.title}
                    </div>
                  </>
                ))}
            </div>

            <div
              className="words_source_flex_data"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              {location?.state?.sentance &&
                location?.state?.sentance?.targetContentArray?.map((v, i) => (
                  <>
                    <div style={{ fontSize: "22px" }} className={`${v.type}`}>
                      {v.title}
                    </div>
                  </>
                ))}
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <HeaderLogin />
      {RenderByCondition()}{" "}
      {/* Assuming RenderByCondition renders something based on a condition */}
      {
        // location?.state?.question?.id === 8 ? (
        <div
          className="langword_bottom"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          {LanguagesWords().map((v, i) => (
            <div
              onClick={() => handleTypeChange(v.type)}
              className="colum_custom_bototm"
              key={"word_" + i}
            >
              <div className={v.type}>{v.title}</div>
              <Link
                to={v.link}
                target="_blank"
                title="click to view definition"
              >
                <AiFillQuestionCircle size={11} color="orange" />
              </Link>
            </div>
          ))}
        </div>
        // ) : null
      }
    </>
  );
};

export default CorrectPage;
