import React, { useEffect, useRef, useState } from "react";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import { FaRegHeart, FaHeart } from "react-icons/fa6";
import { HiMiniSpeakerWave } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../base-components/Loader/Loader";
import { FatchApi } from "../../utils/FatchApi";
import { IsHeart_Reducer } from "../../lib/Redux/slices/ExtraSlice";
import styled from 'styled-components';

const Index = () => {

  const MainContainer = styled.div`
  width: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 85vh;
  -webkit-box-shadow: 0px 5px 29px -11px rgba(0, 0, 0, 0.64);
  -moz-box-shadow: 0px 5px 29px -11px rgba(0, 0, 0, 0.64);
  box-shadow: 0px 5px 29px -11px rgba(0, 0, 0, 0.64);
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 10px;

  @media (max-width: 1024px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 90%;
    height: 80vh;
  }

  @media (max-width: 480px) {
    width: 95%;
    height: 75vh;
  }
`;

  const Container = styled.div`
  width: 95%;
  padding: 15px;
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  margin: auto;
  background-color: #f1f3f4;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    width:80%;
  }
`;

const Content = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: start;
  height: 37px;
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const TagContainer = styled.div`
  width: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }
`;

const Tag = styled.div`
  margin-right: 40px;
  padding: 10px;
  border-radius: 10px;
  border: solid 1px lightgray;
`;

const NoData = styled.div`
  width: 98%;
  height: 98%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

  const [selectedValue, setSelectedValue] = useState("");
  const [filterHeart, setFilterHeart] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sentance, setSentance] = useState([]);
  const [inpval, setInpVal] = useState();
  const [selValue,setSelValue]=useState('defualt')
  const [selectedMode,setSelectedMode]=useState(false)

  const AllAvilable = useSelector((state) => state.AllAvilable);
  const User = useSelector((state) => state.user);
  const LanguagesCourse = useSelector((state) => state.UserOwnCourse);
  const DialectsCourse = useSelector((state) => state.UserOwnDialects);
  const heart = useSelector((state) => state.ExtraSlice);
  const AllCourses = [...LanguagesCourse?.data, ...DialectsCourse?.data];

  const isDialect = useSelector((state) => state.IsDialect);
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  // console.log(heart?.IsHeart_type, "all data");

  useEffect(() => {
    handleSelectChangeDefualt();
  }, []);

  const handleSelectChangeDefualt = async (e) => {
    setLoading(true);
    // console.log(AllCourses[0]?._id);

    try {
      const data = await FatchApi({
        request: "get",
        data: "",
        Api: `/api/v1/get-read-learner-sentences?allCourse=${"All-Course"}`,
      });

      if (data) {
        console.log(data?.sentences, "data");
        setLoading(false);
        setSentance(data?.sentences);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };


  const handleSelectChange = async (e) => {
    console.log(selValue,'sel val here');
    setSelValue(e?.target?.value || selValue || 'defualt' )
    if (e?.target?.value === "defualt" || selValue === 'defualt') {
      handleSelectChangeDefualt();
    } else {
      const value = e?.target?.value || selValue ;
      setSelectedValue(value); // Update the selected value
      setLoading(true);
      console.log("time here");
      console.log(AllCourses[0]?._id);

      try {
        const data = await FatchApi({
          request: "get",
          data: "",
          Api: `/api/v1/get-read-learner-sentences?courseId=${value && value}`,
        });

        if (data) {
          console.log(data?.sentences, "data");
          setLoading(false);
          setSentance(data?.sentences);
          setSelectedMode(false)
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
  };

    const [isPlaying, setIsPlaying] = useState({type:'', Index:'',content:''});
  
    const playAudio = (link, type,index) => {
      console.log(link, type, 'item and type ..');
  
      const audio = new Audio(link);
      audio.play();
      setIsPlaying({type:true,Index:index,content:type});
      audio.addEventListener('ended', () => {
        setIsPlaying({type:false,Index:'',content:''});
      });
      audio.addEventListener('pause', () => {
        setIsPlaying({type:false,Index:'',content:''}); 
      });
    };

    const handleHeartClick = async (type,item)=>{
      console.log(item,'item');
      // setLoading(true)
    //  const obj = {
    //   type:type,    
    //   sentenceId:item._id,
    //   courseId:item?._id      
    //  }

     var obj = {
      sentenceId: item._id,
      userId: user.userId,
    };

     console.log(obj,'obj');
     try {
      const data = await FatchApi({
        request: "post",
        data: obj,
        Api : !item?.isDialect  ? `/api/v1/sentence-like`: `/api/v1/sentence-like-dia`,
      });

      if (data) {
        setSelectedMode(true)
        console.log(data?.sentences, "data");
        setLoading(false);
        setSentance(data?.sentences);
        handleSelectChange() 
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
    }

  const AllSentance =sentance && sentance?.length <= 0 ? (
    <NoData>No Data Found</NoData>
    ) : (
      sentance
        ?.filter((item) => {
          if (inpval?.length >= 1) {
            const lowerInpval = inpval.toLowerCase();
            return (
              item?.targetContent.toLowerCase().includes(lowerInpval) ||
              item?.sourceContent.toLowerCase().includes(lowerInpval) ||
              item?.tag.toLowerCase().includes(lowerInpval)
            );
          }
          return item;
        })
        ?.filter((item) =>
          heart?.IsHeart_type
            ? item?.userLikes?.find((likes) => likes === User?.user?.userId)
              ? item
              : ""
            : item
        )
        .map((item,index) => {
          return (
            <>
            <Container key={index}>
          <Content>
            <Row>
              {item?.targetContentArray?.map((subItem, subIndex) => (
                <span key={subIndex} className={subItem?.type}>{subItem?.title}</span>
              ))}
              <HiMiniSpeakerWave
                onClick={() => playAudio(item?.sourceAudio, 'target', index)}
                style={{
                  fontSize: "26px",
                  cursor: "pointer",
                  color: isPlaying?.type && isPlaying?.Index === index && isPlaying?.content === 'target' ? '#F89700' : '#545454'
                }}
              />
            </Row>
            <Row>
              {item?.sourceContentArray?.map((subItem, subIndex) => (
                <span key={subIndex} className={subItem?.type}>{subItem?.title}</span>
              ))}
              <HiMiniSpeakerWave
                onClick={() => playAudio(item?.targetAudio, 'source', index)}
                style={{
                  fontSize: "26px",
                  cursor: "pointer",
                  color: isPlaying?.type && isPlaying?.Index === index && isPlaying?.content === 'source' ? '#F89700' : '#545454'
                }}
              />
            </Row>
            <Row style={{ fontSize: "16px" }}>
              {item?.latinContentArray?.map((subItem, subIndex) => (
                <span key={subIndex} className={subItem?.type}>{subItem?.title}</span>
              ))}
            </Row>
          </Content>
          <TagContainer>
            <Tag>{item?.tag}</Tag>
            <div  >
              {item?.userLikes?.some((like) => like === User?.user?.userId) ? (
                <FaHeart onClick={()=> handleHeartClick('dislike',item)} style={{ fontSize: "25px",cursor:'pointer' }} />
              ) : (
                <FaRegHeart onClick={()=> handleHeartClick('like',item)} style={{ fontSize: "25px",cursor:'pointer'}} />
              )}
            </div>
          </TagContainer>
        </Container>
            </>
          );
        })
    );

  const handleHeartFilter = (type) => {
    console.log(type, "type");
    dispatch(IsHeart_Reducer(type));
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);


  return (
    <>
      <HeaderLogin />
   <MainContainer>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "start",
            gap: "20px",
            top: "10%",
            // border: "solid 1px green",
            marginBottom: "10px",
            padding: "15px",
            borderBottom: "solid 1px lightgray",
          }}
        >
          {/* // */}
          <input
            ref={inputRef}
            onChange={(e) => setInpVal(e.target.value)}
            value={inpval}
            style={{
              padding: "8px",
              borderRadius: "5px",
              border: "solid 1px lightgray",
              outline: "none",
              width: "25%",
              fontSize: "18px",
            }}
            placeholder="Search here"
          />
          {/* // */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "45%",
            }}
          >
            <select
              id="dynamic-select"
              value={selectedValue}
              onChange={handleSelectChange}
              style={{ fontSize: "18px", padding: "5px", width: "100%" }}
            >
              <option value="defualt">All Courses</option>
              {AllCourses?.map((item, index) => (
                <option key={index} value={item._id}>
                  {item?.courseName}
                </option>
              ))}
            </select>
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "25px",
              cursor: "pointer",
            }}
          >
            {heart?.IsHeart_type ? (
              <FaHeart onClick={() => handleHeartFilter(false)} />
            ) : (
              <FaRegHeart onClick={() => handleHeartFilter(true)} />
            )}
          </div>
        </div>
        <div
          style={{
            height: "90%",
            width: "100%",
            overflowY: "auto",
          }}
        >
          {loading ? (
            <>
              <div style={{width:'98%',height:'98%',display:'flex',justifyContent:'center',alignItems:'center'}} >
                <Loader />
              </div>
            </>
          ) : (
            AllSentance
          )}
        </div>
   </MainContainer>
    </>
  );
};

export default Index;
