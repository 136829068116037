import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const PublicRoute = ({ children, type = "user" }) => {
  const { isAuthenticated, user ,isLearn } = useSelector((state) => state.user);

  console.log(user,isAuthenticated,isLearn,'user');
// debugger
  if (isAuthenticated && type === "user" && isLearn) {
    return <Navigate to="/learn" replace={true} />;
  }
  if (isAuthenticated && type === "user" && !isLearn) {
    return <Navigate to="/earn" replace={true} />;
  }
  return children;
};
