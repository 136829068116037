import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../base-components/Header";
import { RESET_PASSWORD_API } from "../../constants/apiEndPoints";
import httpRequest from "../../axios/index.js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { clearTemp } from "../../lib/Redux/slices/userslice.jsx";

function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { TempForgotEmail } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (TempForgotEmail === "") {
        setLoading(false);
        toast.warning("Forgot with valid way !");
        setTimeout(() => {
          navigate("/forgot/password");
        }, 2000);
        return;
      }
      if (newPassword !== confirmPassword) {
        setLoading(false);
        toast.error("Passwords do not match.");
        return;
      }
      if (newPassword.length < 8 || confirmPassword.length < 8) {
        setLoading(false);
        toast.error("Passwords must be 8 characters or longer.");
        return;
      }

      const response = await httpRequest.post(RESET_PASSWORD_API, {
        email: TempForgotEmail,
        newPassword,
      });

      if (response.status === 200 || response.status === 201) {
        setLoading(false);
        toast.success(response?.data?.message);
        dispatch(clearTemp());
        navigate("/login");
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <div className="container">
      <Header />
      <div className="wrapper_full">
        <div className="login-container">
          <div className="">
            <h1>Reset Password</h1>
            <p>
              or <Link to="/login">Log in</Link>
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form_middle">
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  name="newPassword"
                  placeholder="New Password"
                  value={newPassword}
                  disabled={TempForgotEmail === "" ? true : false}
                  onChange={handleNewPasswordChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  disabled={TempForgotEmail === "" ? true : false}
                  onChange={handleConfirmPasswordChange}
                  required
                />
              </div>

              <div className="form-group ">
                <div className="center_button_custom_width">
                  <button type="submit" className="button_custom primary wd100">
                    {loading ? "Resetting..." : "Reset Password"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
