import React, { useEffect, useState, useRef, forwardRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LOGO from "./../../assets/images/logo.svg";
import { AiFillCloseCircle, AiOutlineArrowRight } from "react-icons/ai";
import { FaChevronDown, FaCreditCard } from "react-icons/fa6";
import { MdLocalLibrary } from "react-icons/md";
import {
  IoCloseCircleOutline,
  IoCog,
  IoFingerPrintOutline,
  IoLogOutOutline,
} from "react-icons/io5";
import { IoIosGlobe } from "react-icons/io";
import { FaRegMoneyBillAlt, FaCcPaypal, FaHandsHelping } from "react-icons/fa";
import { LuArrowRightCircle } from "react-icons/lu";
import { HiOutlinePlusCircle, HiOutlineMinusCircle } from "react-icons/hi";
import { GiCog } from "react-icons/gi";
import BURGER from "../../assets/images/hambergermenu.svg";
import { useDispatch, useSelector } from "react-redux";
import { clearUser } from "../../lib/Redux/slices/userslice";
import useResetReducer from "../../utils/useResetReducer";
import { SentanceFilter } from "../../lib/Redux/slices";
import { SentanceFilter_Reducer } from "../../lib/Redux/slices/SentanceFilter";
import Loader from "../Loader/Loader";
import { FatchApi } from "../../utils/FatchApi";
import { FaQ } from "react-icons/fa6";
import { profileVerified_Reducer } from "../../lib/Redux/slices/PorfileVarified";
import {
  toggleVerb,
  selectAllVerbs,
  deselectAllVerbs,
} from "../../lib/Redux/slices/VerbValue";
import useToast from "../../hooks/useToast";
import { IsDialect } from "../../lib/Redux/slices/IsDialectCondion";
// import { useLocation } from "react-router-dom";

const Header = ({ otherProps,newRef }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showhover, setShowHover] = useState(false);
  const [userdrop, setUserDrop] = useState(false);
  const [usersettings, setUserSettings] = useState(false);
  const [popupdeposit, setPopupDeposit] = useState(false);
  const [popupwithdrawal, setPopupWithdrawal] = useState(false);
  const [usertype, setUserType] = useState("");
  const [method, setMethod] = useState(0);
  const [showleft, setShowLeft] = useState(false);
  const [volum, setVolum] = useState(() => {
    const storedVolume = localStorage.getItem("volume");
    return storedVolume !== null ? JSON.parse(storedVolume) : 100;
  });
  const [isSubLanguage, setIssublanguage] = useState(false);
  const [showMid, setShowMid] = useState();
  const [verb, setVerb] = useState(false);

  // console.log(verb,'verb...');

  const showToast = useToast();

  const { isAuthenticated } = useSelector((state) => state.user);
  const { profileVerified } = useSelector((state) => state);
  const [settingLoading, setSettingLoading] = useState(false);
  const [minimumWithDraw, setMinimumWithDraw] = useState();

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  const Box = JSON.parse(localStorage.getItem("box"));
  // console.log(Box.box,'box...');

  const resetAllReducers = useResetReducer();

  // const { data } = useSelector((state) => state.SentanceFilter);

  const data = useSelector((state) => state.SentanceFilter.data);

  // useEffect(()=>{
  //   dispatch(SentanceFilter_Reducer([{radio:'Only new and repetition',manageType:"Only new and repetition",tags:['slang','classic']}]));
  // },[])

  // const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);
  const [screenSize, setScreenSize] = useState({
    isWideScreen: window.innerWidth > 768,
    isMediumScreen: window.innerWidth > 650,
    isSmallScreen: window.innerWidth > 600,
    isXSmallScreen: window.innerWidth > 550,
    isXXSmallScreen: window.innerWidth > 500,
    isTinyScreen: window.innerWidth > 450,
    isVeryTinyScreen: window.innerWidth > 400,
    isUltraTinyScreen: window.innerWidth > 350,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        isWideScreen: window.innerWidth > 768,
        isMediumScreen: window.innerWidth > 650,
        isSmallScreen: window.innerWidth > 600,
        isXSmallScreen: window.innerWidth > 550,
        isXXSmallScreen: window.innerWidth > 500,
        isTinyScreen: window.innerWidth > 450,
        isVeryTinyScreen: window.innerWidth > 400,
        isUltraTinyScreen: window.innerWidth > 350,
      });

      console.log(
        {
          isWideScreen: window.innerWidth > 768,
          isMediumScreen: window.innerWidth > 650,
          isSmallScreen: window.innerWidth > 600,
          isXSmallScreen: window.innerWidth > 550,
          isXXSmallScreen: window.innerWidth > 500,
          isTinyScreen: window.innerWidth > 450,
          isVeryTinyScreen: window.innerWidth > 400,
          isUltraTinyScreen: window.innerWidth > 350,
        },
        "screenSize"
      );
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const do_make_action = () => {
    document.body.classList.add("mobile_view_p");
  };

  useEffect(() => {
    let prev = localStorage.getItem("USERTYPE");
    setUserType(prev);
  }, [slugFromUrl]);

  const [PaypalEmail, setPaypalEmail] = useState(null);

  const do_submit = async () => {
    if (PaypalEmail == null) {
      console.log(PaypalEmail);
      showToast("Add your PaypalEmail", "error");
      return;
    }

    if (Amount == null) {
      showToast("Add your Amount", "error");
      return;
    }

    if (Amount < minimumWithDraw) {
      showToast(
        ` please enter at least the minimum withdrawal amount of £${minimumWithDraw}`,
        "error"
      );
      return;
    }

    if (Number(Amount) > Pounds) {
      showToast(
        `you should add min value from your current balance ${
          "(" + Pounds + ")"
        }`,
        "error"
      );
      return;
    }

    var obj = {
      payPalEmail: PaypalEmail,
      amount: Number(Amount),
    };

    console.log(Pounds, "obj");

    try {
      const data = await FatchApi({
        // user: user,
        request: "put",
        data: obj,
        Api: "/users/update-user-details",
      });

      if (data) {
        toast.success("your withdrawal request is being processed");
        setPopupDeposit(false);
        setPopupWithdrawal(false);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const minus_volum = async () => {
    var old_vol = volum - 10;
    if (old_vol < 10) {
      setVolum(10);
      localStorage.setItem("volume", JSON.stringify(volum));
    } else {
      setVolum(old_vol);
      localStorage.setItem("volume", JSON.stringify(volum));
    }
  };

  const plus_volum = async () => {
    var old_vol = volum + 10;
    if (old_vol > 300) {
      setVolum(300);
      localStorage.setItem("volume", JSON.stringify(volum));
    } else {
      setVolum(old_vol);
      localStorage.setItem("volume", JSON.stringify(volum));
    }
  };

  const set_volum = async () => {
    setVolum(100);
    localStorage.setItem("volume", JSON.stringify(100));
  };

  const handlesubLanguages = () => {
    setIssublanguage(!isSubLanguage);
  };

  const handleLogout = () => {
    dispatch(clearUser());
    localStorage.clear();
    resetAllReducers(false);
    toast.success("Logged off successfully");
    setTimeout(() => {
      navigate("/");
    }, 2000);
  };

  const user = useSelector((state) => state.user);

  // console.log(user.isLearn, "user...");

  const getSetting = async () => {
    setSettingLoading(true);
    try {
      const data = await FatchApi({
        request: "get",
        Api: "/api/v1/settings",
      });
      if (data) {
        setSettingLoading(false);
        // console.log(data[0]?.withdraw, "setting data");
        setMinimumWithDraw(data[0]?.withdraw);
      }
    } catch (err) {
      setSettingLoading(false);
      console.log(err);
    }
  };

  const userDropRef = useRef(null);
  const userSettingsRef = useRef(null);
  const responsiveUserDropRef = useRef(null);
  const responsiveSettingDropRef = useRef(null);
  const [userRespdrop, setUserRespDrop] = useState(false);
  const [userRespsettings, setUserRespSettings] = useState(false);

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (userDropRef.current && !userDropRef.current.contains(event.target)) {
  //       setUserDrop(false);
  //     }
  //     if (
  //       userSettingsRef.current &&
  //       !userSettingsRef.current.contains(event.target)
  //     ) {
  //       setUserSettings(false);
  //     }
  //     if (
  //       responsiveUserDropRef.current &&
  //       !responsiveUserDropRef.current.contains(event.target)
  //     ) {
  //       setUserRespDrop(false);
  //     }
  //     if (
  //       responsiveSettingDropRef.current &&
  //       !responsiveSettingDropRef.current.contains(event.target)
  //     ) {
  //       setUserRespSettings(false);
  //     }
  //   }

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  // useEffect(()=>{
  //   setUserSettings(true)
  // },[])

  // const [tag, setTag] = useState([]);

  const handleTag = (e) => {
    const tagValue = e.target.value;
    const currentTags = data[0]?.tags || [];

    let updatedTags;

    if (currentTags.includes(tagValue)) {
      updatedTags = currentTags.filter((t) => t !== tagValue);
    } else {
      updatedTags = [...currentTags, tagValue];
    }

    const obj = {
      radio: data[0]?.radio,
      tags: updatedTags,
    };

    dispatch(SentanceFilter_Reducer([obj]));
  };

  const handleChange = (e) => {
    console.log(e.target.value, "vvv");

    if (e.target.value === "1") {
      var obj = {
        tags: data[0]?.tags,
        radio: "Only new",
      };

      // console.log(obj, "in 1");

      dispatch(SentanceFilter_Reducer([obj]));
    } else if (e.target.value === "2") {
      var obj = {
        tags: data[0]?.tags,
        radio: "Only repetition",
      };

      // console.log(obj, "in 2");

      dispatch(SentanceFilter_Reducer([obj]));
    } else if (e.target.value === "3") {
      var obj = {
        tags: data[0]?.tags,
        radio: "Only new and repetition",
      };

      // console.log(obj, "in 3");

      dispatch(SentanceFilter_Reducer([obj]));
    }
  };

  const [WithDrawLoader, setWithdrawLoader] = useState(false);
  const [Points, setPoints] = useState("0,00");
  const [Pounds, setPounds] = useState(0);
  const [PayReq, setPayReq] = useState();
  const [Amount, setAmount] = useState(null);
  const [UserP, setUserP] = useState();

  // const dispatch = useDispatch()

  const fetchData = async () => {
    setWithdrawLoader(true);
    try {
      const data = await FatchApi({
        request: "get",
        Api: "/users/get-user-points",
      });
      if (data) {
        setWithdrawLoader(false);
        console.log(data, "data of points..");
        dispatch(profileVerified_Reducer(data));
        const amount = data?.userAmount.toFixed(2);
        setPounds(`${amount}`);
        // console.log(data?.userPoints,'points...');
        // eslint-disable-next-line eqeqeq
        setPoints(`${data?.userPoints} ${data?.userPoints == 1 ? 'Point' : 'Points' }`);
        if (data?.userAmount <= 0) {
          dispatch(profileVerified_Reducer(data));
          // eslint-disable-next-line eqeqeq
          setPoints(`${data?.userPoints} ${data?.userPoints == 1 ? 'Point' : 'Points' }  `);
          const amount = data?.userAmount.toFixed(2);
          setPounds(`${amount}`);
        } else {
          const amount = data?.userAmount.toFixed(2);
          // setPoints(`$ ${amount}`);
        }
        setPayReq(data?.userAmount >= 1 ? false : true);
      }
      console.log(data);
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
      dispatch(clearUser());
      localStorage.clear();
      resetAllReducers(false);
      setTimeout(() => {
        navigate("/");
      }, 2000);

      // console.log("Error fetching data:", error?.response?.data?.message);
      setWithdrawLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleModleOpen = async () => {
    fetchData();
    getSetting();
  };

  ////////////

  const handleClickOutside = (event) => {
    if (userDropRef.current && !userDropRef.current.contains(event.target)) {
      setUserDrop(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        userSettingsRef.current &&
        !userSettingsRef.current.contains(event.target)
      ) {
        setUserSettings(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userSettingsRef]);

  const VerbsData = [
    {
      title: "nouns",
      color: "#BDBBBB",
    },
    {
      title: "pronouns",
      color: "#1C6E2F",
    },
    {
      title: "verbs",
      color: "#BF9898",
    },
    {
      title: "adverbs",
      color: "#CF1B1B",
    },
    {
      title: "adjectives",
      color: "#28CD19",
    },
    {
      title: "prepositions",
      color: "#372ADB",
    },
    {
      title: "conjunctions",
      color: "#BA20B4",
    },
    {
      title: "interjections",
      color: "#F8B1CF",
    },
    {
      title: "articles",
      color: "#31C6DA",
    },
    {
      title: "determiners",
      color: "#C2CF28",
    },
  ];

  ////

  // const dispatch = useDispatch();
  const selectedVerbs = useSelector((state) => state.Verb);

  console.log(selectedVerbs, "selected verb");

  const handleCheckboxChange = (verb) => {
    dispatch(toggleVerb(verb));
  };

  const handleSelectAll = () => {
    if (selectedVerbs?.data.length === VerbsData.length) {
      dispatch(deselectAllVerbs());
    } else {
      dispatch(selectAllVerbs(VerbsData.map((item) => item.title)));
    }
  };

  ////

  const LeftSide = (
    <div
      style={{
        position: "absolute",
        width: "50%",
        height: "100%",
        left: "-50%",
        top: "0%",
        backgroundColor: "#EFEFEF",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <button
        onClick={handleSelectAll}
        style={{
          width: "90%",
          fontSize: "14px",
          cursor: "pointer",
          marginTop: "5px",
          marginBottom: "5px",
          backgroundColor: "#D9D9D9",
          border: "none",
          borderRadius: "4px",
          padding: "5px",
        }}
      >
        {selectedVerbs.length === VerbsData.length
          ? "Deselect All"
          : "Select All"}
      </button>
      {VerbsData?.map((item) => {
        const { color, title } = item;
        return (
          <div
            key={title}
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "flex-start",
              height: "20px",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              checked={selectAllVerbs && selectedVerbs?.data?.includes(title)}
              onChange={() => handleCheckboxChange(title)}
            />
            <div
              style={{
                color: `${color}`,
                marginLeft: "5px",
                fontSize: "14px",
              }}
            >
              {title}
            </div>
          </div>
        );
      })}
    </div>
  );

  const BalanceBox = !isAuthenticated ? (
    ""
  ) : (
    <div className="balance_box">
      <div
        style={{ width: "150px" }}
        className="balance"
        onMouseEnter={() => setShowHover(true)}
        onMouseLeave={() => setShowHover(false)}
      >
        {!showhover ? (
          <>
            {WithDrawLoader ? (
              <Loader />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "25px",
                  flexDirection: "column",
                }}
              >
                <h3>{`£${Pounds}`}</h3>
                <small style={{ fontSize: "12px" }}>{Points}</small>
              </div>
            )}
          </>
        ) : (
          <div
            className="show_on_hover"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Link to="/support">
              <button>support</button>
            </Link>
            <Link
            
             ref={newRef}
              onClick={() => {
                handleModleOpen();
                setPopupWithdrawal(true);
              }}
            >
              <button  >withdraw</button>
            </Link>
          </div>
        )}
        <span  ref={newRef}
        id="newRefButton"
              onClick={() => {
                handleModleOpen();
                setPopupWithdrawal(true);
              }}></span>
      </div>
    </div>
  );

  const Wheel = Box && Box.box !== "earn" && (
    <>
      <div
        style={{ marginLeft: "20px" }}
        className="setting_icon"
        onClick={() => {
          setUserSettings(!usersettings);
          setUserDrop(false);
          setIssublanguage(false);
        }}
      >
        <GiCog size={30} />
      </div>

      {usersettings && (
        <>
          <div
            style={{
              marginLeft:
                !screenSize?.isXXSmallScreen &&
                !screenSize?.isXSmallScreen &&
                !screenSize?.isWideScreen &&
                !screenSize?.isMediumScreen &&
                !screenSize?.isSmallScreen
                  ? "15%"
                  : !screenSize?.isXSmallScreen &&
                    !screenSize?.isWideScreen &&
                    !screenSize?.isMediumScreen &&
                    !screenSize?.isSmallScreen
                  ? "30%"
                  : !screenSize?.isWideScreen &&
                    !screenSize?.isMediumScreen &&
                    !screenSize?.isSmallScreen
                  ? "40%"
                  : !screenSize?.isWideScreen && !screenSize?.isMediumScreen
                  ? "50% "
                  : !screenSize?.isWideScreen
                  ? "55%"
                  : "0px",
              marginTop: !screenSize?.isWideScreen ? "-50px" : "10px",
            }}
            className="user_setting_box"
            ref={userSettingsRef}
          >
            {verb && LeftSide}

            <h4>Settings</h4>
            <div className="section_setting_drop">
              <label>
                <span>Classic</span>
                <input
                  checked={
                    data[0]?.tags?.some((tag) => tag === "classic") || false
                  }
                  onChange={handleTag}
                  type="checkbox"
                  value={"classic"}
                />
              </label>
              <label>
                <span>Slang</span>
                <input
                  checked={
                    data[0]?.tags?.some((tag) => tag === "slang") || false
                  }
                  onChange={handleTag}
                  type="checkbox"
                  value={"slang"}
                />
              </label>
              <label>
                <span>Insult</span>
                <input
                  checked={
                    data[0]?.tags?.some((tag) => tag === "insult") || false
                  }
                  onChange={handleTag}
                  type="checkbox"
                  value={"insult"}
                />
              </label>
            </div>
            <div className="section_setting_drop space-setting">
              <span onClick={() => setVerb(!verb)}>
                mark different parts of the sentences
              </span>

              <span
                style={{ cursor: "pointer" }}
                onClick={() => setVerb(!verb)}
              >
                <LuArrowRightCircle />
              </span>
            </div>
            <div className="section_setting_drop column_setting">
              <label className="wd100">
                <input
                  checked={data[0]?.radio === "Only new" ? true : false}
                  onChange={handleChange}
                  type="radio"
                  value={1}
                  name="sentence_selection"
                />
                <span>Only new</span>
              </label>
              <label className="wd100">
                <input
                  checked={data[0]?.radio === "Only repetition" ? true : false}
                  onChange={handleChange}
                  type="radio"
                  value={2}
                  name="sentence_selection"
                />
                <span>Only repetition</span>
              </label>
              <label className="wd100">
                <input
                  checked={
                    data[0]?.radio === "Only new and repetition" ? true : false
                  }
                  onChange={handleChange}
                  type="radio"
                  value={3}
                  name="sentence_selection"
                />
                <span>New and repetition</span>
              </label>
            </div>
            <div className="section_setting_drop space-setting">
              <span>Playback speed</span>
              <span className="plus_minus_flex">
                <HiOutlineMinusCircle onClick={minus_volum} />
                <span
                  onClick={set_volum}
                >
                  {volum}%
                </span>
                <HiOutlinePlusCircle onClick={plus_volum} />
              </span>
            </div>
            {/* <div
                onClick={handleLogout}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  border: "solid 1px black",
                  cursor: "pointer",
                }}
              >
                Logout
              </div> */}
          </div>
        </>
      )}
    </>
  );

  const LeftSideSection = (
    <div className="buttons_login relative">
      {user.isLearn == true || user?.isLearn == false ? (
        isAuthenticated ? (
          <>
            <div
              className="user_dropdown"
              onClick={() => {
                setUserSettings(false);
                setUserDrop(!userdrop);
                setIssublanguage(false);
              }}
            >
              <span>Langbook</span>
              <span>
                <FaChevronDown />
              </span>
            </div>
            {userdrop && (
              <>
                <div className="user_dropdown_box" ref={userDropRef}>
                  <div className="links_dropdown">
                    <Link onClick={handlesubLanguages}>
                      <IoIosGlobe />
                      <span>Language</span>
                    </Link>
                    {isSubLanguage && (
                      <div className="Sublanguage">
                        <span onClick={() => setUserDrop(false)}>
                          {/* {profileVerified &&
                            profileVerified?.data &&
                            profileVerified?.data?.nativeLanguage?.name} */}
                          English
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="links_dropdown">
                    <Link to="/library">
                      <MdLocalLibrary />
                      <span>Library</span>
                    </Link>
                  </div>
                  <div className="links_dropdown">
                    <Link to="/verification">
                      <IoFingerPrintOutline />
                      <span>Verification</span>
                    </Link>
                  </div>
                  <div className="links_dropdown">
                    <Link to="/settings">
                      <IoCog />
                      <span>Settings</span>
                    </Link>
                  </div>
                  <div className="links_dropdown">
                    <Link to="/transactions">
                      <FaRegMoneyBillAlt />
                      <span>Transactions</span>
                    </Link>
                  </div>
                  <div className="links_dropdown">
                    <Link to="/support">
                      <FaHandsHelping />
                      <span>Support</span>
                    </Link>
                  </div>
                  <div className="links_dropdown">
                    <Link to="/Faq">
                      <FaQ />
                      <span>FAQ</span>
                    </Link>
                  </div>
                  <div onClick={handleLogout} className="links_dropdown">
                    <Link className="hover_red">
                      <IoLogOutOutline />
                      <span>Sign Out</span>
                    </Link>
                  </div>
                </div>
              </>
            )}

            {screenSize?.isWideScreen && Wheel}
          </>
        ) : (
          <>
            {!isAuthenticated && (
              <button
                style={{
                  border: "solid 1px #F89700",
                  backgroundColor: "white",
                  padding: "10px",
                  borderRadius: "20px",
                  color: "#F89700",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={() => navigate("/login")}
              >
                Login
              </button>
            )}
          </>
        )
      ) : (
        user?.isLearn == true && (
          <>
            {isAuthenticated ? (
              <div
                className="setting_icon"
                onClick={() => {
                  setUserSettings(!usersettings);
                  setUserDrop(false);
                  setIssublanguage(false);
                }}
              >
                <GiCog size={30} />
              </div>
            ) : (
              <button
                style={{
                  border: "solid 1px #F89700",
                  backgroundColor: "white",
                  padding: "10px",
                  borderRadius: "20px",
                  color: "#F89700",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={() => navigate("/login")}
              >
                Login
              </button>
            )}
          </>
        )
      )}
    </div>
  );

  return (
    <>
      <div className="">
        <div className="header_fixed_login">
          <div className="wrapper_full mobil_needede">
            <div className="flex-space-between">
              <div className="mobile_view">
                <div className="top_logo_section">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        className="icon_three dashboard_header_logo"
                        style={{ cursor: "pointer", marginRight: "15px" }}
                        onClick={() => setShowLeft(true)}
                      >
                        <img src={BURGER} />
                      </div>
                      <Link
                        to="/onboard"
                        className="no_mobile dashboard_header_logo"
                      >
                        <img src={LOGO} />
                      </Link>
                    </div>

                    <div>{Wheel}</div>
                  </div>
                </div>
              </div>
              {showleft && (
                <div
                  className="mobile_left_popup"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "-25px",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      onClick={() => setShowLeft(false)}
                      style={{ border: "none", padding: "5px" }}
                    >
                      X
                    </button>
                  </div>
                  <div style={{ marginLeft: "50px" }}>{BalanceBox}</div>
                  <div style={{ marginTop: "20px" }}>{LeftSideSection}</div>
                </div>
              )}

              {/* ///////////////////////// */}

              <div className="new_desktop flex-space-between wd100 relative">
                <Link to={`${user.isLearn ? "/" : "/"}`} className="logo">
                  <img src={LOGO} />
                </Link>

                {BalanceBox}
                {LeftSideSection}
              </div>

              {/* /////////////////////////// */}
            </div>
          </div>
        </div>

        {popupdeposit && (
          <div className="outer_wrapper">
            <div className="inner_wrapper_bottom">
              <div className="white_wrapper wd100">
                <div className="heading_languages">
                  <div className="">Charge Your Account</div>
                  <span
                    className="pointer"
                    onClick={() => setPopupDeposit(false)}
                  >
                    <IoCloseCircleOutline />
                  </span>
                </div>

                <div className="choose_lang_">
                  <div className="flex-space-between-stretch wd100 deposit_withdrawal">
                    <div className="left_deposit_ w60">
                      <div className="">
                        How much money would you like to <br />
                        deposit?
                      </div>
                      <div className="input_deposit relative">
                        <input type="number" className="deposit_input" />
                        <div className="abs_symbol">EUR</div>
                      </div>

                      <div className="method_wrap">
                        <h4>Deposit method</h4>
                        <div className="methods_list">
                          <div
                            className={`method_box ${
                              method == 1 ? "method_box_hover" : ""
                            }`}
                            onClick={() => setMethod(1)}
                          >
                            <FaCcPaypal />
                            <span>Paypal</span>
                          </div>
                          <div
                            className={`method_box ${
                              method == 2 ? "method_box_hover" : ""
                            }`}
                            onClick={() => setMethod(2)}
                          >
                            <FaCreditCard />
                            <span>Debit/Credit card</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="left_deposit_ w40">
                      <h4>Restrictions</h4>
                      <p className="light_text_restriction">
                        The final payment will be made in EUR. A 10% fee
                        <br /> will be applied if you choose to withdraw money
                        not <br />
                        earned through our commissions program.
                        {/* ////////////////////////////////////////////////////////////////////// */}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="footer_languages">
                  <div className=""></div>
                  <span onClick={() => setPopupDeposit(false)}>
                    <button className="button_custom_large_rount primary flex-space-between gap_10">
                      Next <AiOutlineArrowRight />
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}

        {popupwithdrawal && (
          <div className="outer_wrapper">
            <div className="inner_wrapper_bottom">
              <div className="white_wrapper wd100 ">
                <div className="heading_languages">
                  <div className="">Withdrawing Money</div>
                  <span
                    className="pointer"
                    onClick={() => setPopupWithdrawal(false)}
                  >
                    <IoCloseCircleOutline />
                  </span>
                </div>
                {!PayReq ? (
                  <>
                    <div className="choose_lang_">
                      <div className="withdrawal_info">
                        Withdrawals are processed on the 1st <br />
                        and 15th of every month.
                      </div>
                      <div className="flex-space-between-stretch wd100 deposit_withdrawal">
                        <div className="left_deposit_ w60">
                          {/* <div className="">
                        Please enter the amount you would like to withdraw{" "}
                        <br />
                        (Min: £30, Available Balance: £0,20).
                      </div>
                      <div className="input_deposit relative">
                        <input type="number" className="deposit_input" />
                        <div className="abs_symbol">GBP</div>
                      </div> */}

                          <div className="method_wrap">
                            <h4>Withdrawal method</h4>
                            <div className="methods_list">
                              {settingLoading ? (
                                <Loader />
                              ) : (
                                <>
                                  <div
                                    className={`w35 ${
                                      method == 2 ? "method_box_hover" : ""
                                    }`}
                                  >
                                    <input
                                      onChange={(e) =>
                                        setPaypalEmail(e.target.value)
                                      }
                                      type="email"
                                      className="deposit_input wd100"
                                      placeholder="Enter your paypal email address"
                                    />
                                  </div>
                                  <div
                                    className={`w35 ${
                                      method == 2 ? "method_box_hover" : ""
                                    }`}
                                  >
                                    <h4 style={{ marginBottom: "25px" }}>
                                      Amount
                                    </h4>
                                    <input
                                      onChange={(e) =>
                                        setAmount(e.target.value)
                                      }
                                      type="number"
                                      className="deposit_input wd100"
                                      placeholder="Enter your Amount"
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="left_deposit_ w40">
                          <h4>Restrictions</h4>
                          <p className="light_text_restriction">
                            Withdrawals are processed twice a month. If you
                            <br /> withdraw funds in any currency other than
                            GBP, the <br /> exchange rates on the day of
                            processing will apply.
                          </p>
                          <span
                            style={{ fontWeight: "600", marginRight: "5px" }}
                          >
                            Minimum Withdrawal:
                          </span>
                          £{minimumWithDraw}
                        </div>
                      </div>
                    </div>

                    <div className="footer_languages">
                      <div className=""></div>
                      <span onClick={() => do_submit()}>
                        <button className="button_custom_large_rount primary flex-space-between gap_10">
                          Submit
                        </button>
                      </span>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "20px",
                      marginTop: "30px",
                    }}
                  >
                    {" "}
                    Your request has been submitted and will be processed
                    shortly{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default Header;
