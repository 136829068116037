import { takeLatest, call, put } from 'redux-saga/effects';
import { Get_AllComments_Request,} from '../../Redux/actions';
import { FatchApi } from '../../../utils/FatchApi';
import { AllCommentsSliceRequestReducer,AllCommentsSliceSuccessReducer,AllCommentsSliceFailerReducer } from '../../Redux/slices/AllComments';

function* fetchDataSaga(action) {
  try {
    yield put(AllCommentsSliceRequestReducer())
    const data = yield  call(FatchApi,action.payload);
    if(data){
      yield put(AllCommentsSliceSuccessReducer(data));
    }
  } catch (error) {
    yield put(AllCommentsSliceFailerReducer(error));
  }
}

export default function* GetAllComments() {
  yield takeLatest(Get_AllComments_Request().type, fetchDataSaga);
}
