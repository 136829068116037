import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Earn from "../../assets/images/earn-money.png";
import Learn from "../../assets/images/learn_img.png";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillFlag,
  AiFillPlusCircle,
  AiFillQuestionCircle,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineFlag,
} from "react-icons/ai";
import { IoCloseCircleOutline, IoMicCircleSharp } from "react-icons/io5";
import { FaRegPauseCircle } from "react-icons/fa";
import { FaHandPointRight, FaRegClock } from "react-icons/fa6";
import { LuClipboardList, LuRepeat } from "react-icons/lu";
import { Tooltip } from "react-tooltip";
import Flag1 from "../../assets/images/eng.png";
import Flag2 from "../../assets/images/russia.png";
import { UploadAssests } from "../../utils/Aws_Upload";
import useToast from "../../hooks/useToast";
import { HiOutlineSpeakerWave } from "react-icons/hi2";
import { Get_Sou_Request, LastUpdate_Request } from "../../lib/Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../base-components/Loader/Loader";
import { LastUpdate_Update_Reducer } from "../../lib/Redux/slices/LastUpdate";

var SourceVal = JSON.parse(localStorage.getItem("source"));
var TargetVal = JSON.parse(localStorage.getItem("target"));

const sentences_lis = [
  {
    title: "Das Wetter ist gut heute.",
    tag: "classic",
    id: 1,
    done: 1,
    here: 0,
  },
  {
    title: "Das Wetter war sehr gut.",
    tag: "classic",
    id: 2,
    done: 1,
    here: 0,
  },
  {
    title: "Das Essen war sehr lecker.",
    tag: "slang",
    id: 3,
    done: 1,
    here: 0,
  },
  {
    title: "Das Fressen war sau lecker.",
    tag: "classic",
    id: 4,
    done: 1,
    here: 0,
  },
];
const reasonsflag = [
  { title: "mistake in the target language", id: 4 },
  { title: "words marked incorrectly", id: 5 },
  { title: "wrong tag (classic, slang, insult)", id: 6 },
  { title: "inappropriate sentence", id: 7 },
  { title: "other", id: 8 },
];

function OnBoard() {
  const LastUpdate = useSelector((state) => state.LastUpdate);
  const SourceAndTarget = useSelector((state) => state.SourceAndTarget);
  const course = useSelector((state) => state.Course);
  const IsDialectCondion = useSelector((state) => state.IsDialect);
  const Dialect = useSelector((state)=> state.profileVerified.data.nativeDialect);
  const data = useSelector((state) => state);

  // console.log(IsDialectCondion,'isdialect...');

  const AllAvilable = useSelector((state) => state.AllAvilable);
  const user = useSelector((state) => state.user);
  const { NativeLanguage } = useSelector((state) => state);

  const location = useLocation();

  console.log(location?.state, "location in dialect case");

  // console.log(SourceAndTarget?.data?.sourceContentArray, "path name");

  const SourceAndTargetAndSourceAudio = useSelector(
    (state) => state.SourceAndTargetAndSourceAudio
  );

  const showToast = useToast();

  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [heading, setHeading] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);
  const [spaceword, setSpaceWord] = useState(
    "Thiswouldbeanexamplesentencewheretherearenospacesbetweenwords."
  );

  const [selected, setSelected] = useState([]);

  const [sentencwritten, setSentenceWritten] = useState([]);
  const [first, setFirst] = useState(false);

  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioRef = useRef();
  const [microphoneList, setMicrophoneList] = useState([]);
  const [selectedMicrophone, setSelectedMicrophone] = useState(null);
  const [flag, setFlag] = useState(false);

  const [InputVal, setInputVal] = useState("");
  const [RadioVal, setRadioVal] = useState("");

  const dispatch = useDispatch();

  const isDialect = useSelector((state) => state.IsDialect);

  useEffect(() => {
    SourceVal = JSON.parse(localStorage.getItem("source"));
    TargetVal = JSON.parse(localStorage.getItem("target"));
  }, [SourceVal?.code, TargetVal?.code]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("type")) {
      const viewValue = params.get("type");
      setFirst(true);
    } else {
      setFirst(false);
    }
  }, [location.search]);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const audioDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      setMicrophoneList(audioDevices);
    });
  }, []);

  const show_popup = async (val) => {
    setHeading(val);
    setPopup(true);
  };

  const [to, setTo] = useState(false);

  const do_next_page = async () => {

    // if (IsDialectCondion.type) {
    //   if (audioBlob) {
    //     const audioLink = await UploadAssests(audioBlob);
    //     navigate("/record/target/language", {
    //       state: {
    //         sourceAudio: audioLink,
    //         TargetSentace: SourceAndTarget?.data[0]?.targetContentArray,
    //         SentenceId: SourceAndTarget?.data[0]?._id,
    //       },
    //     });
    //     return;
    //   } else {
    //     showToast("Add your Target Audio", "warning");
    //     return;
    //   }
    // }

    setTo(true);

    console.log(location?.state,'id when');
    //
    const SentenceId = SourceAndTarget && SourceAndTarget?.data[0]?._id;
    const CourseId = location?.state?.courseId;

    if (audioBlob) {
      const audioLink = await UploadAssests(audioBlob);

      if (isDialect.type) {
        const obj = {
          // sourceAudio: audioLink,
          targetAudio:audioLink,
          languageSentence:location?.state?.languageSentence,
          // checkSentence: "Source Audio",  
          nativeDialect: Dialect && Dialect?.label ,
          sourceContent: location?.state?.sourceContent,
          targetContent:location?.state?.targetContent,
          sourceContentArray: location?.state?.sourceContentArray ,
          targetContentArray: location?.state?.targetContentArray ,
          status: location?.state?.status,
          tag: location?.state?.tag ,
          latinContent:location?.state?.latinContent,
          latinContentArray:location?.state?.latinContentArray,
          courseId:CourseId,
        };

        try {
          dispatch(
            LastUpdate_Request({
              user: user?.user,
              request: "post",
              data: obj,
              Api: isDialect.type
              ? `/api/v1/create-sentences-dia`
              : `/api/v1/create-sentences`,
            })
          );
        } catch (err) {
          console.error("Error updating sentence:", err);
        }

        //////
      } else {
        const obj = {
          sourceAudio: audioLink,
          checkSentence: "Source Audio",
        };
        try {
          dispatch(
            LastUpdate_Request({
              user: user?.user,
              request: "put",
              data: obj,
              Api: isDialect.type
                ? `/api/v1/sentences-dia/${SentenceId}`
                : `/api/v1/sentences/${SentenceId}`,
            })
          );
        } catch (err) {
          console.error("Error updating sentence:", err);
        }
      }
    } else {
      showToast("Add your Target Audio", "warning");
    }
  };

  useEffect(() => {
    // console.log("asfas", LastUpdate.success);
    if (LastUpdate.success) {
      if (to) {
        showToast("This sentence has been added successfully", "success");
        setTo(false);
      } else {
        showToast(" Sentence Reported Successfully", "success");
        setTo(false);
      }
      setTimeout(() => {
        navigate("/earn");
        dispatch(LastUpdate_Update_Reducer());

        dispatch(
          Get_Sou_Request({
            user: user,
            request: "get",
            data: "",
            Api: isDialect.type
              ? `/api/v1/get-content-sentences-dia?courseId=${location?.state?.courseId}&nativeDialects=${data?.languages?.data && data?.languages?.data?.languages[0]?.name}`
              : `/api/v1/get-content-sentences?courseId=${location?.state?.courseId}&nativeLanguage=${
                TargetVal?.name
                }`,
          })
        );

      }, 2000);
    }
  }, [LastUpdate.success]);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: selectedMicrophone },
    });

    const mediaRecorder = new MediaRecorder(stream);

    const chunks = [];

    mediaRecorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        chunks.push(e.data);
      }
    };

    mediaRecorder.onstop = () => {
      const blob = new Blob(chunks, { type: "audio/wav" });
      setAudioBlob(blob);
      const audioUrl = URL.createObjectURL(blob);
      audioRef.current.src = audioUrl;
    };

    mediaRecorderRef.current = mediaRecorder;
    mediaRecorder.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const playAudio = () => {
    if (audioBlob) {
      const audioUrl = URL.createObjectURL(audioBlob);
      audioRef.current.src = audioUrl;
      audioRef.current.play();
    }
  };

  const handleMicrophoneChange = async (deviceId) => {
    stopRecording();
    setSelectedMicrophone(deviceId);
  };

  const handleFlog = () => {
    const SentenceId =
      location?.pathname === "/record/source/language"
        ? SourceAndTarget?.data[0]?._id
        : AllAvilable?.data[0]?._id;

    // console.log(SentenceId);

    // console.log(user.user,'into flog');

    const obj = {
      isFinalCheck: false,
      isReported: true,
      checkSentence: "Flag Report",
      isLearner: false,
      reportFlag: {
        title: RadioVal,
        comment: InputVal,
        user: user.user,
      },
    };

    // console.log(obj,'obj for flag');

    try {
      dispatch(
        LastUpdate_Request({
          user: user?.user,
          request: "put",
          data: obj,
          Api: isDialect.type
            ? `/api/v1/sentences-dia/${SentenceId}`
            : `/api/v1/sentences/${SentenceId}`,
        })
      );
    } catch (err) {
      console.error("Error updating sentence:", err);
    }
  };

  return (
    <div className="container">
      <HeaderLogin />
      <div className="wrapper">
        <div className="langugae_courses_heading">
          <span>
            <span>
              {isDialect.type ? "" : <img src={SourceVal && SourceVal?.flag} />}
            </span>{" "}
            {SourceVal && SourceVal?.code}
          </span>
          <AiOutlineArrowRight />{" "}
          <span>
            <span>
              {isDialect.type ? "" : <img src={TargetVal && TargetVal?.flag} />}
            </span>{" "}
            {isDialect.type
              ? course?.data[0]?.targetLanguage?.name
              : TargetVal && TargetVal?.code}{" "}
          </span>{" "}
        </div>
        <div className="my_languages_wrap">
          <div className="box_left_add_ card">
            <div className="heading_languages mb-0">
              <div className="">Previous Sentences</div>
            </div>
            <div className="p10 previous_sentences">
              {!first ? (
                !SourceAndTarget.loading ? (
                  SourceAndTarget.data ? (
                    SourceAndTarget.data.map((v, i) => (
                      <div className="sentence_" key={i} style={{display:'flex',marginTop:'7px'}} >
                        <span  >#{i + 1} </span>
                        <span style={{marginLeft:'5px',display:'flex',gap:'5px',width:'92%',flexWrap:'wrap', wordBreak: 'break-word'}} >
                          {course.data[0]?.spaces
                            ? v?.targetContentArray?.map((item)=>{
                              return(
                                <>
                                <div  >{item?.title}</div>
                                </>
                              )
                            })
                            : v?.targetContentArray?.map((item)=>{
                              return(
                                <>
                                <div  >{item?.title}</div>
                                </>
                              )
                            })}
                        </span>
                      </div>
                    ))
                  ) : (
                    <Loader />
                  )
                ) : (
                  <></>
                )
              ) : null}
            </div>
          </div>

          <div className="box_left_add_">
            <div className="card p15 wd100 text-center ">
              <span> { isDialect && isDialect?.type  ? 'Please record this sentence' : 'Please record this sentence ' }</span>
            </div>
            <div className="card p20 mt-10px" >
              <div className="sentence_source text_user_show flex-space-between" >
                <span>
                  {isDialect.type ? (
                    ""
                  ) : (
                    <img src={SourceVal && SourceVal?.flag} />
                  )}
                </span>{" "}
                <div className="" style={{ display: "flex",width:'96%',flexWrap:'wrap',maxHeight:'165%',overflowY:'auto',justifyContent:'center' }}>
                  {course?.data[0]?.spaces
                    ? 
                    isDialect.type ? 
                    location?.state?.targetContentArray.map(
                      (items) => {
                        return (
                          <>
                            <div>{items.title}</div>
                          </>
                        );
                      }
                    )
                    :
                    SourceAndTarget?.data[0]?.targetContentArray.map(
                        (items) => {
                          return (
                            <>
                              <div >{items.title}</div>
                            </>
                          );
                        }
                      )

                    : 

                    isDialect.type ? 

                    location?.state?.sourceContentArray.map(
                      (items) => {
                        return (
                          <>
                            <div
                              
                              className={items.type}
                              style={{ marginLeft: "7px"}}
                            >
                              {items.title}
                            </div>
                          </>
                        );
                      }
                    )

                    :
                    
                    SourceAndTarget?.data[0]?.targetContentArray.map(
                        (items) => {
                          return (
                            <>
                              <div
                                className={items.type}
                                style={{ marginLeft: "7px" }}
                              >
                                {items.title}
                              </div>
                            </>
                          );
                        }
                      )
                      
                      }
                </div>
                <span>
                  {isDialect.type ? (
                    ""
                  ) : (
                    <img src={SourceVal && SourceVal?.flag} />
                  )}
                </span>{" "}
              </div>

              <div
                className="add_sentence_source microphone_divs flex-space-between"
                style={{ alignItems: "flex-end" }}
              >
                {
                  !isDialect?.type ?
                <div
                  className="icon_flag pointer"
                  onClick={() => {
                    setFlag(true);
                  }}
                >
                  <AiOutlineFlag />
                </div>
                : 
                <div className="icon_flag pointer" >

                </div>
                }

                <div
                  className="microphone_width"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {isRecording ? (
                    <div
                      className="microphone pointer"
                      style={{ width: "max-content" }}
                      onClick={stopRecording}
                    >
                      <FaRegPauseCircle size={70} color="var(--dark-color)" />
                    </div>
                  ) : (
                    <div
                      className="microphone pointer"
                      onClick={startRecording}
                      disabled={isRecording}
                      style={{ width: "max-content" }}
                    >
                      <IoMicCircleSharp
                        size={70}
                        color="var(--primary-color)"
                      />
                    </div>
                  )}

                  {/* {audioBlob === null ? ( */}
                  {/* "" */}
                  {/* ) : ( */}
                  <br />
                  <audio
                    ref={audioRef}
                    controls
                    style={{ display: `${audioBlob ? "block" : "none"}` }}
                  />
                  {/* )} */}
                </div>

                <div>
                  <div style={{ marginBottom: "10px", position: "relative" }}>
                    {/* <HiOutlineSpeakerWave
                      onClick={playAudio}
                      style={{
                        cursor: audioBlob && "pointer",
                        color: audioBlob ? "black" : "lightgray",
                      }}
                    /> */}
                  </div>
                  <select
                    onChange={(e) => handleMicrophoneChange(e.target.value)}
                    style={{ width: "100px" }}
                  >
                    <option value={null}>Microphone</option>
                    {microphoneList.map((microphone) => (
                      <option
                        key={microphone.deviceId}
                        value={microphone.deviceId}
                      >
                        {microphone.label ||
                          `Microphone ${
                            microphoneList.indexOf(microphone) + 1
                          }`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div
                className="question_mark_"
                data-tooltip-id="question_new_sentence"
                data-tooltip-html={
                  `
                  ${IsDialectCondion?.type ? 'Please record this sentence. Make sure that you use a microphone with a good quality and avoid background, rustling or otherwise distracting noise. Please report the sentence if it contains a mistake.' : 'Please record this sentence. Make sure that you use a microphone with a good quality and avoid background, rustling or otherwise distracting noise. It’s absolutely OK if you have a foreign accent. Please report the sentence if it contains a mistake.'} 
                  `
                }
              >
                <AiFillQuestionCircle />
              </div>
              <Tooltip
                id="question_new_sentence"
                style={{
                  textAlign: "center",
                  padding: "13px 10px",
                  fontSize: "13px",
                  zIndex: 9,
                  width: "350px",
                }}
              />
            </div>
          </div>

          <div className="box_left_add_ tags_button_next flex-space-between  p20">
            <div className="button_tags">
              <button
                className="button_custom_med secondary"
                onClick={() => navigate(-1)}
              >
                <AiOutlineArrowLeft /> Back{" "}
              </button>
              <button
                className="button_custom_med primary  mt-20px"
                onClick={() => do_next_page()}
              >
                {IsDialectCondion?.type ? "Finish" : "Finish"}
              </button>
            </div>
          </div>
        </div>
      </div>

      {flag && (
        <div className="outer_wrapper">
          <div className="inner_wrapper">
            <div className="white_wrapper w40">
              <div className="heading_languages">
                <div className="">Report the sentence</div>
                <span className="pointer" onClick={() => setFlag(false)}>
                  <IoCloseCircleOutline />
                </span>
              </div>
              <div className="" style={{ padding: "0px 40px" }}>
                {reasonsflag.map((v, i) => (
                  <div key={i} className="flag_reasons">
                    <label>
                      <input
                        onChange={(e) => setRadioVal(v.title)}
                        type="radio"
                        val={v.id}
                        name="flag"
                      />
                      <span>{v.title}</span>
                    </label>
                  </div>
                ))}

                <div className="flag_textarea">
                  <textarea
                    onChange={(e) => setInputVal(e.target.value)}
                    className="form-control"
                    placeholder="Please tell us more (optional)"
                  ></textarea>
                </div>
              </div>
              <div className="footer_languages">
                <div className=""></div>
                <div
                  className="button_custom_round primary"
                  onClick={() => {
                    setFlag(false);
                    handleFlog();
                  }}
                >
                  submit
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OnBoard;
