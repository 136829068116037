import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Earn from "../../assets/images/earn-money.png";
import Learn from "../../assets/images/learn_img.png";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import { AiFillCheckCircle, AiFillClockCircle, AiFillPlusCircle, AiFillQuestionCircle, AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaHandPointRight, FaRegClock } from "react-icons/fa6";
import { LuClipboardList, LuRepeat } from "react-icons/lu";
import { Tooltip } from "react-tooltip";
import Flag1 from "../../assets/images/eng.png";
import Flag2 from "../../assets/images/russia.png";

  const sentences_lis = [
    {title: "Das Wetter ist gut heute.", "tag": "classic", id: 1, done: 1, here: 0 },
    {title: "Das Wetter war sehr gut.", "tag": "classic", id: 2, done: 1, here: 0 },
    {title: "Das Essen war sehr lecker.", "tag": "slang", id: 3, done: 1, here: 0 },
    {title: "Das Fressen war sau lecker.", "tag": "classic", id: 4, done: 1, here: 0 },
]
function OnBoard() {
  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } = useAuth();
  const [heading, setHeading] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);

  const [selected, setSelected] = useState([]);

  const [sentencwritten, setSentenceWritten] = useState([]);
  const [first, setFirst] = useState(false);

  useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('type')) {
            const viewValue = params.get('type');
            setFirst(true)
        }  else {
            setFirst(false)
        }
    }, [window.location.search]); 

  const show_popup = async (val) => {
      setHeading(val);
      setPopup(true)
  }

  const do_next_page = async () => {
    navigate("/step/three")
  }

  function do_show_new_old(e) {
    const textareaContent = e.target.value;
    const wordsArray = textareaContent.split(/\s+/);
    const formattedArray = [];
    for (let i = 0; i < wordsArray.length; i++) {
      let wordClass = 'blacktext';
      if (i < 2) {
        wordClass = 'blacktext';
      } else if (i < 4) {
        wordClass = 'green';
      }
      formattedArray.push({ title: wordsArray[i], class: wordClass });
    }
    setSentenceWritten(formattedArray);
  }
  
  return (
    <div className="container">
      <HeaderLogin />
      <div className="wrapper">
        <div className="langugae_courses_heading">
            <span><span><img src={Flag1} /></span> {'EN'} </span> <AiOutlineArrowRight /> <span><span><img src={Flag2} /></span> {'RU'}</span>
        </div>
        <div className="my_languages_wrap">

          <div className="box_left_add_ card">
              <div className="heading_languages mb-0">
                <div className="">Previous Sentences</div>
              </div>
              <div className="p10 previous_sentences">
                {
                  !first ? sentences_lis.map((v,i)=>(
                    <>
                      <div className="senetence_">
                        <span>#{i+1}</span>
                        <span>{v.title}</span>
                      </div>
                    </>
                  ))
                  :<></>
                }
              </div>
          </div>

          <div className="box_left_add_">
            <div className="card p15 wd100 text-center">
                Please translate the sentence in the target language 
            </div>
            <div className="card p20 mt-10px">
              
                <div className="sentence_source">
                
                  <>
                  <div className="">The weather is good today.</div>
                  </>
                </div>
              

              <div className="add_sentence_source">
                <textarea name="add_sentence" className="form-control-less" placeholder="Type here" onChange={(e)=> do_show_new_old(e)}></textarea>
              </div>

             

              <div className="question_mark_" data-tooltip-id="question_new_sentence"  data-tooltip-html={'Please translate the sentence into the target language. <br />Try to keep it as literal and natural as possible. If this isn’t possible, <br />keep it natural. The use of artificial intelligence is encouraged, <br />however you are ultimately responsible for what you submit.'}>
                <AiFillQuestionCircle />
              </div>
              <Tooltip
                  id="question_new_sentence"
                  style={{ textAlign:"center", padding:"13px 10px", fontSize:"13px", zIndex:9 }}
              />
             
            </div>
            
          </div>

          <div className="box_left_add_ tags_button_next flex-space-between  p20">
              <div className="tags_left">
                <label>
                  <input type="checkbox" val={1}  disabled={'disabled'} />
                  Classic
                </label>
                <label>
                  <input type="checkbox" val={1}  checked/>
                  Slang
                </label>
                <label>
                  <input type="checkbox" val={1} disabled={'disabled'} />
                  Insult
                </label>
              </div>

              <div className="button_tags">
                <button className="button_custom_med secondary" onClick={()=> navigate(-1)}><AiOutlineArrowLeft /> Back </button>
                <button className="button_custom_med primary  mt-20px" onClick={()=> do_next_page()}>Next <AiOutlineArrowRight /></button>
              </div>
          </div>
        
        </div>
      </div>
    </div>
  );
}

export default OnBoard;
