import React from 'react'

const Loader = () => {
  return (
    <>
    Loading ....
    </>
  )
}

export default Loader