import React, { useState, useEffect } from "react";
import { FatchApi } from "../../utils/FatchApi";
import { MdOutlineKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import FaqItems from "./FaqItems";
import HeaderLogin from "../../base-components/Header/HeaderLogin";

const FAQS_API = "/api/v1/faqs";

const Fag = ({ user }) => {
  const [allFAQS, setAllFAQS] = useState([]);
  const [openFAQ, setOpenFAQ] = useState(null);

  const fetchFaqs = async () => {
    try {
      const response = await FatchApi({
        request: "get",
        Api: FAQS_API,
      });
      if (response) {
        setAllFAQS(response);
      }
    } catch (error) {
      console.log(error?.message);
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  const toggleFAQ = (id) => {
    console.log(id,'idd');
    setOpenFAQ(openFAQ === id ? null : id);
  };

  return (
    <>    
    <HeaderLogin/>
    <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "1rem" }}>
      <h1
        style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "1rem" }}
      >
        FAQs
      </h1>
      <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
        {allFAQS.map((faq) => (
          <FaqItems
            openFAQ={openFAQ}
            MdOutlineKeyboardArrowDown={MdOutlineKeyboardArrowDown}
            MdKeyboardArrowUp={MdKeyboardArrowUp}
            toggleFAQ={toggleFAQ}
            faq={faq}
          />
        ))}
      </ul>
    </div>
    </>

  );
};

export default Fag;
