// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { GetlanguageRequest } from '../../actions';

const initialState = {
 data:[]
};

const CourseIdSlice = createSlice({
  name: 'CourseId',
  initialState,
  reducers: {
    CourseId: (state,action) => {
      state.data=action.payload;
    },
  },
});

export const {CourseId} = CourseIdSlice.actions;

export default CourseIdSlice.reducer;
