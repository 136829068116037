import React, { useState } from "react";
import { Link, json, useNavigate } from "react-router-dom";
import Header from "../../base-components/Header";
import { Registeruser } from "../../constants/apiEndPoints/index.js";
import httpRequest from "../../axios/index.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function Signup() {
  const navigate = useNavigate();
  const isLearn = useSelector((state) => state.user.isLearn);
  const [loading, setLoading] = useState(false);
  const UserType = useSelector((state)=> state.UserTypes)

  // console.log(isLearn,'learner inti');

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    isLearn: true,
    isEarner:false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {

    if(isLearn !== null ){
          e.preventDefault();
          setLoading(true);
          try {
            const response = await httpRequest.post(Registeruser, formData);
      
            if (response.status === 200 || response.status === 201) {
              toast.success(response?.data?.message);
              // localStorage.setItem('Profile', JSON.stringify({ Profile: false }));
              setTimeout(() => {
                navigate("/login");
              }, 2500);
            }
            console.log(
              "🚀 ~ handleSubmit ~ response?.data?.message:",
              response?.data?.message
            );
          } catch (error) {
            toast.error(error?.response?.data?.message);
          }
          setLoading(false);
    }
    else{
      toast.success('chose your type first');
      setTimeout(() => {
        navigate("/");
      }, 2500);
    }
  };

  return (
    <div className="container">
      <Header />
      <div className="wrapper_full">
        <div className="login-container">
          <div className="">
            <h1>Create An Account</h1>
            <p>
              or <Link to="/login">Log in</Link>
            </p>
          </div>
          <div className="form_middle">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  minLength={8}
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group ">
                <div className="center_button_custom_width">
                  <button
                    type="submit"
                    className="button_custom primary wd100"
                    disabled={loading}
                  >
                    {loading ? "Creating Account..." : "Create Account"}
                  </button>
                </div>
              </div>
            </form>

            <div className="text-center line_height_text">
              By signing up, you agree to our{" "}
              <Link to="/terms-conditions">Terms</Link> and
              <br /> that you have read our{" "}
              <Link to="/privacy-policy">Privacy Policy.</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
