import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast,} from "react-toastify";
import Earn from "../../assets/images/earn-money.png";
import Learn from "../../assets/images/learn_img.png";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import useToast from "../../hooks/useToast";
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillEye,
  AiFillEyeInvisible,
  AiFillPlusCircle,
  AiFillQuestionCircle,
  AiOutlineArrowRight,
} from "react-icons/ai";
import {
  IoCloseCircleOutline,
  IoCopyOutline,
  IoHeartOutline,
  IoHeart,
  IoFlagOutline,
  IoFlag,
} from "react-icons/io5";
import {
  FaHandPointRight,
  FaRegClock,
  FaRegCirclePlay,
  FaRegCirclePause,
} from "react-icons/fa6";
import { IoMdAttach } from "react-icons/io";

import { BiMessage } from "react-icons/bi";

import { LuClipboardList, LuRepeat } from "react-icons/lu";
import Source from "./../../assets/source.mp3";
import Target from "./../../assets/target.mp3";
import { Tooltip } from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { FatchApi } from "../../utils/FatchApi";

function OnBoard() {
  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [heading, setHeading] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(true);
  const [spaces, setSpaces] = useState(true);
  const [play, setPlay] = useState(false);
  const [dictionary, setDictionary] = useState(false);
  const [selected, setSelected] = useState([]);
  const [audioCount, setAudioCount] = useState(0);
  const [audio, setAudio] = useState(null);
  const [isPlaying, setPlaying] = useState(false);
  const [countdown, setCountdown] = useState(false);
  const [count, setCount] = useState(5);
  const [heart, setHeart] = useState(false);
  const [view, setView] = useState(false);

  const showToast = useToast()

  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch()

  console.log(user,'user data');

  const NewPasswordRef = useRef();
  const ConfirmPasswordRef = useRef();
  const PasswordRef = useRef();

   const [obj,setObj]=useState()

  const handleChange = (e) => {

        var obj = {
        email: user.userEmail,
        currentPassword: PasswordRef.current.value,
        newPassword:  NewPasswordRef.current.value,
      };

      setObj(obj);

      // console.log(obj,'handle change');

  };

  const handleUpdate= async ()=>{

    console.log(obj,'ooo');
    
    if (NewPasswordRef.current.value === ConfirmPasswordRef.current.value) {

      try{
        const data = await FatchApi({
          user: user,
          request: "post",
          data: obj,
          Api: `users/update-password`
        });
  
        if(data){
          showToast("Password Update Successfully", "success");
          setTimeout(()=>{
             navigate('/')
          },2000)
        }
  
        // console.log(data,'daat');
      }
      catch(err){
           console.log(err,'update password');
      }


    }
    else{
      console.log('not same');
    }
  }

  return (
    <div className="container">
      <HeaderLogin />
      <div className="wrapper">
        <div className="my_languages_wrap">
          <div className="verifiy_form w60">
            <div className="card wd100 p20 ">
              <h4>Personal Information</h4>
              <div className="form-group mt-20">
                <label>
                  <span>Email</span>
                </label>
                <input type="text" className="" value={user?.userEmail} disabled />
              </div>
              <p className="mb-15">
                Please contact us if you want to change your email address or
                delete your account at support@langbook.org.{" "}
              </p>

              <div
                className="form-group text-center"
                style={{
                  borderBottom: "1px solid #f0f0f0",
                  paddingBottom: "20px",
                }}
              ></div>

              <div className="form-group">
                <label>Current password</label>
                <input
                  onChange={handleChange}
                  ref={PasswordRef}
                  type={`${view ? "text" : "password"}`}
                  className=""
                />
                <div className="abs_icon_view" onClick={() => setView(!view)}>
                  {view ? <AiFillEye /> : <AiFillEyeInvisible />}
                </div>
              </div>
              <div className="form-group">
                <label>New password</label>
                <input
                  onChange={handleChange}
                  ref={NewPasswordRef}
                  type={`${view ? "text" : "password"}`}
                  className=""
                />
                <div className="abs_icon_view" onClick={() => setView(!view)}>
                  {view ? <AiFillEye /> : <AiFillEyeInvisible />}
                </div>
              </div>
              <div className="form-group">
                <label>Confirm new password</label>
                <input
                  onChange={handleChange}
                  ref={ConfirmPasswordRef}
                  type={`${view ? "text" : "password"}`}
                  className=""
                />
                <div className="abs_icon_view" onClick={() => setView(!view)}>
                  {view ? <AiFillEye /> : <AiFillEyeInvisible />}
                </div>
              </div>

              <div className="flex-center mt-10px" style={{ clear: "both" }}>
                <button onClick={handleUpdate} className="button_custom_large_rount primary flex-space-between">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnBoard;
