import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation , useNavigate } from "react-router-dom";
import httpRequest from "../../axios";
import { PROTECTED } from "../../constants/apiEndPoints";
import { toast } from "react-toastify";
import { clearUser } from "../../lib/Redux/slices/userslice";
import useResetReducer from "../../utils/useResetReducer";

export const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const location = useLocation();
  const [granted, setGranted] = useState(false);

  const navigate = useNavigate()
  const resetAllReducers = useResetReducer();

  useEffect(() => {
    console.log(location.pathname, "path name");

    const checkToken = async () => {
      if (location.pathname === "/learn") {
        // console.log('ddddjjjjjj');
        navigate('/learn')
        return;
      } else {
        console.log(user?.token, "user token");
        try {
          const resp = await httpRequest.get(PROTECTED, {
            headers: {
              Authorization: `Bearer ${user?.token}`,
            },
          });
          console.log(resp, "response ...");
          if (resp?.data?.message === "Access Granted") {
            setGranted(true);
          }
        } catch (err) {
          if (err?.response?.status === 401) {
            //  debugger
            handleUnauthorized();
          }
        }
      }
    };
    checkToken();
  }, [location.pathname]);

  const handleUnauthorized = () => {

    toast.error("Session Expired. Please Login Again!");
    dispatch(clearUser());
    localStorage.clear();
    resetAllReducers(false);
      navigate("/");
    // dispatch(clearUser());
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  const targetRoute = getTargetRoute(user.role, location.pathname);

  if (targetRoute !== location.pathname) {
    return <Navigate to={targetRoute} replace />;
  }

  return children;
};

const getTargetRoute = (userRole, currentPath) => {
  console.log(currentPath, "currentPath");

  // if (userRole) {
  //   if (currentPath.startsWith("/earn")) {
  //     return "/learn";
  //   }
  // } else {
  //   if (currentPath.startsWith("/learn")) {
  //     return "/earn";
  //   }
  // }

  return currentPath;
};
