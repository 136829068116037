import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { LanguagesWords } from "../../utils/functions";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import {
  IoCloseCircleOutline,
  IoCopyOutline,
  IoHeartOutline,
  IoHeart,
  IoFlagOutline,
} from "react-icons/io5";
import { FaRegCirclePlay, FaRegCirclePause } from "react-icons/fa6";
import { BiMessage } from "react-icons/bi";
import Source from "./../../assets/source.mp3";
import Target from "./../../assets/target.mp3";
import { FatchApi } from "../../utils/FatchApi";
import { useDispatch, useSelector } from "react-redux";
import { LastUpdate_Request } from "../../lib/Redux/actions";
import useToast from "../../hooks/useToast";
import {
  IsNext_Reducer,
  IsPlay_Reducer,
} from "../../lib/Redux/slices/ExtraSlice";
import ReactAudioPlayer from "react-audio-player";
import Loader from "../../base-components/Loader/Loader";
import { LastUpdate_Update_Reducer } from "../../lib/Redux/slices/LastUpdate";

function OnBoard() {
  const navigate = useNavigate();
  const popupRef = useRef(null);

  const course = useSelector((state) => state.Course);
  const CourseIdR = useSelector((state) => state.CourseId);
  const LastUpdate = useSelector((state) => state.LastUpdate);

  const location = useLocation();

  // console.log(course?.data?.spaces,'course here');

  const Source =
    location?.state?.alldata &&
    location?.state?.alldata[0]?.courseId?.sourceLanguage?.code.toLowerCase();
  const Target =
    location?.state?.alldata &&
    location?.state?.alldata[0]?.courseId?.targetLanguage?.code.toLowerCase();

  console.log(location?.state, "state");

  const urldictionary = `https://glosbe.com/${Source}/${Target}/`;
  const urldictionarytarget = `https://glosbe.com/${Target}/${Source}/`;

  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();

  const [TargetSen, setTartgeSen] = useState(
    location?.state?.v?.targetContentArray
  );
  const [SouSen, setSouSen] = useState(location?.state?.v?.sourceContentArray);
  const [Index, setIndex] = useState(location?.state?.index);
  const [spaces, setSpaces] = useState(false);
  const [play, setPlay] = useState(false);
  const [dictionary, setDictionary] = useState(false);
  const [selected, setSelected] = useState([]);
  const [audio, setAudio] = useState(null);
  const [countdown, setCountdown] = useState(false);
  const [count, setCount] = useState(5);
  const [heart, setHeart] = useState(false);
  const [flag, setFlag] = useState(false);
  const [issentence, setIssentence] = useState(false);
  const [isActualsentence, setActualsentence] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [FirstSentence, setFirstSentence] = useState(false);
  const [SecondSentence, setSecondSentence] = useState(false);
  const [ThirdSentence, setThirdSentence] = useState(false);
  const [AudioAllow, setAudioAllow] = useState(true);
  const [SourceMode, setSourceMode] = useState(true);
  const [TargetMode, setTargetMode] = useState(false);
  const [TargetMode2, setTargetMode2] = useState(false);
  const [starttime, setStartTime] = useState();
  const [isAllow, setIsAllow] = useState(true);

  // const [playbackPercentage, setPlaybackPercentage] = useState(() => {
  //   const storedVolume = localStorage.getItem("volume");
  //   return storedVolume !== null ? JSON.parse(storedVolume) : 100;
  // });

  const { isAuthenticated } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.user);
  const Extra = useSelector((state) => state.ExtraSlice);
  const verb = useSelector((state) => state?.Verb);
  const isDialect = useSelector((state) => state.IsDialect);
  const sentancefilter = useSelector((state) => state.SentanceFilter);

  // console.log('ssssssssdddddddddddd',sentancefilter?.data?.radio , isDialect );
  // console.log(verb,'bbb');

  // console.log(location.state.alldata[Index], "oojjb");

  // useEffect(() => {
  //   // Define the audio variables
  //   let newAudio, TargetAudio, TargetAudio2;

  //   // Cleanup function to pause the audio elements when the component unmounts
  //   return () => {

  //     setAudioAllow(false)

  //     console.log('component dead');
  //     newAudio = new Audio(location?.state?.alldata[Index]?.sourceAudio);
  //     TargetAudio = new Audio(location?.state?.alldata[Index]?.targetAudio);
  //     TargetAudio2 = new Audio(location?.state?.alldata[Index]?.targetAudio);

  //     newAudio.pause();
  //     TargetAudio.pause();
  //     TargetAudio2.pause();
  //   };
  // }, []);
  // useEffect(() => {
  //   console.log(Extra, "extra on it you know ...");
  //   if (Extra?.IsNext_type) {
  //     playTheAudioSource()
  //   }
  // }, [Extra?.IsNext_type]);

  useEffect(() => {
    return () => {
      AllStop();
    };
  }, []);

  const do_save_langs = async (val) => {
    const isValueInArray = selected.includes(val);
    if (isValueInArray) {
      const newArray = selected.filter((item) => item !== val);
      setSelected(newArray);
    } else {
      setSelected([...selected, val]);
    }
  };

  const reasonsflag = [
    { title: "mistake in the source language audio", id: 1 },
    { title: "mistake in the target language audio", id: 2 },
    { title: "mistake in the source language", id: 3 },
    { title: "mistake in the target language", id: 4 },
    { title: "words  marked incorrectly", id: 5 },
    { title: "wrong tag (classic, slang, insult)", id: 6 },
    { title: "inappropriate sentence", id: 7 },
    { title: "other", id: 8 },
  ];

  // const VerbsData = [
  //   {
  //     title: "nouns",
  //     color: "#BDBBBB",
  //   },
  //   {
  //     title: "pronouns",
  //     color: "#1C6E2F",
  //   },
  //   {
  //     title: "verbs",
  //     color: "#BF9898",
  //   },
  //   {
  //     title: "adverbs",
  //     color: "#CF1B1B",
  //   },
  //   {
  //     title: "adjectives",
  //     color: "#28CD19",
  //   },
  //   {
  //     title: "prepositions",
  //     color: "#372ADB",
  //   },
  //   {
  //     title: "conjunctions",
  //     color: "#BA20B4",
  //   },
  //   {
  //     title: "interjections",
  //     color: "#F8B1CF",
  //   },
  //   {
  //     title: "articles",
  //     color: "#31C6DA",
  //   },
  //   {
  //     title: "determiners",
  //     color: "#C2CF28",
  //   },
  // ];

  const [isPlaying, setIsPlaying] = useState(false);
  const [counterplay, setCounterPlay] = useState(false);
  const intervalRef = useRef(null);
  const startTimeRef = useRef(null);
  let interval;

  var newAudio, TargetAudio, TargetAudio2;

  //  new

  // console.log(sentancefilter?.data[0], "state of id");

  // const [currentAudio, setCurrentAudio] = useState(null);

  // const playAudioSource = async () => {
  //   setFirstSentence(true);
  //   setSecondSentence(false);
  //   setFirstSentence(false);

  // var playbackPercentageDiff = localStorage.getItem("volume");
  // var playbackPercentage = playbackPercentageDiff !== null? JSON.parse(playbackPercentageDiff): 100;

  //   const starttime = Date.now();

  //   setPlay(true);
  //   setIssentence(true); // Set to true before playing

  //   newAudio = new Audio(location?.state?.alldata[Index]?.sourceAudio);
  //   TargetAudio = new Audio(location?.state?.alldata[Index]?.targetAudio);
  //   TargetAudio2 = new Audio(location?.state?.alldata[Index]?.targetAudio);

  //   // Ensure playbackPercentage is valid
  //   const playbackRate = playbackPercentage / 100;
  //   if (isNaN(playbackRate) || playbackRate <= 0) {
  //     console.error("Invalid playback percentage:", playbackPercentage);
  //     return;
  //   }

  //   // Play the newAudio with the specified playback rate
  //   newAudio.playbackRate = playbackRate;
  //   setCurrentAudio(newAudio);
  //   if (AudioAllow) {
  //     newAudio.play();
  //   }

  //   // When newAudio finishes playing
  //   newAudio.onended = () => {
  //     setIssentence(true);
  //     setActualsentence(false);
  //     setCurrentAudio(TargetAudio);

  //     // Play TargetAudio after a 2-second delay
  //     setTimeout(() => {
  //       setFirstSentence(false);
  //       setSecondSentence(true);
  //       setFirstSentence(false);

  //       setIssentence(false);
  //       TargetAudio.playbackRate = playbackRate;
  //       setCurrentAudio(TargetAudio);
  //       if (AudioAllow) {
  //         TargetAudio.play();
  //       }
  //       setActualsentence(true);
  //     }, 2000);

  //     // When TargetAudio finishes playing
  //     TargetAudio.onended = () => {
  //       setIssentence(false);
  //       setCurrentAudio(TargetAudio2);

  //       // Play TargetAudio2 after a 2-second delay
  //       setTimeout(() => {
  //         setFirstSentence(false);
  //         setSecondSentence(false);
  //         setFirstSentence(true);

  //         TargetAudio2.playbackRate = playbackRate;
  //         setCurrentAudio(TargetAudio2);
  //         if (AudioAllow) {
  //           TargetAudio2.play();
  //         }
  //       }, 2000);

  //       // When TargetAudio2 finishes playing
  //       TargetAudio2.onended = () => {
  //         setFirstSentence(false);
  //         setSecondSentence(false);
  //         setFirstSentence(false);

  //         setActualsentence(false);
  //         setCountdown(true);
  //         setPlay(false);
  //         clearInterval(intervalRef.current);

  //         const endtime = Date.now();
  //         const tottaltime = endtime - starttime;
  //         SendData(tottaltime);

  //         setIsNext(true);
  //       };
  //     };
  //   };
  // };

  // const pauseAudio2 = async () => {
  //   if (currentAudio) {
  //     currentAudio.pause();
  //     setIssentence(false);
  //     setPlay(false);
  //   }
  // };

  // old

  // const [currentAudio, setCurrentAudio] = useState(null);

  // const playAudioSource = async () => {
  //     setFirstSentence(true);
  //     setSecondSentence(false);
  //     setFirstSentence(false);

  //     var playbackPercentageDiff = localStorage.getItem("volume");
  //     var playbackPercentage =
  //       playbackPercentageDiff !== null
  //         ? JSON.parse(playbackPercentageDiff)
  //         : 100;

  //     const starttime = Date.now();

  //     setPlay(true);
  //     setIssentence(true); // Set to true before playing

  //     newAudio = new Audio(location?.state?.alldata[Index]?.sourceAudio);
  //     TargetAudio = new Audio(location?.state?.alldata[Index]?.targetAudio);
  //     TargetAudio2 = new Audio(location?.state?.alldata[Index]?.targetAudio);

  //     // Ensure playbackPercentage is valid
  //     const playbackRate = playbackPercentage / 100;
  //     if (isNaN(playbackRate) || playbackRate <= 0) {
  //       console.error("Invalid playback percentage:", playbackPercentage);
  //       return;
  //     }

  //     // Play the newAudio with the specified playback rate
  //     newAudio.playbackRate = playbackRate;
  //     newAudio.play();

  //     // When newAudio finishes playing
  //     newAudio.onended = () => {
  //       setIssentence(true);
  //       setActualsentence(false);

  //       // Play TargetAudio after a 2-second delay
  //       setTimeout(() => {
  //         setFirstSentence(false);
  //         setSecondSentence(true);
  //         setFirstSentence(false);

  //         setIssentence(false);
  //         TargetAudio.playbackRate = playbackRate;
  //         TargetAudio.play();
  //         setActualsentence(true);
  //       }, 2000);

  //       // When TargetAudio finishes playing
  //       TargetAudio.onended = () => {
  //         setIssentence(false);

  //         // Play TargetAudio2 after a 2-second delay
  //         setTimeout(() => {
  //           setFirstSentence(false);
  //           setSecondSentence(false);
  //           setFirstSentence(true);

  //           TargetAudio2.playbackRate = playbackRate;
  //           TargetAudio2.play();
  //         }, 2000);

  //         // When TargetAudio2 finishes playing
  //         TargetAudio2.onended = () => {
  //           setFirstSentence(false);
  //           setSecondSentence(false);
  //           setFirstSentence(false);

  //           setActualsentence(false);
  //           setPlay(false);

  //           setCountdown(true);
  //           clearInterval(intervalRef.current);
  //           const endtime = Date.now();
  //           const tottaltime = endtime - starttime;
  //           SendData(tottaltime);

  //           setIsNext(true);
  //         };
  //       };
  //     };
  //   };
  //  const pauseAudio = async () => {

  //   newAudio?.pause();
  //   TargetAudio?.pause();
  //   TargetAudio2?.pause();

  //   setPlay(true)

  //   console.log('pause...',FirstSentence,SecondSentence,ThirdSentence);

  //   if (FirstSentence) {
  //     console.log('first',FirstSentence);
  //     newAudio.pause();
  //   }
  //   if (SecondSentence) {
  //     console.log('second',SecondSentence);
  //     TargetAudio.pause();
  //   }
  //   if (ThirdSentence) {
  //     console.log('third',ThirdSentence);
  //     TargetAudio2.pause();
  //   }
  //   setIssentence(false);
  // };

  const SendData = async (elapsedMilliseconds, isNextPlay) => {
    setNextLoading(true);
    setActualsentence(false);
    setIssentence(false);
    setSourceMode(true);
    setTargetMode(false);
    setTargetMode2(false);
    setPlay(false);

    try {
      // Check authentication and index
      if (!isAuthenticated && Index >= 19) {
        showToast("You need to login", "error");
        return;
      }

      if (isAuthenticated) {
        if (isAllow) {
          // Prepare data object
          const obj = {
            courseId: CourseIdR && CourseIdR?.data,
            stayTime: elapsedMilliseconds,
            sentenceId: location?.state?.alldata[Index]?._id,
            ...(sentancefilter?.data[0]?.radio === "Only new"
              ? { currentIndexNew: Index }
              : sentancefilter?.data[0]?.radio === "Only repetition"
              ? { currentIndexRepetition: Index }
              : { currentIndexNewandrepetition: Index }),
          };

          // Fetch API request
          const data = await FatchApi({
            request: "post",
            data: obj,
            Api: !isDialect?.type
              ? `/api/v1/update-learner-sentence-manage`
              : `/api/v1/update-learner-sentence-dialect-manage`,
          });

          // Handle response data
          if (data) {
            if (isNextPlay == true) {
              setIndex((index) => index + 1);
              setTimeout(() => {
                setNextLoading(false);
                if (location?.state?.alldata?.length == Index + 1) {
                  return;
                } else {
                  console.log(
                    "not return",
                    "Index data :",
                    Index + 1,
                    "all data :",
                    location?.state?.alldata?.length
                  );
                  playTheAudioSource();
                }
              }, 2000);
            }
          }
        } else {
          if (isNextPlay == true) {
            setIsAllow(true)
            setIndex((index) => index + 1);
            setTimeout(() => {
              setNextLoading(false);
              if (location?.state?.alldata?.length == Index + 1) {
                return;
              } else {
                console.log(
                  "not return",
                  "Index data :",
                  Index + 1,
                  "all data :",
                  location?.state?.alldata?.length
                );
                playTheAudioSource();
              }
            }, 2000);
          }
        }
      }
    } catch (error) {
      // Handle any errors that occur during the async operation
      console.error("Error sending data:", error);
      showToast("An error occurred while sending data", "error");
    } finally {
      // Ensure loading state is turned off if not already handled
      if (isAuthenticated) {
        setNextLoading(false);
      }
    }
  };

  // useEffect(() => {
  //   console.log("Extra play", Extra?.IsNext_type);
  //   if (Extra && Extra?.IsNext_type) {
  //     playAudioSource();
  //   }
  // }, [Index, Extra?.isNext_type]);

  useEffect(() => {
    if (countdown) {
      if (!isAuthenticated && Index >= 19) {
        setCountdown(false);
        setIsNext(false);
        setCount(5);
        return;
      }

      if (count <= 0) {
        setCountdown(false);
        setIsNext(false);
        setCount(5);
        const endtime = Date.now();
        const tottaltime = endtime - starttime;
        SendData(tottaltime, true);
        return;
      }
      const interval = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [countdown, count]);

  ////////////////////////////////

  const copyText = async (val) => {
    navigator.clipboard.writeText(val);
    // if(issentence){
    //   toast.success("Source sentence copied successfully!");
    // }
    // else{
    toast.success("Target sentence copied successfully!");
    // }
  };

  useEffect(() => {
    return () => clearInterval(intervalRef.current); // Cleanup interval on component unmount
  }, []);

  const dispatch = useDispatch();

  const [InputVal, setInputVal] = useState();
  const [RadioVal, setRadioVal] = useState();

  // const [flagloading,setFlagLoading] = useState(false);

  const showToast = useToast();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleFlagReport = () => {
    const date = new Date();
    const currentDate = date.getDate();
    const currentMonth = date.getMonth();
    const currentYear = date.getFullYear();

    const monthName = monthNames[currentMonth];

    console.log(RadioVal, "object here");

    const SentenceId = location?.state?.alldata[Index]?._id;
    const obj = {
      isFinalCheck: false,
      isReported: true,
      checkSentence: "Flag Report",
      isLearner: true,
      reportFlag: {
        earnerSide: false,
        title: RadioVal,
        comment: InputVal,
        user: user,
        reportedDate: {
          data: currentDate,
          month: monthName,
          year: currentYear,
        },
      },
    };

    try {
      dispatch(
        LastUpdate_Request({
          request: "put",
          data: obj,
          Api: isDialect?.type
            ? `/api/v1/sentences-dia/${SentenceId} `
            : `/api/v1/sentences/${SentenceId}`,
        })
      );
    } catch (err) {
      console.error("Error updating sentence:", err);
    }
  };

  useEffect(() => {
    // console.log(LastUpdate.success,'success here');
    if (LastUpdate.success) {
      showToast("Sentence reported successfully", "success");
      setTimeout(() => {
        dispatch(LastUpdate_Update_Reducer());
        navigate("/learn");
      }, 2000);
    }
  }, [LastUpdate.success]);

  const [IsHeartBack, setIsHeartBack] = useState(false);

  useEffect(() => {
    const handleHeartGet = async () => {
      try {
        const sentenceId = location.state.alldata[Index]?._id;

        const data = await FatchApi({
          request: "get",
          data: "",
          Api: !isDialect?.type
            ? `/api/v1/get-sentence-like?sentenceId=${sentenceId}`
            : `/api/v1/get-sentence-like-dia?sentenceId=${sentenceId}`,
        });

        if (data) {
          const finddata = data?.userLikes?.filter(
            (item) => item === user.userId
          );
          console.log(finddata, "finddata", user.userId);
          if (finddata && finddata.length > 0) {
            setIsHeartBack(true);
            console.log(IsHeartBack, "is heart back");
          } else {
            setIsHeartBack(false);
          }
        }
      } catch (error) {
        console.error("Error fetching sentence likes:", error);
      }
    };

    handleHeartGet();
  }, []);

  const handleHeart = async () => {
    if (!isAuthenticated) {
      showToast("Need to Login", "info");
    } else {
      setHeart(!heart);

      var obj = {
        sentenceId: location.state.alldata[Index]?._id,
        userId: user.userId,
      };

      const data = await FatchApi({
        request: "post",
        data: obj,
        Api: !isDialect?.type
          ? `/api/v1/sentence-like`
          : `/api/v1/sentence-like-dia`,
      });
      if (data) {
        showToast(data?.message, "success");
      }
    }
  };

  const [nextLoading, setNextLoading] = useState(false);

  const handleNext = () => {
    if (!isAuthenticated && Index >= 19) {
      showToast("You need to login", "error");
      return;
    }

    // if(issentence == true || isActualsentence == true ){
    //   showToast('playing','info')
    //   return
    // }

    location?.state?.alldata && location?.state?.alldata?.length === Index + 1
      ? dispatch(IsNext_Reducer(false))
      : dispatch(IsNext_Reducer(true));
    // dispatch(IsPlay_Reducer(true))
    setCountdown(false);
    setCount(5);
    const starttime = Date.now();
    const endtime = Date.now();
    const tottaltime = endtime - starttime;
    // console.log(isAllow,'is Allow handleNext');
    SendData(tottaltime, true);
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  };

  const AddClass = (v) => {
    const match = verb?.data?.some((item) => item === v.type);
    return match;
  };

  ////////////////////////////

  const audioPlayerRefSource = useRef(null);
  const audioPlayerRefTarget = useRef(null);
  const audioPlayerRefTarget2 = useState(null);

  const playTheAudioSource = async () => {
    if (audioPlayerRefSource.current) {
      const audioElement = await audioPlayerRefSource.current.audioEl.current;

      try {
        await audioElement.pause();

        const start = Date.now();
        setStartTime(start);
        setSourceMode(true);
        setTargetMode(false);
        setTargetMode2(false);
        setPlay(true);
        setIssentence(true);

        const playbackPercentageDiff = localStorage.getItem("volume");
        // console.log(playbackPercentageDiff, "playback per diff");

        let playbackPercentage =
          playbackPercentageDiff !== null
            ? JSON.parse(playbackPercentageDiff)
            : 100;
        playbackPercentage = Math.max(10, Math.min(playbackPercentage, 300));
        const playbackRate = playbackPercentage / 100;
        if (playbackRate >= 0.1 && playbackRate <= 3.0) {
          audioElement.playbackRate = playbackRate;
        } else {
          console.error(
            "Playback rate is out of range. It must be between 0.1 and 3.0"
          );
        }

        if (audioElement) {
          await audioElement.play();
        }
      } catch (error) {
        console.error("Error playing audio:", error);
      }
    }
  };

  const pauseTheAudioSource = () => {
    if (audioPlayerRefSource?.current) {
      const audioElement = audioPlayerRefSource.current.audioEl.current;
      setSourceMode(true);
      setTargetMode(false);
      setTargetMode2(false);
      setPlay(false);
      audioElement.pause();
    }
  };

  const handleAudioEndSource = () => {
    setTimeout(() => {
      setSourceMode(false);
      setTargetMode(true);
      setTargetMode2(false);
      setIssentence(false);
      setPlay(false);
      playTheAudioTarget();
    }, 2000);
  };

  const playTheAudioTarget = async () => {
    if (audioPlayerRefTarget?.current) {
      const audioElement = audioPlayerRefTarget.current.audioEl.current;

      try {
        await audioElement.pause();
        audioElement.currentTime = 0;

        setSourceMode(false);
        setTargetMode(true);
        setTargetMode2(false);
        setPlay(true);
        setActualsentence(true);
        const playbackPercentageDiff = localStorage.getItem("volume");
        const playbackPercentage =
          playbackPercentageDiff !== null
            ? JSON.parse(playbackPercentageDiff)
            : 100;
        const playbackRate = playbackPercentage / 100;
        if (playbackRate >= 0.1 && playbackRate <= 3.0) {
          audioElement.playbackRate = playbackRate;
        } else {
          console.error(
            "Playback rate is out of range. It must be between 0.5 and 2.0"
          );
        }
        await audioElement.play();
      } catch (error) {
        console.error("Error playing audio:", error);
      }
    }
  };

  const pauseTheAudioTarget = () => {
    if (audioPlayerRefTarget?.current) {
      const audioElement = audioPlayerRefTarget.current.audioEl.current;
      setSourceMode(false);
      setTargetMode(true);
      setTargetMode2(false);
      setPlay(false);
      audioElement.pause();
    }
  };

  const handleAudioEndTarget = () => {
    setTimeout(() => {
      setSourceMode(false);
      setTargetMode(false);
      setTargetMode2(true);
      playTheAudioTarget2();
    }, 2000);
  };

  const playTheAudioTarget2 = async () => {
    if (audioPlayerRefTarget2?.current) {
      const audioElement = audioPlayerRefTarget2.current.audioEl.current;

      try {
        await audioElement.pause();
        audioElement.currentTime = 0;

        setActualsentence(true);
        setSourceMode(false);
        setTargetMode(false);
        setTargetMode2(true);
        setPlay(true);

        const playbackPercentageDiff = localStorage.getItem("volume");
        const playbackPercentage =
          playbackPercentageDiff !== null
            ? JSON.parse(playbackPercentageDiff)
            : 100;
        const playbackRate = playbackPercentage / 100;
        if (playbackRate >= 0.1 && playbackRate <= 3.0) {
          audioElement.playbackRate = playbackRate;
        } else {
          console.error(
            "Playback rate is out of range. It must be between 0.5 and 2.0"
          );
        }
        await audioElement.play();
      } catch (error) {
        console.error("Error playing audio:", error);
      }
    }
  };

  const pauseTheAudioTarget2 = () => {
    if (audioPlayerRefTarget2?.current) {
      const audioElement = audioPlayerRefTarget2.current.audioEl.current;
      setSourceMode(false);
      setTargetMode(false);
      setTargetMode2(true);
      setPlay(false);
      audioElement.pause();
    }
  };

  const handleAudioEndTarget2 = () => {
    setSourceMode(true);
    setTargetMode(false);
    setTargetMode2(false);
    setActualsentence(false);
    setPlay(false);

    if (!isAuthenticated && Index >= 19) {
      setCountdown(false);
      showToast("you need to Login", "error");
      return;
    } else {
      setCountdown(true);
    }
    clearInterval(intervalRef.current);
  };

  const AllStop = () => {
    handleAudioEndTarget();
    handleAudioEndSource();
    handleAudioEndTarget2();
  };

  const PlayOn = () => {
    var sourceAudio = location?.state?.alldata[Index]?.sourceAudio;
    var targetAudio = location?.state?.alldata[Index]?.targetAudio;

    console.log(sourceAudio, targetAudio, "source and target");

    if (
      sourceAudio == "" ||
      sourceAudio == undefined ||
      sourceAudio == null ||
      sourceAudio?.length <= 0
    ) {
      showToast("No audio found", "error");
      return;
    }

    if (
      targetAudio == "" ||
      targetAudio == undefined ||
      targetAudio == null ||
      targetAudio?.length <= 0
    ) {
      showToast("No audio found", "error");
      return;
    }

    console.log(SourceMode, "source mode");

    if (SourceMode) {
      playTheAudioSource();
    } else if (TargetMode) {
      playTheAudioTarget();
    } else if (TargetMode2) {
      playTheAudioTarget2();
    } else {
      // Do nothing or handle the case where none of the conditions are true
    }
  };

  const PauseOff = () => {
    console.log(SourceMode, TargetMode, TargetMode2, "pauseOff");
    if (SourceMode) {
      pauseTheAudioSource();
    } else if (TargetMode) {
      pauseTheAudioTarget();
    } else if (TargetMode2) {
      pauseTheAudioTarget2();
    } else {
      // Do nothing or handle the case where none of the conditions are true
    }
  };

  //////////////////////////

  return (
    <div className="container">
      <HeaderLogin />
      <div className="wrapper">
        <div className="my_languages_wrap">
          <div className="card wd100 p20">
            <div className="links_top_sentence flex-space-between">
              <div className="left_sentence_pills">
                <span className="pill_circle">
                  {(location?.state?.alldata &&
                    location?.state?.alldata[Index]?.tag) ||
                    ""}
                </span>
                {/* <span className="pill_circle">Slang</span>  */}
              </div>

              <div className="left_sentence_pills">
                {course && course?.data && course?.data?.spaces ? (
                  <span
                    className={`pill_circle dark pointer ${
                      !spaces ? "" : "primary"
                    }`}
                    onClick={() => setSpaces(!spaces)}
                  >
                    {spaces ? "Unspace words" : "Space words"}
                  </span>
                ) : (
                  " "
                )}
                {isDialect?.type ? (
                  ""
                ) : (
                  <span
                    className={`pill_circle dark pointer ${
                      dictionary ? "primary" : ""
                    }`}
                    onClick={() => setDictionary(!dictionary)}
                  >
                    {!dictionary
                      ? "Link to the dictionary"
                      : "Unlink from the dictionary"}
                  </span>
                )}
              </div>
            </div>

            {location?.state?.alldata &&
            location?.state?.alldata?.length === Index ? (
              <div
                style={{
                  height: "290px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <h1>No more sentences</h1>
                  <button onClick={() => navigate("/course/details")}>
                    Back
                  </button>
                </div>
              </div>
            ) : (
              <div className="sentence_wrapper">
                <div
                  className="play_icons pointer"
                  // onClick={() => setPlay(!play)}
                >
                  {!play ? (
                    <FaRegCirclePlay
                      size={60}
                      color="var(--primary-color)"
                      onClick={() => PlayOn()}
                      // onClick={playAudioSource}
                    />
                  ) : (
                    <FaRegCirclePause
                      size={60}
                      color="var(--primary-color)"
                      onClick={() => PauseOff()}
                      // onClick={pauseAudio}
                    />
                  )}
                </div>

                <div className="right_sentences_data">
                  <div
                    // style={{border:'solid 1px red'}}
                    className={`sentence_data  ${
                      course && course?.data?.spaces ? 'gap_10' : '0'
                      // course && course?.data?.spaces
                      //   ? spaces
                      //     ? "gap_10"
                      //     : ""
                      //   : "gap_10"
                    } ${issentence ? "flashing-div" : ""}`}
                  >
                    <>
                    <div style={{width:'100%',overflowX:'auto'}} >
                      {location?.state?.alldata &&
                        location?.state?.alldata[
                          Index
                        ]?.targetContentArray?.map((v, i) => (
                          <>
                            {dictionary ? (
                              <>
                                <a
                                style={{marginLeft: spaces ? '8px' : course && !course?.data?.spaces ?'8px': '0px'}}
                                  onClick={() => PauseOff()}
                                  href={  
                                    urldictionary +
                                    v.title.replace(/[.,!?;:]/g, "")
                                  }
                                  target="_blank"
                                  className={AddClass(v) ? v.type : ""}
                                >
                                  {v.title}
                                </a>
                              </>
                            ) : (
                              <span style={{marginLeft: spaces ? '8px' : course && !course?.data?.spaces ?'8px': '0px'}} className={AddClass(v) ? v.type : ""}>
                                {v.title}
                              </span>
                            )}
                          </>
                        ))}
                    </div>
                    </>
                  </div>
                  <div
                    className={`sentence_data
                       ${
                      course && course?.data?.spaces
                        ? spaces
                          ? "gap_10"
                          : ""
                        : "gap_10"
                    } 
                    ${isActualsentence ? "flashing-div" : ""}`}
                    style={{ height: "100px", alignItems: "flex-start" }}
                  >
                    <div style={{ display: "flex", flexDirection: "column",width:'100%',overflowX:'auto',padding:'5px' }}>
                      <div
                        style={{
                          display: "flex",
                          width:'100%',
                          // border:'solid 1px red',
                          gap:
                            course && course?.data?.spaces
                              ? ` ${spaces ? "8px" : ""}`
                              : `8px`,
                        }}
                      >
                        {location?.state?.alldata &&
                          location?.state?.alldata[
                            Index
                          ]?.sourceContentArray?.map((v, i) => (
                            <>
                              {dictionary ? (
                                <>
                                  <a
                                    onClick={() => PauseOff()}
                                    href={
                                      urldictionarytarget +
                                      v.title.replace(/[.,!?;:]/g, "")
                                    }
                                    target="_blank"
                                    className={AddClass(v) ? v.type : ""}
                                    style={{
                                      marginLeft: spaces ? "5px" : "0px",
                                    }}
                                  >
                                    {v.title}
                                  </a>
                                </>
                              ) : (
                                <span
                                  style={{ marginLeft: spaces ? "5px" : "0px" }}
                                  className={AddClass(v) ? v.type : ""}
                                >
                                  {v.title}
                                </span>
                              )}
                            </>
                          ))}
                      </div>
                      <div
                        style={{
                          marginTop: "7px",
                          fontSize: "14px",
                          display: "flex",
                          // border:'solid 1px red',
                        }}
                      >
                        {location?.state?.alldata &&
                          location?.state?.alldata[
                            Index
                          ]?.latinContentArray?.map((latin) => {
                            return (
                              <>
                                <div
                                  style={{ marginLeft: "5px" }}
                                  className={latin?.type}
                                >
                                  {latin?.title}
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </div>

                  <div className="icons_sentence">
                    <div
                      className="icon_set pointer"
                      onClick={() =>
                        copyText(
                          //   issentence ?
                          //   location?.state?.alldata &&
                          location?.state?.alldata[Index]?.sourceContent
                          // :
                          // location?.state?.alldata &&
                          // location?.state?.alldata[Index]?.sourceContent
                        )
                      }
                    >
                      <IoCopyOutline />
                    </div>
                    <div className="icon_set pointer" onClick={handleHeart}>
                      {!isAuthenticated ? (
                        <IoHeartOutline />
                      ) : IsHeartBack ? (
                        <IoHeart />
                      ) : IsHeartBack && heart ? (
                        <IoHeart />
                      ) : heart ? (
                        <IoHeart />
                      ) : !heart && IsHeartBack ? (
                        <IoHeartOutline />
                      ) : (
                        <IoHeartOutline />
                      )}
                    </div>
                    <div
                      className="icon_set pointer"
                      onClick={() =>
                        isAuthenticated
                          ? setFlag(true)
                          : showToast("you need to login", "info")
                      }
                    >
                      <IoFlagOutline />
                    </div>
                    <div
                      className="icon_set pointer"
                      onClick={() =>
                        isAuthenticated
                          ? navigate("/comment/sentence", {
                              state: {
                                sourceContent: location.state.alldata[Index],
                              },
                            })
                          : showToast("you need to login", "info")
                      }
                    >
                      <BiMessage />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "-32px",
              }}
            >
              {/* {isNext && ( */}
              {location?.state?.alldata &&
              location?.state?.alldata?.length === Index ? (
                ""
              ) : (
                <button
                  disabled={nextLoading}
                  onClick={handleNext}
                  style={{
                    border: " solid 1px #F89700",
                    color: "#F89700",
                    backgroundColor: "white",
                    padding: "6px",
                    fontSize: "14px",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                >
                  {nextLoading ? <Loader /> : "Next Sentence"}
                </button>
              )}
              {/* )} */}
            </div>
            {!isAuthenticated && Index >= 19 && (
              <p
                style={{
                  marginTop: "20px",
                  marginLeft: "20%",
                  width: "60%",
                  color: "red",
                  textAlign: "center",
                }}
              >
                You need to be logged in to see the rest of the course
              </p>
            )}
          </div>
        </div>

        <div
          className="langword_bottom wd100"
          style={{ justifyContent: "center", }}
        >
          {LanguagesWords().map((v, i) => (
            <>
              <div key={"word_" + i} className={v.type}>
                <Link className={v.type} target="_blank" to={v.link}>
                  {v.title}
                </Link>
              </div>
            </>
          ))}
        </div>

        {countdown && (
          <div
            className="outer_wrapper"
            // style={{ border: "solid 1px red" }}
            onClick={(e) => {
              e.stopPropagation();
              setCountdown(false);
              // dispatch(IsPlay_Reducer(false))
              setCount(5);
              const endtime = Date.now();
              const tottaltime = endtime - starttime;
              setIsAllow(false);
              SendData(tottaltime, false);
              // setIndex((index)=> index+1);
            }}
          >
            <div className="inner_wrapper_bottom">
              <div className="white_wrapper wd100" ref={popupRef}>
                <div className="heading_languages">
                  <div className="">Next Sentence?</div>
                  <span
                    className="pointer"
                    onClick={() => {
                      setCountdown(false);
                      setCount(5);
                      // dispatch(IsPlay_Reducer(false))
                      const endtime = Date.now();
                      const tottaltime = endtime - starttime;
                      SendData(tottaltime, false);
                      // setIndex((index)=> index+1);
                    }}
                  >
                    <IoCloseCircleOutline />
                    {/* next s */}
                  </span>
                </div>
                <div className="count_downflex">
                  <>
                    <div className="">
                      You will be redirected to the next sentence in
                    </div>
                    <div
                      className="count_down"
                      style={{ textAlign: "center", fontSize: "70px" }}
                    >
                      {count}
                    </div>
                    <small>Seconds...</small>
                  </>
                </div>
                <div className="footer_languages">
                  <div className=""></div>
                  <span
                    onClick={() => {
                      setCountdown(false);
                      setCount(5);
                      // setIndex((index)=> index+1)
                    }}
                  >
                    <button className="button_custom_round primary">
                      Stop
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* {
      popup &&
      <div className="outer_wrapper">
        <div className="inner_wrapper">
          <div className="white_wrapper w40">
              <div className="heading_languages">
                <div className="">Disclaimer</div>
                <span className="pointer" onClick={()=> setPopup(false)}>
                  <IoCloseCircleOutline />
                </span>
              </div>
              <div className="">
                    <>
                      <div className="lang_my_selection relative pointer line_height_20" style={{textAlign:"center"}}>
                        Every new sentence will deduct 0,01€ from your balance. Repetitions are 
                        free of charge. <br />Currently your settings are set to "new and repetitions" and
                        "classic mode only".<br /> <br />You can change your settings by clicking on the
                        cogwheel at the top right.
                      </div>
                    </>
              </div>
              <div className="footer_languages">
                <div className=""></div>
                <span onClick={()=> setPopup(false)}>
                  <button className="button_custom_round primary">Continue</button>
                </span>
              </div>
          </div>
        </div>
      </div>
    } */}

        {flag && (
          <div className="outer_wrapper">
            <div className="inner_wrapper">
              <div className="white_wrapper w40">
                <div className="heading_languages">
                  <div className="">Report the sentence</div>
                  <span className="pointer" onClick={() => setFlag(false)}>
                    <IoCloseCircleOutline />
                  </span>
                </div>
                <div className="" style={{ padding: "0px 40px",}}>
                  {reasonsflag.map((v, i) => (
                    <>
                      <div className="flag_reasons">
                        <label>
                          <input
                            onChange={(e) => setRadioVal(v.title)}
                            type="radio"
                            val={v.title}
                            name="flag"
                          />
                          <span>{v.title}</span>
                        </label>
                      </div>
                    </>
                  ))}

                  <div className="flag_textarea">
                    <textarea
                      onChange={(e) => setInputVal(e.target.value)}
                      className="form-control"
                      placeholder="Please tell us more (optional)"
                    ></textarea>
                  </div>
                </div>
                <div className="footer_languages">
                  <div className=""></div>
                  <span onClick={() => setFlag(false)}>
                    <button
                      onClick={handleFlagReport}
                      className="button_custom_round primary"
                    >
                      Submit
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* ////////////// */}

      <ReactAudioPlayer
        ref={audioPlayerRefSource}
        src={location?.state?.alldata[Index]?.sourceAudio}
        style={{ display: "none" }}
        controls
        onEnded={handleAudioEndSource}
      />

      {/* //////////////// */}

      <ReactAudioPlayer
        ref={audioPlayerRefTarget}
        src={location?.state?.alldata[Index]?.targetAudio}
        style={{ display: "none" }}
        controls
        onEnded={handleAudioEndTarget}
      />

      {/* ////////////////// */}

      <ReactAudioPlayer
        ref={audioPlayerRefTarget2}
        src={location?.state?.alldata[Index]?.targetAudio}
        style={{ display: "none" }}
        controls
        onEnded={handleAudioEndTarget2}
      />
    </div>
  );
}

export default OnBoard;
