import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail, LanguagesWords } from "../../utils/functions";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Earn from "../../assets/images/earn-money.png";
import Learn from "../../assets/images/learn_img.png";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import { MdCancel } from "react-icons/md";
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillPlusCircle,
  AiFillQuestionCircle,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineAudio,
} from "react-icons/ai";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaHandPointRight, FaRegClock } from "react-icons/fa6";
import { LuClipboardList, LuRepeat } from "react-icons/lu";
import { HiOutlineSpeakerWave } from "react-icons/hi2";

import { Tooltip } from "react-tooltip";
import Flag1 from "../../assets/images/eng.png";
import Flag2 from "../../assets/images/russia.png";
import Source from "./../../assets/source.mp3";
import Loader from "../../base-components/Loader/Loader";
import { useSelector } from "react-redux";
import useToast from "../../hooks/useToast";

var SourceVal = JSON.parse(localStorage.getItem("source"));
var TargetVal = JSON.parse(localStorage.getItem("target"));

const source_text = [
  { title: "The", type: "" },
  { title: "weather", type: "" },
  { title: "is", type: "" },
  { title: "good", type: "" },
  { title: "today", type: "" },
];

const target_text = [
  { title: "Погода", type: "" },
  { title: "сегодня", type: "" },
  { title: "хорошая", type: "" },
];

const reasonsflag = [
  { title: "mistake in the source language audio", id: 1 },
  { title: "mistake in the target language audio", id: 2 },
  { title: "mistake in the source language", id: 3 },
  { title: "mistake in the target language", id: 4 },
  { title: "words  marked incorrectly", id: 5 },
  { title: "wrong tag (classic, slang, insult)", id: 6 },
  { title: "inappropriate sentence", id: 7 },
  { title: "other", id: 8 },
];
function OnBoard() {
  const location = useLocation();

  console.log(location.state, "dfdf location");

  const course = useSelector((state) => state.Course);
  const RightToLeft = useSelector((state) => state.RightToLeft.data);

  const SourceAndTargetAndSourceAudio = useSelector(
    (state) => state.SourceAndTargetAndSourceAudio
  );
  const user = useSelector((state) => state.user);
  const { NativeLanguage } = useSelector((state) => state);

  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [heading, setHeading] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);

  const [sourcetext, setSourceText] = useState(source_text);
  const [targettext, setTargetText] = useState(target_text);

  const [sourceselected, setSourceSelected] = useState("");
  const [targetselected, setTargetSelected] = useState("");

  const [selected, setSelected] = useState([]);
  const [audio, setAudio] = useState(null);

  const isDialect = useSelector((state) => state.IsDialect);

  useEffect(() => {
    SourceVal = JSON.parse(localStorage.getItem("source"));
    TargetVal = JSON.parse(localStorage.getItem("target"));
  }, [SourceVal?.code, TargetVal?.code]);

  // useEffect(()=>{
  //   if (!location?.state?.isTarget && !location?.state?.isSource && !location?.state?.isWrongTag){
  //     HandleNext();
  //   }
  // },[])

  const show_popup = async (val) => {
    setHeading(val);
    setPopup(true);
  };

  const do_next_page = async () => {
    navigate("/step/three");
  };

  const do_source_click = async (v) => {
    setTargetSelected("");
    if (sourceselected == v.title) {
      setSourceSelected("");
    } else {
      setSourceSelected(v.title);
    }
  };

  const do_target_click = async (v) => {
    setSourceSelected("");
    if (targetselected == v.title) {
      setTargetSelected("");
    } else {
      setTargetSelected(v.title);
    }
  };

  const handleLangwords = (type) => {
    if (sourceselected) {
      var selectedIndex = sourcetext.findIndex(
        (item) => item.title === sourceselected
      );
    } else {
      var selectedIndex = targettext.findIndex(
        (item) => item.title === targetselected
      );
    }

    if (selectedIndex !== -1) {
      if (sourceselected) {
        const updatedSourceText = [...sourcetext];
        updatedSourceText[selectedIndex].type = type;
        setSourceText(updatedSourceText);
      } else {
        const updatetargettext = [...targettext];
        updatetargettext[selectedIndex].type = type;
        setTargetText(updatetargettext);
      }
      setSourceSelected("");
      setTargetSelected("");
    }
  };

  const [inputDataSource, setInputDataSource] = useState(null);
  const [inputDataTarget, setInputDataTarget] = useState(null);
  const [Tag, setTag] = useState(null);

  const handleChange = (e, type) => {
    if (type === "source") {
      setInputDataSource(e.target.value);
    } else {
      setInputDataTarget(e.target.value);
    }
  };

  const handleRadio = (val) => {
    setTag(val);
  };

  const showToast = useToast();

  const InputContent = (
    <>
      {location?.state?.isSource && (
        <>
          <div className="add_sentence_source">
            <textarea
              onChange={(e) => handleChange(e, "source")}
              name="add_sentence"
              className="form-control-less"
              placeholder="Type here"
            ></textarea>
          </div>
        </>
      )}

      {location?.state?.isTarget && (
        <div className="add_sentence_source">
          <textarea
            onChange={(e) => handleChange(e, "target")}
            name="add_sentence"
            className="form-control-less"
            placeholder="Type here"
          ></textarea>
        </div>
      )}

      <div
        style={{
          border: `solid 1px ${
            location?.state?.isWrongTag ? "red" : "lightgray"
          }`,
          padding: "8px",
          marginTop: "10px",
          borderRadius: "5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        {location?.state?.taghere}
        {location?.state?.isWrongTag && (
          <span>
            <MdCancel color={"red"} size={24} />
          </span>
        )}
      </div>

      {location?.state?.isWrongTag && (
        <div
          style={{
            border: "solid 1px rgb(240,240,240)",
            padding: "12px",
            borderRadius: "7px",
          }}
        >
          <h3 style={{ marginBottom: "10px" }}>Chose Your Correct Tag</h3>

          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h5> {isDialect.type ? "Classic/Slang" : "Classic"} </h5>
              <input
                onChange={() => handleRadio("classic")}
                value="tag"
                name="tag"
                type="radio"
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "12px",
              }}
            >
              {!isDialect.type && (
                <>
                  <h5>Slang</h5>
                  <input
                    onChange={() => handleRadio("slang")}
                    value="tag"
                    name="tag"
                    type="radio"
                  />
                </>
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "12px",
              }}
            >
              <h5>Insult</h5>
              <input
                onChange={() => handleRadio("insult")}
                value="tag"
                name="tag"
                type="radio"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );

  const HandleNext = () => {
    navigate("/mistake/comment", {
      state: {
        sourceaudiohere: location?.state?.sourceaudiohere,
        sourcehere: location?.state?.sourcehere,
        targethere: location?.state?.targethere,
        taghere: location?.state?.taghere,
        inputDataSource: inputDataSource,
        inputDataTarget: inputDataTarget,
        checkedReasons: location?.state?.checkedReasons,
        tag: Tag,
        isSourceAudio: location?.state?.checkedReasons.some(
          (items) => items.name === "source audio mistake"
        )
          ? true
          : false,
        isSource: location?.state?.checkedReasons.some(
          (items) => items.name === "source language mistake"
        )
          ? true
          : false,
        isTarget: location?.state?.checkedReasons.some(
          (items) => items.name === "target language mistake"
        )
          ? true
          : false,
        isIncorrectly: location?.state?.checkedReasons.some(
          (items) => items.name === "marked incorrectly"
        )
          ? true
          : false,
        isWrongTag: location?.state?.checkedReasons.some(
          (items) => items.name === "wrong tag"
        )
          ? true
          : false,
        isInappropriate: location?.state?.checkedReasons.some(
          (items) => items.name === "inappropriate sentence"
        )
          ? true
          : false,
        other: location?.state?.checkedReasons.some(
          (items) => items.name === "other mistake"
        )
          ? true
          : false,
        Content: location?.state?.Content,
        count: location?.state?.count,
      },
    });
  };

  const do_play_audio = (t) => {
    // console.log(
    //   "play",
    //   SourceAndTargetAndSourceAudio?.data[
    //     location?.state && location?.state?.count
    //   ]?.sourceAudio
    // );
    if (t == "l") {
      const newAudio = new Audio(
        SourceAndTargetAndSourceAudio?.data[
          location?.state && location?.state?.count
        ]?.sourceAudio
      );
      newAudio.pause();
      newAudio.play();
      setAudio(true);
    } else {
      const newAudio = new Audio(
        SourceAndTargetAndSourceAudio?.data[
          location?.state && location?.state?.count
        ]?.sourceAudio
      );
      newAudio.pause();
      newAudio.play();
      setAudio(true);
    }
  };

  return (
    <div className="container">
      <HeaderLogin />
      <div className="wrapper">
        <div className="langugae_courses_heading">
          <span>
            <span>{isDialect.type ? "" : <img src={SourceVal?.flag} />}</span>{" "}
            {SourceVal?.code}
          </span>
          <AiOutlineArrowRight />{" "}
          <span>
            <span>{isDialect.type ? "" : <img src={TargetVal?.flag} />}</span>{" "}
            {isDialect.type
              ? course?.data[0]?.targetLanguage?.name
              : TargetVal?.code}
          </span>{" "}
        </div>
        <div className="my_languages_wrap">
          <div className="box_left_add_ card">
            <div className="heading_languages mb-0">
              <div className="">Previous Sentences</div>
            </div>
            <div className="p10 previous_sentences">
              {!SourceAndTargetAndSourceAudio?.loading ? (
                SourceAndTargetAndSourceAudio?.data?.map((v, i) => (
                  <>
                    <div className="senetence_">
                      <span>#{i + 1}</span>
                      <span  style={{display:'flex',flexWrap:'wrap'}} >
                        {course?.data[0]?.spaces
                          ? v?.sourceContentArray?.length >= 1 &&
                            v?.sourceContentArray?.map((items) => {
                              return (
                                <div
                                // className={items.type}
                                style={{ wordBreak: 'break-word' }}
                                
                                >
                                  {items.title}
                                </div>
                              );
                            })
                          : v?.sourceContentArray?.map((items) => {
                              return (
                                <div
                                  // className={items.type}
                                  style={{ marginLeft: "7px",wordBreak: 'break-word' }}
                                >
                                  {items.title}
                                </div>
                              );
                            })}
                      </span>
                    </div>
                  </>
                ))
              ) : (
                <Loader />
              )}
            </div>
          </div>

          <div className="box_left_add_">
            <div className="card p15 wd100 text-center">
              {location.state.isTarget === true &&
              location.state.isSource === false
                ? "Please correct the target sentence"
                : location.state.isSource === true &&
                  location.state.isTarget === false
                ? "Please correct the source sentence"
                : location.state.isSource === true &&
                  location.state.isTarget === true
                ? "Please correct the source and target sentence"
                : "Please correct the sentence"}
            </div>

            <div className="card p20 mt-10px">
              {InputContent}

              <div className="sentence_source mt-10px flex-startpointer text_user_show flex-space-between">
                <span>
                  {isDialect.type ? "" : <img src={SourceVal?.flag} />}
                </span>
                <span
                  style={{
                    display:'flex',flexWrap:'wrap',
                    height:'95%',
                    overflow:'auto',
                    borderBottom:
                      location.state.isSource === true ? `solid 1px black` : "",
                  }}
                >
                  {SourceAndTargetAndSourceAudio.loading ? (
                    <Loader />
                  ) : course?.data[0]?.spaces ? (
                    SourceAndTargetAndSourceAudio?.data[
                      location?.state && location?.state?.count
                    ]?.targetContentArray?.map((item) => {
                      return (
                        <>
                          <div
                            className={item?.type}
                            style={{ border: "solid 1px black" }}
                          >
                            {item?.title}
                          </div>
                        </>
                      );
                    })
                  ) : (
                    SourceAndTargetAndSourceAudio?.data[
                      location?.state && location?.state?.count
                    ]?.targetContentArray?.map((item) => {
                      return (
                        <>
                          <div
                            style={{ marginLeft: "7px" }}
                            className={item?.type}
                          >
                            {item?.title}
                          </div>
                        </>
                      );
                    })
                  )}
                </span>
                <span>
                  {isDialect.type ? "" : <img src={SourceVal?.flag} />}
                </span>

                {!isDialect?.type && (
                  <span className="pointer" onClick={() => do_play_audio("l")}>
                    <HiOutlineSpeakerWave />
                  </span>
                )}
              </div>

              <div className="sentence_source flex-start  mt-10px  pointer text_user_show flex-space-between">
                <span>
                  {isDialect?.type ? "" : <img src={TargetVal?.flag} />}
                </span>
                <span
                  style={{
                    display:'flex',flexWrap:'wrap',
                    height:'95%',
                    overflow:'auto',
                    borderBottom:
                      location.state.isTarget === true ? `solid 1px black` : "",
                  }}
                >
                  {SourceAndTargetAndSourceAudio.loading ? (
                    <Loader />
                  ) : course?.data[0]?.spaces ? (
                    SourceAndTargetAndSourceAudio?.data[
                      location?.state && location?.state?.count
                    ]?.sourceContentArray?.map((item) => {
                      return (
                        <>
                          <div className={item?.type}>{item?.title}</div>
                        </>
                      );
                    })
                  ) : (
                    SourceAndTargetAndSourceAudio?.data[
                      location?.state && location?.state?.count
                    ]?.sourceContentArray?.map((item) => {
                      return (
                        <>
                          <div
                            style={{ marginLeft: "7px" }}
                            className={item?.type}
                          >
                            {item?.title}
                          </div>
                        </>
                      );
                    })
                  )}
                </span>
                <span>
                  {isDialect.type ? "" : <img src={TargetVal?.flag} />}
                </span>

                {isDialect?.type && (
                  <span className="pointer" onClick={() => do_play_audio("d")}>
                    <HiOutlineSpeakerWave />
                  </span>
                )}
              </div>

              <div
                className="question_mark_"
                data-tooltip-id="question_new_sentence"
                data-tooltip-html="Please correct the mistake(s)."
              >
                <AiFillQuestionCircle />
              </div>
              <Tooltip
                id="question_new_sentence"
                style={{
                  textAlign: "center",
                  padding: "13px 10px",
                  fontSize: "13px",
                  zIndex: 9,
                  width: "350px",
                }}
              />
            </div>

            <div className="text-center wd100 mt-20px float-left green" style={{display:'flex',flexWrap:'wrap',justifyContent:'center'}} >
              {/* {location?.state?.checkedReasons[0]?.title} */}
              {location?.state?.checkedReasons.map((item) => (
                <div style={{marginTop:'8px',marginLeft:'8px'}} > {item?.name} </div>
              ))}
            </div>
          </div>

          <div
            className="box_left_add_ tags_button_next flex-space-between  p20"
            style={{ flexDirection: "column" }}
          >
            <div className="flex-space-between gap_20 custom_tags_button">
              {/* <div className="tags_left">
                <label>
                  <input type="checkbox" val={1} checked />
                  Classic
                </label>
              </div> */}

              <div className="button_tags">
                <button
                  className="button_custom_med secondary"
                  onClick={() => navigate(-1)}
                >
                  <AiOutlineArrowLeft /> Back{" "}
                </button>
                <button
                  className="button_custom_med primary mt-20px"
                  onClick={() => {
                    if (
                      location?.state?.isTarget &&
                      location?.state?.isSource &&
                      location?.state?.isWrongTag
                    ) {
                      if (inputDataSource?.length >= 1) {
                        if (inputDataTarget?.length >= 1) {
                          if (Tag != null && Tag?.length >= 1) {
                            HandleNext();
                          } else {
                            showToast("Please select Tag", "error");
                          }
                        } else {
                          showToast("empty field not allowed", "error");
                        }
                      } else {
                        showToast("empty field not allowed", "error");
                      }
                    } else if (
                      location?.state?.isTarget &&
                      location?.state?.isSource &&
                      !location?.state?.isWrongTag
                    ) {
                      if (inputDataSource?.length >= 1) {
                        if (inputDataTarget?.length >= 1) {
                          HandleNext();
                        } else {
                          showToast("empty field not allowed", "error");
                        }
                      } else {
                        showToast("empty field not allowed", "error");
                      }
                    } else if (
                      location?.state?.isTarget &&
                      !location?.state?.isSource &&
                      location?.state?.isWrongTag
                    ) {
                      if (inputDataTarget?.length >= 1) {
                        if (Tag != null && Tag?.length >= 1) {
                          HandleNext();
                        } else {
                          showToast("Please select Tag", "error");
                        }
                      } else {
                        showToast("empty field not allowed", "error");
                      }
                    } else if (
                      !location?.state?.isTarget &&
                      location?.state?.isSource &&
                      location?.state?.isWrongTag
                    ) {
                      if (inputDataSource?.length >= 1) {
                        if (Tag != null && Tag?.length >= 1) {
                          HandleNext();
                        } else {
                          showToast("Please select Tag", "error");
                        }
                      } else {
                        showToast("empty field not allowed", "error");
                      }
                    } else if (
                      location?.state?.isTarget &&
                      !location?.state?.isSource &&
                      !location?.state?.isWrongTag
                    ) {
                      if (inputDataTarget?.length >= 1) {
                        HandleNext();
                      } else {
                        showToast("empty field not allowed", "error");
                      }
                    } else if (
                      !location?.state?.isTarget &&
                      location?.state?.isSource &&
                      !location?.state?.isWrongTag
                    ) {
                      if (inputDataSource?.length >= 1) {
                        HandleNext();
                      } else {
                        showToast("empty field not allowed", "error");
                      }
                    } else if (
                      !location?.state?.isTarget &&
                      !location?.state?.isSource &&
                      location?.state?.isWrongTag
                    ) {
                      if (Tag != null && Tag?.length >= 1) {
                        HandleNext();
                      } else {
                        showToast("Please select Tag", "error");
                      }
                    }
                  }}
                >
                  Next <AiOutlineArrowRight />
                </button>
              </div>
            </div>
            <div className="langword_bottom custom_mar">
              {LanguagesWords().map((v, i) => (
                <>
                  <Link
                    to={v.link}
                    target="_blank"
                    onClick={() => handleLangwords(v.type)}
                    key={"word_" + i}
                    className={v.type}
                  >
                    {v.title}
                  </Link>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnBoard;
