// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success:false,
};

const SourceSlice = createSlice({
  name: 'SourceSlice',
  initialState,
  reducers: {
    Get_Sou_Request_Reducer: (state) => {
      state.loading = true;
      state.error = null;
    },
    Get_Sou_Success_Reducer: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
      state.success = true;
    },
    Get_Sou_Failer_Reducer: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { Get_Sou_Request_Reducer,  Get_Sou_Success_Reducer, Get_Sou_Failer_Reducer } = SourceSlice.actions;

export default SourceSlice.reducer;

