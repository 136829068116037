import { takeLatest, call, put } from "redux-saga/effects";
import {
  Get_WordArray_Request,
} from "../../Redux/actions";
import { FatchApi } from "../../../utils/FatchApi";
import { Get_WordArraySlice_Failer_Reducer, Get_WordArraySlice_Request_Reducer, Get_WordArraySlice_Success_Reducer } from "../../Redux/slices/WordsArray";

function* fetchDataSaga(action) {
  try {
    yield put(Get_WordArraySlice_Request_Reducer());
    const data = yield call(FatchApi,action.payload);
    if (data) {
      yield put(Get_WordArraySlice_Success_Reducer(data));
    }
  } catch (error) {
    yield put(Get_WordArraySlice_Failer_Reducer(error));
  }
}

export default function* GetWordArray() {
  yield takeLatest(Get_WordArray_Request().type, fetchDataSaga);
}
