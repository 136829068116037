import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LOGO from "./../../assets/images/logo.svg";
import {
  AiOutlineArrowLeft,
  AiOutlineBackward,
  AiOutlineLogout,
} from "react-icons/ai";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import BackArrow from "./../../assets/images/back.svg";
import { TbArrowBackUpDouble } from "react-icons/tb";
import { IoArrowBack } from "react-icons/io5";
import { useLocation } from "react-router-dom";

function Header({ onSearch }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [searchval, setSearchVal] = useState(null);

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  console.log(location?.pathname, "path name");

  return (
    <>
      {location.pathname === "/" ? (
        ""
      ) : (location?.pathname === location?.pathname) === "/view/sentence" ? (
        <div
          className="go_back_icon"
          onClick={() => navigate("/course/details")}
        >
          <IoArrowBack size={30} />
        </div>
      ) : location?.pathname === "/course/details" ? (
        <div className="go_back_icon" onClick={() => navigate("/learn")}>
          <IoArrowBack size={30} />
        </div>
      ) : (location?.pathname  === "/learn" )? (
        <div className="go_back_icon" onClick={() => navigate('/')}>
          <IoArrowBack size={30} />
        </div>
      ) : (
        <div className="go_back_icon" onClick={() => navigate(-1)}>
          <IoArrowBack size={30} />
        </div>
      )}
    </>
  );
}
export default Header;
