import React, { useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaCcPaypal, FaCreditCard } from "react-icons/fa6";

const DepositPopup = ({ setPopupDeposit = () => {} }) => {
  const [method, setMethod] = useState(1);
  const [recurring, setRecurrinh] = useState(1);
  return (
    <div className="outer_wrapper">
      <div className="inner_wrapper_bottom">
        <div className="white_wrapper wd100">
          <div className="heading_languages">
            <div className="">Donate</div>
            <span className="pointer" onClick={() => setPopupDeposit(false)}>
              <IoCloseCircleOutline />
            </span>
          </div>

          <div className="choose_lang_">
            <div className="flex-space-between-stretch wd100 deposit_withdrawal">
              <div className="left_deposit_ w60">
                <div className="">
                  How much money would you like to <br />
                  donate?
                </div>
                <div className="input_deposit relative">
                  <input type="number" className="deposit_input" />
                  <div className="abs_symbol">GBP</div>
                </div>

                <div className="method_wrap">
                  <h4>Donation method</h4>
                  <div className="methods_list">
                    <div
                      className={`method_box ${
                        method == 1 ? "method_box_hover" : ""
                      }`}
                      onClick={() => setMethod(1)}
                    >
                      <FaCcPaypal />
                      <span>Paypal</span>
                    </div>
                    <div
                      className={`method_box ${
                        method == 2 ? "method_box_hover" : ""
                      }`}
                      onClick={() => setMethod(2)}
                    >
                      <FaCreditCard />
                      <span>Debit/Credit card</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="left_deposit_ w40">
                <h4>Disclaimer</h4>
                <p className="light_text_restriction">
                  Your donation will be used to cover the running costs of the
                  website,
                  <br /> as well as paying the content contributors.
                </p>

                <div className="methods_list" style={{ flexDirection: "row" }}>
                  <div
                    className={`method_box ${
                      recurring == 1 ? "method_box_hover" : ""
                    }`}
                    onClick={() => setRecurrinh(1)}
                  >
                    <span>One Time</span>
                  </div>
                  <div
                    className={`method_box ${
                      recurring == 2 ? "method_box_hover" : ""
                    }`}
                    onClick={() => setRecurrinh(2)}
                  >
                    <span>monthly</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer_languages">
            <div className=""></div>
            <span onClick={() => setPopupDeposit(false)}>
              <button className="button_custom_large_rount primary flex-space-between gap_10">
                Next <AiOutlineArrowRight />
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositPopup;
