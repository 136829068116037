import { takeLatest, call, put } from 'redux-saga/effects';
import {CoursesSentenceRequest,CoursesSentenceFailure, Get_Sou_Request, Get_SouAndTar_Request} from '../../Redux/actions';
import { FatchApi } from '../../../utils/FatchApi';
import { Get_SouAndTar_Request_Reducer , Get_SouAndTar_Success_Reducer, Get_SouAndTar_Failer_Reducer } from '../../Redux/slices/Sou_Tar';

function* fetchDataSaga(action) {
  try {
    yield put(Get_SouAndTar_Request_Reducer())
    const data = yield  call(FatchApi,action.payload);
    if(data){
      yield put(Get_SouAndTar_Success_Reducer(data));
    }
  } catch (error) {
    yield put(Get_SouAndTar_Failer_Reducer (error));
  }
}

export default function* Get_SourceAndTarget() {
  yield takeLatest(Get_SouAndTar_Request().type, fetchDataSaga);
}