import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../base-components/Header";
import { useDispatch, useSelector } from "react-redux";
import { loginuser } from "../../constants/apiEndPoints/index.js";
import httpRequest from "../../axios/index.js";
import { toast } from "react-toastify";
import { setUser } from "../../lib/Redux/slices/userslice";
import { IsNext_Reducer } from "../../lib/Redux/slices/ExtraSlice/index.jsx";
import { IsDialect } from "../../lib/Redux/slices/IsDialectCondion/index.js";
import { SentanceFilter_Reducer } from "../../lib/Redux/slices/SentanceFilter/index.js";

function Login() {

  

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // const {isLearn} = useSelector((state)=> state.UserTypes)
  const isLearn = useSelector((state) => state.user.isLearn);
  const user = useSelector((state) => state.user);

  console.log(user, "user");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    isLearn: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [Loader, setLoader] = useState(false);

  // const handleLogin = async (e) => {
  //   setLoader(true);

  //   e.preventDefault();
  //   try {
  //     const Response = await httpRequest.post(loginuser, formData);

  //     // console.log(Response,'Response');

  //     if (Response.status === 200 || Response.status === 201) {
  //       // if (Response?.data?.message === "Please choose the correct type") {
  //       //   toast.info(Response?.data?.message);
  //       //   setTimeout(() => {
  //       //     navigate("/");
  //       //   }, 2000);
  //       //   setLoader(false);
  //       // } else {
  //       //   if (Response?.data?.data?.profileVerified) {
  //       //     toast.success(Response?.data?.message);
  //       //     dispatch(setUser(Response?.data?.data));
  //       //     localStorage.setItem("token", Response?.data?.data?.token);
  //       //     setLoader(false);
  //       //   } else {
  //       //     setLoader(false);
  //       //     toast.info("You need to verify your profile");
  //       //     setTimeout(() => {
  //       //       navigate("/verification", { state: { email: formData.email } });
  //       //       dispatch(setUser(Response?.data?.data));
  //       //       localStorage.setItem("token", Response?.data?.data?.token);
  //       //     }, 2000);
  //       //   }

  //         // if (
  //         //   Response?.data?.data?.role === true ||
  //         //   (Response?.data?.data?.profileVerified &&
  //         //     Response?.data?.data?.role === false)
  //         // ) {
  //         //   toast.success(Response?.data?.message);
  //         //   dispatch(setUser(Response?.data?.data));
  //         //   localStorage.setItem("token", Response?.data?.data?.token);
  //         //   setLoader(false)
  //         // } else {
  //         //   setLoader(false)
  //         //   toast.info("You need to verify your profile");
  //         //   setTimeout(() => {
  //         //     navigate("/verification", { state: { email: formData.email } });
  //         //     dispatch(setUser(Response?.data?.data));
  //         //     localStorage.setItem("token", Response?.data?.data?.token);
  //         //   }, 2000);
  //         // }
  //       }
  //     }
  //   } catch (err) {
  //     // if (err.response) {
  //     //   toast.error(err.response.data.message);
  //     //   setLoader(false);
  //     // } else {
  //     //   toast.error("Internal server error");
  //     //   setLoader(false);
  //     // }
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    console.log(user, "user in veri");
    if (user?.isEarner) {
      dispatch(IsDialect(false));
      dispatch(
        SentanceFilter_Reducer([
          {
            radio: "Only new and repetition",
            manageType: "Only new and repetition",
            tags: ["slang", "classic"],
          },
        ])
      );
      navigate("/earn");
      return;
    }
  }, []);

  useEffect(() => {
    if (user?.user?.token) {
      const Box = JSON.parse(localStorage.getItem("box"));
      if (Box && Box.box == "earn") {
        dispatch(IsDialect(false));
        dispatch(
          SentanceFilter_Reducer([
            {
              radio: "Only new and repetition",
              manageType: "Only new and repetition",
              tags: ["slang", "classic"],
            },
          ])
        );
        navigate("/earn");
      } else {
        dispatch(IsDialect(false));
        dispatch(
          SentanceFilter_Reducer([
            {
              radio: "Only new and repetition",
              manageType: "Only new and repetition",
              tags: ["slang", "classic"],
            },
          ])
        );
        navigate("/learn");
      }
    }
  }, [user?.user?.token]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const Response = await httpRequest.post(loginuser, formData);
      if (Response.status === 200 || Response.status === 201) {
        dispatch(setUser(Response?.data?.data));
        localStorage.removeItem("target");
        localStorage.removeItem("source");
        localStorage.setItem("token", Response?.data?.data?.token);
        toast.success(Response?.data?.message);
        dispatch(IsNext_Reducer(false));
        dispatch(IsDialect(false));
      }
    } catch (err) {
      // console.log(err, "login error");
      if (err.response) {
        toast.error(err.response.data.message);
        setLoader(false);
      } else {
        toast.error("Internal server error");
        setLoader(false);
      }
    }
  };

  return (
    <div className="container">
      <Header />
      <div className="wrapper_full">
        <div className="login-container">
          <div className="">
            <h1>Login</h1>
            <p>
              or <Link to="/signup">Sign up</Link>
            </p>
          </div>
          <div className="form_middle">
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Email Address"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  onChange={handleChange}
                  placeholder="Password"
                  required
                />
              </div>
              <div className="form-group ">
                <div className="center_button_custom_width">
                  <button type="submit" className="button_custom primary wd100">
                    {Loader ? "Loading ... " : "Login"}
                  </button>
                </div>
              </div>
            </form>

            <div className="text-center">
              <Link to="/forgot/password">Forgot your password?</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
