import React, { useRef } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PublicRoute } from "./routes/routes/PublicRoute";
import { PrivateRoute } from "./routes/routes/PrivateRoute";
import ScrollToTop from "./base-components/ScrollToTop";
import { ToastContainer } from "react-toastify";
import Goback from "./base-components/Header/GoBack";
import "./assets/css/app.css";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Forgot from "./pages/Forgot";
import OTP from "./pages/Forgot/Otp";
import TermsConditions from "./pages/Static/Terms";
// LEARN PAGE ROUTES
import Learn from "./pages/Learn/Main";
import CourseDetails from "./pages/Learn/CourseDetails";
import ViewSentence from "./pages/Learn/ViewSentence";
import CommentSentence from "./pages/Learn/CommentSentence";

// EARN PAGE ROUTES
import Earn from "./pages/Earn/Main";
import AddSentence from "./pages/Earn/AddSentence";
import AddSentencetwo from "./pages/Earn/AddSentenctwo";
import Transiliate from "./pages/Earn/Transiliate";
import AddSpace from "./pages/Earn/AddSpace";
import StepThree from "./pages/Earn/StepThree";
import SourceAudio from "./pages/Earn/SourceAudio";
import TargetCheck from "./pages/Earn/TargetCheck";
import TargetAudio from "./pages/Earn/TargetAudio";
import ReportMistake from "./pages/Earn/ReportMistake";
import MistakeResponse from "./pages/Earn/MistakeResponse";
import MistakeComment from "./pages/Earn/MistakeComment";
import ControlPage from "./pages/Earn/ControlPage";
import QuestionCheck from "./pages/Earn/QuestionCheck";
import Verification from "./pages/Verification";
import Settings from "./pages/Verification/Settings";
import Transactions from "./pages/Verification/Transactions";
import Support from "./pages/Verification/Support";
import OnBoard from "./pages/Login/OnBoard";
import "react-toastify/dist/ReactToastify.css";
import ResetPassword from "./pages/Forgot/reset";
import SentanceLibrary from './pages/SentanceLibrary';

import Faq from "./pages/Verification/Fag";
import CheckSentance from "./pages/Earn/CheckSentance";
import CorrectPage from "./pages/Earn/CorrectPage";
import First from "./pages/Earn/First";

const App = () => {

  // const withDrawRef = useRef();

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          className="toast_class"
        />
        <Routes>
          <Route path ='/create-sentance' element={<First/>}/>
          <Route path="/forgot/password" element={<Forgot />} />
          <Route path="/otp" element={<OTP />} />
          <Route path="/reset-success" element={<ResetPassword />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<TermsConditions />} />
          <Route path="/onboard" element={<OnBoard />} />
          <Route path="/course/details" element={<CourseDetails />} />
          <Route path="/view/sentence" element={<ViewSentence />} />
          <Route path="/comment/sentence" element={<CommentSentence />} />
          <Route path="/add-sentence" element={<AddSentence />} />
          <Route path="/step/three" element={<StepThree />} />
          <Route path="/step/two" element={<AddSentencetwo />} />
          <Route path="/transiliate" element={<Transiliate />} />
          <Route path="/add/space" element={<AddSpace />} />
          <Route path="/record/source/language" element={<SourceAudio />} />
          <Route path="/target/check" element={<TargetCheck />} />
          <Route path="/record/target/language" element={<TargetAudio />} />
          <Route path="/report/mistake" element={<ReportMistake />} />
          <Route path="/mistake/response" element={<MistakeResponse />} />
          <Route path="/mistake/comment" element={<MistakeComment />} />
          <Route path="/final/check" element={<ControlPage />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/transactions" element={<Transactions  />} />
          <Route path="/support" element={<Support />} />
          <Route path="/questions/check" element={<QuestionCheck />} />
          <Route path="/check-sentance" element={<CheckSentance />}></Route>
          <Route path="/correct-sentance" element={<CorrectPage />}></Route>
          <Route path="/library" element={<SentanceLibrary />}></Route>
          
          {/* <Route path="/register" element={<Register />} /> */}
          <Route
            path="/login"
            element={
              // <PublicRoute type="user">
                <Login />
              // </PublicRoute>
            }
          />
          <Route
            path="/signup"
            element={
              <PublicRoute type="user">
                <Signup />
              </PublicRoute>
            }
          />
          <Route
            path="/"
            element={
              // <PublicRoute path='/' type="user">
                <OnBoard />
              // </PublicRoute>
            }
          />
          <Route
            path="/learn"
            element={
              // <PrivateRoute type="user">
              <Learn />
            //  </PrivateRoute>
            }
          />
          <Route
            path="/earn"
            element={
              <PrivateRoute type='/user' >
                <Earn />
              </PrivateRoute>
            }
          />
          <Route
            path="/faq"
            element={
              <PrivateRoute type="user">
                <Faq />
              </PrivateRoute>
            }
          />
        </Routes>
        {/* if(location.path==='/course/details'){

        }
        else{
        <Goback />
        } */}

        <Goback />
      </BrowserRouter>
    </>
  );
};

export default App;
