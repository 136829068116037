// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [{
    // tags:["insult", "classic", "slang"],
    tags:['classic'],
    manageType:'Only new and repetition ',
    radio:'Only new and repetition',
  }],
};

const SentanceFilter = createSlice({
  name: 'SentanceFilter',
  initialState,
  reducers: {
    SentanceFilter_Reducer: (state,action) => {
      state.data = action.payload;
    },
  },
});

export const { SentanceFilter_Reducer } = SentanceFilter.actions;

export default SentanceFilter.reducer;

