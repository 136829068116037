// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

const profileVerified = createSlice({
  name: 'profileVerified',
  initialState,
  reducers: {
    profileVerified_Reducer: (state,action) => {
      state.data = action.payload;
    },
  },
});

export const { profileVerified_Reducer } = profileVerified.actions;

export default profileVerified.reducer;

