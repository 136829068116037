import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import {
  Link,
  useNavigate,
  useLocation,
  useParams,
  json,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Inactive from "../../assets/images/inactive_arrow.svg";
import Active from "../../assets/images/active_arrow.svg";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import { IoIosArrowDown } from "react-icons/io";
import {
  AiFillCheckCircle,
  AiFillPlusCircle,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { IoCloseCircleOutline } from "react-icons/io5";

import Flag1 from "../../assets/images/eng.png";
import Flag2 from "../../assets/images/russia.png";

import { Dropdown, Menu } from "antd";
import { API_BASE_URL, GET_ALL_LANGUAGES } from "../../constants/apiEndPoints";

import httpRequest from "../../axios";
import { useDispatch, useSelector } from "react-redux";
import {
  Get_SouAndTarAndSouAudio_Request,
  Get_SouAndTar_Request,
  Get_Sou_Request,
  GetlanguageRequest,
  Get_SouAndTarAndSouAudioAndTarAudio_Request,
  GetlanguageSuccess,
  Get_WordArray_Request,
  Get_C1_Request,
} from "../../lib/Redux/actions";
import Loader from "../../base-components/Loader/Loader";
import { FatchApi } from "../../utils/FatchApi";
import useToast from "../../hooks/useToast";
import { GetCourse } from "../../lib/Redux/slices/Course";
import useResetReducer from "../../utils/useResetReducer";
import IsDialectCondion, {
  IsDialect,
} from "../../lib/Redux/slices/IsDialectCondion";
import { GetLanguagesUpdateReducer } from "../../lib/Redux/slices/languagesSlices/languages";
import { NativeLanguage_Reducer } from "../../lib/Redux/slices/NativeLanguage";
import { clearUser } from "../../lib/Redux/slices/userslice";
import { RightToLeft } from "../../lib/Redux/slices";
import { RightToLeft_Reducer } from "../../lib/Redux/slices/RightToLeft";
// import useResetReducer from "../../utils/useResetReducer";

function OnBoard() {
  var SourceVal = JSON.parse(localStorage.getItem("source"));
  var TargetVal = JSON.parse(localStorage.getItem("target"));

  // console.log(SourceVal, TargetVal, "source and target");

  useEffect(() => {
    SourceVal = JSON.parse(localStorage.getItem("source"));
    TargetVal = JSON.parse(localStorage.getItem("target"));
  }, [SourceVal?.code, TargetVal?.code]);

  useEffect(() => {}, [SourceVal, TargetVal]);

  const resetAllReducers = useResetReducer();

  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [heading, setHeading] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);
  const [default1, setDefault] = useState(false);
  const [LData, setLData] = useState();
  const [selected, setSelected] = useState(null);
  const [selected2, setSelected2] = useState();
  const [SourceItem, setSourceItem] = useState();
  const [TargetItem, setTargetItem] = useState();
  const [languages, setLanguages] = useState([]);
  const [C1arry, setC1arry] = useState([]);
  const [DiaNative, setDiaNative] = useState();
  const [earnerVari, setEarnerVari] = useState();
  const [c2Lang, setC2Lang] = useState();

  const [showdialetic, setShowDialetic] = useState(false);

  const IsDialectCondition = useSelector((state) => state.IsDialect);
  const profileVerified = useSelector((state) => state.profileVerified);
  const Source = useSelector((state) => state.Source);
  const NativeLanguage = useSelector((state) => state.NativeLanguage.data);

  // console.log(NativeLanguage,'native language..');

  const { user } = useSelector((state) => state.user);
  const C1 = useSelector((state) => state.C1);

  const handleIsDialectsTrue = () => {
    if (earnerVari?.teachDialect === null) {
      showToast("You haven’t added a dialect yet", "error");
      return;
    }

    // if (user?.nativeDialect === null || user?.nativeDialect == "") {
    //   showToast("Dialect not Found", "error");
    //   return;
    // }

    if (IsDialectCondition.type) {
      localStorage.removeItem("source");
      localStorage.removeItem("target");

      setSelected(null);
      resetAllReducers();
      dispatch(IsDialect(false));
      setShowDialetic(!IsDialectCondition.type);
    } else {
      // localStorage.removeItem('source')
      // localStorage.removeItem('target')

      setSelected(null);
      resetAllReducers();
      dispatch(IsDialect(true));
      setShowDialetic(!IsDialectCondition.type);
    }
  };

  const data = useSelector((state) => state);
  const CoursesSentence = useSelector((state) => state);

  // console.log(,'dd ll dd');

  const dispatch = useDispatch();

  // useEffect(()=>{
  //   if(!user?.profileVerified){
  //     navigate("/verification", { state: { email: user.userEmail } });
  //   }
  // },[])

  const [pointsData, setPointsData] = useState(null);
  const [nativeDialects, setNativeDialects] = useState();
  const [nativeLan, setNativeLan] = useState(null);

  useEffect(() => {
    const fetchPointsData = async () => {
      try {
        const data = await FatchApi({
          request: "get",
          Api: "/users/get-user-points",
        });

        if (data?.isAllowed === false) {
          dispatch(clearUser());
          localStorage.clear();
          showToast("Congratulations! Your profile has been verified!", "info");
          navigate("/login");
          return;
        }

        setPointsData(data?.nativeLanguage);
        setNativeDialects(data?.nativeDialect[0]);
      } catch (error) {
        console.error("Error fetching points data:", error);
      }
    };

    fetchPointsData();
  }, []);

  useEffect(() => {
    if (user?.isLearn) {
      console.log("play useEffect... if ", user);
      navigate("/learn");
      return;
    } else {
      // console.log('play useEffect... ealse ',profileVerified?.data?.profileVerified);
      if (profileVerified?.data?.profileVerified === false) {
        showToast("You need to verifiy your account", "info");
        navigate("/verification", { state: { email: user.userEmail } });
        return;
      }
    }
  }, [
    profileVerified?.data?.profileVerified,
    profileVerified?.data?.isAllowed,
  ]);

  useEffect(() => {
    if (IsDialectCondition != null) {
      setShowDialetic(IsDialectCondition.type);
    }
  }, [IsDialectCondion.type]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        var Response = await httpRequest.get(
          `/api/v1/admins/get-earner-verification/${user.userEmail}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (Response) {
          setC2Lang(Response?.data?.c2native);
          setEarnerVari(Response?.data?.earnerVerification);
          setDiaNative(Response?.data?.earnerVerification?.nativeLanguage);

          // console.log(Response?.data?.earnerVerification?.teachDialect,'earnervari...');

          var dia = JSON.stringify(
            Response?.data?.earnerVerification?.teachDialect
          );

          var data = JSON.stringify(Response?.data?.c2native);

          var data2 = JSON.stringify(
            Response?.data?.earnerVerification?.teachLanguageC1
          );

          const fetchData = async () => {
            try {
              if (data) {
                console.log(data, "data of the");
                dispatch(
                  GetlanguageRequest(
                    {
                    user: user,
                    request: "get",
                    data: "",
                    Api: IsDialectCondition.type
                      ? `/api/v1/dialectics?dialects=${dia}`
                      : `/api/admin/filterd-languages?languages=${data}`,
                  }
                )
                );
              }

              if (data2) {
                dispatch(
                  Get_C1_Request({
                    user: user,
                    request: "get",
                    data: "",
                    Api: IsDialectCondition.type
                      ? `/api/v1/dialectics?dialects=${data2}`
                      : `/api/admin/source-filterd-languages?languages=${data2}`,
                  })
                );
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          };

          fetchData();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      ////
    };
    fetchData();
  }, [IsDialectCondition.type]);

  /////

  ////

  useEffect(() => {
    let item;

    const SourceVal = JSON.parse(localStorage.getItem("source"));
    const TargetVal = JSON.parse(localStorage.getItem("target"));

    // console.log(SourceVal,C1?.data?.languages,'in st datad');

    setTargetItem(
      !IsDialectCondition.type
        ? TargetVal
          ? TargetVal
          : c2Lang && c2Lang?.length >= 1 && c2Lang[0]
        : data.languages?.data?.languages &&
            data.languages?.data?.languages?.length >= 1 &&
            data.languages?.data?.languages[0]
    );

    // console.log(SourceVal?._id?.length,'sourceVal..');

    setSourceItem(
      SourceVal && SourceVal?._id && SourceVal?._id?.length > 1
        ? SourceVal
        : C1?.data?.languages && C1?.data?.languages[0]
    );

    // console.log(C1?.data?.languages[0],'dfdf');

    if (!IsDialectCondition.type) {
      if (
        data?.languages?.data?.languages &&
        data?.languages?.data?.languages?.length >= 1
      ) {
        item =
          data &&
          data?.languages?.data?.languages &&
          data?.languages?.data?.languages?.length >= 2
            ? data?.languages?.data?.languages[0]
            : data?.languages?.data?.languages[0];

        // console.log(item, "itmmm");

        dispatch(NativeLanguage_Reducer(item));
        GetingScreenContent(item);
      } else {
        // console.log('useEffect play when language success else ');
        item =
          data &&
          data?.languages?.data?.languages &&
          data?.languages?.data?.languages?.length >= 1 &&
          data &&
          data?.languages?.data?.languages[0];
        // console.log("play useeffect into elese", item);
        GetingScreenContent(item);
      }
      dispatch(GetLanguagesUpdateReducer());
    } else {
      if (data?.nativeDialect) {
        item = nativeDialects && nativeDialects;

        // console.log(item, "iii");

        dispatch(NativeLanguage_Reducer(item));
        GetingScreenContent(item);
      } else {
        item = nativeDialects && nativeDialects;

        // console.log(item, "iii");

        dispatch(NativeLanguage_Reducer(item));
        GetingScreenContent(item);
      }
      dispatch(GetLanguagesUpdateReducer());
    }

    // if(data?.languages?.data?.success){
    //   setDefault(()=> !default1)
    // }
  }, [data?.languages?.success, c2Lang]);

  const GetingScreenContent = async (target, source) => {
    // console.log(SourceItem, TargetItem, "when...");

    if (
      IsDialectCondition.type ? DiaNative : target !== null && source !== null
    ) {
      setSelected(target);
      const payload = { user: user, request: "get", data: "" };
      // console.log(selected, "selected value");

      try {
        //////////////////////////////

        // console.log(TargetItem._id, "coma");

        // get the course
          const data = await FatchApi({
            user: user,
            request: "get",
            data: "",
            Api: IsDialectCondition.type
              ? `/api/v1/get-dialects-course?sourceId=${DiaNative._id}&targetId=${TargetItem._id}`
              : `/api/v1/get-course?sourceId=${SourceItem._id}&targetId=${TargetItem._id}`,
          });
        setLData(data);
        dispatch(GetCourse(data));
        const courseId = data[0]?._id;

        // get source content and target content for position 3
        if (courseId) {
          dispatch(
            RightToLeft_Reducer({
              Source: SourceItem?.rtl || false,
              Target: TargetItem?.rtl || false,
            })
          );

          if (IsDialectCondition?.type) {
            localStorage.setItem("source", JSON.stringify(DiaNative));
          }

          // console.log('dainative...');

          if (
            localStorage.getItem("source") &&
            localStorage.getItem("target")
          ) {
            console.log("ifiiiiiiiiiiiiii ");
          } else {
            dispatch(IsDialect(false));

            // console.log('eleeeeeeeeeeeeeeee',localStorage.getItem("source"),localStorage.getItem("target"));

            if (localStorage.getItem("source") === null  ) {
              C1?.data?.languages &&
                C1?.data?.languages.length >= 1 &&
                localStorage.setItem(
                  "source",
                  JSON.stringify(C1?.data?.languages[0])
                );
            }

            if (localStorage.getItem("target") === null ) {
              c2Lang &&
                c2Lang?.length >= 1 &&
                localStorage.setItem("target", JSON.stringify(c2Lang[0]));
              setNativeLan(c2Lang[0]);
            }
          }

          try {
            dispatch(
              Get_SouAndTar_Request({
                user: user,
                request: "get",
                data: "source and target",
                Api: IsDialectCondition.type
                  ? `/api/v1/get-source-audios-dia?courseId=${courseId}&nativeDialects=${
                      profileVerified &&
                      profileVerified?.data &&
                      profileVerified?.data?.nativeDialect?.label
                    }`
                  : `/api/v1/get-source-audios?courseId=${courseId}&nativeLanguage=${
                      (TargetVal && TargetVal?.name) || c2Lang[0]?.name
                    }`,
              })
            );
          } catch (error) {
            console.error(
              "Error fetching source and target content for position 3:",
              error
            );
          }

          // console.log("for position 3");

          // get source and target content and sourceAudio for position 4

          try {
            dispatch(
              Get_SouAndTarAndSouAudio_Request({
                user: user,
                request: "get",
                data: "",
                Api: IsDialectCondition.type
                  ? `/api/v1/get-target-audios-dia?courseId=${courseId}&nativeDialects=${
                      profileVerified &&
                      profileVerified?.data &&
                      profileVerified?.data?.nativeDialect?.label
                    }`
                  : `/api/v1/get-target-audios?courseId=${courseId}&nativeLanguage=${
                      (TargetVal && TargetVal?.name) || c2Lang[0]?.name
                    }`,
              })
            );
          } catch (error) {
            console.error(
              "Error fetching source and target content and source audio for position 4:",
              error
            );
          }

          //     // get all for position 5
          try {
            dispatch(
              Get_SouAndTarAndSouAudioAndTarAudio_Request({
                user: user,
                request: "get",
                data: "",
                Api: IsDialectCondition.type
                  ? `/api/v1/get-final-sentences-dia?courseId=${courseId}&nativeDialects=${
                      profileVerified &&
                      profileVerified?.data &&
                      profileVerified?.data?.nativeDialect?.label
                    }`
                  : `/api/v1/get-final-sentences?courseId=${courseId}&nativeLanguage=${
                      (TargetVal && TargetVal?.name) || c2Lang[0]?.name
                    }`,
              })
            );
          } catch (error) {
            console.error("Error fetching all content for position 5:", error);
          }
        } else {
          if (user.profileVerified) {
            // showToast("No Data found from this course", "warning");
          } else {
          }
          resetAllReducers({ nolanguages: true });
        }

        /////////////////////////////
      } catch (error) {
        console.error("Error fetching the course:", error);
      }
    }
  };

  const handleClick = async (items) => {
    console.log(items, "items....items");
    localStorage.setItem("target", JSON.stringify(items));
    const SourceVal = JSON.parse(localStorage.getItem("target"));
    setTargetItem(SourceVal);
    dispatch(NativeLanguage_Reducer(SourceVal));
  };

  const items = !IsDialectCondition?.type
    ? c2Lang &&
      c2Lang?.length >= 1 &&
      c2Lang?.map((item) => ({
        key: item?._id,
        label: (
          <div
            onClick={() => handleClick(item)}
            key={item?._id}
            className="left_lang_section"
            style={{ cursor: "pointer" }}
          >
            <span>
              <img src={item?.flag} alt={`${item?.code} flag`} />
            </span>
            <span>{item?.code}</span>
          </div>
        ),
      }))
    : data?.languages?.data?.languages &&
      data?.languages?.data?.languages
        ?.filter((item) => item.name === user.nativeDialect)
        .map((item) => ({
          key: item?._id,
          label: (
            <div
              onClick={() => handleClick(item)}
              key={item?._id}
              className="left_lang_section"
              style={{ cursor: "pointer" }}
            >
              <span>
                <img src={item?.code?.flag} alt={`${item?.code?.code} flag`} />
              </span>
              <span>{item?.name}</span>
            </div>
          ),
        }));

  // console.log(items, "c2Lang...");

  ////////////

  useEffect(() => {
    GetingScreenContent(TargetItem, SourceItem);
  }, [TargetItem, SourceItem, data?.languages]);

  const handleClickC1 = (items) => {
    localStorage.setItem("source", JSON.stringify(items));
    const SourceVal = JSON.parse(localStorage.getItem("source"));

    setSourceItem(SourceVal);
    setSelected2(SourceVal);
  };

  useEffect(() => {
    // console.log(C1?.data?.languages[0],'c1kkkk');
    if (C1?.data?.languages) {
      setSourceItem(C1?.data?.languages[0]);
    }
  }, [C1?.data?.languages]);

  const C1items =
    C1?.data &&
    C1?.data?.languages?.length >= 1 &&
    C1?.data?.languages.map((item) => ({
      key: item?._id,
      label: (
        <div
          onClick={() => handleClickC1(item)}
          key={item?._id}
          className="left_lang_section"
          style={{ cursor: "pointer" }}
        >
          <span>
            <img src={item?.flag} alt={`${item?.code} flag`} />
          </span>
          <span>{item?.code}</span>
        </div>
      ),
    }));

  const menu = <Menu items={C1items} />;

  // console.log(items, "of the array");

  const showToast = useToast();

  const handleAddSentence = () => {
    console.log(user?.nativeLanguage?.name, "ldata");
    if (LData) {
      if (LData[0]?._id) {
        if (selected) {
          const Nat = JSON.parse(localStorage.getItem("target"));
          const courseId = LData[0]?._id;
          console.log(Nat, "courseId,....abc");
          dispatch(
            Get_Sou_Request({
              user: user,
              request: "get",
              data: "",
              Api: IsDialectCondition.type
                ? `/api/v1/get-content-sentences-dia?courseId=${courseId}&nativeDialects=${
                    data?.languages?.data &&
                    data?.languages?.data?.languages[0]?.name
                  }`
                : `/api/v1/get-content-sentences?courseId=${courseId}&nativeLanguage=${
                    Nat?.name || nativeLan
                  }`,
            })
          );
          if (courseId) {
            navigate("/add-sentence", {
              state: {
                TargetSentance: false,
                courseId: courseId,
                Latin: LData[0]?.alphabets,
              },
            });
          } else {
            showToast("Course not available", "warning");
          }
        } else {
          showToast("Please select your target language first", "warning");
        }
      } else {
        showToast("Course not available", "warning");
      }
    }
  };

  const handleAddTargetSentence = () => {
    if (LData) {
      if (LData[0]?._id) {
        if (selected) {
          if (LData && LData?.length > 0) {
            const courseId = LData[0]?._id;
            dispatch(
              Get_Sou_Request({
                user: user,
                request: "get",
                data: "",
                Api: IsDialectCondition.type
                  ? `/api/v1/get-content-sentences-dia?courseId=${courseId}&nativeDialects=${pointsData?.name}`
                  : `/api/v1/get-content-sentences?courseId=${courseId}&nativeLanguage=${
                      JSON.parse(localStorage.getItem("target")).name
                    }`,
              })
            );
            navigate("/add-sentence", { state: { TargetSentance: true } });
          } else {
            showToast("Course not Avilable", "warning");
          }
          ////
        }
      } else {
        showToast("Course Not Avilable", "warning");
      }
    }
  };

  const handleSourceRecordAudio = () => {
    if (
      data.SourceAndTarget?.data &&
      data.SourceAndTarget?.data?.length === 0
    ) {
      // showToast("Dont allow", "warning");
      // return;
    } else {
      navigate("/record/source/language");
    }

    // navigate("/record/source/language");
  };

  // console.log(data?.languages?.data?.languages[0]?.flag,'flag');

  const defaultFlagSrc = SourceVal
    ? SourceVal?.flag
    : C1?.data?.languages?.length > 0
    ? C1.data.languages[0].flag
    : null;
  const flagSrc = selected2?.flag || defaultFlagSrc;

  const defaultCode = SourceVal
    ? SourceVal?.code
    : C1?.data?.languages?.length > 0
    ? C1.data.languages[0].code
    : null;
  const code = selected2?.code || defaultCode;

  useEffect(() => {
    FlagDrop = c2Lang && c2Lang.length >= 1 && c2Lang[0]?.flag;
    CodeDrop = c2Lang && c2Lang.length >= 1 && c2Lang[0]?.code;
  }, [code, flagSrc]);

  let FlagDrop, CodeDrop;

  FlagDrop = c2Lang && c2Lang.length >= 1 && c2Lang[0]?.flag;
  CodeDrop = c2Lang && c2Lang.length >= 1 && c2Lang[0]?.code;

  // console.log(FlagDrop,'flegDrop...');

  useEffect(() => {
    FlagDrop = c2Lang && c2Lang.length >= 1 && c2Lang[0]?.flag;
    CodeDrop = c2Lang && c2Lang.length >= 1 && c2Lang[0]?.code;
  }, [FlagDrop, CodeDrop]);

  // console.log(c2Lang && c2Lang.length >= 1 && c2Lang, "data lang..");

  return (
    <div className="container">
      <HeaderLogin />
      <div className="wrapper">
        <div className="">
          <div className="languages_header_bar flex-space-between">
            {/* ////// */}
            {/* 
            <div
              className="left_lang_section"
              style={{ cursor: "pointer", width: "33%" }}
            >
              <span>
                <img src={pointsData?.flag} />
              </span>
              <span>{pointsData?.code}</span>
            </div> */}

            {/* ////Source//// */}

            <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',fontWeight:'500'}} >
             <p>Source language</p>
            {IsDialectCondition.type ? (
              <div style={{ display: "flex", alignItems: "center" }}>
               {/* <p>Source</p> */}
                <span>
                  <img
                    style={{ height: "22px", width: "22px" }}
                    src={DiaNative?.flag}
                    alt="Flag"
                  />
                </span>

                <span style={{ marginLeft: "10px" }}>{DiaNative?.code}</span>
              </div>
            ) : C1?.loading ? (
              <Loader />
            ) : (

              <Dropdown
                overlay={menu}
                placement="bottomLeft"
                arrow
                trigger={["click"]}
              >
                <div
                  className="left_lang_section"
                  style={{ cursor: "pointer" }}
                >
                  <span>
                    <img src={flagSrc} alt="Flag" />
                  </span>

                  <span>{defaultCode}</span>

                  <span>
                    <IoIosArrowDown />
                  </span>
                </div>
              </Dropdown>
            )}
            </div>

            {/* ///// */}
            <div className="center_lang_section" style={{ width: "33%" }}>
              <span className={`${!IsDialectCondition?.type  ? "boldtext" : ""}`}>
                Languages
              </span>
              <span>
                <img
                  src={IsDialectCondition?.type ? Active : Inactive}
                  // onClick={() => setShowDialetic(!showdialetic)}
                  onClick={handleIsDialectsTrue}
                />
              </span>
              <span className={`${IsDialectCondition?.type ? "boldtext" : ""}`}>
                Dialects
              </span>
            </div>

            <div style={{ border: "solid 1px transparent" }}>
              
              <div style={{flexDirection:'column',justifyContent:'center',alignItems:'center',display:'flex',fontWeight:'500'}} >
                <p>Target language</p>
              {!IsDialectCondition.type && c2Lang && c2Lang?.length ? (
                <Dropdown
                  menu={{ items }}
                  placement="bottomLeft"
                  arrow
                  trigger={["click"]}
                >
                  <div
                    className="left_lang_section"
                    style={{ cursor: "pointer" }}
                  >
                    <span>
                      <img
                        src={TargetVal ? TargetVal?.flag : FlagDrop}
                        alt="Flag"
                      />
                    </span>
                    <span>{TargetVal ? TargetVal?.code : CodeDrop}</span>
                    <span>
                      <IoIosArrowDown />
                    </span>
                  </div>
                </Dropdown>
              ) : (
                <>
                  {data && data?.languages && data?.languages?.loading ? (
                    <Loader />
                  ) : (
                    data?.languages?.data?.languages &&
                    data?.languages?.data?.languages?.length >= 1 && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {data?.languages?.data?.languages && (
                          <>
                            <span>
                              <img
                                style={{ height: "22px", width: "22px" }}
                                src={
                                  data.languages.data.languages[0]?.code?.flag
                                }
                                alt="Flag"
                              />
                            </span>

                            <span style={{ marginLeft: "10px" }}>
                              {data.languages.data.languages[0]?.name}
                            </span>
                          </>
                        )}
                      </div>
                    )
                  )}
                </>
              )}
              </div>


            </div>

            {/* /////////////////////////////////////////////////////////// */}
          </div>
        </div>

        <div className="buttons_earn">
          <button
            className="button_custom_round dark_white"
            onClick={handleAddSentence}
          >
            Add Sentence
          </button>

          {IsDialectCondition?.type ? (
            ""
          ) : (
            <button
              style={{
                backgroundColor:
                  data?.SourceAndTarget.data?.length === 0 ? "#ADADAD" : "",
                cursor:
                  data?.SourceAndTarget?.data?.length === 0
                    ? "default"
                    : "pointer",
                borderRadius:
                  data?.SourceAndTarget?.data?.length === 0 ? "20px" : "",
                border: data?.SourceAndTarget?.data?.length === 0 ? "none" : "",
              }}
              className={`${
                data?.SourceAndTarget?.data &&
                data.SourceAndTarget.data.length === 0
                  ? ""
                  : "button_custom_round dark_white"
              }`}
              onClick={handleSourceRecordAudio}
            >
              Record source language audio (
              {data?.SourceAndTarget && data?.SourceAndTarget?.loading ? (
                <Loader />
              ) : (
                data?.SourceAndTarget && data?.SourceAndTarget?.data?.length
              )}
              )
            </button>
          )}

          <button
            style={{
              backgroundColor:
                data?.SourceAndTargetAndSourceAudio?.data?.length === 0
                  ? "#ADADAD"
                  : "",
              cursor:
                data?.SourceAndTargetAndSourceAudio?.data?.length === 0
                  ? "default"
                  : "pointer",
              borderRadius:
                data?.SourceAndTargetAndSourceAudio?.data?.length === 0
                  ? "20px"
                  : "",
              border:
                data?.SourceAndTargetAndSourceAudio?.data?.length === 0
                  ? "none"
                  : "",
            }}
            className={`${
              data && data?.SourceAndTargetAndSourceAudio?.data?.length === 0
                ? ""
                : "button_custom_round dark_white"
            }`}
            onClick={() => {
              if (data && data?.SourceAndTargetAndSourceAudio?.data?.length !== 0) {
                navigate("/target/check");
              }
            }}
          >
            {IsDialectCondition && IsDialectCondition?.type ? "Record Source" : "Record target"}{" "}
            language audio + checks (
            { data && data?.SourceAndTargetAndSourceAudio?.loading ? (
              <Loader />
            ) : (
              data && data?.SourceAndTargetAndSourceAudio?.data?.length
            )}
            )
          </button>

          <button
            style={{
              backgroundColor:
                data?.AllAvilable?.data && data?.AllAvilable?.data?.length === 0
                  ? "#ADADAD"
                  : "",
              cursor:
                data?.AllAvilable?.data && data?.AllAvilable?.data?.length === 0
                  ? "default"
                  : "pointer",
              borderRadius:
                data?.AllAvilable?.data && data?.AllAvilable?.data?.length === 0
                  ? "20px"
                  : "",
              border:
                data?.AllAvilable?.data && data?.AllAvilable?.data?.length === 0
                  ? "none"
                  : "",
            }}
            className={`${
              data?.AllAvilable?.data && data.AllAvilable.data.length === 0
                ? ""
                : "button_custom_round dark_white"
            }`}
            onClick={() =>
              data?.AllAvilable?.data && data.AllAvilable?.data?.length === 0
                ? ""
                : //  showToast("Dont allow ", "warning")
                  navigate("/final/check")
            }
          >
            Final checks (
            {data?.AllAvilable?.loading ? (
              <Loader />
            ) : (
              data?.AllAvilable?.data && data?.AllAvilable?.data?.length
            )}
            )
          </button>
        </div>
      </div>
    </div>
  );
}

export default OnBoard;
