import { takeLatest, call, put } from 'redux-saga/effects';
import {CoursesSentenceRequest,CoursesSentenceFailure, Get_Sou_Request} from '../../Redux/actions';
import { FatchApi } from '../../../utils/FatchApi';
import { Get_Sou_Request_Reducer,Get_Sou_Success_Reducer,Get_Sou_Failer_Reducer } from '../../Redux/slices/Sou';

function* fetchDataSaga(action) {
  try {
    yield put(Get_Sou_Request_Reducer())
    const data = yield  call(FatchApi,action.payload);
    if(data){
      yield put(Get_Sou_Success_Reducer(data));
    }
  } catch (error) {
    yield put(Get_Sou_Failer_Reducer(error));
  }
}

export default function* GetLanguages() {
  yield takeLatest(Get_Sou_Request().type, fetchDataSaga);
}