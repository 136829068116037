// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success:false,
};

const SourceAndTargetSlice = createSlice({
  name: 'SourceAndTargetSlice',
  initialState,
  reducers: {
    Get_SouAndTar_Request_Reducer: (state) => {
      state.loading = true;
      state.error = null;
    },
    Get_SouAndTar_Success_Reducer: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
      state.success = true;
    },
    Get_SouAndTar_Failer_Reducer: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { Get_SouAndTar_Request_Reducer,  Get_SouAndTar_Success_Reducer, Get_SouAndTar_Failer_Reducer } = SourceAndTargetSlice.actions;

export default SourceAndTargetSlice.reducer;