// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success:false,
};

const UserOwnCourse = createSlice({
  name: 'UserOwnCourse',
  initialState,
  reducers: {
    UserOwnCourse_Reducer: (state,action) => {
     state.data = action.payload;
    },
  },
});

export const { UserOwnCourse_Reducer,  } = UserOwnCourse.actions;

export default UserOwnCourse.reducer;