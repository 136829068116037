import { takeLatest, call, put } from 'redux-saga/effects';
import {CoursesSentenceRequest,CoursesSentenceFailure, Get_Sou_Request, LastUpdate_Request} from '../../Redux/actions';
import { FatchApi } from '../../../utils/FatchApi';
import { LastUpdate_Request_Reducer, LastUpdate_Success_Reducer, LastUpdate_Failer_Reducer  } from '../../Redux/slices/LastUpdate';
import { LAST_UPDATE_REQUEST } from '../../../constants/actionstype';

function* fetchDataSaga(action) {
  try {
    yield put(LastUpdate_Request_Reducer());
    const data = yield call(FatchApi, action.payload);
    
    if (data?.status >= 400) { 
      yield put(LastUpdate_Failer_Reducer(data || 'error'));
    }

    yield put(LastUpdate_Success_Reducer(data));
  } catch (error) {
    // console.log(error,'error into catch');
    yield put(LastUpdate_Failer_Reducer(error.message || error));
  }
}

export default function* LastUpdate() {
  yield takeLatest(LastUpdate_Request().type, fetchDataSaga);
}