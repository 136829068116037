import { API_BASE_URL } from "../constants/apiEndPoints";
import httpRequest from "../axios";
import axios from "axios";

export const  UploadAssests = async (selectedFile) => {
  console.log("🚀 ~ UploadAssests ~ selectedFile:", selectedFile);

  try {
    const response = await httpRequest.get(
      `${API_BASE_URL}/api/generate-presigned-url`,
      {
        params: {
          fileName: selectedFile.name,
          fileType: selectedFile.type,
        },
      }
    );

    const { url, key } = response.data;

    console.log(url,key ,'rul and key');

    const uploadResponse = await axios.put(url, selectedFile, {
      headers: {
        "Content-Type": selectedFile.type,
        Authorization: response.data.Authorization,
      },
    });

    if (uploadResponse.status === 200) {
      return `https://langbookbucket.s3.us-east-1.amazonaws.com/${key}`;
    } else {
      throw new Error("Failed to upload file.");
    }
  } catch (error) {
    console.error("Error uploading the file:", error);
    throw error;
  }
};
