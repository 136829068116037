// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success:false,
};

const UserOwnDialects = createSlice({
  name: 'UserOwnDialects',
  initialState,
  reducers: {
    UserOwnDialects_Reducer: (state,action) => {
     state.data = action.payload;
    },
  },
});

export const { UserOwnDialects_Reducer,  } = UserOwnDialects.actions;

export default UserOwnDialects.reducer;