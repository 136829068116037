import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Earn from "../../assets/images/earn-money.png";
import Learn from "../../assets/images/learn_img.png";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUser,
  setIsEarn,
  setIsLearn,
} from "../../lib/Redux/slices/userslice";
import Header from "../../base-components/Header";
import { fetchDataRequest } from "../../lib/Redux/actions";
import { SentanceFilter_Reducer } from "../../lib/Redux/slices/SentanceFilter";
import { FatchApi } from "../../utils/FatchApi";
import useToast from "../../hooks/useToast";
import { selectAllVerbs } from "../../lib/Redux/slices/VerbValue";

function OnBoard() {

  const VerbsData = [
    {
      title: "nouns",
      color: "#BDBBBB",
    },
    {
      title: "pronouns",
      color: "#1C6E2F",
    },
    {
      title: "verbs",
      color: "#BF9898",
    },
    {
      title: "adverbs",
      color: "#CF1B1B",
    },
    {
      title: "adjectives",
      color: "#28CD19",
    },
    {
      title: "prepositions",
      color: "#372ADB",
    },
    {
      title: "conjunctions",
      color: "#BA20B4",
    },
    {
      title: "interjections",
      color: "#F8B1CF",
    },
    {
      title: "articles",
      color: "#31C6DA",
    },
    {
      title: "determiners",
      color: "#C2CF28",
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const User = useSelector((state) => state?.user);

  const showToast = useToast();

  useEffect(() => {
    const fetchPointsData = async () => {
      try {
        const data = await FatchApi({
          request: "get",
          Api: "/users/get-user-points",
        });

        if (data?.isAllowed === false) {
          dispatch(clearUser());
          localStorage.clear();
          showToast("Congratulations! Your profile has been verified!", "info");
          navigate("/login");
          return;
        }
      } catch (error) {
        // console.error("Error fetching points data:", error);
      }
    };

    fetchPointsData();
  }, []);

  const userTypes = (user) => {
    if (user == "earn") {
      localStorage.setItem("box", JSON.stringify({ box: user }));
    } else {
      localStorage.setItem("box", JSON.stringify({ box: user }));
    }

    dispatch(
      SentanceFilter_Reducer([
        {
          radio: "Only new and repetition",
          manageType: "Only new and repetition",
          tags: ["slang", "classic"],
        },
      ])
    );

    if(User && User?.isAuthenticated == true){
      if (user === "earn") {
        if (User && User?.user?.isEarner == true) {
          navigate("/earn");
        } else {
          dispatch(setIsEarn());
          navigate("/verification");
        }
      } else {
        dispatch(setIsLearn());
        dispatch(selectAllVerbs(VerbsData.map((item) => item.title)));
        navigate("/learn");
      }
    }
    else{
      navigate('/login')
    }
  };

  return (
    <div className="container">
      {User && User?.isAuthenticated ? 
      <HeaderLogin />
       :
       <Header />
       }
      <div className="wrapper_full">
        <div className="onboard">
          <div className="onboard_heading">
            <h4>Welcome to</h4>
            <h1>Langbook</h1>
          </div>
          <div className="icon_onboarding">
            <div className="flex-center gap_45">
              <div onClick={() => userTypes("earn")} className="boxes_onboard">
                <img src={Earn} />
                <div className="text_onboard">Earn</div>
              </div>
              <div onClick={() => userTypes("learn")} className="boxes_onboard">
                <img src={Learn} />
                <div className="text_onboard">Learn</div>
              </div>
            </div>
          </div>
        </div>

        <div className="image_credits">
          Images by{" "}
          <a
            href="https://www.flaticon.com/free-icon/earn-money_5303805"
            target="_blank"
          >
            Flaticon
          </a>{" "}
          and{" "}
          <a
            href="https://www.freepik.com/free-vector/boy-student-sitting-stack-books-with-laptop-flat-icon-illustration_17543960.htm"
            target="_blank"
          >
            Freepick
          </a>
          .
        </div>
      </div>
    </div>
  );
}

export default OnBoard;
