import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail, LanguagesWords } from "../../utils/functions";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Earn from "../../assets/images/earn-money.png";
import Learn from "../../assets/images/learn_img.png";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import useToast from "../../hooks/useToast";
import { GrStatusGood } from "react-icons/gr";
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillPlusCircle,
  AiOutlineArrowRight,
} from "react-icons/ai";
import {
  IoCloseCircleOutline,
  IoCopyOutline,
  IoHeartOutline,
  IoHeart,
  IoFlagOutline,
  IoFlag,
} from "react-icons/io5";
import {
  FaHandPointRight,
  FaRegClock,
  FaRegCirclePlay,
  FaRegCirclePause,
} from "react-icons/fa6";
import { BiMessage } from "react-icons/bi";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";

import { HiOutlineSpeakerWave } from "react-icons/hi2";

import { LuClipboardList, LuRepeat } from "react-icons/lu";
import Source from "./../../assets/source.mp3";
import Target from "./../../assets/target.mp3";
import { Tooltip } from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import {
  Get_AllComments_Request,
  LastUpdate_Request,
} from "../../lib/Redux/actions";
import Loader from "../../base-components/Loader/Loader";
import { FatchApi } from "../../utils/FatchApi";

function OnBoard() {
  const { AllComments } = useSelector((state) => state);
  const { LastUpdate } = useSelector((state) => state);
  const user = useSelector((state) => state.user);
  const { AllAvilable } = useSelector((state) => state);
  const { profileVerified } = useSelector((state) => state);

  console.log(AllComments, "profile verified");

  const isDialect = useSelector((state) => state.IsDialect);

  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [heading, setHeading] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [spaces, setSpaces] = useState(true);
  const [play, setPlay] = useState(false);
  const [dictionary, setDictionary] = useState(false);
  const [selected, setSelected] = useState([]);
  const [audioCount, setAudioCount] = useState(0);
  const [audio, setAudio] = useState(null);
  const [isPlaying, setPlaying] = useState(false);
  const [countdown, setCountdown] = useState(false);
  const [count, setCount] = useState(5);
  const [heart, setHeart] = useState(false);
  const [flag, setFlag] = useState(false);
  const [showreply, setShowReply] = useState(0);
  const [Contributor, setContributor] = useState([]);

  const showToast = useToast();
  const location = useLocation();

  // const {user} = useSelector((state)=> state.user)
  // console.log(user?.user?.userId,'user...');

  const urldictionary = "https://glosbe.com/en/es/";
  const urldictionarytarget = "https://glosbe.com/es/en/";

  // console.log(location?.state?.sourceContent?.courseId?.targetLanguage?.name , user?.user?.nativeDialect?.value
  //   , "Created by");
  // console.log(location?.state?.sourceContent?.addedByUser,"Created by");
  // console.log(location?.state?.sourceContent?.sourceAudioUser,"Created by");
  // console.log(location?.state?.sourceContent?.targetAudioUser,"Created by");
  // console.log(location?.state?.sourceContent?.finalCheckUser,"Created by");

  useEffect(() => {
    setContributor([
      {
        contributor:
          location?.state && location?.state?.sourceContent?.addedByUser,
      },
      {
        contributor:
          location?.state && location?.state?.sourceContent?.sourceAudioUser,
      },
      {
        contributor:
          location?.state && location?.state?.sourceContent?.targetAudioUser,
      },
      {
        contributor:
          location?.state && location?.state?.sourceContent?.finalCheckUser,
      },
    ]);
  }, []);

  const do_save_langs = async (val) => {
    const isValueInArray = selected.includes(val);
    if (isValueInArray) {
      const newArray = selected.filter((item) => item !== val);
      setSelected(newArray);
    } else {
      setSelected([...selected, val]);
    }
  };

  const GetAllComments = () => {
    console.log("use effect play");
    if (user.isLearn) {
      dispatch(
        Get_AllComments_Request({
          request: "get",
          data: "",
          Api: isDialect?.type
            ? `/api/v1/get-comments-dia?sentenceId=${location?.state?.sourceContent?._id}`
            : `/api/v1/get-comments?sentenceId=${location?.state?.sourceContent?._id}`,
        })
      );
    } else {
      dispatch(
        Get_AllComments_Request({
          request: "get",
          data: "",
          Api: isDialect?.type
            ? `/api/v1/get-comments-dia?sentenceId=${location?.state?.sourceContent?._id}`
            : `/api/v1/get-comments?sentenceId=${location?.state?.sourceContent?._id}`,
          // AllAvilable.data[0]._id
        })
      );
    }
  };

  // useEffect(() => {
  //   let uniqueArr = AllComments?.data?.filter(
  //     (value, index, self) =>
  //       index === self.findIndex((t) => t.user.email === value.user.email)
  //   );

  //   console.log(uniqueArr,'uniqueArr');
  // }, [LastUpdate]);

  useEffect(() => {
    try {
      GetAllComments();
    } catch (err) {
      console.log(err, "err in the comments");
    }
  }, []);

  const reasonsflag = [
    { title: "factually wrong", id: 1 },
    { title: "insult", id: 2 },
    { title: "spam", id: 3 },
    { title: "other", id: 4 },
  ];

  const playAudioSource = () => {
    const newAudio = new Audio(location?.state?.sourceContent?.targetAudio);
    newAudio.pause();
    newAudio.play();
    setAudio(true);
  };

  const playAudioTarget = () => {
    const newAudio = new Audio(location?.state?.sourceContent?.sourceAudio);
    newAudio.play();
    newAudio.addEventListener("ended", handleAudioEnded);
  };

  const handleAudioEnded = () => {
    setPlaying(false);
    setPlay(false);
  };
  const pauseAudio = () => {
    if (audio) {
      audio.pause();
    }
  };

  const dispatch = useDispatch();

  const postinputRef = useRef();
  const reppostRef = useRef();

  const [CommentInputData, setCommentInputData] = useState();
  const [postLoading, setPostLoading] = useState(false);

  const handleCommentPost = async (e) => {
    if (user?.isLearn) {
      if (!CommentInputData === "" || !CommentInputData?.length <= 0) {
        setPostLoading(true);
        var content = {
          content: CommentInputData,
          sentenceId: location?.state?.sourceContent?._id,
        };
        console.log(location?.state?.sourceContent, "content");
        const data = await FatchApi({
          request: "post",
          data: content,
          Api: isDialect.type
            ? "/api/v1/create-comment-dia"
            : `/api/v1/create-comment`,
        });

        if (data) {
          console.log(data, "data here after posting");
          postinputRef.current.value = "";
          GetAllComments();
          setPostLoading(false);
          showToast("Posted successfully", "success");
        }
      } else {
        showToast("Invalid Input Field", "warning");
      }
    } else {
      if (!CommentInputData === "" || !CommentInputData?.length <= 0) {
        setPostLoading(true);
        var content = {
          content: CommentInputData,
          // sentenceId:AllAvilable && AllAvilable.data[0]?._id,
          sentenceId: location?.state?.sourceContent?._id,
        };
        console.log(AllAvilable && AllAvilable.data[0]?._id, "contnet");
        const data = await FatchApi({
          // user: user,
          request: "post",
          data: content,
          Api: isDialect.type
            ? "/api/v1/create-comment-dia"
            : `/api/v1/create-comment`,
        });

        postinputRef.current.value = "";

        if (data) {
          GetAllComments();
          setPostLoading(false);
          showToast("Posted successfully", "success");
        }
      } else {
        showToast("Invalid Input Field", "warning");
      }
    }
  };

  const [postreplay, setPostreplay] = useState();
  const [getId, setGetId] = useState();
  const [type, setType] = useState();
  const [reppostLoading, setRepPostLoading] = useState(false);
  const [ReportInput, setReportInput] = useState();
  const [Radio, setRadio] = useState();

  const handleReplayPost = async (commentId) => {
    if (reppostRef.current.val === "" || reppostRef?.current.val?.length <= 0) {
      showToast("Invalid Filed", "warning");
    } else {
      setRepPostLoading(true);
      var content = {
        content: postreplay,
        sentenceId: location?.state?.sourceContent?._id,
      };
      try {
        const data = await FatchApi({
          // user: user,
          request: "post",
          data: content,
          Api: isDialect?.type
            ? `/api/v1/comments-replies-dia/${commentId}`
            : `/api/v1/comments-replies/${commentId}`,
        });

        if (data) {
          GetAllComments();
          setRepPostLoading(false);
          showToast("Posted successfully", "success");
          setShowReply(0);
          reppostRef.current.val = "";
        }
      } catch (err) {
        setRepPostLoading(false);
      }
    }
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [repload, setRepLoad] = useState(false);

  const handleReported = async () => {
    setRepLoad(true);

    const date = new Date();
    const currentDate = date.getDate();
    const currentMonth = date.getMonth();
    const currentYear = date.getFullYear();

    const monthName = monthNames[currentMonth];

    console.log();

    var content = {
      reportContent: {
        ReportInput,
        user: user,
        radio: Radio,
        reportedDate: {
          date: currentDate,
          month: monthName,
          year: currentYear,
        },
      },
      isReported: true,
    };

    console.log(content);

    try {
      const data = await FatchApi({
        // user: user,
        request: "put",
        data: content,
        Api: isDialect.type
          ? type === "comment"
            ? `/api/v1/create-comment-dia/${getId}`
            : `/api/v1/replies-dia/${getId}`
          : type === "comment"
          ? `/api/v1/create-comment/${getId}`
          : `/api/v1/replies/${getId}`,
      });

      if (data) {
        setRepLoad(false);
        GetAllComments();
        setFlag(false);
        showToast("reported successfully", "success");
      }
    } catch (err) {
      setRepLoad(false);
    }
  };

  const handleLike = async (id, type, isComment) => {
    var content = { userId: user?.user?.userId, voteType: type };

    try {
      const data = await FatchApi({
        // user: user,
        request: "post",
        data: content,
        Api: isDialect.type
          ? isComment
            ? `/api/v1/comments-dia/like/${id}`
            : `/api/v1/reply-dia/like/${id}`
          : isComment
          ? `/api/v1/comments/like/${id}`
          : `/api/v1/reply/like/${id}`,
      });

      if (data) {
        GetAllComments();
      }
    } catch (err) {}
  };

  // useEffect(() => {
  //   if (LastUpdate.loading === false && LastUpdate.success) {
  //     GetAllComments();
  //     setFlag(false);
  //   }
  // }, [LastUpdate && LastUpdate?.success]);

  console.log(AllComments, "all comment data.......");

  const [Load, setLoad] = useState(false);
  const [showgood, setShowgood] = useState(false);
  const [plusLoad, setPlusLoad] = useState(false);

  const handlePoint = async (ReplayId, EarnerId, v) => {
    setLoad(true);

    var obj = {
      learnerId: user?.user?.userId,
      commentId: ReplayId,
      earnerId: EarnerId,
      type: v?.type,
      sentenceId: location?.state?.sourceContent?._id,
      userCommentId: v?.user?._id,
    };

    // console.log(location?.state?.sourceContent?._id  ,'obj');

    try {
      setPlusLoad(true);
      const data = await FatchApi({
        request: "post",
        data: obj,
        Api: isDialect.type ? "/api/v1//tip-reply-dia" : "/api/v1//tip-reply",
      });

      if (data) {
        GetAllComments();
        if (data?.message != "You have already tipped for this reply.") {
          showToast("Send Points Successfully", "success");
        }
        setPlusLoad(false);
        console.log(data, "dataaaa");
        setLoad(false);
        setShowgood(true);
        showToast(
          data?.message,
          data?.message === "You have already tipped for this reply."
            ? "warning"
            : "successfully"
        );
        setTimeout(() => {
          setShowgood(false);
        }, 2000);
      }
    } catch (err) {
      setPlusLoad(false);
      console.log(err, "this is error dataaaa ");
    }
  };

  // var ernerCount = 0,
  // learnerCount = 0;
  var ernerCountR = 0,
    learnerCountR = 0;

  ////

  const uniqueEmails = new Set();
  const userNumberMap = new Map();
  let learnerCount = 0;
  let earnerCount = 0;

  const NoFun = () => {
    AllComments?.commentsusers?.forEach((item) => {
      const email = item?.user?.email;
      if (email && !uniqueEmails.has(email)) {
        uniqueEmails.add(email);
        if (
          isDialect?.type
            ? item?.user?.isEarner &&
              // location?.state?.sourceContent?.courseId?.targetLanguage
              //   ?.name === user?.user?.nativeDialect?.value) ||
              item?.user?.nativeDialect?.value ==
                location?.state?.sourceContent?.courseId?.targetLanguage?.name
            : (item?.user?.isEarner &&
                // profileVerified?.data?.c2Languages?.some(
                //   (item) =>
                //     item.value ===
                //     location?.state?.sourceContent?.courseId?.targetLanguage
                //       ?.name
                // )) ||
                // user?.user?.nativeLanguage?.name ==
                //   location?.state?.sourceContent?.courseId?.targetLanguage
                //     ?.name ||
                item?.user?.c2Languages?.some(
                  (item) =>
                    item.value ===
                    location?.state?.sourceContent?.courseId?.targetLanguage
                      ?.name
                )) ||
              item?.user?.nativeLanguage?.name ==
                location?.state?.sourceContent?.courseId?.targetLanguage?.name
        ) {
          earnerCount += 1;
          userNumberMap.set(email, `Native ${earnerCount}`);
        } else {
          learnerCount += 1;
          userNumberMap.set(email, `Learner ${learnerCount}`);
        }
      }
    });
    console.log(Array.from(userNumberMap.entries()));
  };

  const EmailMatch = (email) => {
    if (userNumberMap.has(email)) {
      return userNumberMap.get(email);
    } else {
      NoFun();
      return userNumberMap.get(email) || "";
    }
  };

  const learnerCountMap = new Map();
  const earnerCountMap = new Map();

  const MatchEmailReply = (email, i) => {
    const commentsusers = AllComments?.data[i]?.replies?.filter(
      (value, index, self) =>
        index === self?.findIndex((t) => t?.user?.email === value?.user?.email)
    );

    const targetLanguageName =
      location?.state?.sourceContent?.courseId?.targetLanguage?.name;

    const Result = commentsusers
      ?.map((data) => {
        let isNative = false;
        let isLearner = false;

        if (isDialect?.type) {
          if (
            data?.user?.isEarner === true &&
            data?.user?.nativeDialect?.value === targetLanguageName
            // targetLanguageName === user?.user?.nativeDialect?.value
          ) {
            console.log(data?.user, "user here True side");
            isNative = true;
            isLearner = false;
          } else {
            console.log(data?.user, "user here false side");
            isNative = false;
            isLearner = true;
          }
        } else {
          if (
            data?.user?.isEarner === true &&
            // profileVerified?.data?.c2Languages?.some((item) => item.value === targetLanguageName)
            // user?.user?.nativeLanguage?.name === targetLanguageName ||
            (data?.user?.c2Languages?.some(
              (item) => item.value === targetLanguageName
            ) ||
              data?.user?.nativeLanguage?.name === targetLanguageName)
          ) {
            // console.log(data?.user?.isEarner,profileVerified?.data?.c2Languages?.some((item) => item.value === targetLanguageName),'there section here ....');
            isLearner = false;
            isNative = true;
          } else {
            isLearner = true;
            isNative = false;
          }
        }

        if (data?.user?.email === email) {
          if (isNative) {
            if (!earnerCountMap.has(email)) {
              earnerCountMap.set(email, earnerCountMap.size + 1);
            }
            return (
              <span style={{ color: "#2DC14D" }}>
                Native {earnerCountMap.get(email)}{" "}
              </span>
            );
          } else if (isLearner) {
            if (!learnerCountMap.has(email)) {
              learnerCountMap.set(email, learnerCountMap.size + 1);
            }
            return (
              <span style={{ color: "#F89700" }}>
                Learner {learnerCountMap.get(email)}{" "}
              </span>
            );
          }
        }

        return null;
      })
      .filter((item) => item !== null);

    return Result?.length > 0 ? Result[0] : "";
  };

  const NativeLanguage = useSelector(
    (state) => state?.profileVerified?.data?.nativeLanguage
  );
  // console.log(NativeLanguage,'NativeLanguage');

  const DialectPlus = (contributor_present, userClick, v) => {
    console.log(v?.user?._id, "v of the comment", user?.user?.userId);

    if (v?.user?._id !== user?.user?.userId) {
      if (
        // user?.user?.nativeDialect?.value === location?.state?.sourceContent?.courseId?.targetLanguage?.name ||
        v?.user?.nativeDialect?.value ===
        location?.state?.sourceContent?.courseId?.targetLanguage?.name
      ) {
        return (
          <>
            <span
              data-tooltip-id="question_new_sentence"
              data-tooltip-html="Was this comment helpful? <br />Show your appreciation by clicking this button"
              onClick={() =>
                contributor_present || userClick
                  ? ""
                  : handlePoint(v._id, v.user._id, v)
              }
              style={{
                color: contributor_present || userClick ? "#A5A8AC" : "#FFD700",
                cursor: contributor_present || userClick ? "auto" : "pointer",
                fontSize: "22px",
              }}
            >
              +
            </span>
            <Tooltip
              id="question_new_sentence"
              style={{
                textAlign: "center",
                padding: "13px 10px",
                fontSize: "13px",
                zIndex: 9,
                width: "250px",
              }}
            />
          </>
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const LanguagePlus = (contributor_present, userClick, v) => {
    if (v?.user?._id !== user?.user?.userId) {
      if (
        // profileVerified?.data?.c2Languages?.some(
        //   (item) => item.value === location?.state?.sourceContent?.courseId?.targetLanguage?.name
        // ) ||
        // user?.user?.nativeLanguage?.name === location?.state?.sourceContent?.courseId?.targetLanguage?.name ||
        v?.user?.c2Languages?.some(
          (item) =>
            item.value ===
            location?.state?.sourceContent?.courseId?.targetLanguage?.name
        ) ||
        v?.user?.nativeLanguage?.name ===
          location?.state?.sourceContent?.courseId?.targetLanguage?.name
      ) {
        return (
          <>
            <span
              data-tooltip-id="question_new_sentence"
              data-tooltip-html="Was this comment helpful? <br />Show your appreciation by clicking this button"
              onClick={() =>
                contributor_present || userClick
                  ? ""
                  : handlePoint(v._id, v.user._id, v)
              }
              style={{
                color: contributor_present || userClick ? "#A5A8AC" : "#FFD700",
                cursor: contributor_present || userClick ? "auto" : "pointer",
                fontSize: "22px",
              }}
            >
              +
            </span>
            <Tooltip
              id="question_new_sentence"
              style={{
                textAlign: "center",
                padding: "13px 10px",
                fontSize: "13px",
                zIndex: 9,
                width: "250px",
              }}
            />
          </>
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const Plus = (v) => {
    var contributor_present = Contributor?.some(
      (item) => item.contributor === user?.user?.userId
    );
    var userClick = v?.userClicks?.includes(user?.user?.userId);

    return v?.user?.isEarner == true
      ? isDialect?.type
        ? DialectPlus(contributor_present, userClick, v)
        : LanguagePlus(contributor_present, userClick, v)
      : "";
  };

  const NativeColorLang = (v) => {
    const targetLanguageName =
      location?.state?.sourceContent?.courseId?.targetLanguage?.name;
    const UserNativeLang = user?.user?.nativeLanguage?.name;

    if (v?.user?.isEarner === true) {
      if (
        // UserNativeLang === targetLanguageName ||
        // profileVerified?.data?.c2Languages?.some(
        //   (item) => item.value === targetLanguageName
        // ) ||
        v?.user?.c2Languages?.some(
          (item) => item.value === targetLanguageName
        ) ||
        v?.user?.nativeLanguage?.name === targetLanguageName
      ) {
        return "#2DC14D";
      } else {
        return "#F89700";
      }
    } else {
      return "#F89700";
    }
  };

  const NativeColorDia = (v) => {
    const targetLanguageDia =
      location?.state?.sourceContent?.courseId?.targetLanguage?.name;
    const UserNativeDia = user?.user?.nativeDialect?.value;

    if (v?.user?.isEarner) {
      if (
        v?.user?.nativeDialect?.value === targetLanguageDia
        // UserNativeDia === targetLanguageDia
      ) {
        return "#2DC14D";
      } else {
        return "#F89700";
      }
    } else {
      return "#F89700";
    }
  };

  return plusLoad ? (
    <div
      style={{
        backgroundColor: "white",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader />
    </div>
  ) : (
    <div className="container">
      <HeaderLogin />
      <div className="wrapper">
        <div className="my_languages_wrap">
          <div className="card comment_left_wrap p20">
            <div className="sentence_wrapper wd100">
              <div className="right_sentences_data">
                <div className={`sentence_data_normal`}>
                  <div className="sound_icon" onClick={() => playAudioSource()}>
                    <HiOutlineSpeakerWave />
                  </div>
                  <div className="content_sond" style={{width:'100%',overflowX:'auto',padding:'5px'}} >
                    <>
                      {location?.state?.sourceContent?.targetContentArray?.map(
                        (v, i) => (
                          <>
                            <span
                              style={{
                                marginLeft: location?.state?.sourceContent
                                  ?.courseId?.spaces
                                  ? ""
                                  : "7px",
                              }}
                              className={v.type}
                            >
                              {v.title}
                            </span>
                          </>
                        )
                      )}
                    </>
                  </div>
                </div>
                <div className={`sentence_data_normal`}>
                  <div className="sound_icon" onClick={() => playAudioTarget()}>
                    <HiOutlineSpeakerWave />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column",width:'100%',overflowX:'auto',padding:'5px' }}>
                    <div className="content_sond">
                      <>
                        {location?.state?.sourceContent?.sourceContentArray?.map(
                          (v, i) => (
                            <>
                              <span
                                style={{
                                  marginLeft: location?.state?.sourceContent
                                    ?.courseId?.spaces
                                    ? ""
                                    : "7px",
                                }}
                                className={v.type}
                              >
                                {v.title}
                              </span>
                            </>
                          )
                        )}
                        {/* ////////////////// */}
                      </>
                    </div>

                    <div style={{ display: "flex", fontSize: "14px" }}>
                      {location?.state?.sourceContent?.latinContentArray?.map(
                        (v, i) => (
                          <>
                            <span
                              style={{
                                marginTop: "5px",
                                marginLeft: "7px",
                              }}
                              className={v.type}
                            >
                              {v.title}
                            </span>
                          </>
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div className="comment_area">
                  <textarea
                    ref={postinputRef}
                    onChange={(e) => setCommentInputData(e.target.value)}
                    className="form-control"
                    placeholder="Leave a comment or ask a question"
                  ></textarea>
                  <button
                    style={{ cursor: "pointer" }}
                    onClick={handleCommentPost}
                    className="primary button_custom_rohandleund mt-10px"
                  >
                    {postLoading ? <Loader /> : "Post"}
                  </button>
                </div>

                <div className="comments_section">
                  <h4>{AllComments?.data?.length} Comments</h4>
                  <div className="comments_outer_wrap">
                    {AllComments.loading ? (
                      <Loader />
                    ) : (
                      AllComments?.data?.map((v, i) => (
                        <div className="comments_wrap">
                          <div
                            className="name_commenter"
                            style={{
                              color: isDialect?.type
                                ? NativeColorDia(v)
                                : NativeColorLang(v),
                            }}
                          >
                            {v?.user ? (
                              EmailMatch(v?.user?.email)
                            ) : (
                              <div style={{ color: "red" }}>
                                Account deleted
                              </div>
                            )}
                          </div>
                          <div className="comment_text">{v?.content}</div>
                          {v?.user && (
                            <div className="comment_icons">
                              <div className="up_down_arrows">
                                <span>{v.upvote}</span>
                                <span className="arrow_up">
                                  <MdKeyboardArrowUp
                                    onClick={() =>
                                      handleLike(v._id, "likes", true)
                                    }
                                    size={20}
                                  />
                                  {v?.likes && v?.likes}
                                  <MdKeyboardArrowDown
                                    onClick={() =>
                                      handleLike(v._id, "dislikes", true)
                                    }
                                    size={20}
                                  />
                                  {v.dislikes && v.dislikes}
                                </span>
                              </div>
                              <div
                                className="up_down reply_comment"
                                onClick={() => setShowReply(i + 1)}
                              >
                                Reply
                              </div>
                              <div
                                className="up_down pointer"
                                style={{ color: "red" }}
                                onClick={() => {
                                  setFlag(true);
                                  setType("comment");
                                  setGetId(v._id);
                                }}
                              >
                                Report
                              </div>
                              {Plus(v)}
                              {/* //////////////////////////////////////////////////////////////////////////////////////////////////// */}
                              <div className="border solid 1px red">
                                {!v?.user?.isEarner && (
                                  <>
                                    {Load ? (
                                      <Loader />
                                    ) : (
                                      <>
                                        {/* <Link
                                        className="price_plus_bonus"
                                        onClick={() =>
                                          handlePoint(v._id, v.user._id)
                                        }
                                      >
                                        <span
                                          style={{
                                            color: "#FFD700",
                                            cursor: "pointer",
                                          }}
                                          data-tooltip-id="question_new_sentence"
                                          data-tooltip-html="Was this comment helpful? <br />Show your appreciation by clicking this button"
                                        >
                                          +
                                        </span>{" "}
                                      </Link> */}

                                        {/* <Tooltip
                                        id="question_new_sentence"
                                        style={{
                                          textAlign: "center",
                                          padding: "13px 10px",
                                          fontSize: "13px",
                                          zIndex: 9,
                                          width: "250px",
                                        }}
                                      /> */}

                                        {showgood ? (
                                          <GrStatusGood
                                            style={{
                                              color: "#2DC146",
                                              fontSize: "12px",
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          )}

                          {showreply == i + 1 && (
                            <div className="reply_board">
                              <h6 style={{ cursor: "pointer" }}>Post Reply</h6>
                              <textarea
                                ref={reppostRef}
                                onChange={(e) => setPostreplay(e.target.value)}
                                className="form-control"
                              ></textarea>
                              <div className="cancel_submiy_bytn mt-10px">
                                <button
                                  onClick={() => handleReplayPost(v._id)}
                                  className="primary button_custom_round "
                                >
                                  {reppostLoading ? <Loader /> : "Submit"}
                                </button>
                                <div
                                  className="can_bbbtn"
                                  onClick={() => setShowReply(0)}
                                >
                                  Cancel
                                </div>
                              </div>
                            </div>
                          )}
                          <>
                            <div className="">
                              {v?.replies.map((vv, ii) => (
                                <>
                                  <div
                                    className="comments_wrap comment_reply"
                                    style={{ marginLeft: "50px" }}
                                  >
                                    {/* <span
                                      className="name_commenter"
                                      style={{
                                        color: v?.user?.isEarner
                                          ? "#FFD700"
                                          : "#F89700",
                                      }}
                                    >
                                      {MatchEmailReply(vv?.user?.email, i)}
                                    </span> */}

                                    <span className="name_commenter">
                                      {MatchEmailReply(vv?.user?.email, i)}
                                    </span>

                                    <div
                                      // className="name_commenter"
                                      style={{
                                        marginTop: "10px",
                                        color: "black",
                                      }}
                                    >
                                      {vv?.content}
                                    </div>
                                    <div className="comment_text">
                                      {vv.comment}
                                    </div>
                                    <div className="comment_icons">
                                      <div className="up_down_arrows">
                                        <span>{vv?.upvote}</span>
                                        <span className="arrow_up">
                                          <MdKeyboardArrowUp
                                            onClick={() =>
                                              handleLike(vv._id, "likes", false)
                                            }
                                            size={20}
                                          />
                                          {vv.likes && vv.likes}
                                          <MdKeyboardArrowDown
                                            onClick={() =>
                                              handleLike(
                                                vv._id,
                                                "dislikes",
                                                false
                                              )
                                            }
                                            size={20}
                                          />
                                          {vv.dislikes && vv.dislikes}
                                        </span>
                                      </div>
                                      {/* <div
                                      className="up_down reply_comment"
                                      onClick={() => setShowReply(i + 1)}
                                    >
                                      Reply
                                    </div> */}
                                      <div
                                        className="up_down pointer"
                                        style={{ color: "red" }}
                                        onClick={() => {
                                          setFlag(true);
                                          setGetId(vv._id);
                                          setType("replay");
                                        }}
                                      >
                                        Report
                                      </div>
                                      {Plus(vv)}
                                    </div>
                                    {/* {
                                                    showreply == ii+1 &&
                                                    <div className="reply_board">
                                                        <h6>Post Reply</h6>
                                                        <textarea className="form-control"></textarea>
                                                        <button className="primary button_custom_round mt-10px">submit</button>
                                                    </div>
                                                } */}
                                  </div>
                                </>
                              ))}
                            </div>
                          </>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nouns_settings">
            {LanguagesWords().map((v, i) => (
              <>
                <Link
                  key={"word_" + i}
                  to={v.link}
                  target="_blank"
                  className={v.type}
                >
                  {v.title}
                </Link>
              </>
            ))}
          </div>
        </div>

        {flag && (
          <div className="outer_wrapper">
            <div className="inner_wrapper">
              <div className="white_wrapper w40">
                <div className="heading_languages">
                  <div className="">Report comment</div>
                  <span className="pointer" onClick={() => setFlag(false)}>
                    <IoCloseCircleOutline />
                  </span>
                </div>
                <div className="" style={{ padding: "0px 40px" }}>
                  {reasonsflag.map((v, i) => (
                    <>
                      <div className="flag_reasons">
                        <label>
                          <input
                            onChange={(e) => setRadio(v.title)}
                            type="radio"
                            val={v.id}
                            name="flag"
                          />
                          <span>{v.title}</span>
                        </label>
                      </div>
                    </>
                  ))}

                  <div className="flag_textarea">
                    <textarea
                      onChange={(e) => setReportInput(e.target.value)}
                      className="form-control"
                      placeholder="Please tell us more (optional)"
                    ></textarea>
                  </div>
                  {/* <div>plus</div> */}
                </div>
                <div className="footer_languages">
                  <div className=""></div>
                  <span onClick={handleReported}>
                    <button className="button_custom_round primary">
                      {repload ? <Loader /> : "Submit"}
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OnBoard;
