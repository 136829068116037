import { useSelector } from "react-redux";
import httpRequest from "../axios";
import axios from "../axios";

export const FatchApi = async (payload) => {

  console.log(payload, "payload... in fetch api..");
  try {
    var response;
    if (payload.request === "get") {
      response = await httpRequest.get(payload?.Api);
      console.log(response,'Fatch Api data ...');
    }
    if (payload.request === "post") {
      response = await httpRequest.post(payload.Api, payload.data);
      console.log(response,'Fatch Api data ...');
    }
    if (payload.request === "put") {
      response = await httpRequest.put(payload.Api , payload.data);
      console.log(response,'Fatch Api data ...');
    }
    if (response?.status === 200 || response?.status === 201) {
      console.log(response.data, "res ....  ");
      return response?.data;
    }
  } catch (err) {
    console.log(err?.response?.data?.message,'res into the fatch');
    throw err;
   
  }
};
