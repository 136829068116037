import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail, LanguagesWords } from "../../utils/functions";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import useToast from "../../hooks/useToast";
import Earn from "../../assets/images/earn-money.png";
import Learn from "../../assets/images/learn_img.png";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
// import { HiOutlineSpeakerWave } from "react-icons/hi2";
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillPlusCircle,
  AiFillQuestionCircle,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineAudio,
} from "react-icons/ai";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaHandPointRight, FaRegClock } from "react-icons/fa6";
import { LuClipboardList, LuRepeat } from "react-icons/lu";
import { HiOutlineSpeakerWave } from "react-icons/hi2";

import { Tooltip } from "react-tooltip";
import Flag1 from "../../assets/images/eng.png";
import Flag2 from "../../assets/images/russia.png";
// import Source from "./../../assets/source.mp3";
import { useSelector } from "react-redux";
import Loader from "../../base-components/Loader/Loader";

var SourceVal = JSON.parse(localStorage.getItem("source"));
var TargetVal = JSON.parse(localStorage.getItem("target"));

const sentences_lis = [
  {
    title: "Das Wetter ist gut heute.",
    tag: "classic",
    id: 1,
    done: 1,
    here: 0,
  },
  {
    title: "Das Wetter war sehr gut.",
    tag: "classic",
    id: 2,
    done: 1,
    here: 0,
  },
  {
    title: "Das Essen war sehr lecker.",
    tag: "slang",
    id: 3,
    done: 1,
    here: 0,
  },
  {
    title: "Das Fressen war sau lecker.",
    tag: "classic",
    id: 4,
    done: 1,
    here: 0,
  },
];

const source_text = [
  { title: "The", type: "" },
  { title: "weather", type: "" },
  { title: "is", type: "" },
  { title: "good", type: "" },
  { title: "today", type: "" },
];

const target_text = [
  { title: "Погода", type: "" },
  { title: "сегодня", type: "" },
  { title: "хорошая", type: "" },
];
function OnBoard() {
  const SourceAndTargetAndSourceAudio = useSelector(
    (state) => state.SourceAndTargetAndSourceAudio
  );

  const user = useSelector((state) => state.user);
  const { NativeLanguage } = useSelector((state) => state);

  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [heading, setHeading] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);

  const [sourcetext, setSourceText] = useState(source_text);
  const [targettext, setTargetText] = useState(target_text);

  const [sourceselected, setSourceSelected] = useState("");
  const [targetselected, setTargetSelected] = useState("");

  const [selected, setSelected] = useState([]);
  const [audio, setAudio] = useState(null);

  const [count, setCount] = useState(0);

  const course = useSelector((state) => state.Course);
  const isDialect = useSelector((state) => state.IsDialect);

  useEffect(() => {
    SourceVal = JSON.parse(localStorage.getItem("source"));
    TargetVal = JSON.parse(localStorage.getItem("target"));
  }, [SourceVal?.code, TargetVal?.code]);

  const showToast = useToast();

  const show_popup = async (val) => {
    setHeading(val);
    setPopup(true);
  };

  const do_next_page = async () => {
    navigate("/step/three");
  };

  const do_source_click = async (v) => {
    setTargetSelected("");
    if (sourceselected == v.title) {
      setSourceSelected("");
    } else {
      setSourceSelected(v.title);
    }
  };

  const do_target_click = async (v) => {
    setSourceSelected("");
    if (targetselected == v.title) {
      setTargetSelected("");
    } else {
      setTargetSelected(v.title);
    }
  };

  const handleLangwords = (type) => {
    if (sourceselected) {
      var selectedIndex = sourcetext.findIndex(
        (item) => item.title === sourceselected
      );
    } else {
      var selectedIndex = targettext.findIndex(
        (item) => item.title === targetselected
      );
    }

    if (selectedIndex !== -1) {
      if (sourceselected) {
        const updatedSourceText = [...sourcetext];
        updatedSourceText[selectedIndex].type = type;
        setSourceText(updatedSourceText);
      } else {
        const updatetargettext = [...targettext];
        updatetargettext[selectedIndex].type = type;
        setTargetText(updatetargettext);
      }
      setSourceSelected("");
      setTargetSelected("");
    }
  };

  const do_play_audio = (type) => {
    if(type === 'source'){
      const newAudio = new Audio(
        SourceAndTargetAndSourceAudio && SourceAndTargetAndSourceAudio?.data[count]?.sourceAudio
      );
      newAudio.pause();
      newAudio.play();
      setAudio(true);
    }
    else{
      const newAudio = new Audio(
        SourceAndTargetAndSourceAudio && SourceAndTargetAndSourceAudio?.data[count]?.targetAudio
      );
      newAudio.pause();
      newAudio.play();
      setAudio(true);
    }
  };

  const handleClick = () => {
    if (SourceAndTargetAndSourceAudio && SourceAndTargetAndSourceAudio?.data?.length - 1 === count) {
      showToast("Bringing you back to the menu", "info");
      setTimeout(() => {
        navigate("/earn");
      }, 2000); // 2000 milliseconds = 2 seconds
    } else {
      setCount(count + 1);
    }
  };

  // console.log(count, SourceAndTargetAndSourceAudio?.data?.length  - 1 ,'count...');

  return (
    <div className="container">
      <HeaderLogin />
      <div className="wrapper">
        {/* /////////////////// */}
        <div className="langugae_courses_heading">
          
          <span>
            <span>{isDialect?.type ? "" : <img src={SourceVal && SourceVal?.flag} />}</span>{" "}
            {SourceVal && SourceVal?.code}
          </span>
          <AiOutlineArrowRight />{" "}
          <span>
            <span>{isDialect.type ? "" : <img src={TargetVal && TargetVal?.flag} />}</span>{" "}
            {isDialect?.type
              ? course?.data[0]?.targetLanguage?.name
              : NativeLanguage?.data?.code}{" "}
          </span>{" "}
        </div>
        {/* ///////////////// */}
        <div className="my_languages_wrap">
          <div className="box_left_add_ card">
            <div className="heading_languages mb-0">
              <div className="">Previous Sentences</div>
            </div>
            <div className="p10 previous_sentences">
              {SourceAndTargetAndSourceAudio && !SourceAndTargetAndSourceAudio?.loading ? (
                SourceAndTargetAndSourceAudio?.data?.map((v, i) => (
                  <>
                    <div className={`senetence_`}>
                      <span>#{i + 1}</span>
                      <span style={{wordBreak: 'break-word'}} >
                        {course && course?.data[0] && course?.data[0]?.spaces
                          ? v?.sourceContent?.split(" ")?.join("")
                          : v?.sourceContent}
                      </span>
                    </div>
                  </>
                ))
              ) : (
                <Loader />
              )}
            </div>
          </div>

          <div className="box_left_add_"  >
            <div className="card p15 wd100 text-center">
              Are these sentences correct?
            </div>

             <div style={{display:'flex',border:'solid 1px transparent',minWidth:'100%'}} >

             
            <div className="card p20 mt-10px">
              <div className="sentence_source flex-startpointer text_user_show flex-space-between">
                <span>
                  {isDialect.type ? "" : <img src={SourceVal?.flag} />}
                </span>


                <span style={{ display: "flex",width:'96%',flexWrap:'wrap',maxHeight:'94%',overflowY:'auto',justifyContent:'center' }}>
                  {SourceAndTargetAndSourceAudio && SourceAndTargetAndSourceAudio?.loading ? (
                    <Loader />
                  ) :
                  course?.data[0]?.spaces ? (
                    SourceAndTargetAndSourceAudio?.data[
                      count
                    ]?.targetContentArray?.map((items) => {
                      return <div className={items.type}>{items.title}</div>;
                    })
                  ) : (
                    SourceAndTargetAndSourceAudio?.data[
                      count
                    ]?.targetContentArray.map((items) => {
                      return (
                        <div
                          className={items.type}
                          style={{ marginLeft: "7px" }}
                        >
                          {items.title}
                        </div>
                      );
                    })
                  )}
                </span>


                <span>
                  {isDialect.type ? "" : <img src={SourceVal?.flag} />}
                </span>

                {isDialect?.type ? (
                  ""
                ) : (
                  <span className="pointer" onClick={() => do_play_audio('source')}>
                    <HiOutlineSpeakerWave />
                  </span>
                )}

                 

              </div>

              <div className="sentence_source flex-start  mt-10px  pointer text_user_show flex-space-between">
                <span>
                  {isDialect.type ? "" : <img src={TargetVal && TargetVal?.flag} />}
                </span>


                <span style={{ display: "flex",width:'96%',flexWrap:'wrap',maxHeight:'94%',overflowY:'auto',justifyContent:'center'  }}>
                  {SourceAndTargetAndSourceAudio && SourceAndTargetAndSourceAudio?.loading ? (
                    <Loader />
                  ) : course?.data[0]?.spaces ? (
                    SourceAndTargetAndSourceAudio?.data[
                      count
                    ]?.sourceContentArray?.map((items) => {
                      return (
                        <div
                          className={items.type}
                        >
                          {items.title}
                        </div>
                      );
                    })
                  ) : (
                    SourceAndTargetAndSourceAudio && SourceAndTargetAndSourceAudio?.data[
                      count
                    ]?.sourceContentArray?.map((items) => {
                      return (
                        <div
                          className={items.type}
                          style={{ marginLeft: "7px" }}
                        >
                          {items.title}
                        </div>
                      );
                    })
                  )}
                </span>

                {!isDialect?.type ? (
                  ""
                ) : (
                  <span className="pointer" onClick={() => do_play_audio('target')}>
                    <HiOutlineSpeakerWave />
                  </span>
                )}

                <span>
                  {isDialect.type ? (
                    ""
                  ) : (
                    <>
                      <img src={TargetVal?.flag} />
                    </>
                  )}
                </span>
              </div>

              <div
                className="question_mark_"
                data-tooltip-id="question_new_sentence"
                data-tooltip-html="Please check the sentences for the following: target and source language are gramatically correct, correct tag, the words are marked correctly (click on the words on the bottom right to get a definition), the sentence is appropriate and helpful to the learner.<br><br> Click `yes` to proceed to the audio. <br>Click `no` if you`ve spotted a mistake.<br><br> You are responsible to not let any mistakes slip. If, for whatever reason, you don`t want to proceed with this sentence, click on `pass` and you will be brought back to the menu."
              >
                <AiFillQuestionCircle />
              </div>
              <Tooltip id="question_new_sentence" />
            </div>

            <span style={{backgroundColor:'white',border:'solid 1px lightgray',fontSize:'12px',borderRadius:'14px',padding:'5px',marginTop:'12px',display:'flex',justifyContent:'center',alignItems:'center'}} >{SourceAndTargetAndSourceAudio && SourceAndTargetAndSourceAudio?.data[count]?.tag}</span>


             </div>

            <div className="checks_buttons flex-space-between">
              <button
                className="yes_button button_custom_med"
                onClick={() =>
                  navigate("/record/target/language", {
                    state: { count: count },
                  })
                }
              >
                YES
              </button>
              <button
                className="maybe_button button_custom_med dark_white"
                onClick={handleClick}
              >
                 Pass
              </button>
              <button
                className="no_button button_custom_med"
                onClick={() =>
                  navigate("/report/mistake", {
                    state: {
                      count: count,
                      sourceContent:
                        SourceAndTargetAndSourceAudio?.data[count]
                          ?.sourceContent,
                      targetContent:
                        SourceAndTargetAndSourceAudio?.data[count]
                          ?.targetContent,
                    },
                  })
                }
              >
                NO
              </button>
            </div>
          </div>

          <div
            className="box_left_add_ tags_button_next flex-space-between  p20"
            style={{ flexDirection: "column" }}
          >
            <div className="flex-space-between gap_20">
            </div>
            <div className="langword_bottom custom_mar">
              {LanguagesWords().map((v, i) => (
                <>
                  <Link
                    to={v.link}
                    target="_blank"
                    onClick={() => handleLangwords(v.type)}
                    key={"word_" + i}
                    className={v.type}
                  >
                    {v.title}
                  </Link>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnBoard;
