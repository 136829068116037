// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success:false,
};

const SourceAndTargetAndSourceAudioSlice = createSlice({
  name: 'SourceAndTargetAndSourceAudioSlice',
  initialState,
  reducers: {
    Get_SouAndTarAndSouAudio_Request_Reducer: (state) => {
      state.loading = true;
      state.error = null;
    },
    Get_SouAndTarAndSouAudio_Success_Reducer: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
      state.success=true;
    },
    Get_SouAndTarAndSouAudio_Failer_Reducer: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {  Get_SouAndTarAndSouAudio_Request_Reducer,  Get_SouAndTarAndSouAudio_Success_Reducer, Get_SouAndTarAndSouAudio_Failer_Reducer } = SourceAndTargetAndSourceAudioSlice.actions;

export default SourceAndTargetAndSourceAudioSlice.reducer;