export const urls = {
  API: "https://dedevelopers.org/mindmap/api/",
  PHP_API: "https://dedevelopers.org/mindmap",

  // API: "http://192.168.18.4/mindmap/api/",
  // PHP_API: "http://192.168.18.4/mindmap/",

  // API: "http://localhost/mindmap/api/",
  // PHP_API: "http://localhost/mindmap/",

  // API: "http://192.168.18.80/mindmap/api/",
  // PHP_API: "http://192.168.18.80/mindmap/",

  
};