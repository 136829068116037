// LanguagesSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { GetlanguageRequest } from '../../actions';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success:false,
  commentsusers:[],
};

const AllCommentsSlice = createSlice({
  name: 'allcomments',
  initialState,
  reducers: {
    AllCommentsSliceRequestReducer: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
      },
      AllCommentsSliceSuccessReducer: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
      state.success = true;

      state.commentsusers = action.payload.filter((value, index, self) => {
        // Check if the user is not null or undefined
        if (!value.user) {
          return false;
        }
        // Check if the current index is the first occurrence of this email
        return index === self.findIndex((t) => t.user && t.user.email === value.user.email);
      });
      
    },
    AllCommentsSliceFailerReducer: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },

  },
});

export const { AllCommentsSliceRequestReducer, AllCommentsSliceSuccessReducer, AllCommentsSliceFailerReducer } = AllCommentsSlice.actions;

export default AllCommentsSlice.reducer;

