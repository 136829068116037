import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Earn from "../../assets/images/earn-money.png";
import Learn from "../../assets/images/learn_img.png";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillPlusCircle,
  AiFillQuestionCircle,
  AiOutlineArrowRight,
} from "react-icons/ai";
import {
  IoCloseCircleOutline,
  IoCopyOutline,
  IoHeartOutline,
  IoHeart,
  IoFlagOutline,
  IoFlag,
} from "react-icons/io5";
import {
  FaHandPointRight,
  FaRegClock,
  FaRegCirclePlay,
  FaRegCirclePause,
  FaTrash,
} from "react-icons/fa6";
import { IoMdAttach } from "react-icons/io";

import { BiMessage } from "react-icons/bi";

import { LuClipboardList, LuRepeat } from "react-icons/lu";
import Source from "./../../assets/source.mp3";
import Target from "./../../assets/target.mp3";
import { Tooltip } from "react-tooltip";
import DepositPopup from "../../base-components/Deposit";

function OnBoard() {
  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [heading, setHeading] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(true);
  const [spaces, setSpaces] = useState(true);
  const [play, setPlay] = useState(false);
  const [dictionary, setDictionary] = useState(false);
  const [selected, setSelected] = useState([]);
  const [audioCount, setAudioCount] = useState(0);
  const [audio, setAudio] = useState(null);
  const [isPlaying, setPlaying] = useState(false);
  const [countdown, setCountdown] = useState(false);
  const [count, setCount] = useState(5);
  const [heart, setHeart] = useState(false);
  const [flag, setFlag] = useState(false);
  const [popupdeposit, setPopupDeposit] = useState(false);

  const urldictionary = "https://glosbe.com/en/es/";
  const urldictionarytarget = "https://glosbe.com/es/en/";

  const do_save_langs = async (val) => {
    const isValueInArray = selected.includes(val);
    if (isValueInArray) {
      const newArray = selected.filter((item) => item !== val);
      setSelected(newArray);
    } else {
      setSelected([...selected, val]);
    }
  };

  const sentence = [
    { title: "Do", type: "verbs" },
    { title: "you", type: "pronouns" },
    { title: "see", type: "verbs" },
    { title: "the", type: "articles" },
    { title: "men", type: "nouns" },
    { title: "over", type: "prepositions" },
    { title: "there", type: "adverb" },
  ];

  const actual_sentence = "Siehst du die Männer dort drüben";
  const sentence_target = [
    { title: "Siehst", type: "verbs" },
    { title: "du", type: "pronouns" },
    { title: "die", type: "verbs" },
    { title: "Männer", type: "nouns" },
    { title: "dort", type: "adverb" },
    { title: "drüben", type: "adverb" },
  ];

  const reasonsflag = [
    { title: "mistake in the source language audio", id: 1 },
    { title: "mistake in the target language audio", id: 2 },
    { title: "mistake in the source language", id: 3 },
    { title: "mistake in the target language", id: 4 },
    { title: "words  marked incorrectly", id: 5 },
    { title: "wrong tag (classic, slang, insult)", id: 6 },
    { title: "inappropriate sentence", id: 7 },
    { title: "other", id: 8 },
  ];

  const playAudioSource = () => {
    const newAudio = new Audio(Source);
    newAudio.play();
    setTimeout(() => {
      const newAudio = new Audio(Target);
      newAudio.play();
      setAudio(newAudio);
      newAudio.addEventListener("ended", handleAudioEnded);
    }, 3000);
  };
  const handleAudioEnded = () => {
    setPlaying(false);
    setPlay(false);
    setCountdown(true);
  };
  const pauseAudio = () => {
    if (audio) {
      audio.pause();
    }
  };

  useEffect(() => {
    if (countdown) {
      const intervalId = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
      if (count === 0) {
        clearInterval(intervalId);
        window.location.reload();
      }
      return () => clearInterval(intervalId);
    }
  }, [count, countdown]);

  const copyText = async (val) => {
    navigator.clipboard.writeText(val);
    toast.success("Target sentence copied successfully!");
  };

  const [languages, setLanguages] = useState([]);

  const addLanguage = () => {
    const newLanguage = { id: Date.now(), value: "" };
    setLanguages([...languages, newLanguage]);
  };

  const deleteLanguage = (id) => {
    const updatedLanguages = languages.filter((language) => language.id !== id);
    setLanguages(updatedLanguages);
  };

  const handleLanguageChange = (id, event) => {
    const updatedLanguages = languages.map((language) => {
      if (language.id === id) {
        return { ...language, value: event.target.value };
      }
      return language;
    });
    setLanguages(updatedLanguages);
  };

  return (
    <div className="container">
      <HeaderLogin />
      <div className="wrapper">
        <div className="my_languages_wrap">
          <div className="verifiy_form w60">
            <div className="card wd100 p20 ">
              <div className="form-group">
                <p>
                  In order to keep{" "}
                  <span style={{ color: "var(--primary-color)" }}>
                    Langbook
                  </span>{" "}
                  free, we need people who contribute to the site. Here are some
                  of the ways in which you can get involved:
                </p>

                <div className="">
                  <p>
                    1. <b>Write content in your native language or dialect:</b>{" "}
                    This site would be nothing if people didn’t add any content.
                    You can get verified <Link to="/verification">here </Link>{" "}
                    and even earn some money.
                  </p>
                  <p>
                    2. <b>Donate money:</b> No website can survive without it
                    (unfortunately). You can donate{" "}
                    <Link to='https://www.patreon.com/Langbookproject' 
                    // onClick={() => setPopupDeposit(true)}
                    >here</Link> .{" "}
                    <span> <Link data-tooltip-id="question_new_sentencess"  data-tooltip-html="Donations are divided into three parts. The first chunk is used to pay the fixed costs like server hosting costs, PayPal fees and work that cannot currently be done by volunteers. 80% of what is left after this step is used to pay our contributors. The remaining 20% go into a pot for unexpected costs and further development of the site.">Wondering how donations are used?</Link></span>

                    <Tooltip
                    place={'right'}
                    id="question_new_sentencess"
                    style={{ textAlign:"center", padding:"13px 10px", fontSize:"13px", zIndex:9, width:"300px"  }}
                />
                  </p>

                  
                  

                  <p>
                    3. <b>Contribute your skills:</b> Various tasks, such as
                    creating cover images for courses, translating the
                    interface, and managing social media accounts, are essential
                    for maintaining and developing Langbook. Do you have skills
                    that could benefit the site? Contact us at{" "}
                    <Link to="mailto:support@langbook.org">
                      support@langbook.org
                    </Link>
                    .
                  </p>
                </div>

               
              </div>
            </div>
          </div>
        </div>
      </div>
      {popupdeposit && <DepositPopup setPopupDeposit={setPopupDeposit} />}
    </div>
  );
}

export default OnBoard;
