import { takeLatest, call, put } from "redux-saga/effects";
import {
  CoursesSentenceRequest,
  CoursesSentenceFailure,
  Get_Sou_Request,
  Get_SouAndTarAndSouAudio_Request,
} from "../../Redux/actions";
import { FatchApi } from "../../../utils/FatchApi";
import {
  Get_SouAndTarAndSouAudio_Request_Reducer,
  Get_SouAndTarAndSouAudio_Success_Reducer,
  Get_SouAndTarAndSouAudio_Failer_Reducer,
} from "../../Redux/slices/Sou_Tar_SouAudio";


function* fetchDataSaga(action) {
  try {
    yield put(Get_SouAndTarAndSouAudio_Request_Reducer());
    const data = yield  call(FatchApi,action.payload);
    if (data) {
      yield put(Get_SouAndTarAndSouAudio_Success_Reducer(data));
    }
  } catch (error) {
    yield put(Get_SouAndTarAndSouAudio_Failer_Reducer(error));
  }
}

export default function* Get_SourceAudio() {
  yield takeLatest(Get_SouAndTarAndSouAudio_Request().type, fetchDataSaga);
}
