import { useDispatch } from "react-redux";
import { AllCommentsSliceSuccessReducer } from "../lib/Redux/slices/AllComments";
import { GetCourse } from "../lib/Redux/slices/Course";
import { GetlanguageSuccessReducer } from "../lib/Redux/slices/languagesSlices/languages";
import { Get_Sou_Success_Reducer } from "../lib/Redux/slices/Sou";
import { Get_SouAndTar_Success_Reducer } from "../lib/Redux/slices/Sou_Tar";
import { Get_SouAndTarAndSouAudio_Success_Reducer } from "../lib/Redux/slices/Sou_Tar_SouAudio";
import { Get_SouAndTarAndSouAudioAndTarAudio_Success_Reducer } from "../lib/Redux/slices/Sou_Tar_SouAudio_TarAudio";
import { SentanceFilter_Reducer } from "../lib/Redux/slices/SentanceFilter";
import { NativeLanguage } from "../lib/Redux/slices";
import { NativeLanguage_Reducer } from "../lib/Redux/slices/NativeLanguage";

const useResetReducer = () => {
  const dispatch = useDispatch();

  const SentanceFilter ={
     tag:['classic'],
     manageType:'Only new',
   };

  const resetAllReducers = (nolanguages) => {

    if (nolanguages) {
      dispatch(AllCommentsSliceSuccessReducer([]));
      dispatch(GetCourse([]));
      dispatch(Get_Sou_Success_Reducer([]));
      dispatch(Get_SouAndTar_Success_Reducer([]));
      dispatch(Get_SouAndTarAndSouAudio_Success_Reducer([]));
      dispatch(SentanceFilter_Reducer(SentanceFilter));
       dispatch(SentanceFilter_Reducer([]));
      dispatch(Get_SouAndTarAndSouAudioAndTarAudio_Success_Reducer([]));
      dispatch(NativeLanguage_Reducer([]))
    } else {
      dispatch(SentanceFilter_Reducer([]));
      dispatch(AllCommentsSliceSuccessReducer([]));
      dispatch(GetCourse([]));
      dispatch(Get_Sou_Success_Reducer([]));
      dispatch(Get_SouAndTar_Success_Reducer([]));
      dispatch(Get_SouAndTarAndSouAudio_Success_Reducer([]));
      dispatch(Get_SouAndTarAndSouAudioAndTarAudio_Success_Reducer([]));
      dispatch(SentanceFilter_Reducer(SentanceFilter));
      dispatch(GetlanguageSuccessReducer([]));
      dispatch(NativeLanguage_Reducer([]))
    }
  };

  return resetAllReducers;
};

export default useResetReducer;
