import React, { useEffect, useState } from "react";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LastUpdate_Request } from "../../lib/Redux/actions";
import useToast from "../../hooks/useToast";
import { LastUpdate_Update_Reducer } from "../../lib/Redux/slices/LastUpdate";
import Loader from "../../base-components/Loader/Loader";
import { AiOutlineArrowRight } from "react-icons/ai";
import { HiOutlineSpeakerWave } from "react-icons/hi2";

const CheckSentence = () => {
  const { data, loading } = useSelector((state) => state.AllAvilable);
  const IsDialectCondion = useSelector((state) => state?.IsDialect?.type);
  const LastUpdate = useSelector((state) => state.LastUpdate);

  var SourceVal = JSON.parse(localStorage.getItem("source"));
  var TargetVal = JSON.parse(localStorage.getItem("target"));

  const questions = [
    {
      question: IsDialectCondion
        ? "Is the dialect sentence correct?"
        : "Is the target language sentence grammatically correct?",
      id: 1,
    },
    {
      question: IsDialectCondion
        ? "Is the dialect audio correct and good"
        : "Is the target language audio correct and good?",
      id: 3,
    },
    {
      question: "Is the source language sentence grammatically correct?",
      id: 4,
    },
    { question: "Is the source language audio correct and good?", id: 6 }, // source audio
    { question: "Is the tag set correctly?", id: 7 }, // tag
  ];

  const navigate = useNavigate();
  const location = useLocation();

  // console.log(location,'loc');

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleNextQuestion = (index, type) => {
    console.log(type,'type..');
    if(type === 'back'){
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
    else if (type === "next") {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      navigate("/correct-sentance", {
        state: {
          sentance: location.state,
          question: questions[currentQuestionIndex],
        },
      });
    }
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
    // position:'reletive',
  };

  const questionStyle = {
    fontSize: "1.5em",
    marginBottom: "20px",
  };

  const buttonGroupStyle = {
    display: "flex",
    gap: "10px",
  };

  const buttonStyle = {
    padding: "10px 20px",
    fontSize: "1em",
    cursor: "pointer",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#4caf50",
    color: "white",
    transition: "background-color 0.3s",
  };

  const mistakeButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#f44336",
  };

  const SentanceContainer = {
    // border: "solid 1px lightgray",
    width: "70%",
    marginTop: "120px",
    padding: "20px",
    transform: "translateY(-200px)",
    borderRadius: "15px",
    WebkitBoxShadow: "0px 2px 7px 0px rgba(150,150,150,1)",
    MozBoxShadow: "0px 2px 7px 0px rgba(150,150,150,1)",
    boxShadow: "0px 2px 7px 0px rgb(229,229,229)",
  };

  const handleMouseEnter = (e, color) => {
    e.target.style.backgroundColor = color;
  };

  const handleMouseLeave = (e, color) => {
    e.target.style.backgroundColor = color;
  };

  const dispatch = useDispatch();
  const isDialect = useSelector((state) => state.IsDialect);

  const showToast = useToast();

  const handleFinal = () => {
    // console.log(location?.state?._id,'sss');
    const SentenceId = location?.state?._id;
    const obj = {
      isFinalCheck: true,
      checkSentence: "Final Check",
      isLearner: true,
    };

    console.log(obj, SentenceId, "both");

    try {
      dispatch(
        LastUpdate_Request({
          request: "put",
          data: obj,
          Api: isDialect.type
            ? `/api/v1/sentences-dia/${SentenceId} `
            : `/api/v1/sentences/${SentenceId}`,
        })
      );
    } catch (err) {
      console.error("Error updating sentence:", err);
    }
  };

  useEffect(() => {
    console.log(LastUpdate,'last update');
    if (LastUpdate?.success == true ) {
       setTimeout(() => {
        showToast("The sentence has been successfully added to the course", "success");
        navigate("/earn");
        dispatch(LastUpdate_Update_Reducer());
      }, 2000);
    }
    if(LastUpdate.iserror == true ){
      console.log(LastUpdate,'data of error');
      setTimeout(() => {
        showToast(LastUpdate && LastUpdate?.data?.data?.message || 'Operation Failed', "error");
        navigate("/");
        dispatch(LastUpdate_Update_Reducer());
      }, 2000);
    }
  }, [LastUpdate && LastUpdate?.success || LastUpdate && LastUpdate?.iserror]);

  const handlePlay = (type) => {
    if (type == "target") {
      const targetAudio = new Audio(data[0]?.targetAudio);
      targetAudio.play();
    } else {
      const sourceAudio = new Audio(data[0]?.sourceAudio);
      sourceAudio.play();
    }
  };

  return (
    <>
      <HeaderLogin />
      <div style={containerStyle}>
        <div style={SentanceContainer}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "0px",
              // border:'solid 1px red',
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {!IsDialectCondion ? (
                <img
                  style={{ height: "24px", width: "24px" }}
                  src={SourceVal && SourceVal?.flag}
                />
              ) : (
                ""
              )}
              <p style={{ marginLeft: "7px" }}>
                {SourceVal && SourceVal?.code}
              </p>
            </div>

            <AiOutlineArrowRight style={{ marginLeft: "12px" }} />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "12px",
              }}
            >
              {!IsDialectCondion ? (
                <img
                  style={{ height: "24px", width: "24px" }}
                  src={TargetVal && TargetVal?.flag}
                />
              ) : (
                ""
              )}
              <p style={{ marginLeft: "7px" }}>
                {TargetVal && TargetVal?.code}
              </p>
            </div>
          </div>

          <h5 style={{ fontSize: "12px" }}>Source</h5>

          {loading && loading ? (
            <Loader />
          ) : (
            <>
              {/* Target */}
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems:'center',
                  border:
                    currentQuestionIndex == 2
                      ? "solid 1px black"
                      : "solid 1px lightgray",
                  padding: "16px ",
                  borderRadius: "14px",
                }}
              >
                {!IsDialectCondion ? (
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src={SourceVal && SourceVal?.flag}
                  />
                ) : (
                  ""
                )}
                <div style={{display:'flex',width:'92%',overflowX:'auto',padding:'5px'}} >
                {data &&
                  data[0]?.targetContentArray?.map((items) => {
                    return (
                      <>
                        <h2
                          className={`${items.type}`}
                          style={{ marginLeft: "7px" }}
                        >
                          {items.title}
                        </h2>
                      </>
                    );
                  })}
                </div>
                {!IsDialectCondion ? (
                  <img
                    style={{ height: "20px", width: "20px", marginLeft: "6px" }}
                    src={SourceVal && SourceVal?.flag}
                  />
                ) : (
                  ""
                )}

                <span
                  style={{
                    marginLeft: "8px",
                    marginTop: "2px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    height: "26px",
                    width: "26px",
                    border:
                      currentQuestionIndex == 3
                        ? "solid 1px black"
                        : "solid 1px transparent",
                  }}
                >
                  <HiOutlineSpeakerWave
                    onClick={() => handlePlay("source")}
                    style={{
                      // marginLeft: "8px",
                      fontSize: "18px",
                      // marginTop: "2px",
                      cursor: "pointer",
                    }}
                  />
                </span>
              </div>

              {/* Source */}

              <h4 style={{ marginTop: "14px" }}>Target </h4>

              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems:'center',
                  border:
                    currentQuestionIndex == 0
                      ? "solid 1px black"
                      : "solid 1px lightgray",
                  padding: "16px ",
                  borderRadius: "14px",
                }}
              >
                {!IsDialectCondion ? (
                  <img
                    style={{ height: "24px", width: "24px" }}
                    src={TargetVal && TargetVal?.flag}
                  />
                ) : (
                  ""
                )}
                    <div style={{display:'flex',width:'92%',overflowX:'auto',padding:'5px',}} >
                {data &&
                  data[0]?.sourceContentArray?.map((items) => {
                    return (
                      <>
                        <h2
                          className={`${items.type}`}
                          style={{ marginLeft: "7px" }}
                        >
                          {items.title}
                        </h2>
                      </>
                    );
                  })}
                    </div>
                {!IsDialectCondion ? (
                  <img
                    style={{ height: "24px", width: "24px", marginLeft: "6px" }}
                    src={TargetVal && TargetVal?.flag}
                  />
                ) : (
                  ""
                )}

                <span
                  style={{
                    marginLeft: "8px",
                    marginTop: "2px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    height: "26px",
                    width: "26px",
                    border:
                      currentQuestionIndex == 1
                        ? "solid 1px black"
                        : "solid 1px transparent",
                  }}
                >
                  <HiOutlineSpeakerWave
                    onClick={() => handlePlay("target")}
                    style={{
                      // marginLeft: "8px",
                      fontSize: "18px",
                      // marginTop: "2px",
                      cursor: "pointer",
                    }}
                  />
                </span>
              </div>
                <div style={{marginTop:'15px',display:'flex',width:'100%',justifyContent:'center',alignItems:'center',flexWrap:'wrap'}} >
                  {
                     data[0]?.latinContentArray?.map((latin)=>{
                      return (
                        <div style={{marginLeft:'4px',fontSize:'18px'}} className={latin?.type} >
                          {latin?.title}
                          </div>
                      )
                     })
                  }
                </div>
            </>
          )}
          {/* ////// */}

          <div
            style={{
              padding: "5px",
              fontSize: "12px",
              border:
                currentQuestionIndex == 4
                  ? "solid 1px black"
                  : "solid 1px lightgray",
              display: "flex",
              width: "max-content",
              borderRadius: "8px",
              marginTop: "25px",
            }}
          >
            {loading && loading ? <Loader /> : data[0]?.tag}
          </div>
        </div>

        {currentQuestionIndex < questions.length ? (
          <>
            <h2 style={questionStyle}>
              {questions[currentQuestionIndex].question}
            </h2>
            <div style={buttonGroupStyle}>
              <button
                onClick={() => handleNextQuestion(currentQuestionIndex, "next")}
                style={buttonStyle}
                onMouseEnter={(e) => handleMouseEnter(e, "#45a049")}
                onMouseLeave={(e) => handleMouseLeave(e, "#4caf50")}
              >
                Yes
              </button>
              <button
                onClick={() => handleNextQuestion(currentQuestionIndex, "no")}
                style={mistakeButtonStyle}
                onMouseEnter={(e) => handleMouseEnter(e, "#e53935")}
                onMouseLeave={(e) => handleMouseLeave(e, "#f44336")}
              >
                No
              </button>
            </div>
          </>
        ) : (
          <>
            <p
              style={{
                fontSize: "18px",
                color: "black",
                marginTop: "-120px",
                width: "40%",
                fontWeight:'500'
              }}
            >
              Is this sentence ready to be published? This is your last chance
              to report any errors before the sentence is added to the course.
            </p>

            <div style={{display:'flex',gap:'10px'}} >
              <button
                style={{
                  backgroundColor: "red",
                  color: "white",
                  border: "none",
                  padding: "10px",
                  outline: "none",
                  borderRadius: "5px",
                  width: "75px",
                  cursor:'pointer',
                }}
                onClick={() => handleNextQuestion(currentQuestionIndex,'back')}
              >
                No
              </button>

              <button
                onClick={() => handleFinal()}
                style={buttonStyle}
                onMouseEnter={(e) => handleMouseEnter(e, "#45a049")}
                onMouseLeave={(e) => handleMouseLeave(e, "#4caf50")}
              >
                Finish
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CheckSentence;
