import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isAuthenticated: false,
  isLearn: false,
  TempForgotEmail: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    clearUser: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
    setIsLearn: (state) => {
      state.isLearn = true;
    },
    setIsEarn: (state) => {
      state.isLearn = false;
    },
    setTempEmail: (state, action) => {
      state.TempForgotEmail = action.payload;
    },
    clearTemp: (state) => {
      state.TempForgotEmail = "";
    },
  },
});

export const {
  setUser,
  clearUser,
  setIsLearn,
  setIsEarn,
  setTempEmail,
  clearTemp,
} = userSlice.actions;
export const selectUser = (state) => state.user.user;
export default userSlice.reducer;
