import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Earn from "../../assets/images/earn-money.png";
import Learn from "../../assets/images/learn_img.png";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillPlusCircle,
  AiFillQuestionCircle,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaHandPointRight, FaRegClock } from "react-icons/fa6";
import { LuClipboardList, LuRepeat } from "react-icons/lu";
import { Tooltip } from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../base-components/Loader/Loader";
import { Get_WordArray_Request } from "../../lib/Redux/actions";
import useToast from "../../hooks/useToast";
import Sou from "../../lib/Redux/slices/Sou";

function OnBoard() {
  var SourceVal = JSON.parse(localStorage.getItem("source"));
  var TargetVal = JSON.parse(localStorage.getItem("target"));

  useEffect(() => {
    SourceVal = JSON.parse(localStorage.getItem("source"));
    TargetVal = JSON.parse(localStorage.getItem("target"));
  }, [SourceVal?.code, TargetVal?.code]);

  const showToast = useToast();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const IsDialectCondition = useSelector((state) => state.IsDialect);
  const RightToLeft = useSelector((state) => state.RightToLeft.data);

  // console.log(RightToLeft,'left');

  const location = useLocation();
  const state = location.state;

  const [LatinStatus, setLatinStatus] = useState();

  const Source = useSelector((state) => state.Source);
  const { user } = useSelector((state) => state.user);
  const nativeLanguage = useSelector((state) => state.NativeLanguage);
  const WordArray = useSelector((state) => state.WordArray);
  const course = useSelector((state) => state.Course);

  console.log(Source?.data?.length, "source data");

  // console.log(loading,'loading...');

  const [TargetRoute, setTargetRoute] = useState(
    state?.TargetSentance && state?.TargetSentance
  );

  const [latin, setLatin] = useState();

  console.log(TargetRoute, "check if or not ");

  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [heading, setHeading] = useState("");
  const [password, setPassword] = useState("");
  // const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);

  const [selected, setSelected] = useState([]);

  const [sentencwritten, setSentenceWritten] = useState([]);
  const [navigateSpace, setNavigateSpace] = useState(false);
  const [next, setNext] = useState(false);
  const [first, setFirst] = useState(false);
  const [Count, setCount] = useState();

  // const [localdata,setLocaldata]=useState()

  const LocalData = JSON.parse(localStorage.getItem("TargetData"));

  useEffect(() => {
    if (!TargetRoute) {
      dispatch(
        Get_WordArray_Request({
          user: user,
          request: "get",
          data: "",
          Api: IsDialectCondition.type
            ? `/api/v1/sentence-words-dia?courseId=${state?.courseId}`
            : `/api/v1/sentence-words?courseId=${state?.courseId}`,
        })
      );
    }
  }, []);

  // useEffect(() => {
  //   if (state?.courseId) {
  //   } else {
  //     showToast("Not allow", "warning");
  //   }
  // }, []);

  const show_popup = async (val) => {
    setHeading(val);
    setPopup(true);
  };

  const [box, setBox] = useState(null);

  const do_next_page = async () => {
    console.log("this play", latin);

    if (course?.data[0]?.alphabets && TargetRoute) {
      if (latin === undefined || latin?.length <= 0) {
        showToast("Please Enter Sentence of Latin Alphabet", "error");
        return;
      }
    }

    if (!next && course?.data[0]?.spaces) {
      showToast("Space is not allowed", "info");
      return;
    }

    if (sentencwritten[0]?.title == null || sentencwritten[0]?.title == "") {
      showToast("please type a sentence", "error");
      return;
    }

    if (sentencwritten != "" && sentencwritten.length >= 1) {
      if (!TargetRoute && box == null) {
        showToast("Please Select a Tag", "error");
        return;
      }

      if (TargetRoute) {
        !course?.data[0]?.spaces
          ? navigate("/step/three", {
              state: {
                TargetSentance: TargetRoute,
                inputValue: sentencwritten,
                checkbox: box,
                latin: latin,
              },
            })
          : navigate("/add/space", {
              state: {
                TargetSentance: TargetRoute,
                inputValue: sentencwritten,
                checkbox: box,
                latin: latin,
              },
            });
      } else {
        if (Source?.data?.length >= 0) {
          console.log(Count, "dfdf");

          if (Count?.length >= 1 && Count?.length <= 2) {
            console.log(Count?.length, "count value");
            !course?.data[0]?.spaces
              ? navigate("/step/three", {
                  state: {
                    TargetSentance: TargetRoute,
                    inputValue: sentencwritten,
                    checkbox: box,
                    latin: latin,
                  },
                })
              : navigate("/add/space", {
                  state: {
                    TargetSentance: TargetRoute,
                    inputValue: sentencwritten,
                    checkbox: box,
                    latin: latin,
                  },
                });
          } else {
            showToast(
              "You have to add at least 1, but not more than 2 new words",
              "error"
            );
          }
        } else {
          // console.log(Count?.length, TargetRoute, "count value");

          console.log(Count?.length, "count value");
          !course?.data[0]?.spaces
            ? navigate("/step/three", {
                state: {
                  TargetSentance: TargetRoute,
                  inputValue: sentencwritten,
                  checkbox: box,
                  latin: latin,
                },
              })
            : navigate("/add/space", {
                state: {
                  TargetSentance: TargetRoute,
                  inputValue: sentencwritten,
                  checkbox: box,
                  latin: latin,
                },
              });
        }
      }
    } else {
      showToast("please type a sentence", "warning");
    }
  };

  const [newArr, setnewArr] = useState([]);

  function do_show_new_old(e) {
    if (
      e.target.value === "" ||
      e.target.value === null ||
      e.target.value === undefined
    ) {
      setSentenceWritten([]);
      return;
    }
    let newValue = e.target.value;

    if (newValue.includes(" ") && course?.data[0]?.spaces) {
      setNext(false);
      showToast("Space not allowed in this course", "info");
      return;
    }

    setNext(true);

    const textareaContent = e.target.value;
    const wordsArray = textareaContent.split(/\s+/);

    var data = WordArray?.data?.sentenceWords?.sentenceWords || [];

    let formattedArray = [];
    var count = [];

    // Updated regex to match Unicode word characters
    const specialCharRegex = /[^\p{L}\p{N}]/gu;

    const cleanedData = data.map((word) =>
      word.replace(specialCharRegex, "").toLowerCase()
    );

    wordsArray.forEach((inputWord) => {
      const cleanedWord = inputWord.replace(specialCharRegex, "").toLowerCase();
      const existsInData = cleanedData.includes(cleanedWord);
      formattedArray.push({ title: inputWord, pre: existsInData });
    });

    count = formattedArray.filter((items) => items.pre === false);

    console.log(count, "count in if");

    setCount(count);
    setnewArr(formattedArray);
    setSentenceWritten(formattedArray);

    return formattedArray;
  }

  const handleShow = () => {
    showToast(
      `Not Allowed , you have ${Count?.length} in your Sentance `,
      "warning"
    );
  };

  var message = "";

  if (IsDialectCondition.type) {
    message = TargetRoute
      ? course?.data?.[0]?.alphabets
        ? "Please translate the sentence and transliterate it"
        : "Please translate the sentence into the standard language"
      : "Please add a sentence in the dialect";
  } else {
    message = TargetRoute
      ? course?.data?.[0]?.alphabets
        ? "Please translate the sentence and transliterate it"
        : "Please translate the sentence into the standard language"
      : "Please add a sentence in the target language";
  }

  const showTextContent = () => {
    if (first) {
      return "Please translate this sentence into the target language. <br />Then click on ‘next’.";
    } else {
      if (IsDialectCondition?.type) {
        return `
          <b  > Please write the sentence EXACTLY like you would say it, even if it would be considered poor spelling. </b> 
          Please write a sensible sentence that you can imagine using in real life. Please make sure that the sentence is <br />
          suitable for the current stage of the course. To aid you with this, you can see the last 10 sentences<br /> 
          of the course on the left hand side. Please make sure that you use a wide range of sentence structures.<br /> 
          Every sentence has to contain no less than one, no more than two new words. Please make sure that the sentence is culturally<br /> 
          adequate or culturally neutral. A sentence like \`Mr Tanaka works in Tokyo\` is fine for the Japanese course,<br /> 
          but not for the French one. Respect the student and write sentences that you would want to learn<br /> 
          if you were learning this language. If you use a numeral, please write it both with letters and with numbers,<br /> 
          e.g. \`the second (2.) day\` or \`five hundred and eighteen (518) euros (€)\`.<br /> 
          If the sentence contains a question, please provide an answer as well, e.g. \`Have you done your homework yet? No, I haven't.\`<br /> 
          Failure to apply with these points may lead to a strike and can get your account blocked.<br /><br /> 
          Please select the correct tag. Select 'classic' for sentences that use correct language,<br /> 
          e.g. \`They ran away when they heard the police.\`<br /> 
          Select 'slang' if the sentence contains words that are very informal,<br /> 
          e.g. \`He went absolutely bonkers when the cops tried to arrest him.\`<br /> 
          Select 'insult' for sentences that use swear words or are otherwise impolite, e.g. \`Get lost, you fat bastard!\`<br /> 
          The use of artificial intelligence is encouraged, however you are ultimately responsible for what you submit.
        `;
      } else {
        return `
          Please write a sensible sentence that you can imagine using in real life. Please make sure that the sentence is <br />
          suitable for the current stage of the course. To aid you with this, you can see the last 10 sentences<br /> 
          of the course on the left hand side. Please make sure that you use a wide range of sentence structures.<br /> 
          Every sentence has to contain no less than one, no more than two new words. Please make sure that the sentence is culturally<br /> 
          adequate or culturally neutral. A sentence like \`Mr Tanaka works in Tokyo\` is fine for the Japanese course,<br /> 
          but not for the French one. Respect the student and write sentences that you would want to learn<br /> 
          if you were learning this language. If you use a numeral, please write it both with letters and with numbers,<br /> 
          e.g. \`the second (2.) day\` or \`five hundred and eighteen (518) euros (€)\`.<br /> 
          If the sentence contains a question, please provide an answer as well, e.g. \`Have you done your homework yet? No, I haven't.\`<br /> 
          Failure to apply with these points may lead to a strike and can get your account blocked.<br /><br /> 
          Please select the correct tag. Select 'classic' for sentences that use correct language,<br /> 
          e.g. \`They ran away when they heard the police.\`<br /> 
          Select 'slang' if the sentence contains words that are very informal,<br /> 
          e.g. \`He went absolutely bonkers when the cops tried to arrest him.\`<br /> 
          Select 'insult' for sentences that use swear words or are otherwise impolite, e.g. \`Get lost, you fat bastard!\`<br /> 
          The use of artificial intelligence is encouraged, however you are ultimately responsible for what you submit.
        `;
      }
    }
  };
  
 
  return !TargetRoute && WordArray.loading ? (
    <div
      style={{
        width: "100%",
        height: "75vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader />
    </div>
  ) : (
    <div className="container">
      <HeaderLogin />
      <div className="wrapper">
        <div className="language_courses_heading">
          {nativeLanguage && user && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                {/* /// */}
                <span>
                  <span>
                    {IsDialectCondition?.type ? (
                      ""
                    ) : (
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src={SourceVal && SourceVal?.flag}
                        alt="User Language Flag"
                      />
                    )}
                  </span>{" "}
                  {SourceVal?.code}
                </span>
                {/* /// */}
                <AiOutlineArrowRight /> {/* /// */}
                <span>
                  <span>
                    {IsDialectCondition?.type ? (
                      ""
                    ) : (
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src={IsDialectCondition?.type ? "" : TargetVal.flag}
                        alt="Native Language Flag"
                      />
                    )}
                  </span>{" "}
                  {IsDialectCondition?.type
                    ? course?.data[0]?.targetLanguage?.name
                    : TargetVal.code}{" "}
                </span>{" "}
                {/* /// */}
              </div>
            </>
          )}
        </div>

        <div className="my_languages_wrap">
          <div className="box_left_add_ card">
            <div className="heading_languages mb-0">
              <div className="">Previous Sentences</div>
            </div>
            <div className="p10 previous_sentences">
              {!first ? (
                (TargetRoute && Source?.data?.length <= 0) ||
                Source?.data?.length <= 0 ? (
                  "No Data Available"
                ) : !Source?.loading ? (
                  course?.data[0]?.spaces ? (
                    Source?.data?.map((v, i) => (
                      <>
                        <div className="senetence_">
                          <span>#{i + 1}</span>
                          <span style={{  wordBreak: 'break-word' }} >{v.sourceContent.split(" ").join("")}</span>
                        </div>
                      </>
                    ))
                  ) : (
                    Source?.data?.map((v, i) => (
                      <>
                        <div className="senetence_">
                          <span>#{i + 1}</span>
                          <span style={{  wordBreak: 'break-word' }} >{v.sourceContent}</span>
                        </div>
                      </>
                    ))
                  )
                ) : (
                  <Loader />
                )
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="box_left_add_">
            <div className="card p15 wd100 text-center">{message}</div>
            <div className="card p20 mt-10px">
              <div className="sentence_source"  style={{maxWidth:'100%',display:'flex',flexWrap:'wrap',maxHeight:'124%',overflowY:'auto'}} >
                {TargetRoute ? (
                  <>
                    {LocalData?.sourceContentArray?.map((items) => {
                      return (
                        <>
                          <div
                            className={`${items.type}`}
                            style={{ marginLeft: "7px" }}
                          >
                            {items.title}
                          </div>
                        </>
                      );
                    })}
                    {/* <div style={{ display: "flex" }}>
                      {!Source.loading ? (
                        course?.data[0]?.spaces ? (
                          Source.data[0]?.sourceContentArray?.map((items) => {
                            return (
                              <>
                                <div className={`${items.type}`}>
                                  {items.title}
                                </div>
                              </>
                            );
                          })
                        ) : LocalData ?  ? (
                          "no data found"
                        ) : (
                          Source.data[0]?.sourceContentArray?.map((items) => {
                            return (
                              <>
                                <div
                                  className={`${items.type}`}
                                  style={{ marginLeft: "7px" }}
                                >
                                  {items.title}
                                </div>
                              </>
                            );
                          })
                        )
                      ) : (
                        <Loader />
                      )}
                    </div> */}
                  </>
                ) : (
                  <>
                    <div className="words_source_flex_data" style={{maxWidth:'100%',display:'flex',flexWrap:'wrap',maxHeight:'124%',overflowY:'auto'}} >
                      {newArr.length > 0 &&
                        newArr?.map((v, i) => (
                          <>
                            <div
                              className={`${v.type}`}
                              style={{
                                color: `${v.pre ? "black" : "#10D73D"}`,
                              }}
                            >
                              {v.title}
                            </div>
                          </>
                        ))}
                    </div>
                  </>
                )}
              </div>

              <div className="add_sentence_source">
                {/* //// */}
                <div style={{ display: "flex", alignItems: "center", gap: "7px" }} >
                  {
                    IsDialectCondition?.type ? "" :
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src={
                      TargetRoute
                        ? SourceVal && SourceVal?.flag
                        : TargetVal && TargetVal?.flag
                    }
                    alt="User Language Flag"
                  />
                  }
                  <textarea
                    value={course?.data[0]?.space && newArr}
                    dir={
                      TargetRoute
                        ? RightToLeft?.Source && "rtl"
                        : RightToLeft?.Target && "rtl"
                    }
                    style={{ justifyContent: "flex-end", }}
                    disabled={
                      // Source?.data?.length <= 0 && TargetRoute ? true : false
                      false
                    }
                    //  style={{border:'solid red 1px'}}
                    name="add_sentence"
                    className="form-control-less"
                    placeholder={
                      TargetRoute ? "Type the translation here" : "Type here"
                    }
                    // value={sentencwritten.title}
                    onChange={(e) => do_show_new_old(e)}
                  ></textarea>

                  {
                    IsDialectCondition?.type ? '' : 
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src={
                      TargetRoute
                        ? SourceVal && SourceVal?.flag
                        : TargetVal && TargetVal?.flag
                    }
                    alt="User Language Flag"
                  />
                  }
                </div>
                {/* //// */}
                {course?.data[0]?.alphabets && TargetRoute ? (
                  <textarea
                    // style={{border:'solid 1px red'}}
                    name="add_sentence"
                    className="form-control-less"
                    placeholder="Rewrite the original sentence into the Latin alphabet"
                    onChange={(e) => setLatin(e.target.value)}
                  ></textarea>
                ) : (
                  ""
                )}
              </div>

              <div
                className="question_mark_"
                data-tooltip-id="question_new_sentence"
                data-tooltip-html={
                  showTextContent()
                }
              >
                <AiFillQuestionCircle />
              </div>
              <Tooltip
                id="question_new_sentence"
                style={{
                  textAlign: "left",
                  padding: "13px 10px",
                  fontSize: "13px",
                  zIndex: 9,
                }}
              />
            </div>
            {!TargetRoute && (
              <div className="flex-space-between show_old_new">
                <div className="blacktext">Old Words</div>
                {Count?.length > 2 && (
                  <p
                    style={{ color: "red", textAlign: "center", width: "60%" }}
                  >
                    {/* you not allowed to add more than 2 new words , but now you
                    have {Count?.length} is avilable in your sentance{" "} */}
                    You have to add at least 1, but not more than 2 new words
                  </p>
                )}
                <div className="green">New Words</div>
              </div>
            )}
          </div>

          <div className="box_left_add_ tags_button_next flex-space-between  p20">
            {!TargetRoute ? (
              <div className="tags_left">
                {!IsDialectCondition?.type ? (
                  <>
                    <label>
                      {first ? (
                        <input
                          onChange={(e) => setBox(e.target.value)}
                          type="radio"
                          value={"classic"}
                          checked
                          name="tag_show"
                        />
                      ) : (
                        <input
                          onChange={(e) => setBox(e.target.value)}
                          type="radio"
                          value={"classic"}
                          name="tag_show"
                        />
                      )}
                      Classic
                    </label>
                    <label>
                      <input
                        onChange={(e) => setBox(e.target.value)}
                        type="radio"
                        value={"slang"}
                        name="tag_show"
                        disabled={first ? "disabled" : ""}
                      />
                      Slang
                    </label>
                  </>
                ) : (
                  <label>
                    {first ? (
                      <input
                        onChange={(e) => setBox(e.target.value)}
                        type="radio"
                        value={"classic"}
                        checked
                        name="tag_show"
                      />
                    ) : (
                      <input
                        onChange={(e) => setBox(e.target.value)}
                        type="radio"
                        value={"classic"}
                        name="tag_show"
                      />
                    )}
                    Classic / Slang
                  </label>
                )}
                <label>
                  <input
                    onChange={(e) => setBox(e.target.value)}
                    type="radio"
                    value={"insult"}
                    name="tag_show"
                    disabled={first ? "disabled" : ""}
                  />
                  Insult
                </label>
              </div>
            ) : (
              ""
            )}

            <div className="button_tags">
              {/* {course?.data[0]?.alphabets ? (
                <button
                  style={{ marginBottom: "20px"}}
                  className="button_custom_med secondary"
                  onClick={() => navigate(-1)}
                >
                  <AiOutlineArrowLeft /> Back{" "}
                </button>
              ) : (
                ""
              )} */}

              {TargetRoute && !LocalData ? (
                <>
                  <button
                    className="button_custom_med primary"
                    onClick={() =>
                      showToast(
                        "No Content for Add the Target Sentance",
                        "error"
                      )
                    }
                  >
                    Next
                    <AiOutlineArrowRight />
                  </button>
                </>
              ) : (
                <>
                  <button
                  style={{ marginBottom: "20px" }}
                  className="button_custom_med secondary"
                  onClick={() => navigate(-1)}
                >
                  <AiOutlineArrowLeft /> Back{" "}
                </button>

                  <button
                    className="button_custom_med primary"
                    onClick={() =>
                      !TargetRoute
                        ? Count?.length > 2
                          ? handleShow()
                          : do_next_page()
                        : do_next_page()
                    }
                  >
                    Next
                    <AiOutlineArrowRight />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnBoard;
