import React, { useState } from "react";
import useAuth from "./../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import {
  AiFillQuestionCircle,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { HiOutlineSpeakerWave } from "react-icons/hi2";

import { Tooltip } from "react-tooltip";
import Flag1 from "../../assets/images/eng.png";
import Flag2 from "../../assets/images/russia.png";
import Source from "./../../assets/source.mp3";
import Target from "./../../assets/target.mp3";

const sentences_lis = [
  {
    title: "Das Wetter ist gut heute.",
    tag: "classic",
    id: 1,
    done: 1,
    here: 0,
  },
  {
    title: "Das Wetter war sehr gut.",
    tag: "classic",
    id: 2,
    done: 1,
    here: 0,
  },
  {
    title: "Das Essen war sehr lecker.",
    tag: "slang",
    id: 3,
    done: 1,
    here: 0,
  },
  {
    title: "Das Fressen war sau lecker.",
    tag: "classic",
    id: 4,
    done: 1,
    here: 0,
  },
];

const source_text = [
  { title: "The", type: "" },
  { title: "weather", type: "" },
  { title: "is", type: "" },
  { title: "good", type: "" },
  { title: "today", type: "" },
];

const target_text = [
  { title: "Погода", type: "" },
  { title: "сегодня", type: "" },
  { title: "хорошая", type: "" },
];
function OnBoard() {
  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [heading, setHeading] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);

  const [sourcetext, setSourceText] = useState(source_text);
  const [targettext, setTargetText] = useState(target_text);

  const [sourceselected, setSourceSelected] = useState("");
  const [targetselected, setTargetSelected] = useState("");

  const [selected, setSelected] = useState([]);
  const [audio, setAudio] = useState(null);

  const show_popup = async (val) => {
    setHeading(val);
    setPopup(true);
  };

  const do_next_page = async () => {
    navigate("/step/three");
  };

  const do_source_click = async (v) => {
    setTargetSelected("");
    if (sourceselected == v.title) {
      setSourceSelected("");
    } else {
      setSourceSelected(v.title);
    }
  };

  const do_target_click = async (v) => {
    setSourceSelected("");
    if (targetselected == v.title) {
      setTargetSelected("");
    } else {
      setTargetSelected(v.title);
    }
  };

  const handleLangwords = (type) => {
    if (sourceselected) {
      var selectedIndex = sourcetext.findIndex(
        (item) => item.title === sourceselected
      );
    } else {
      var selectedIndex = targettext.findIndex(
        (item) => item.title === targetselected
      );
    }

    if (selectedIndex !== -1) {
      if (sourceselected) {
        const updatedSourceText = [...sourcetext];
        updatedSourceText[selectedIndex].type = type;
        setSourceText(updatedSourceText);
      } else {
        const updatetargettext = [...targettext];
        updatetargettext[selectedIndex].type = type;
        setTargetText(updatetargettext);
      }
      setSourceSelected("");
      setTargetSelected("");
    }
  };

  const do_play_audio = () => {
    const newAudio = new Audio(Target);
    newAudio.pause();
    newAudio.play();
    setAudio(true);
  };
  return (
    <div className="container">
      <HeaderLogin />
      <div className="wrapper">
        <div className="langugae_courses_heading">
          <span>
            <span>
              <img src={Flag1} />
            </span>{" "}
            {"EN"}{" "}
          </span>{" "}
          <AiOutlineArrowRight />{" "}
          <span>
            <span>
              <img src={Flag2} />
            </span>{" "}
            {"RU"}
          </span>
        </div>
        <div className="my_languages_wrap">
          <div className="box_left_add_ card">
            <div className="heading_languages mb-0">
              <div className="">Previous Sentences</div>
            </div>
            <div className="p10 previous_sentences">
              {sentences_lis.map((v, i) => (
                <>
                  <div className="senetence_">
                    <span>#{i + 1}</span>
                    <span>{v.title}</span>
                  </div>
                </>
              ))}
            </div>
          </div>

          <div className="box_left_add_">
            <div className="card p15 wd100 text-center">
              Is the target language sentence gramatically correct?
            </div>
            <div className="card p20 mt-10px">
              <div className="sentence_source flex-startpointer text_user_show flex-space-between">
                <span>
                  <img src={Flag2} />
                </span>
                <span>Siehst du die Männer dort drüben?</span>
                <span className="pointer" onClick={() => do_play_audio()}>
                  <HiOutlineSpeakerWave />
                </span>
              </div>

              <div className="sentence_source flex-start  mt-10px  pointer text_user_show flex-space-between">
                <span>
                  <img src={Flag1} />
                </span>
                <span>Do you see the men over there?</span>
                <span>
                  <img src={Flag1} />
                </span>
              </div>

              <div
                className="question_mark_"
                data-tooltip-id="question_new_sentence"
                data-tooltip-html="Please check that the target language, the source language, the tag, the target audio and the source audio are correct, that the words are marked correctly and that the sentence is adequate."
              >
                <AiFillQuestionCircle />
              </div>
              <Tooltip
                id="question_new_sentence"
                style={{
                  textAlign: "center",
                  padding: "13px 10px",
                  fontSize: "13px",
                  zIndex: 9,
                  width: "350px",
                }}
              />
            </div>

            <div className="checks_buttons flex-space-between">
              <button
                className="yes_button button_custom_med"
                onClick={() => navigate("/earn")}
              >
                YES
              </button>
              <button
                className="maybe_button button_custom_med dark_white"
                onClick={() => navigate("/earn")}
              >
                Pass
              </button>
              <button
                className="no_button button_custom_med"
                onClick={() => navigate("/report/mistake")}
              >
                NO
              </button>
            </div>
          </div>

          <div
            className="box_left_add_ tags_button_next flex-space-between  p20"
            style={{ flexDirection: "column" }}
          >
            <div className="flex-space-between gap_20">
              {/* <div className="tags_left">
                    <label>
                    <input type="checkbox" val={1} checked />
                    Classic
                    </label>
                </div> */}

              <div className="button_tags">
                <div style={{ marginBottom: "50px", color: "#fff" }}>...</div>
                <button
                  className="button_custom_med secondary"
                  onClick={() => navigate(-1)}
                >
                  <AiOutlineArrowLeft /> Back{" "}
                </button>
              </div>
            </div>
            {/* <div className="langword_bottom custom_mar" >
                {
                    LanguagesWords().map((v,i)=>(
                        <>
                            <Link to={v.link} target="_blank" onClick={()=>handleLangwords(v.type)} key={'word_'+i} className={v.type}>{v.title}</Link>
                        </>
                    ))
                }
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnBoard;
